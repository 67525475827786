import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import { ListResources } from './ListResources';
import { ResourceDetails } from './ResourceDetails';
import { Profile } from './Profile';
import { EditProfile } from './EditProfile';
import { EditResource } from './EditResource';
import { CreateManifest } from '../Manifest/CreateManifest';
import { SortYourCrew } from '../Manifest/SortYourCrew';
import { VehicleList } from '../Manifest/VehicleList';
import { Finalize } from '../Manifest/Finalize';
import { ViewManifest } from '../Manifest/ViewManifest';
import '../assets/styles/resource.css';

class Resources extends React.Component {
  constructor(props) {
    super(props);

    var step, id;
    if (this.props.match.path === '/resources/:id') {
      step = 'Details';
      id = this.props.match.params.id;
    } else if (this.props.match.path === '/resource-profile/:hash') {
      step = 'Profile';
      id = this.props.match.params.hash;
    } else {
      step = 'List';
      id = '';
    }
    this.state = {
      mainHtml: '',
      step: step,
      button: '',
      props: id,
      hash: '',
      id: '',
      resource_id: '',
      manifest_id: '',
      contract_number_id: '',
      prevtype: '',
      type: '',
      prev: false,
      vPrev: false,
      edit: false,
      permissions: [],
      redirect: '',
    };
    this.changeTab = this.changeTab.bind(this);
    this.profile = this.profile.bind(this);
    this.editResource = this.editResource.bind(this);
    this.details = this.details.bind(this);
    this.createManifest = this.createManifest.bind(this);
    this.yourCrew = this.yourCrew.bind(this);
    this.goToType = this.goToType.bind(this);
    this.toVehicle = this.toVehicle.bind(this);
    this.goToCrew = this.goToCrew.bind(this);
    this.finalize = this.finalize.bind(this);
    this.goToVehicle = this.goToVehicle.bind(this);
    this.editManifest = this.editManifest.bind(this);
    this.viewManifest = this.viewManifest.bind(this);
    this.goBackDetails = this.goBackDetails.bind(this);
    this.editProfile = this.editProfile.bind(this);
  }

  changeTab(tab) {
    this.setState({ step: tab }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  details(props) {
    this.setState(
      { step: 'Details', props: props, edit: false, prev: false },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  profile(hash) {
    this.setState({ step: 'Profile', hash: hash }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editProfile(hash) {
    this.setState({ step: 'EditProfile', hash: hash }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editResource(id) {
    this.setState({ step: 'Edit', id: id }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  createManifest() {
    this.setState({ step: 'CreateManifest' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  yourCrew(type, contract_number_id) {
    this.setState(
      { step: 'SortYourCrew', type, contract_number_id },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  goToType(type) {
    this.setState(
      { step: 'CreateManifest', prevtype: type, prev: false },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  goToCrew(crews) {
    this.setState(
      { step: 'SortYourCrew', crews: crews, prev: true },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  toVehicle(crews) {
    this.setState({ step: 'VehicleList', crews: crews }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  goToVehicle(crews) {
    this.setState(
      { step: 'VehicleList', crews: crews, vPrev: true },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  finalize(vehicles) {
    this.setState({ step: 'Finalize', vehicles: vehicles }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  goBackDetails() {
    this.setState({ step: 'List', prev: false }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editManifest(id, type) {
    this.setState(
      { step: 'SortYourCrew', manifest_id: id, type, edit: true },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  viewManifest(id, type) {
    this.setState(
      { step: 'ViewManifest', manifest_id: id, type: type },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem('user') === null) {
      this.setState({
        redirect: '/login',
      });
    }
    this.componentWillReceiveProps(this.props);
    var self = this;
    fetch(config.apiUrl + '/api/permission/getuserpermissions', {
      headers: authHeader(),
      method: 'GET',
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      })
      .catch((err) => console.log(err));
    // this.props.dispatch(resourceActions.getAllVehicles());
  }

  componentWillReceiveProps(nextProps) {
    var options;
    if (this.state.step === 'List') {
      let assignmentStatus =
        this.props.location.state && this.props.location.state.assignmentStats
          ? this.props.location.state.assignmentStats
          : '';
      options = (
        <div>
          <ListResources
            details={this.details.bind(this)}
            assignmentStatus={assignmentStatus}
          />
        </div>
      );
    }

    if (this.state.step === 'Details') {
      options = (
        <div>
          <ResourceDetails
            goBack={this.changeTab.bind(this, 'List')}
            props={this.state.props}
            profile={this.profile.bind(this)}
            editResource={this.editResource.bind(this)}
            createManifest={this.createManifest.bind(this)}
            editManifest={this.editManifest.bind(this)}
            viewManifest={this.viewManifest.bind(this)}
            details={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === 'Profile') {
      options = (
        <div>
          <Profile
            {...this.props}
            goBack={this.details.bind(this)}
            props={this.state.props}
            hash={this.state.hash}
            resourceDetails={this.state.props}
            id={this.state.id}
            editProfile={this.editProfile}
            toResourceList={this.goBackDetails}
          />
        </div>
      );
    }

    if (this.state.step === 'EditProfile') {
      options = (
        <div>
          <EditProfile
            goBack={this.profile.bind(this)}
            hash={this.state.hash}
            resourceDetails={this.state.props}
            toResourceList={this.goBackDetails}
            id={this.state.id}
          />
        </div>
      );
    }

    if (this.state.step === 'Edit') {
      options = (
        <div>
          <EditResource
            goBack={this.details.bind(this)}
            id={this.state.props}
          />
        </div>
      );
    }

    if (this.state.step === 'CreateManifest') {
      options = (
        <div>
          <CreateManifest
            yourCrew={this.yourCrew}
            // crew_id={this.state.crew_id}
            props={this.state.props}
            type={this.state.prevtype}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === 'SortYourCrew') {
      options = (
        <div>
          <SortYourCrew
            resource={this.state.props}
            type={this.state.type}
            previous={this.goToType}
            next={this.toVehicle.bind(this)}
            crews={this.state.crews}
            savedCrews={this.state.crews}
            prev={this.state.prev}
            edit={this.state.edit}
            manifest_id={this.state.manifest_id}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === 'VehicleList') {
      options = (
        <div>
          <VehicleList
            crews={this.state.crews}
            resource={this.state.props}
            previous={this.goToCrew.bind(this)}
            next={this.finalize.bind(this)}
            SelVehicles={this.state.vehicles}
            prev={this.state.vPrev}
            edit={this.state.edit}
            manifest_id={this.state.manifest_id}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === 'Finalize') {
      options = (
        <Finalize
          crews={this.state.crews}
          resourceDetails={this.state.props}
          vehicles={this.state.vehicles}
          details={this.details.bind(this)}
          manifestType={this.state.type}
          contract_number_id={this.state.contract_number_id}
          previous={this.goToVehicle.bind(this)}
          edit={this.state.edit}
          manifest_id={this.state.manifest_id}
        />
      );
    }

    if (this.state.step === 'ViewManifest') {
      options = (
        <div>
          <ViewManifest
            resourceDetails={this.state.props}
            details={this.details.bind(this)}
            manifestType={this.state.type}
            manifest_id={this.state.manifest_id}
          />
        </div>
      );
    }

    this.setState({ mainHtml: options });
  }

  // if(this.state.step === 'CreateManifest') {

  //     options = <div>
  //                 <CreateManifest goBack={this.changeTab.bind(this,'Details')} hash={this.state.hash}/>
  //             </div>
  // }

  componentDidUpdate() {
    if (
      this.props.match &&
      this.props.match.path === '/resources' &&
      this.state.step !== 'List'
    ) {
      this.setState(
        {
          step: 'List',
        },
        () => {
          this.componentWillReceiveProps(this.props);
        }
      );
    }
  }

  render() {
    if (this.state.redirect === '/login') {
      return <Redirect to="/login" />;
    }
    const { permissions } = this.state;
    return (
      permissions.length > 0 &&
      (!permissions.includes('Manage Resources') ? (
        <div className="content_div">
          <div className="no_permission">No permission to this page</div>
        </div>
      ) : (
        this.state.mainHtml
      ))
    );
  }
}

function mapStateToProps(state) {
  const { vehicles } = state;
  return {
    vehicles,
  };
}

const connectedResourcePage = connect(mapStateToProps)(Resources);
export { connectedResourcePage as Resources };
