import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentUserPermissions } from '_helpers/reduxSelector';
import { getCompanyName } from '_utils/utils';

export default function ApplicantHistory({ hash, date = '', companyId = '' }) {
  const currentUserPermissions = useSelector(getCurrentUserPermissions);

  return currentUserPermissions?.includes('Manage Applicant History') ? (
    <div className="applicant_history_container">
      <p className="applicant_history_text">{`This applicant has also applied to ${getCompanyName(
        companyId
      )} on ${date}.`}</p>
      <Link to={'/applicant-view/' + hash} target="_blank">
        <img
          alt="view"
          src={require('../assets/images/view.png').default}
          className="applicant_history_icon"
        />
      </Link>
    </div>
  ) : (
    <></>
  );
}
