import React, { Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { chatActions } from '../_actions';
import { config, authHeader } from '../_helpers';
import ReactModal from 'react-modal';

function Spinner() {
  return (
    <React.Fragment>
      <span className="conversation-search-loader">
        <img
          src={`${config.assetUrl}icons/circle.svg`}
          className="spinner fa-spin"
          alt=""
        />
      </span>
    </React.Fragment>
  );
}

function Message(props) {
  let {
    conversation,
    chat,
    id,
    selectConversation,
    info,
    recipient,
    date,
    lastMessage,
    icon,
    selected,
    user,
    unreadCount,
  } = props;

  let { users } = conversation;

  let isAdmin = chat.admin.active;

  function parseJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  function getLastMessage(lastMessage) {
    if (lastMessage) {
      switch (lastMessage.type) {
        case 'text':
          return lastMessage.body;
        case 'file':
          let file = parseJson(lastMessage.body);
          if (file.type.includes('image')) return 'Image';
          return 'File';
        case 'info':
          let notification = '';
          try {
            notification = JSON.parse(lastMessage.body);
          } catch (e) {}
          let body = notification.message ? notification.message : '';
          if (
            user.profile &&
            user.profile.data &&
            user.profile.data.hash == notification.by_user.hash
          ) {
            body = body.replace('#by_user', `You`);
          } else {
            body = body.replace(
              '#by_user',
              `${notification.by_user.first_name} ${notification.by_user.last_name}`
            );
          }
          if (
            user.profile &&
            user.profile.data &&
            user.profile.data.hash == notification.user.hash
          ) {
            body = body.replace('#user', `You`);
          } else {
            body = body.replace(
              '#user',
              `${notification.user.first_name} ${notification.user.last_name}`
            );
          }
          return body;
        default:
          return '';
      }
    }
    return '';
  }

  function getMessageTime(date) {
    let today = moment();
    let day = moment(date);
    if (today.isSame(day, 'day')) {
      let dateText = day.fromNow();
      if (dateText == 'a few seconds ago' || dateText == 'in a few seconds') {
        return 'Just Now';
      } else if (dateText.includes('hours')) {
        return dateText.replace('hours', 'hrs');
      } else if (dateText.includes('minutes')) {
        return dateText.replace('minutes', 'mins');
      } else if (dateText.includes('minute')) {
        return dateText.replace('minute', 'min');
      }
      return day.fromNow();
    }
    return day.format('ll');
  }

  return (
    <Link
      to={`/chat/${id}`}
      onClick={() => {
        selectConversation(conversation);
      }}
    >
      <div
        className={`msg-users ${selected ? 'msg-selected' : ''} ${info.type == 'single' && isAdmin ? 'is-admin' : ''}`}
      >
        <div className={`msg-user-pic ${unreadCount ? 'has-message' : ''}`}>
          {unreadCount ? <span className="msg-count">{unreadCount}</span> : ''}
          {isAdmin ? (
            <React.Fragment>
              {info.type == 'group' ? (
                <Fragment>
                  {info.profile_photo ? (
                    <img
                      src={config.baseUrl + info.profile_photo}
                      onError={(e) => {
                        e.target.setAttribute(
                          'src',
                          config.apiUrl + '/' + config.altImg
                        );
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${config.assetUrl}icons/group-info-icon.png`}
                      className="group-image"
                      alt=""
                    />
                  )}
                </Fragment>
              ) : users.length == 2 ? (
                <React.Fragment>
                  <img
                    src={config.baseUrl + users[0].profile_photo}
                    onError={(e) => {
                      e.target.setAttribute(
                        'src',
                        config.apiUrl + '/' + config.altImg
                      );
                    }}
                    alt=""
                  />
                  <img
                    src={config.baseUrl + users[1].profile_photo}
                    onError={(e) => {
                      e.target.setAttribute(
                        'src',
                        config.apiUrl + '/' + config.altImg
                      );
                    }}
                    alt=""
                    className="img-chat-person"
                  />
                </React.Fragment>
              ) : (
                <img
                  src={config.baseUrl + recipient.profile_photo}
                  onError={(e) => {
                    e.target.setAttribute(
                      'src',
                      config.apiUrl + '/' + config.altImg
                    );
                  }}
                  alt=""
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {info.type == 'group' ? (
                <Fragment>
                  {info.profile_photo ? (
                    <img
                      src={config.baseUrl + info.profile_photo}
                      onError={(e) => {
                        e.target.setAttribute(
                          'src',
                          config.apiUrl + '/' + config.altImg
                        );
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${config.assetUrl}icons/group-info-icon.png`}
                      className="group-image"
                      alt=""
                    />
                  )}
                </Fragment>
              ) : (
                <img
                  src={config.baseUrl + recipient.profile_photo}
                  onError={(e) => {
                    e.target.setAttribute(
                      'src',
                      config.apiUrl + '/' + config.altImg
                    );
                  }}
                  alt=""
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className="msg-messageData">
          {isAdmin ? (
            <React.Fragment>
              {info.type == 'group' ? (
                <div className="msg-name">{info.title}</div>
              ) : users.length == 2 ? (
                <div className="msg-name">{`${users[0].first_name} & ${users[1].first_name}`}</div>
              ) : (
                <div className="msg-name">{`${users[0].first_name} ${users[0].last_name}`}</div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {info.type == 'group' ? (
                <div className="msg-name">{info.title}</div>
              ) : users.length == 2 ? (
                <div className="msg-name">{`${recipient.first_name} ${recipient.last_name}`}</div>
              ) : (
                <div className="msg-name">{`${users[0].first_name} ${users[0].last_name}`}</div>
              )}
            </React.Fragment>
          )}
          <div className="time">
            {lastMessage
              ? getMessageTime(lastMessage.created_at)
              : getMessageTime(conversation.created_at)}
          </div>
          {lastMessage && !lastMessage.deleted_at ? (
            <div className="msg-preview">
              <span className="user">
                {user.profile &&
                lastMessage.sender.id == user.profile.data.id ? (
                  <React.Fragment>
                    {lastMessage.type == 'info' ? '' : 'You:'}
                  </React.Fragment>
                ) : conversation.data.type == 'group' ? (
                  `${lastMessage.sender.first_name} ${lastMessage.sender.last_name}:`
                ) : (
                  ''
                )}
              </span>
              {getLastMessage(lastMessage)}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Link>
  );
}

class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      lastSearchText: '',
      conversationLoading: true,
      searchMode: 'single',
      searchLoader: false,
      conversation: {},
      group: [],
      favorite: [],
      single: [],
      searchList: {
        group: [],
        single: [],
      },
      createModal: false,
    };

    this.selectConversation = this.selectConversation.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.sortMessages = this.sortMessages.bind(this);
    this.markRead = this.markRead.bind(this);
  }

  searchTimeout = null;

  selectConversation(conversation) {
    this.setState({
      searchText: '',
      conversation: conversation,
    });
  }

  handleSearch(e) {
    this.setState(
      {
        lastSearchText: this.state.searchText,
        searchText: e.target.value,
        searchLoader: true,
      },
      () => {
        clearTimeout(this.searchTimeout);

        if (this.state.searchText.length) {
          this.searchTimeout = setTimeout(() => {
            let { admin } = this.props.chat;
            let requestData = new FormData();
            requestData.append('keyword', this.state.searchText);
            requestData.append('admin_mode', admin.active);
            this.props.dispatch(chatActions.searchConversation(requestData));
          }, 600);
        }
      }
    );
  }

  sortMessages(c1, c2) {
    if (c1.last_message && c2.last_message) {
      return (
        moment(c2.last_message.created_at).valueOf() -
        moment(c1.last_message.created_at).valueOf()
      );
    } else if (c1.last_message) {
      return (
        moment(c2.created_at).valueOf() -
        moment(c1.last_message.created_at).valueOf()
      );
    } else if (c2.last_message) {
      return (
        moment(c2.last_message.created_at).valueOf() -
        moment(c1.created_at).valueOf()
      );
    }
  }

  markRead() {
    const _requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    const _url = `${config.apiUrl}/api/chat/conversations/markallasread`;
    fetch(_url, _requestOptions).then((response) =>
      response.json().then(
        (res) => {
          //console.log("Unavailable Reasons", res);
          this.props.dispatch(chatActions.getConversations());
        },
        (err) => console.error(err)
      )
    );
  }

  componentDidMount() {
    let { conversation, conversations } = this.props.chat;

    conversations.data.sort(this.sortMessages);

    this.setState({
      favorite: conversations.data.filter(
        (conversation) => conversation.mark == 1
      ),
      group: conversations.data.filter(
        (conversation) =>
          conversation.data.type == 'group' && conversation.mark != 1
      ),
      single: conversations.data.filter(
        (conversation) =>
          conversation.data.type == 'single' && conversation.mark != 1
      ),
    });

    this.setState(
      {
        conversation: conversation,
      },
      () => this.props.dispatch(chatActions.getConversations())
    );
  }

  componentDidUpdate(prevProps) {
    let { conversation, conversations } = this.props.chat;

    if (conversations != prevProps.chat.conversations) {
      if (
        prevProps.chat.conversations.searchList != conversations.searchList &&
        this.state.searchText.length > 0 &&
        conversations.searchList
      ) {
        let searchList = {
          group: conversations.searchList.filter(
            (conversation) => conversation.data.type == 'group'
          ),
          single: conversations.searchList.filter(
            (conversation) => conversation.data.type == 'single'
          ),
        };

        let searchMode = searchList.group.length > 0 ? 'group' : 'single';

        this.setState({
          searchMode,
          searchList,
          searchLoader: false,
        });
      } else {
        conversations.data.sort(this.sortMessages);
        this.setState({
          favorite: conversations.data.filter(
            (conversation) => conversation.mark == 1
          ),
          group: conversations.data.filter(
            (conversation) =>
              conversation.data.type == 'group' && conversation.mark != 1
          ),
          single: conversations.data.filter(
            (conversation) =>
              conversation.data.type == 'single' && conversation.mark != 1
          ),
          conversationLoading: false,
        });
      }
    }

    if (conversation != prevProps.chat.conversation) {
      this.setState({
        conversation: conversation,
      });
    }
  }

  toggleAdmin() {
    let { admin } = this.props.chat;
    this.props.dispatch(
      chatActions.toggleAdminMode({ adminMode: !admin.active })
    );
  }

  render() {
    let {
      conversation,
      searchText,
      searchMode,
      searchList,
      conversationLoading,
    } = this.state;

    let { user, chat, permission } = this.props;

    let { permissions } = permission;

    let { admin } = chat;

    return (
      <div
        className={`msg-col msg-user-list ${chat.conversation.id ? ' hidemb' : ' showmb'}`}
      >
        <div className="msg-userlist-search">
          <div className="d-flex msg-read">
            {permissions && permissions.data.includes('Manage Chat') ? (
              <div className="is-admin-mob" id="adminmode">
                <div className="is-admin-header">
                  Admin Mode
                  <div className="pretty p-switch p-fill">
                    <input
                      type="checkbox"
                      id="admin-toggle"
                      checked={admin.active}
                      onChange={this.toggleAdmin}
                    />
                    <div className="state">
                      <label htmlFor="admin-toggle"> </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="is-admin-header">
              <button className="btn-mark-as-read btn" onClick={this.markRead}>
                Mark all as read
              </button>
            </div>
          </div>
          <div className="search_inputs">
            <a className="mob-backbtn hide">
              <img src={`${config.assetUrl}icons/back-btn.svg`} alt="" />
            </a>
            <input
              className={`${chat.admin.active ? 'admin_mode' : ''}`}
              type="text"
              placeholder="Search people or group"
              style={{
                backgroundImage: `url(${config.assetUrl}icons/search-icon.svg)`,
              }}
              value={searchText}
              onChange={this.handleSearch}
            />
            {searchText.length > 0 && (
              <button
                onClick={() => {
                  this.setState({ searchText: '' });
                }}
                className="close-inputBtn"
              >
                <img src={`${config.assetUrl}icons/close.svg`} />
              </button>
            )}
            {!chat.admin.active && (
              <button
                className="mob-createnewBtn"
                onClick={() => {
                  this.setState({ createModal: true });
                }}
              >
                <img src={`${config.assetUrl}icons/create-new-alt.svg`} />
              </button>
            )}
          </div>
          {/* modal for new message/group */}
          <ReactModal
            isOpen={this.state.createModal}
            onRequestClose={() => {
              this.setState({ createModal: false });
            }}
            shouldCloseOnOverlayClick={true}
            className="newmsgMob"
            overlayClassName="newmsgMob-overlay"
          >
            <div className="modal-content">
              <div className="modal-header">
                <Link to={`/chat/new-message`} className="mobPopupNew">
                  <i className="fas fa-envelope"></i> New Message
                </Link>
              </div>
              {permissions && permissions.data.includes('Create Group') ? (
                <div className="modal-header">
                  <Link to={`/chat/new-group`} className="mobPopupNew">
                    <i className="fas fa-users"></i> Create New Group
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </ReactModal>
        </div>
        {searchText.length > 0 ? (
          <div className="msg-list-section searchlist ">
            <ul className="nav nav-tabs" role="tablist">
              <li
                role="presentation"
                className={searchMode == 'group' ? 'active' : ''}
              >
                <a
                  onClick={() => {
                    this.setState({ searchMode: 'group' });
                  }}
                  className={`btn search-filter ${searchMode === 'group' ? 'active' : ''}`}
                  aria-controls="home"
                  role="tab"
                  data-toggle="tab"
                >
                  Groups
                </a>
              </li>
              <li
                role="presentation"
                className={searchMode == 'single' ? 'active' : ''}
              >
                <a
                  onClick={() => {
                    this.setState({ searchMode: 'single' });
                  }}
                  className={`btn search-filter ${searchMode === 'single' ? 'active' : ''}`}
                  aria-controls="profile"
                  role="tab"
                  data-toggle="tab"
                >
                  People
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                role="tabpanel"
                className="tab-pane active search-result-tab"
                id="groups"
              >
                {searchMode === 'group' && (
                  <React.Fragment>
                    {searchList.group.length ? (
                      searchList.group.map((item, index) => {
                        return (
                          <Message
                            chat={chat}
                            conversation={item}
                            key={index}
                            info={item.data}
                            id={item.id}
                            user={user}
                            unreadCount={item.unreadCount}
                            recipient={item.recipients[0]}
                            date={conversation.created_at}
                            lastMessage={item.last_message}
                            selectConversation={this.selectConversation}
                          />
                        );
                      })
                    ) : this.state.searchLoader ? (
                      <Spinner />
                    ) : (
                      <div className="no-results">No Results Found</div>
                    )}
                  </React.Fragment>
                )}
                {searchMode === 'single' && (
                  <React.Fragment>
                    {searchList.single.length ? (
                      searchList.single.map((item, index) => {
                        return (
                          <Message
                            key={index}
                            info={item.data}
                            conversation={item}
                            chat={chat}
                            id={item.id}
                            user={user}
                            unreadCount={item.unreadCount}
                            recipient={item.recipients[0]}
                            date={conversation.created_at}
                            lastMessage={item.last_message}
                            selectConversation={this.selectConversation}
                          />
                        );
                      })
                    ) : this.state.searchLoader ? (
                      <Spinner />
                    ) : (
                      <div className="no-results">No Results Found</div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {chat.conversations.data.length ? (
              <div className="msg-lists">
                {this.state.favorite.length > 0 && (
                  <div className="msg-list-section">
                    <h3 className="msg-section-title">Favorites</h3>
                    {this.state.favorite.map((item, index) => {
                      return (
                        <Message
                          key={index}
                          info={item.data}
                          conversation={item}
                          id={item.id}
                          chat={chat}
                          user={user}
                          unreadCount={item.unreadCount}
                          selected={item.id === conversation.id}
                          recipient={item.recipients[0]}
                          date={conversation.created_at}
                          lastMessage={item.last_message}
                          selectConversation={this.selectConversation}
                        />
                      );
                    })}
                  </div>
                )}
                {this.state.group.length > 0 && (
                  <div className="msg-list-section">
                    <h3 className="msg-section-title">Groups</h3>
                    {this.state.group.map((item, index) => {
                      return (
                        <Message
                          key={index}
                          id={item.id}
                          conversation={item}
                          chat={chat}
                          user={user}
                          unreadCount={item.unreadCount}
                          selected={item.id === conversation.id}
                          info={item.data}
                          date={conversation.created_at}
                          lastMessage={item.last_message}
                          selectConversation={this.selectConversation}
                        />
                      );
                    })}
                  </div>
                )}
                {this.state.single.length > 0 && (
                  <div className="msg-list-section">
                    <h3 className="msg-section-title">People</h3>
                    {this.state.single.map((item, index) => {
                      return (
                        <Message
                          key={index}
                          chat={chat}
                          conversation={item}
                          id={item.id}
                          info={item.data}
                          user={user}
                          unreadCount={item.unreadCount}
                          selected={item.id === conversation.id}
                          recipient={item.recipients[0]}
                          date={conversation.created_at}
                          lastMessage={item.last_message}
                          selectConversation={this.selectConversation}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              !conversationLoading && (
                <div className="msg-landing mobile">
                  <div className="mgs-landing_content">
                    <img
                      className="landing-img"
                      src={`${config.assetUrl}icons/new-msg-landing.svg`}
                      alt=""
                    />
                    <h3>Hi, Welcome to NVAM Messaging.</h3>
                    <p>
                      Send messages to others by selecting 'New Message' or
                      'Search for People or Group'.
                    </p>
                  </div>
                </div>
              )
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user, permission } = state;
  return { chat, user, permission };
}

export default connect(mapStateToProps)(Messages);
