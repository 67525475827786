import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewModal from 'react-responsive-modal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '_actions';
import { toast } from 'react-toastify';

const currentStatusStep = {
  one: '1',
  two: '2',
  twoA: '2a',
  twoB: '2b',
  three: '3',
  lockProfile: '4',
  lockProfileConfirm: '4a',
  unlockProfile: '4b',
};

export const currentStatus = {
  Available: 'Available',
  Unavailable: 'Unavailable',
};

const unavailableReasonIsOther = '4';

const getCurrentUserInfo = (state) =>
  state.user && state.user.profile && state.user.profile.data;

const mapUserInfo = (state) =>
  state.user && state.user.items && state.user.items.data;

const ChangeStatusModal = ({
  open,
  onClose,
  forceCurrentUser,
  lockProfile,
  timeOffFlag,
}) => {
  const dispatch = useDispatch();
  // const location = useHistory();
  //
  const [status, setStatus] = useState('');
  const [userHash, setUserHash] = useState('');
  const [currentStep, setCurrentStep] = useState(
    lockProfile ? currentStatusStep.lockProfile : currentStatusStep.one
  );
  const [unavailableReason, setUnavailableReason] = useState('0');
  const [returnDate, setReturnDate] = useState(moment());
  const [docsRequired, setDocsRequired] = useState(true);
  const [otherReason, setOtherReason] = useState('');
  const [errors, setErrors] = useState({});

  console.log(timeOffFlag);

  const currentUserInfo = useSelector((state) => getCurrentUserInfo(state));

  const userInfo = useSelector((state) => mapUserInfo(state));

  const isCurrentUser =
    forceCurrentUser ||
    (userInfo && userInfo.hash) === (currentUserInfo && currentUserInfo.hash);

  //  name/you/is/are
  useEffect(() => {
    const status =
      forceCurrentUser || isCurrentUser
        ? currentUserInfo && currentUserInfo.active
        : userInfo && userInfo.active;

    const _userHash =
      forceCurrentUser || isCurrentUser
        ? currentUserInfo && currentUserInfo.hash
        : userInfo && userInfo.hash;

    setUserHash(_userHash);
    setStatus(status);
  }, [userInfo, currentUserInfo]);

  const stepOne = currentStep === currentStatusStep.one;
  const stepTwoAsAvailable =
    currentStep === currentStatusStep.two && status === currentStatus.Available;
  const stepTwoAsNotAvailable =
    currentStep === currentStatusStep.two && status !== currentStatus.Available;
  const isLockProfile = currentStep === currentStatusStep.lockProfile;
  const handleOnChangeCurrentStep = (newCurrentStep) =>
    setCurrentStep(newCurrentStep);

  const updateStatus = () => {
    const _status =
      status === currentStatus.Available
        ? currentStatus.Unavailable
        : currentStatus.Available;

    let data = new FormData();
    data.append('hash', userHash);
    data.append('status', _status);
    data.append('unavailableReason', unavailableReason);

    if (returnDate) {
      data.append('end_date', moment(returnDate).format('MM/DD/YY'));
    }
    if (unavailableReason === unavailableReasonIsOther) {
      data.append('otherReason', otherReason);
    } else {
      data.append('otherReason', '');
    }
    if (isCurrentUser) {
      dispatch(userActions.changeSelfStatus(data));
    } else dispatch(userActions.changeStatus(data, '?hash=' + userHash));
    onClose();
  };

  const handleLockProfile = () => {
    let lockStatus = 'Locked';
    let hash = '?hash=' + userHash;
    let data = new FormData();
    data.append('hash', userHash);
    data.append('status', lockStatus);
    dispatch(userActions.lockUserToggle(data, hash));
    onClose();
  };

  const handleUnlockProfile = () => {
    let lockStatus = 'Unlocked';
    let hash = '?hash=' + userHash;
    let data = new FormData();
    data.append('hash', userHash);
    data.append('status', lockStatus);
    data.append('document', docsRequired);
    dispatch(userActions.lockUserToggle(data, hash));
    onClose();
  };

  const resetAll = () => {
    setCurrentStep(currentStatusStep.one);
  };

  const errorHandler = (key, value) => {
    let _errors = { ...errors };
    if (value !== errors[key]) {
      _errors[key] = value;
      setErrors(_errors);
    }
  };

  const validateAndNext = (nextStep) => {
    if (!timeOffFlag && unavailableReason == '1') {
      return toast.error(
        'Please get approval on your Time Off Request Document'
      );
    }
    if (unavailableReason === '0')
      return errorHandler('unavailableReason', 'error');
    if (!otherReason && unavailableReason === unavailableReasonIsOther)
      return errorHandler('otherReason', 'error');
    handleOnChangeCurrentStep(nextStep);
  };

  const userName = isCurrentUser
    ? 'You are'
    : `${userInfo && userInfo.first_name} is`;

  return (
    <NewModal
      id="status_popup"
      className="status_change_modal"
      classNames={{ modal: 'status_change_modal' }}
      open={open}
      onClose={onClose}
      little
      onExited={() => resetAll()}
    >
      <div id="statusPopup">
        <div className="modal-head">
          <h4 className="modal-title">Current Status</h4>
        </div>
        <div className="modal-body">
          {/* Lock Profile Management */}
          {isLockProfile && (
            <div>
              <div className="user-status-edit-wrapper">
                <div>
                  {userInfo && userInfo.is_locked === 0 ? (
                    <button
                      className="button"
                      onClick={() =>
                        handleOnChangeCurrentStep(
                          currentStatusStep.lockProfileConfirm
                        )
                      }
                    >
                      Lock Profile
                    </button>
                  ) : (
                    <button
                      className="button"
                      onClick={() =>
                        handleOnChangeCurrentStep(
                          currentStatusStep.unlockProfile
                        )
                      }
                    >
                      Unlock Profile
                    </button>
                  )}
                </div>
                <div>
                  <button
                    onClick={() =>
                      handleOnChangeCurrentStep(currentStatusStep.two)
                    }
                    className={`button outlined ${userInfo && userInfo.is_locked === 1 ? 'disabled' : ''}`}
                    disabled={userInfo && userInfo.is_locked === 1}
                  >
                    {status === 'Available'
                      ? 'Make Unavailable'
                      : 'Make Available'}
                  </button>
                </div>
              </div>
            </div>
          )}

          {currentStep === currentStatusStep.lockProfileConfirm && (
            <div className="modal-body">
              <div className="status_step step1">
                <section className="d-flex flex-column checkb">
                  <p>Are you sure you want to Lock the selected employee ?</p>
                </section>
                <div className="user-status-edit-wrapper">
                  <div>
                    <button
                      className="button disabled"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      className="button"
                      onClick={() => handleLockProfile()}
                    >
                      Lock
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentStep === currentStatusStep.unlockProfile && (
            <div className="status_step step1">
              <section className="d-flex flex-column checkb">
                <h6 className="text-bold">
                  Are you sure you want to Unlock the selected employee
                </h6>

                {((userInfo && userInfo.docs_status === -1) ||
                  (userInfo && userInfo.rehire_docs_status === -1)) && (
                  <label>
                    <input
                      type="checkbox"
                      value={docsRequired}
                      onChange={(event) =>
                        setDocsRequired(event.target.checked)
                      }
                    />
                    <span>Ask to fill Documents</span>
                  </label>
                )}
              </section>
              <div className="user-status-edit-wrapper">
                <div>
                  <button className="button disabled" onClick={() => onClose()}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className="button"
                    onClick={() => handleUnlockProfile()}
                  >
                    Unlock
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Lock Profile Management End*/}

          {/* Status Change Management */}
          {stepOne && (
            <div className="status_step step1">
              <label>
                {userName}:{' '}
                <span className="green">{status ? status : 'loading..'}</span>
              </label>
              <br />
              <button
                className="button"
                onClick={() => handleOnChangeCurrentStep(currentStatusStep.two)}
              >
                Change Current Status
              </button>
              <p className="step_no">
                {status === currentStatus.Available ? '1/3' : '1/2'}
              </p>
            </div>
          )}
          {stepTwoAsAvailable && (
            <div className="status_step step2">
              <label>Reason for Unavailability: </label>
              <select
                name="unavailableReason"
                className="unavailability_reason"
                value={unavailableReason}
                onChange={(event) => {
                  errorHandler('unavailableReason', '');
                  setUnavailableReason(event.target.value);
                }}
                errors={errors.unavailableReason}
              >
                <option value="0">-- Select Reason --</option>
                <option value="1">Time Off</option>
                <option value="2">Sick</option>
                <option value="3">Injury</option>
                <option value="4">Other</option>
              </select>

              {unavailableReason === unavailableReasonIsOther && (
                <div classNames="full-width">
                  <textarea
                    rows="3"
                    className="other_reason"
                    value={otherReason}
                    onChange={(event) => {
                      errorHandler('otherReason', '');
                      setOtherReason(event.target.value);
                    }}
                    errors={errors.otherReason}
                  />
                </div>
              )}
              {unavailableReason && (
                <div>
                  <label>Do you know when you will return?</label>
                  <button
                    className="button"
                    onClick={() => {
                      validateAndNext(currentStatusStep.twoA);
                      // setCurrentStep()
                      // setCurrentStep(currentStatusStep.twoA)
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="button grey"
                    onClick={() => validateAndNext(currentStatusStep.twoB)}
                  >
                    No
                  </button>
                </div>
              )}

              <p className="step_no">2/3</p>
            </div>
          )}

          {stepTwoAsNotAvailable && (
            <div className="status_step step2">
              <label>Make Available?</label>
              <button className="button" onClick={() => updateStatus()}>
                Yes
              </button>
              <button className="button grey" onClick={() => onClose()}>
                No
              </button>
              <p className="step_no">2/2</p>
            </div>
          )}

          {currentStep === '2a' && (
            <div className="status_step step4">
              <label>Choose date of return:</label>
              <DatePicker
                minDate={moment()}
                value={
                  (returnDate && moment(returnDate).format('MM/DD/YY')) || ''
                }
                onChange={(date) => setReturnDate(date)}
                className="returnDateField"
                name="returnDateField"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {returnDate ? (
                <button
                  style={{ marginLeft: 10 }}
                  className="button"
                  onClick={() => setCurrentStep(currentStatusStep.three)}
                >
                  Next
                </button>
              ) : null}

              <p className="step_no">2/3</p>
            </div>
          )}
          {currentStep === '2b' && (
            <div className="status_step step2">
              <label>Make Unavailable?</label>
              <button className="button" onClick={() => updateStatus()}>
                Yes
              </button>
              <button className="button grey" onClick={() => onClose()}>
                No
              </button>
              <p className="step_no">3/3</p>
            </div>
          )}
          {currentStep === '3' && (
            <div className="status_step step5">
              <label>Are you sure you want to switch to Unavailable?</label>
              <p>(returning {moment(returnDate || '').format('MM/DD/YY')})</p>
              <button className="button" onClick={() => updateStatus()}>
                Yes
              </button>
              <button className="button grey" onClick={() => onClose()}>
                No
              </button>
              <p>Time off must be approved by your supervisor.</p>
              <p className="step_no">3/3</p>
            </div>
          )}
        </div>
        {/* Status Change Management End */}
      </div>
    </NewModal>
  );
};

export default ChangeStatusModal;

ChangeStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  forceCurrentUser: PropTypes.bool,
  lockProfile: PropTypes.bool,
  timeOffFlag: PropTypes.bool,
};
