import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers/history';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { Chat } from '../Chat';
import { LoginPage } from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import { Application } from '../Application';
import { ApplicationForm } from '../ApplicationForm';
import { Applicants } from '../Applicants';
import { Resources } from '../Resources';
import { CreateResource } from '../Resources/CreateResource';
import { EmployeeVerification } from '../Resources/EmployeeVerification';
import { Settings } from '../Settings';
import { SettingsProfile } from '../Settings/SettingsProfile';
import { Notifications } from '../Notifications';
import { ResetPassword } from '../ResetPassword';
import { ForgotPassword } from '../ForgotPassword';
import ReportPage from '../Reports';
import { Manifest } from '../Manifest';
import { Navbar } from '../_components/Navbar/Navbar';
import { Assign } from '../AssignStaff/Assign';
import { Inventory } from '../Inventory';
import { CrewAssignmentStatusReport } from '../CrewAssignmentStatusReport';
import { ArchivedResources } from '../Resources/ArchivedResources';
import { ArchivedEmployees } from '../Resources/ArchivedEmployees';
import { YourCrew } from '../YourCrew';
import { Employee } from '../HomePage/Employee';
import { Employees } from '../HomePage/Employees';
import { ViewManifestNotify } from '../Resources/ViewManifestNotify';
import NotFound from '../NotFound/NotFound';
import { isIOS } from 'react-device-detect';
import { DocsContainer } from 'Docs';
import { ToastContainer, toast } from 'react-toastify';
import { AlertPopup } from '../_components/AlertPopup';
import notif_ico from 'assets/images/exclm.svg';
import notif_close_ico from 'assets/images/notify-close.svg';
import { unregister } from '../_interceptors/http.interceptor';
import { Offline } from 'react-detect-offline';
import { config } from '_helpers';
import '../assets/styles/fonts.css';
import '../assets/styles/style.css';
import '../assets/styles/docs.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import InterviewForm from 'InterviewForm/index';
import ActivityLogContainer from '_components/ActivityLog';
import TORDocumentsContainer from 'TORWidget/TORDocumentsContainer';
import DirectDepositForm from 'DirectDepositForm';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crew_id: '',
      socket: null,
      popup: false,
      alertHeading: 'Success',
      banner_flag: false,
      message: {
        action: 0,
      },
      timer: 60,
      networkFlag: true,
      netWorkStatus: true,
    };

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
      window.scrollTo(0, 0);
    });
    this.overlay = React.createRef();
    this.banner = React.createRef();
    this.callToast = this.callToast.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.refreshWindow = this.refreshWindow.bind(this);
    this.truncate = this.truncate.bind(this);
  }

  closeAlert() {
    this.setState(
      {
        popup: false,
      },
      () => {
        this.props.dispatch(alertActions.clear());
      }
    );
  }

  callToast(message, flag) {
    this.setState({ banner_flag: flag, message }, () => {
      if (Number(this.state.message.action) === 2) {
        this.interval = setInterval(() => {
          if (this.state.timer === 0) {
            clearInterval(this.interval);
            this.setState({ banner_flag: false, timer: 60 }, () =>
              window.location.reload(true)
            );
          }
          this.setState({ timer: this.state.timer - 1 });
        }, 1000);
      }
    });
  }

  closeToast() {
    clearInterval(this.interval);
    this.setState({ banner_flag: false, timer: 60 });
  }

  closeErrorBanner = () => {
    this.setState({
      networkFlag: false,
      netWorkStatus: true,
    });
  };

  refreshWindow() {
    this.setState({ banner_flag: false }, () => {
      window.location.reload(true);
    });
  }

  truncate(string) {
    let max;
    window.innerWidth <= 481 ? (max = 80) : (max = 120);
    return string.length > max ? string.substring(0, max) + '...' : string;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    if (
      !this.state.popup &&
      this.props.alert &&
      this.props.alert.type &&
      !String(window.location.pathname).includes('reset_password')
    ) {
      this.setState({ popup: true });
    } else if (this.state.popup && this.props.alert && !this.props.alert.type) {
      this.setState({ popup: false }, () =>
        this.props.dispatch(alertActions.clear())
      );
    }
  }

  onNetworkChange = (e) => {
    this.setState({
      netWorkStatus: e,
      networkFlag: true,
    });
  };

  render() {
    let banner_height = 0;
    let banner = null;
    if (this.state.banner_flag) {
      window.innerWidth <= 481 ? (banner_height = 100) : (banner_height = 50);
    }
    if (!this.state.netWorkStatus) {
      window.innerWidth <= 481 ? (banner_height = 100) : (banner_height = 50);
    }
    // (this.state.banner_flag && this.banner.current) ? this.banner_height = this.banner.current.clientHeight : this.banner_height = 0;
    switch (this.state.message.action) {
      case 1:
        banner = (
          <span>
            New updates have been applied to the system. Please refresh the
            page.
          </span>
        );
        break;
      case 2:
        banner = (
          <span>
            The system has been updated to a newer version. The page will reload
            in <em>{this.state.timer} seconds</em>. Reload Now.
          </span>
        );
        break;
      case 3:
        banner = (
          <span>
            System maintenance is being performed. Please check back in{' '}
            <em>{this.state.message.content}</em> hours. Thank you!
          </span>
        );
        break;
      case 4:
        banner = <span>{this.truncate(this.state.message.content)}</span>;
        break;
      default:
        banner = (
          <span>
            New updates have been applied to the system. Please refresh the
            page.
          </span>
        );
    }

    const router = (
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={() => (
            <Redirect to={{ pathname: '/dashboard' }}></Redirect>
          )}
        />
        <PrivateRoute path="/dashboard" component={HomePage} />
        <PrivateRoute path="/chat" component={Chat} />
        <Route exact path="/resources" component={Resources} />
        <PrivateRoute path="/application" component={Application} />
        <PrivateRoute path="/applicants" component={Applicants} />
        <PrivateRoute
          exact
          path="/applicant-view/:hash"
          component={Applicants}
        />
        <PrivateRoute path="/create-resource" component={CreateResource} />
        <PrivateRoute path="/employee" component={EmployeeVerification} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/docs" component={DocsContainer} />
        <PrivateRoute path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/manifest" component={Manifest} />
        <PrivateRoute exact path="/inventory" component={Inventory} />
        <PrivateRoute
          exact
          path="/inventory/inventory-view/:id"
          component={Inventory}
        />
        <PrivateRoute
          path="/archived-resources"
          component={ArchivedResources}
        />
        <PrivateRoute path="/archived" component={ArchivedEmployees} />
        <PrivateRoute path="/crew" component={YourCrew} />
        <PrivateRoute path="/assignstaff" component={Assign} />
        <PrivateRoute exact path="/profile/:hash" component={SettingsProfile} />
        <PrivateRoute
          exact
          path="/profile/:hash/docs/:category/:doc/:id"
          component={SettingsProfile}
        />

        <PrivateRoute
          path="/direct-deposit-form"
          component={DirectDepositForm}
        />

        <Route exact path="/resource-profile/:hash" component={Resources} />
        <PrivateRoute
          path="/report/:id"
          component={CrewAssignmentStatusReport}
        />
        <PrivateRoute
          exact
          path="/employee-profile/:hash"
          component={Employee}
        />
        <PrivateRoute path="/resources/:id" component={Resources} />
        <PrivateRoute exact path="/employees/:id" component={Employees} />
        <PrivateRoute
          exact
          path="/manifest/:id/:type/:resource"
          component={ViewManifestNotify}
        />
        <PrivateRoute
          exact
          path="/verification/:hash"
          component={EmployeeVerification}
        />
        <PrivateRoute path="/application-form" component={ApplicationForm} />
        <PrivateRoute path="/register" component={RegisterPage} />
        <PrivateRoute path="/reports" component={ReportPage} />
        <PrivateRoute
          path="/tor-pending-docs"
          component={TORDocumentsContainer}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset_password/:token" component={ResetPassword} />
        <PrivateRoute path="/activity-log" component={ActivityLogContainer} />
        <Route component={NotFound} />
      </Switch>
    );
    let pages = window.location.href.split('/');
    var page = window.location.href.split('/').pop();
    /**
     * Commented below code as a part of slitting the job application
     */
    /*if (page === "application-form") {
      window.location.assign("job-application");
    }*/

    let { permissions } = this.props.permission;

    let user = localStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
      user = user.success;
    }

    return (
      <Router history={history}>
        {/* Check for the application is logged in or not */}
        <React.Fragment>
          <ToastContainer />
          <Offline
            onChange={(e) => this.onNetworkChange(e)}
            polling={{
              url: `${config.apiUrl}/healthcheck.html`,
              interval: 10000,
            }}
          >
            {this.state.networkFlag && (
              <div className={`top-notification`}>
                {/* { notif_ico && <img className="icon" src={notif_ico} alt="warn" /> } */}
                <span>{'The network connection was lost'}</span>
                <span className="closebtn" onClick={this.closeErrorBanner}>
                  {/* { notif_close_ico ? <img src={notif_close_ico} alt="close" /> : <span>X</span> } */}
                  <span>X</span>
                </span>
              </div>
            )}
          </Offline>
          {this.state.popup && (
            <AlertPopup
              head={this.state.alertHeading}
              message={this.props.alert.message}
              viewOpen="true"
              alertFunction={() => history.push('/login')}
              close={this.closeAlert.bind(this)}
            />
          )}
          {user && user.type == 'employee' && page !== 'application-form' ? (
            <div
              className={`outer${pages.some((_page) => _page.includes('chat')) ? ' chat-active' : ''}${
                permissions && permissions.data.includes('Manage Chat')
                  ? ' admin-user'
                  : ''
              }`}
            >
              <div className="container-fluid">
                {/* Notification */}
                {this.state.banner_flag && (
                  <div className={`top-notification`} ref={this.banner}>
                    <img className="icon" src={notif_ico} alt="icon" />
                    {banner}
                    {(this.state.message.action == 1 ||
                      this.state.message.action == 2) && (
                      <button
                        className="refresh-btn"
                        onClick={this.refreshWindow}
                      >
                        Refresh
                      </button>
                    )}
                    <span className="closebtn" onClick={this.closeToast}>
                      <img src={notif_close_ico} alt="icon" />
                    </span>
                  </div>
                )}
                <div className="row outer_container">
                  <div className="col-sm-2 left_sidebar">
                    <div
                      className="new_overlay hidden"
                      ref={this.overlay}
                    ></div>
                    {/* <Navigation {...this.props} /> */}
                    <Navbar
                      banner={this.state.banner_flag}
                      banner_height={banner_height}
                      callToast={this.callToast}
                      overlay={this.overlay}
                    />
                  </div>
                  <div className="col-sm-10 right_sidebar" id="right_sidebar">
                    {/* <TopHeader /> */}
                    <div
                      className={`outerMostContainer${isIOS ? ' ios' : ''}${
                        isIOS &&
                        (window.screen.height == 812 ||
                          window.screen.height == 896)
                          ? ' iphx'
                          : ''
                      }`}
                    >
                      {router}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            router
          )}
        </React.Fragment>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, chat, permission } = state;
  return {
    alert,
    user,
    chat,
    permission,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
