import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import Modal from 'react-responsive-modal';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { resourceActions, permissionActions } from '../_actions';
import '../assets/styles/resource.css';
import { AlertPopup } from '../_components/AlertPopup';
import { ListView } from './ListView';
import ListSVG from '../assets/images/view_list.svg';
import GridSVG from '../assets/images/view_grid.svg';

const SortableItem = SortableElement(
  ({ item, clickFunction, onImageError }) => {
    return (
      <Link to={'/resources/' + item.id} className="link_resource">
        <div className="resource_item">
          <img
            alt="close"
            src={item.resource_photo}
            onError={(e) => onImageError(e)}
          />
          <p className="d-flex m-0 mt-1">
            <span className="status">
              {item.assignment_status == 1 && (
                <img
                  className="mr-1"
                  alt=""
                  src={require('../assets/images/Available1.svg').default}
                />
              )}
              {item.assignment_status == 2 && (
                <img
                  className="mr-1"
                  alt=""
                  src={require('../assets/images/unavailable1.svg').default}
                />
              )}
              {item.assignment_status == 3 && (
                <img
                  className="mr-1"
                  alt=""
                  src={require('../assets/images/Committed-one.svg').default}
                />
              )}

              {item.resource_name}
            </span>
          </p>
          <p className="m-0 avail-status">
            {' '}
            {item.assignment_status == 2 && item.unavailable_reason && (
              <span>
                <small>({item.unavailable_reason})</small>
              </span>
            )}
            {item.assignment_status == 3 && item.project_name && (
              <small>{item.project_name}</small>
            )}
          </p>
        </div>
      </Link>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, clickFunction, onImageError, collection }) => {
    return (
      <div className="Showcase_inner_wrapper">
        {items.length < 1 ? (
          <div className="no_crews">
            <p className="no_resource float">No Resource Found</p>
          </div>
        ) : (
          items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              item={value}
              clickFunction={clickFunction}
              onImageError={onImageError}
              collection={collection}
            />
          ))
        )}
      </div>
    );
  }
);

class ListResources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusOpen: false,
      list_view: 'grid',
      keyword: '',
      message: '',
      crew_identifier_id: '',
      email: true,
      sms: true,
      adminList: [],
      aboutTreesList: [],
      NVTSList: [],
      conentWrapperMinHeight: 'auto',
      conentWrapperMinHeighta: 'auto',
      alertPop: false,
      smsVal: 1,
      emailVal: 1,
      selectedStatus: '',
      aboutTreesListRaw: [],
      adminListRaw: [],
      NVTSListRaw: [],
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.details = this.details.bind(this);
    this.newResource = this.newResource.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortStart = this.onSortStart.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  toggleView() {
    let list_view;
    this.state.list_view === 'grid'
      ? (list_view = 'list')
      : (list_view = 'grid');
    this.setState({ list_view });
  }

  onSortStart() {
    this.divRef.classList.add('dragging');
  }

  onSortEnd({ oldIndex, newIndex, collection }) {
    this.divRef.classList.remove('dragging');
    this.setState(
      {
        [collection]: arrayMove(this.state[collection], oldIndex, newIndex),
      },
      function () {
        let data = new FormData();
        this.state[collection].map((item, index) => {
          data.append('data[' + index + ']', item.id);
          return data;
        });
        fetch(config.apiUrl + '/api/resource/resourceposition', {
          headers: authHeader(),
          method: 'POST',
          body: data,
        });
      }
    );
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, message: '' });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  componentDidMount() {
    this.setState({ selectedStatus: this.props.assignmentStatus });
    this.props.dispatch(resourceActions.getAll());
    this.props.dispatch(permissionActions.getPermission());
  }

  details(props) {
    this.props.details(props);
  }

  newResource() {
    this.props.newResource();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.setState({ keyword: event.target.value });
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(value);
      }.bind(this),
      1000
    );
  }

  handleSearch(text) {
    let data = new FormData();
    data.append('keyword', text);
    this.props.dispatch(resourceActions.searchResource(data));
  }

  handleCheckbox(e) {
    var value = e.target.value,
      emailVal = 0,
      smsVal = 0,
      name = e.target.name;
    // if(value === "email") {
    //     this.setState({ email: !this.state.email, emailVal })
    // }
    // if(value === "sms") {
    //     this.setState({ sms: !this.state.sms, smsVal})
    // }
    this.setState({
      [name]: e.target.checked ? 1 : 0,
    });
  }

  sendMail() {
    let data = new FormData();
    this.statusCloseModal();
    var emailVal = this.state.email ? 1 : 0,
      smsVal = this.state.sms ? 1 : 0;
    // if(this.state.email) {
    data.append('type', 'mail');
    data.append('email', emailVal);
    data.append('sms', smsVal);
    data.append('message', this.state.message);
    if (this.state.crew_identifier_id !== '') {
      data.append('crew_identifier_id', this.state.crew_identifier_id);
    }
    var self = this;
    fetch(config.apiUrl + '/api/employee/sendmails', {
      headers: authHeader(),
      method: 'POST',
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: 'Success',
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: '',
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: 'Error',
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: '',
          });
        }
      });
    // }

    this.setState({ crew_identifier_id: '' });
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }

  componentWillReceiveProps(nextProps) {
    const { resource } = nextProps;
    if (resource.items) {
      const { data } = resource.items;
      if (data) {
        let adminList = [];
        let aboutTreesList = [];
        let NVTSList = [];

        if (data.AdminStaff) {
          if (data.AdminStaff.length > 0) {
            adminList = resource.items.data.AdminStaff;
          }
        }
        if (data.AboutTrees) {
          if (data.AboutTrees.length > 0) {
            aboutTreesList = resource.items.data.AboutTrees;
          }
        }
        if (data.NVTS) {
          if (data.NVTS.length > 0) {
            NVTSList = resource.items.data.NVTS;
          }
        }

        this.setState(
          {
            aboutTreesListRaw: aboutTreesList,
            adminListRaw: adminList,
            NVTSListRaw: NVTSList,
          },
          () => {
            this.handleFilter(this.state.selectedStatus);
          }
        );
      }
    }
  }

  handleFilter = (value) => {
    let { adminListRaw, aboutTreesListRaw, NVTSListRaw } = this.state;
    if (value != '') {
      this.setState({ selectedStatus: value });

      let filterdAdminList = adminListRaw.filter((item) => {
        return item.assignment_status == value;
      });
      let filterdAboutTreesList = aboutTreesListRaw.filter((item) => {
        return item.assignment_status == value;
      });
      let filterdNVTSList = NVTSListRaw.filter((item) => {
        return item.assignment_status == value;
      });

      this.setState({
        aboutTreesList: filterdAboutTreesList,
        adminList: filterdAdminList,
        NVTSList: filterdNVTSList,
      });
    } else {
      this.setState({
        aboutTreesList: aboutTreesListRaw,
        adminList: adminListRaw,
        NVTSList: NVTSListRaw,
        selectedStatus: '',
      });
    }
  };

  render() {
    const { resource, permission } = this.props;
    var resource_list = [];

    if (resource.items) {
      const { data } = resource.items;
      var resource_list = [];
      Object.keys(data).map((key) => {
        if (key && key != '' && Array.isArray(data[key])) {
          data[key].map((item, index) => {
            resource_list.push(
              <option key={index} className="item" value={item.id}>
                {item.resource_name}
              </option>
            );
          });
        }
      });
    }
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    const btnList = (
      <div className="btn_list">
        <button
          className="button outlined filter_item"
          onClick={this.toggleView}
        >
          {this.state.list_view === 'grid' && <img src={ListSVG} />}
          {this.state.list_view === 'list' && <img src={GridSVG} />}
        </button>
        {permList.includes('Manage Resources') &&
          permList.includes('Add/Edit Resource') && (
            <Link to={'/create-resource'} className="create_resource button">
              New Resource
            </Link>
          )}
        {permList.includes('Manage Resources') &&
          permList.includes('List Archived Employees') && (
            <Link to={'/archived-resources'} className="new_resource button">
              Archived Resources
            </Link>
          )}
        {/* permList.includes("Manage Resources") && permList.includes("Manage Vehicles") && <Link to={"/vehicles"} className="vehicles button">Vehicle Manager</Link>*/}
        {permList.includes('Manage Resources') &&
          permList.includes('List Archived Employees') && (
            <Link to={'/archived'} className="new_resource button">
              Archived Employees
            </Link>
          )}
        {permList.includes('Manage Resources') &&
          permList.includes('Message Employees') && (
            <button
              className="button create_manifest"
              onClick={this.statusOpenModal.bind(this)}
            >
              Message Employees
            </button>
          )}
      </div>
    );
    if (resource.items) {
      return (
        <div className="resource_container listResourcePage">
          {/* Heading */}
          <div className="page_title float">
            <h1>Resources</h1>
            {btnList}
          </div>

          {/* EACH Resources */}
          <div
            className="resource_list 1"
            ref={(element) => (this.divRef = element)}
          >
            <div className="inner_head">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <h2>About Trees Resource</h2>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <section className="resource_filter">
                  <select
                    ref="statusType"
                    className="filter_item"
                    value={this.state.selectedStatus}
                    onChange={(event) => {
                      this.handleFilter(event.target.value);
                    }}
                  >
                    <option value="">Assignment Status</option>
                    <option value="3">Committed</option>
                    <option value="1">Available</option>
                    <option value="2">Unavailable</option>
                  </select>

                  <input
                    type="text"
                    name="keyword"
                    className="filter_item"
                    placeholder="Search Resources..."
                    onKeyUp={this.keyUpFn.bind(this)}
                    defaultValue={this.state.keyword}
                    autoComplete="off"
                  />
                </section>
              </div>
            </div>
            <div
              id="Showcase_Content_wrapper"
              className="Showcase_Content_wrapper"
              style={{ height: this.state.conentWrapperMinHeight }}
            >
              {this.state.list_view === 'grid' && (
                <SortableList
                  items={this.state.aboutTreesList}
                  // specify collection name as property name in state
                  collection="aboutTreesList"
                  onSortStart={this.onSortStart.bind(this)}
                  onSortEnd={this.onSortEnd.bind(this)}
                  clickFunction={this.details.bind(this)}
                  onImageError={this.onImageError.bind(this)}
                  pressDelay={200}
                  transitionDuration={1200}
                  helperClass="sortableHelper"
                  axis="xy"
                  useWindowAsScrollContainer={true}
                />
              )}

              {this.state.list_view === 'list' && (
                <ListView items={this.state.aboutTreesList}></ListView>
              )}
            </div>
          </div>
          {/* ------------------------------------------------------------------- */}

          <div
            className="resource_list 2"
            ref={(element) => (this.divRefa = element)}
          >
            <div className="inner_head">
              <h2>North Valley Tree Service Resource</h2>
            </div>
            <div
              id="Showcase_Content_wrapper_test"
              className="Showcase_Content_wrapper"
            >
              {this.state.list_view === 'grid' && (
                <SortableList
                  items={this.state.NVTSList}
                  // specify collection name as property name in state
                  collection="NVTSList"
                  onSortStart={this.onSortStart.bind(this)}
                  onSortEnd={this.onSortEnd.bind(this)}
                  clickFunction={this.details.bind(this)}
                  onImageError={this.onImageError.bind(this)}
                  pressDelay={200}
                  transitionDuration={1200}
                  helperClass="sortableHelper"
                  axis="xy"
                  useWindowAsScrollContainer={true}
                />
              )}
              {this.state.list_view === 'list' && (
                <ListView items={this.state.NVTSList}></ListView>
              )}
            </div>
          </div>
          {/* ------------------------------------------------------------------- */}
          {/*Removed Fuel resource as a part of clone activity*/}
          {/* ------------------------------------------------------------------- */}
          {/*Removed GIS resource as a part of clone activity*/}
          {/* ------------------------------------------------------------------- */}

          <div
            className="resource_list 5"
            ref={(element) => (this.divRefa = element)}
          >
            <div className="inner_head">
              <h2>Admin Staff Resource</h2>
            </div>
            <div
              id="Showcase_Content_wrapper_test"
              className="Showcase_Content_wrapper"
            >
              {this.state.list_view === 'grid' && (
                <SortableList
                  items={this.state.adminList}
                  // specify collection name as property name in state
                  collection="adminList"
                  onSortStart={this.onSortStart.bind(this)}
                  onSortEnd={this.onSortEnd.bind(this)}
                  clickFunction={this.details.bind(this)}
                  onImageError={this.onImageError.bind(this)}
                  pressDelay={200}
                  transitionDuration={1200}
                  helperClass="sortableHelper"
                  axis="xy"
                  useWindowAsScrollContainer={true}
                />
              )}
              {this.state.list_view === 'list' && (
                <ListView items={this.state.adminList}></ListView>
              )}
            </div>
          </div>

          {/* Modal */}
          <Modal
            style={'width: 200px'}
            id="status_popup"
            className="status_popup"
            open={this.state.statusOpen}
            onClose={this.statusCloseModal.bind(this)}
            little
          >
            <div id="statusPopup">
              <div className="modal-head">
                <h4 className="modal-title">Custom Email</h4>
              </div>

              <div className="modal-body">
                <div className="status_step step1">
                  <select
                    name="crew_identifier_id"
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value="">Message all employees</option>
                    {resource_list}
                  </select>
                  <div className="cust_email_options">
                    <div className="option_email">
                      <input
                        type="checkbox"
                        name="email"
                        defaultChecked
                        //  value="email"
                        onChange={this.handleCheckbox}
                      />
                      <span>Email</span>
                      <div className="clearfix" />
                    </div>
                    <div className="option_sms">
                      <input
                        type="checkbox"
                        name="sms"
                        defaultChecked
                        // value="sms"
                        onChange={this.handleCheckbox}
                      />
                      <span>SMS</span>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <textarea
                    name="message"
                    cols="80"
                    rows="10"
                    value={this.state.message}
                    onChange={this.handleChange.bind(this)}
                  />
                  <p>Signature will automatically be included.</p>
                  <br />
                  <div className="custom_email_bt_wrp">
                    <button
                      className="button grey"
                      onClick={this.statusCloseModal.bind(this)}
                    >
                      Cancel
                    </button>
                    <button
                      className="button"
                      onClick={this.sendMail.bind(this)}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {this.state.alertPop === true && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      );
    }

    // SHOWING Loaders until Resources are loaded
    return (
      <div className="resource_container listResourcePage">
        <div className="page_title float">
          <h1>Resources</h1>
          {btnList}
          {/* Loaders */}
          <div
            className="resource_list 5"
            ref={(element) => (this.divRef = element)}
          >
            <div className="inner_head">
              <h2>About Trees Resource</h2>
            </div>
            <center
              id="feedLoader"
              className={this.state.loader ? this.state.loader : ''}
            >
              <img
                alt="Loader"
                src={require('../assets/images/loader.gif').default}
              />
            </center>
          </div>
          {/* Loaders */}
          <div
            className="resource_list 6"
            ref={(element) => (this.divRefa = element)}
          >
            <div className="inner_head">
              <h2>Admin Staff Resource</h2>
            </div>
            <center
              id="feedLoader"
              className={this.state.loader ? this.state.loader : ''}
            >
              <img
                alt="Loader"
                src={require('../assets/images/loader.gif').default}
              />
            </center>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource, permission } = state;
  return {
    resource,
    permission,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ListResources);
export { connectedResourcePage as ListResources };
