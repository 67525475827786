export const interviewConstants = {
  INT_FORM_LIST_REQUEST: 'INT_FORM_LIST_REQUEST',
  INT_FORM_LIST_SUCCESS: 'INT_FORM_LIST_SUCCESS',
  INT_FORM_LIST_FAILURE: 'INT_FORM_LIST_FAILURE',

  INT_FORM_GET_REQUEST: 'INT_FORM_GET_REQUEST',
  INT_FORM_GET_SUCCESS: 'INT_FORM_GET_SUCCESS',
  INT_FORM_GET_FAILURE: 'INT_FORM_GET_FAILURE',

  INT_FORM_SAVE_REQUEST: 'INT_FORM_SAVE_REQUEST',
  INT_FORM_SAVE_SUCCESS: 'INT_FORM_SAVE_SUCCESS',
  INT_FORM_SAVE_FAILURE: 'INT_FORM_SAVE_FAILURE',

  INT_FORM_DELETE_REQUEST: 'INT_FORM_DELETE_REQUEST',
  INT_FORM_DELETE_SUCCESS: 'INT_FORM_DELETE_SUCCESS',
  INT_FORM_DELETE_FAILURE: 'INT_FORM_DELETE_FAILURE',
};
