import React, { Component } from 'react';
import { config } from '_helpers/config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { docsActions } from '_actions/docs.actions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tab, Row, Col, NavItem, Nav } from 'react-bootstrap';
import { PaycheckDistribution } from 'Docs/Forms/PaycheckDistribution';
import { CandidateInfoPage } from 'Docs/Forms/CandidateInfoPage';
import { W4 } from 'Docs/Forms/W4';
import { docsService } from '_services/docs.service';
import { chatActions } from '_actions/chat.actions';
import { isMobileOnly } from 'react-device-detect';
import InterviewForm from '../InterviewForm';
import { getCurrentUserPermissions } from '_helpers/reduxSelector';
import TimeOffRequest from 'Docs/Forms/TimeOffRequest';
import DocStatusTag from '_components/utils/DocStatusTag';
class DocsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminMode: false,
      view_doc: false,
      outerTab: 'onboarding',
      tab: 'list',
      doc: {
        new: null,
        id: null,
        slug: 'merchandise-order-form',
      },
      mobile: {
        screen: 'sidebar',
        enabled: false,
        closeConfirmationModal: false,
      },
    };
    this._compareFn = this._compareFn.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.generateDocList = this.generateDocList.bind(this);
    this.createDocument = this.createDocument.bind(this);
    this.viewDoc = this.viewDoc.bind(this);
    this.viewList = this.viewList.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.tabChangeOuter = this.tabChangeOuter.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidUpdate() {
    let { tab } = this.state;
    if (tab === 'document') {
      document.body.classList.add('document-active');
    } else {
      document.body.classList.remove('document-active');
    }
  }
  componentDidMount() {
    let { doc, category, hash, id } = this.props.match.params;
    this.props.dispatch(chatActions.getConversationSettings());

    this.props.match.path === '/profile/:hash' ||
    this.props.match.path === '/profile/:hash/docs/:category/:doc/:id' // single_doc
      ? this.props.dispatch(docsActions.getUserDocs(hash))
      : this.props.dispatch(docsActions.getDocs());

    this.props.match.path === '/profile/:hash/docs/:category/:doc/:id' && // single_doc
      this.setState({
        outerTab: 'other',
        adminMode: true,
        doc: { ...this.state.doc, id, slug: doc },
        view_doc: true,
      });

    this.props.dispatch(docsActions.getDocCategory());
    this.props.dispatch(docsActions.getDocTypes());
    // @param type 'Onboarding = 1,Rehire = 2,Other = 3'
    this.props.dispatch(docsActions.getCategoryDocs(3));

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.props.dispatch(docsActions.getFormOptions());
  }

  handleResize() {
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(() => {
      if (isMobileOnly && window.screen.width < 768) {
        this.setState({ mobile: { ...this.state.mobile, enabled: true } });
      } else {
        this.setState({ mobile: { ...this.state.mobile, enabled: false } });
      }
    }, 100);
  }

  componentWillReceiveProps(props) {
    let loggedInUser = null;
    this.props.user &&
      this.props.user.profile &&
      (loggedInUser = this.props.user.profile.data);
    loggedInUser && this.setState({ adminMode: loggedInUser.is_admin > 0 });
    // single_doc
    this.props.match.path === '/profile/:hash/docs/:category/:doc/:id' &&
      props.docs.userDocs.length > 0 &&
      this.setState({ tab: 'document' });
  }
  // Compare function
  _compareFn(a, b, type, by) {
    if (type === 'docs_name') {
      if (by === 'asc') {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return -1;
        } else if (name_1 > name_2) {
          return 1;
        } else return 0;
      } else {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return 1;
        } else if (name_1 > name_2) {
          return -1;
        } else return 0;
      }
    } else if (type === 'updated_at') {
      if (by === 'asc') {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    }
    return 0;
  }
  // Handle sorting in table
  sortHandler(type) {
    let documents = this.props.docs.userDocs;
    documents.sort();
    switch (type) {
      case 'docs_name':
        if (this.state.order_doc === 'asc') {
          documents.sort((a, b) => this._compareFn(a, b, 'docs_name', 'asc'));
          this.setState({ documents, order_doc: 'desc' });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, 'docs_name', 'desc'));
          this.setState({ documents, order_doc: 'asc' });
        }
        break;
      case 'updated_at':
        if (this.state.order_at === 'asc') {
          documents.sort((a, b) => this._compareFn(a, b, 'updated_at', 'asc'));
          this.setState({ documents, order_at: 'desc' });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, 'updated_at', 'desc'));
          this.setState({ documents, order_at: 'asc' });
        }
        break;
      default:
        return this.state.documents;
    }
  }
  tabChange(tab) {
    this.setState({ tab }, () => {
      let { doc, category, hash, id } = this.props.match.params;
      this.props.match.path === '/profile/:hash' ||
      this.props.match.path === '/profile/:hash/docs/:category/:doc/:id' // single_doc
        ? this.props.dispatch(docsActions.getUserDocs(hash))
        : this.props.dispatch(docsActions.getDocs());
      if (this.props.match.path === '/profile/:hash/docs/:category/:doc/:id') {
        this.props.match.path = '/profile/:hash';
      }
    });
  }
  tabChangeOuter(outerTab) {
    this.setState({ outerTab });
  }
  createDocument() {
    let item = this.props.docs.category_docs[this.state.doc.new]; // index from dropdown
    docsService
      .createDocument(item.docs_type_id, item.docs_category_id)
      .then((res) => {
        console.log(res);
        this.props.dispatch(docsActions.getDocs()).then((response) => {
          this.setState({ doc: { ...this.state.doc, id: res.data.id } }, () => {
            this.tabChange('document');
          });
        });
      })
      .catch((err) => console.log(err));
  }

  generateDocList(list, type, other, rehire) {
    let { doc, category, id } = this.props.match.params;
    let hash = (() => {
      let hash;
      this.props.match.path === '/settings'
        ? this.props.user &&
          this.props.user.profile &&
          (hash = this.props.user.profile.data.hash)
        : (hash = this.props.match.params.hash);
      return hash;
    })();
    let docTypes = this.props?.docs?.types;
    let uid, signature;
    let empty_list, docs_list;
    this.props.user.profile &&
      this.props.user.profile.data &&
      (uid = this.props.user.profile.data.hash); // the user who logged in
    this.props.chat &&
      this.props.chat.settings.signature &&
      (signature = this.props.chat.settings.signature);

    let selectedUser = (() => this.props.user && this.props.user.items.data)();
    let loggedInUser = (() =>
      this.props.user && this.props.user.profile.data)();
    let filteredList = list.filter((doc) => doc.docs_categories_id == type);

    (filteredList.length === 0 && type === 1) || type === 4
      ? (empty_list = (
          <p className="doc-list-empty">
            {this.state.adminMode && selectedUser.hash !== loggedInUser.hash ? (
              <strong>{selectedUser.full_name}</strong>
            ) : (
              'You'
            )}{' '}
            haven't submitted any Onboarding Documents
          </p>
        ))
      : type === 3 || type === 6
        ? (empty_list = (
            <p className="doc-list-empty">
              {this.state.adminMode &&
              selectedUser.hash !== loggedInUser.hash ? (
                <strong>{selectedUser.full_name}</strong>
              ) : (
                'You'
              )}{' '}
              haven't added any Other Documents
            </p>
          ))
        : (empty_list = (
            <p className="doc-list-empty">
              {this.state.adminMode &&
              selectedUser.hash !== loggedInUser.hash ? (
                <strong>{selectedUser.full_name}</strong>
              ) : (
                'You'
              )}{' '}
              haven't added any Rehire Documents
            </p>
          ));

    docs_list = empty_list;

    let timeOffRequestDoc = docTypes?.find(
      (doc) => doc.slug === 'time-off-request'
    );
    let timeOffRequestId = timeOffRequestDoc ? timeOffRequestDoc.id : null;

    filteredList.length > 0 &&
      (docs_list = filteredList.map((doc, index) => {
        let docStatus = '';
        const docStatusColor = doc.status === 3 ? '#3f7e39' : '#c9c188';

        switch (doc?.status) {
          case 1:
            docStatus = 'Not Filled';
            break;
          case 2:
            docStatus = 'Submitted';
            break;
          case 3:
            docStatus = 'Approved';
            break;
          case 4:
            docStatus = 'Denied';
            break;
          default:
            docStatus = '';
            break;
        }
        return (
          <div className="applicant_item list_item" key={index}>
            <div className="homeEmp_list">
              <div className="basic_info unavailable_employe">
                <div className="item_cell">
                  <img
                    className="doc-list-img"
                    src={`${config.assetUrl}icons/doc-check-circle.svg`}
                    alt=""
                  />
                  <span>
                    {doc.doc_type_name}
                    {!!(doc.docs_type_id === timeOffRequestId) && (
                      <DocStatusTag
                        status={docStatus}
                        bgColor={docStatusColor}
                        fontColor={doc.status === 3 ? '#fff' : ''}
                      />
                    )}
                  </span>
                </div>
                <div className="item_cell">
                  <span>
                    {moment(doc.updated_at, 'MM-DD-YYYY').format('MM/DD/YYYY')}
                  </span>
                </div>
                <div className="item_cell  doc_download">
                  {/* Download PDF */}
                  {this.state.adminMode && (
                    <span className={`download-complete`}>
                      <a
                        href={`${config.baseUrl}docs/pdf/${doc.slug}?id=${doc.id}&uid=${uid}&signature=${signature}`}
                        target="_blank"
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-download.svg`}
                          className="closeIco downloadIco"
                          alt=""
                        />
                      </a>
                    </span>
                  )}
                  {other ? (
                    <span>
                      <button
                        className="button plain"
                        onClick={() => this.viewDoc(doc)}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </button>
                    </span>
                  ) : rehire ? (
                    <span>
                      {this.state.adminMode ? (
                        <Link to={`/docs/${hash}/${doc.slug}?rehire=true`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link to={`/dashboard/docs/${doc.slug}?rehire=true`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      )}
                    </span>
                  ) : (
                    <span>
                      {this.state.adminMode ? (
                        <Link to={`/docs/${hash}/${doc.slug}?rehire=false`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link to={`/dashboard/docs/${doc.slug}?rehire=false`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }));

    return docs_list;
  }
  selectHandler(e) {
    let slug = this.props.docs.category_docs[e.target.value]
      ? this.props.docs.category_docs[e.target.value].slug
      : ''; // eg: merchandise order form
    let doc = this.state.doc;
    this.setState({
      doc: { ...doc, new: e.target.value, slug }, // index of item in array - this.props.docs.category_docs
    });
  }
  viewDoc(doc) {
    this.setState({
      doc: { ...this.state.doc, id: doc.id, slug: doc.slug },
      view_doc: true,
      tab: 'document',
    });
  }
  viewList() {
    this.setState({
      doc: { ...this.state.doc, id: null },
      view_doc: false,
      tab: 'list',
    });
  }
  render() {
    let documents = this.props.docs.userDocs;
    let docTypes = this.props.docs.types;
    let categories = this.props.docs.categories;
    let category_docs = this.props.docs.category_docs;
    let selectedUser = null;
    this.props.user &&
      this.props.user.items &&
      (selectedUser = this.props.user.items.data);
    let { outerTab } = this.state;

    return (
      <div className="documents-list user-docs">
        {/* <!--enable this tab contents corresponding to the tab clicked--> */}
        <Tab.Container id="tabs-with-dropdown" activeKey={outerTab}>
          {/* Tabs */}
          <Row className="clearfix">
            <Col sm={12}>
              <div className="custom-tabs">
                <Nav bsStyle="tabs">
                  <NavItem
                    eventKey="onboarding"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('onboarding')}
                  >
                    <i className="fa fa-file"></i>
                    Onboarding <span>Documents</span>
                  </NavItem>
                  <NavItem
                    eventKey="rehire"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('rehire')}
                  >
                    <i className="fa fa-file"></i>
                    Rehire <span>Documents</span>
                  </NavItem>

                  <NavItem
                    eventKey="other"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('other')}
                  >
                    <i className="fa fa-file-alt"></i>
                    Other <span>Documents</span>
                  </NavItem>
                  {this?.props?.currentUserPermissions?.includes(
                    'Manage Interview Forms'
                  ) && (
                    <NavItem
                      eventKey="interviewForms"
                      className="custom-tab"
                      onClick={() => this.tabChangeOuter('interviewForms')}
                    >
                      <i className="fa fa-clipboard-list"></i>
                      Interview Forms
                    </NavItem>
                  )}
                  {/**/}
                </Nav>
              </div>
            </Col>
            {/* Content */}
            <Col sm={12}>
              <div className="custom-tab-content">
                <Tab.Content animation>
                  {/* Onboarding DOCUMENTS */}
                  <Tab.Pane eventKey="onboarding">
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler('docs_name')}
                      >
                        <span className="sort-titles">Name</span>
                        <span
                          className={`${
                            this.state.order_doc === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler('updated_at')}
                      >
                        <span className="sort-titles">Date Updated</span>
                        <span
                          className={`${
                            this.state.order_at === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(documents, 1, false, false)}
                  </Tab.Pane>

                  {/* Rehire DOCUMENTS */}
                  <Tab.Pane eventKey="rehire">
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler('docs_name')}
                      >
                        <span className="sort-titles">Name</span>
                        <span
                          className={`${
                            this.state.order_doc === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler('updated_at')}
                      >
                        <span className="sort-titles">Date Updated</span>

                        <span
                          className={`${
                            this.state.order_at === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(documents, 2, false, true)}
                  </Tab.Pane>

                  {/* Other Documents */}
                  <Tab.Pane eventKey="other">
                    <section className="content onboarding-docs">
                      {/* LIST */}
                      {this.state.tab === 'list' && (
                        <section className="list">
                          <p className="height-none button-container">
                            {this.props.match.path != '/profile/:hash' && (
                              <button
                                className="button"
                                onClick={() => this.tabChange('new')}
                              >
                                Add New Document
                              </button>
                            )}
                          </p>
                          <div className="appln_list_header">
                            <div
                              className="list_item_head"
                              onClick={() => this.sortHandler('docs_name')}
                            >
                              <span className="sort-titles">Name</span>
                              <span
                                className={`${
                                  this.state.order_doc === 'asc'
                                    ? 'asc'
                                    : 'desc'
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div
                              className="list_item_head appln_date"
                              onClick={() => this.sortHandler('updated_at')}
                            >
                              <span className="sort-titles">Date Updated</span>
                              <span
                                className={`${
                                  this.state.order_at === 'asc' ? 'asc' : 'desc'
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div className="list_item_head"></div>
                          </div>
                          {selectedUser &&
                            this.generateDocList(documents, 3, true, false)}
                        </section>
                      )}
                      {/* SELECT DOCUMENT */}
                      {this.state.tab === 'new' && (
                        <section className="new">
                          <p>Document</p>
                          <div className="inputs-items">
                            <select
                              className=""
                              name="document"
                              id="document"
                              value={this.state.doc.new}
                              onChange={this.selectHandler}
                            >
                              <option value="">-Not Selected-</option>
                              {category_docs &&
                                category_docs.map((item, index) => {
                                  let val = JSON.stringify(item);
                                  return (
                                    <option key={index} value={index}>
                                      {item.doc_type_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <p className="actions">
                            <button
                              className="button grey"
                              onClick={() => this.tabChange('list')}
                            >
                              Cancel
                            </button>
                            <button
                              className={`btn button ${this.state.doc.new === '' && 'disabled'}`}
                              onClick={this.createDocument}
                              disabled={
                                String(this.state.doc.new) === '' ||
                                this.state.doc.new === null
                              }
                            >
                              Add Document
                            </button>
                          </p>
                        </section>
                      )}
                      {/* DOCUMENT */}
                      {this.state.tab === 'document' && (
                        <section className="document merchandise-form">
                          <div className="container doc_info">
                            <p className="button-container doc_info-rh no-border form-top-buttons">
                              <button
                                id="back-button"
                                className="button"
                                onClick={this.viewList}
                              >
                                Back
                              </button>
                            </p>
                          </div>

                          <div className="container doc_info full-width_form">
                            {this.state?.doc?.slug ===
                              'paycheck-distribution-form' && (
                              <PaycheckDistribution
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug ===
                              'candidate-information-page' && (
                              <CandidateInfoPage
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === 'w-4' && (
                              <W4
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === 'time-off-request' && (
                              <TimeOffRequest
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                                docDetails={this.state?.doc}
                                status={this.state.doc.status}
                              />
                            )}
                          </div>
                        </section>
                      )}
                    </section>
                  </Tab.Pane>

                  {/* Interview Forms */}

                  <Tab.Pane eventKey="interviewForms">
                    <InterviewForm hash={this.props.userHash} listOnly />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, chat, permission, user } = state;
  return {
    docs,
    chat,
    permission,
    user,
    currentUserPermissions: getCurrentUserPermissions(state),
  };
}

let connectedDocList = compose(withRouter, connect(mapStateToProps))(DocsList);
export { connectedDocList as DocsList };
