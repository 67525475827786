import React from 'react';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { inventoryService, resourceService } from '_services';
import styles from './inventoryQuickAssign.module.css';
import moment from 'moment';

const InventoryQuickAssign = ({
  defaultData,
  onClose,
  equipmentId,
  onComplete,
}) => {
  const [selectedResource, setSelectedResource] = React.useState(null);
  const [selectedDriver, setSelectedDriver] = React.useState(null);
  const [assignedDate, setAssignedDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChooseResources = async (text) => {
    let options = [];
    let data = new FormData();
    data.append('keyword', text);
    const response = await resourceService?.searchResource(data);
    Object.values(response?.data ?? [])?.forEach((items) => {
      items?.forEach((item) => {
        options?.push({ label: item?.resource_name, value: item?.id });
      });
    });
    return options;
  };

  const handleChooseDrivers = async (text) => {
    let options = [];
    if (selectedResource?.value) {
      let drivers = new FormData();
      drivers.append('keyword', text);
      drivers.append('id', selectedResource?.value);
      const response = await resourceService?.searchDriver(drivers);

      response?.data?.forEach((item) => {
        options?.push({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.hash,
        });
      });
    }
    return options;
  };

  React.useEffect(() => {
    if (defaultData?.resource) {
      setSelectedResource({
        label: defaultData?.resource?.resource_name,
        value: defaultData?.resource?.id,
      });
    }
    if (defaultData?.user) {
      setSelectedDriver({
        label: defaultData?.user?.full_name,
        value: defaultData?.user?.hash,
      });
    }
    if (defaultData?.assign_date) {
      setAssignedDate(moment(defaultData?.assign_date));
    }
  }, [defaultData]);

  const handleAssign = async () => {
    setLoading(true);
    let data = new FormData();
    data.append('id', equipmentId ?? '');
    data.append('rid', selectedResource?.value ?? '');
    data.append(
      'assign_date',
      moment(assignedDate)?.isValid()
        ? moment(assignedDate).format('MM/DD/YYYY')
        : ''
    );
    data.append('hash', selectedDriver?.value ?? '');
    await inventoryService?.assignEquipment(data);
    onClose?.();
    onComplete?.();
    setLoading(false);
  };

  return (
    <div className={`${styles?.container} ${loading ? styles?.isLoading : ''}`}>
      <div className={styles?.header}>
        <div>
          <h5>Assign (Resource/Driver/Employee)</h5>
          <p className={styles?.uniqueId}>{defaultData?.unique_id}</p>
        </div>

        <div onClick={() => onClose?.()}>
          <img
            alt="close"
            className="closeModal"
            src={require('../../assets/images/close_green.png').default}
          />
        </div>
      </div>
      <div className={styles?.content}>
        <div>
          <label>Assign Resource</label>
          <AsyncSelect
            value={selectedResource}
            cacheOptions
            loadOptions={handleChooseResources}
            onChange={(selection) => {
              setSelectedResource(selection);
              setSelectedDriver({});
            }}
            defaultOptions
            isClearable
          />
        </div>
        <div className={styles?.marginVertical15}>
          <label>Assign Driver/Employee</label>
          <AsyncSelect
            value={selectedDriver}
            loadOptions={handleChooseDrivers}
            onChange={(selection) => setSelectedDriver(selection)}
            defaultOptions
            isClearable
            placeholder="Search Driver/Employee"
          />
        </div>
        <div className={styles?.datePickerContainer}>
          <label>Assigned Date</label>
          <DatePicker
            id="assignedDate"
            name="assignedDate"
            selected={assignedDate}
            onChange={(date) => setAssignedDate(date)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={moment()}
          />
        </div>
      </div>

      <div className={styles?.footer}>
        <button
          disabled={
            !selectedResource?.value || !selectedDriver?.value || !assignedDate
          }
          onClick={() => handleAssign()}
          className="button"
        >
          Assign
        </button>
      </div>
    </div>
  );
};

export default InventoryQuickAssign;
