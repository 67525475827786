import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { toast } from 'react-toastify';
import { SignPad } from 'Docs/Components';
import moment from 'moment';
import { FormNav } from './FormNav';
import { navigate, validate } from './formHandlers';
import logo from 'assets/images/logo.svg';
import { dateTimeConstants } from '_constants';

class MealPeriodInformationAndWaiver extends Component {
  constructor(props) {
    const options = {
      timeZone: dateTimeConstants.PST_TIMEZONE,
      month: dateTimeConstants.TWO_DIGIT_FORMAT,
      day: dateTimeConstants.TWO_DIGIT_FORMAT,
      year: dateTimeConstants.NUMERIC_FORMAT,
    };
    const formattedDate = new Date().toLocaleDateString(
      dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
      options
    );

    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        allow_submit: false,

        // Dates
        first_meal_period_waiver_date: formattedDate,
        second_meal_period_waiver_date: formattedDate,
        first_meal_period_withdrawal_waiver_date: formattedDate,
        second_meal_period_withdrawal_waiver_date: formattedDate,

        // Employee Signatures
        first_meal_period_waiver_employee_signature: null,
        second_meal_period_waiver_employee_signature: null,
        first_meal_period_withdrawal_waiver_employee_signature: null,
        second_meal_period_withdrawal_waiver_employee_signature: null,

        // Employee names
        first_meal_period_waiver_employee_name: null,
        second_meal_period_waiver_employee_name: null,
        first_meal_period_withdrawal_waiver_employee_name: null,
        second_meal_period_withdrawal_waiver_employee_name: null,
      },
      errors: {},
      form_edited: false,
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  allowFormSubmission() {
    let allow_submit = false;
    let first_meal_period_waiver =
      this.state.form.first_meal_period_waiver_date &&
      this.state.form.first_meal_period_waiver_employee_signature &&
      this.state.form.first_meal_period_waiver_employee_name;
    let second_meal_period_waiver =
      this.state.form.second_meal_period_waiver_date &&
      this.state.form.second_meal_period_waiver_employee_signature &&
      this.state.form.second_meal_period_waiver_employee_name;
    let first_meal_period_withdrawal_waiver =
      this.state.form.first_meal_period_withdrawal_waiver_date &&
      this.state.form.first_meal_period_withdrawal_waiver_employee_signature &&
      this.state.form.first_meal_period_withdrawal_waiver_employee_name;
    let second_meal_period_withdrawal_waiver =
      this.state.form.second_meal_period_withdrawal_waiver_date &&
      this.state.form.second_meal_period_withdrawal_waiver_employee_signature &&
      this.state.form.second_meal_period_withdrawal_waiver_employee_name;

    if (
      first_meal_period_waiver &&
      second_meal_period_waiver &&
      first_meal_period_withdrawal_waiver &&
      second_meal_period_withdrawal_waiver
    ) {
      allow_submit = false;
    } else if (
      (first_meal_period_waiver &&
        second_meal_period_waiver &&
        !first_meal_period_withdrawal_waiver &&
        !second_meal_period_withdrawal_waiver) ||
      (first_meal_period_withdrawal_waiver &&
        second_meal_period_withdrawal_waiver &&
        !first_meal_period_waiver &&
        !second_meal_period_waiver)
    ) {
      allow_submit = true;
    }

    this.setState({
      form: {
        ...this.state.form,
        allow_submit,
      },
    });
    return {
      meal_period_waiver: first_meal_period_waiver && second_meal_period_waiver,
      meal_period_withdrawal_waiver:
        first_meal_period_withdrawal_waiver &&
        second_meal_period_withdrawal_waiver,
    };
  }

  // Handle Datepicker changes
  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
    this.allowFormSubmission();
  }

  // Handle Form Change
  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
      this.allowFormSubmission();
    }

    !this.state.form_edited && this.setState({ form_edited: true });
  }

  submitForm(e) {
    e.preventDefault();

    // Check if required set of fields are filled
    const withdrawOrAcceptWaiverResponse = this.allowFormSubmission();
    if (!this.state.form.allow_submit) {
      toast.error(
        'Please complete all sections of either the Consent or Withdrawal Form for Meal Period Waiver.'
      );
      return;
    }

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (withdrawOrAcceptWaiverResponse.meal_period_waiver) {
      // If meal waiver has been filled, make withdrawal fields null
      data.form = {
        ...data.form,
        first_meal_period_withdrawal_waiver_date: null,
        first_meal_period_withdrawal_waiver_employee_signature: null,
        first_meal_period_withdrawal_waiver_employee_name: null,
        second_meal_period_withdrawal_waiver_date: null,
        second_meal_period_withdrawal_waiver_employee_signature: null,
        second_meal_period_withdrawal_waiver_employee_name: null,
      };
    } else if (withdrawOrAcceptWaiverResponse.meal_period_withdrawal_waiver) {
      // If meal waiver withdrawal has been filled, make acceptance fields null
      data.form = {
        ...data.form,
        first_meal_period_waiver_date: null,
        first_meal_period_waiver_employee_signature: null,
        first_meal_period_waiver_employee_name: null,
        second_meal_period_waiver_date: null,
        second_meal_period_waiver_employee_signature: null,
        second_meal_period_waiver_employee_name: null,
      };
    }

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: '',
      },
    });
    this.allowFormSubmission();
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.first_meal_period_waiver_date =
          form.first_meal_period_waiver_date ||
          currentState.form.first_meal_period_waiver_date;
        form.second_meal_period_waiver_date =
          form.second_meal_period_waiver_date ||
          currentState.form.second_meal_period_waiver_date;
        form.first_meal_period_withdrawal_waiver_date =
          form.first_meal_period_withdrawal_waiver_date ||
          currentState.form.first_meal_period_withdrawal_waiver_date;
        form.second_meal_period_withdrawal_waiver_date =
          form.second_meal_period_withdrawal_waiver_date ||
          currentState.form.second_meal_period_withdrawal_waiver_date;

        if (!form?.first_meal_period_waiver_employee_name) {
          form.first_meal_period_waiver_employee_name = user?.full_name ?? '';
        }
        if (!form?.second_meal_period_waiver_employee_name) {
          form.second_meal_period_waiver_employee_name = user?.full_name ?? '';
        }
        if (!form?.first_meal_period_withdrawal_waiver_employee_name) {
          form.first_meal_period_withdrawal_waiver_employee_name =
            user?.full_name ?? '';
        }
        if (!form?.second_meal_period_withdrawal_waiver_employee_name) {
          form.second_meal_period_withdrawal_waiver_employee_name =
            user?.full_name ?? '';
        }

        // spread the data coming from API into state
        this.setState({
          form,
        });

        this.allowFormSubmission();
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    let { activeForm } = this.props.docs;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });

    this.getFormData(doc.id);
  }

  render() {
    let { form, errors } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title fs-consent-doc-title">
          <img src={logo} className="doc-title-img" />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Meal Period Information and Waiver</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form mpn"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="mpn"
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <p>
                  Employees who work more than five (5) hours in a work period
                  are entitled to an unpaid duty-free meal period of at least
                  thirty (30) minutes in length starting no later than the end
                  of the fifth hour of work. The Company provides employees the
                  opportunity to take meal breaks in accordance with the law.
                </p>
                <p>
                  Employees who work no more than six (6) hours in a day may
                  waive the meal period upon written agreement between the
                  Company and the employee.
                </p>
                <p>
                  By signing below, you affirmatively choose to waive your meal
                  period when you work no more than six (6) hours in a day. By
                  providing you with this form, the Company expressly agrees to
                  the waiver. You can withdraw this consent to waive the meal
                  period at any time without any consequences.
                </p>
              </div>
            </div>

            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.first_meal_period_waiver_date'] ? 'error' : ''} w-100`}
                      name="first_meal_period_waiver_date"
                      id="first_meal_period_waiver_date"
                      selected={
                        form.first_meal_period_waiver_date
                          ? moment(form.first_meal_period_waiver_date)
                          : null
                      }
                      field="form.first_meal_period_waiver_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.first_meal_period_waiver_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors['form.first_meal_period_waiver_date'] && (
                      <label
                        htmlFor="first_meal_period_waiver_date"
                        className="error_label"
                      >
                        {errors['form.first_meal_period_waiver_date']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.first_meal_period_waiver_employee_signature'
                            )}
                            signature={
                              form.first_meal_period_waiver_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.first_meal_period_waiver_employee_signature
                            }
                            name="first_meal_period_waiver_employee_signature"
                            field="form.first_meal_period_waiver_employee_signature"
                          ></input>
                          {errors[
                            'form.first_meal_period_waiver_employee_signature'
                          ] && (
                            <label
                              htmlFor="first_meal_period_waiver_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  'form.first_meal_period_waiver_employee_signature'
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div
                  className="inputs-col"
                  style={{ width: '50%', marginLeft: 'auto' }}
                >
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.first_meal_period_waiver_employee_name'] ? 'error' : ''}`}
                      name="first_meal_period_waiver_employee_name"
                      id="first_meal_period_waiver_employee_name"
                      value={form.first_meal_period_waiver_employee_name}
                      field="form.first_meal_period_waiver_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors['form.first_meal_period_waiver_employee_name'] && (
                      <label
                        htmlFor="first_meal_period_waiver_employee_name"
                        className="error_label"
                      >
                        {errors['form.first_meal_period_waiver_employee_name']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                class="col-md-12"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h5 style={{ fontWeight: '600' }}>
                  Waiver of Second Meal Period
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  Employees are also entitled to take a second thirty-minute
                  meal period after working more than ten (10) hours in a single
                  work period. The Company provides employees the opportunity to
                  take meal breaks in accordance with the law. Employees may
                  waive this second meal period if all of the following
                  conditions are met:
                </p>
                <ul>
                  <li>
                    Employee has already taken their first meal period; and
                  </li>
                  <li>
                    Employee’s work period does not exceed twelve (12) total
                    hours.
                  </li>
                </ul>
                <p>
                  Unless the preceding conditions are met, Employee may not
                  waive the second meal period.
                </p>
                <p>
                  By signing below, you affirmatively choose to waive your
                  second meal period when you work between 10 – 12 hours in a
                  given work period. By providing you with this form, the
                  Company expressly agrees to the waiver. You can withdraw this
                  consent form to waive the second meal period by signing your
                  consent form to withdraw at any time without any consequences.
                </p>
              </div>
            </div>

            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.second_meal_period_waiver_date'] ? 'error' : ''} w-100`}
                      name="second_meal_period_waiver_date"
                      id="second_meal_period_waiver_date"
                      selected={
                        form.second_meal_period_waiver_date
                          ? moment(form.second_meal_period_waiver_date)
                          : null
                      }
                      field="form.second_meal_period_waiver_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.second_meal_period_waiver_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors['form.second_meal_period_waiver_date'] && (
                      <label
                        htmlFor="second_meal_period_waiver_date"
                        className="error_label"
                      >
                        {errors['form.second_meal_period_waiver_date']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.second_meal_period_waiver_employee_signature'
                            )}
                            signature={
                              form.second_meal_period_waiver_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.second_meal_period_waiver_employee_signature
                            }
                            name="second_meal_period_waiver_employee_signature"
                            field="form.second_meal_period_waiver_employee_signature"
                          ></input>
                          {errors[
                            'form.second_meal_period_waiver_employee_signature'
                          ] && (
                            <label
                              htmlFor="second_meal_period_waiver_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  'form.second_meal_period_waiver_employee_signature'
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div
                  className="inputs-col"
                  style={{ width: '50%', marginLeft: 'auto' }}
                >
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.second_meal_period_waiver_employee_name'] ? 'error' : ''}`}
                      name="second_meal_period_waiver_employee_name"
                      id="second_meal_period_waiver_employee_name"
                      value={form.second_meal_period_waiver_employee_name}
                      field="form.second_meal_period_waiver_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors['form.second_meal_period_waiver_employee_name'] && (
                      <label
                        htmlFor="second_meal_period_waiver_employee_name"
                        className="error_label"
                      >
                        {errors['form.second_meal_period_waiver_employee_name']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '160px',
                borderTop: '#dadada 1px solid',
              }}
            >
              <p>-- OR --</p>
            </div>

            <div className="doc-title fs-consent-doc-title">
              <img src={logo} className="doc-title-img" />
              <span className="close-button" onClick={this.goBack}>
                <img src={`${config.assetUrl}icons/back-btn.svg`} />
              </span>
              <h3>Consent To Withdraw Meal Waiver</h3>
            </div>

            <div className="row" style={{ paddingTop: '30px' }}>
              <div className="col-md-12">
                <p>
                  Only sign if you are withdrawing consent to meal waivers
                  previously signed.
                </p>
                <p>
                  <u>First Meal Period</u>
                </p>
                <p>
                  I hereby withdraw my consent to waive my first meal period
                  when working six hours or less a day.
                </p>
              </div>
            </div>

            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.first_meal_period_withdrawal_waiver_date'] ? 'error' : ''} w-100`}
                      name="first_meal_period_withdrawal_waiver_date"
                      id="first_meal_period_withdrawal_waiver_date"
                      selected={
                        form.first_meal_period_withdrawal_waiver_date
                          ? moment(
                              form.first_meal_period_withdrawal_waiver_date
                            )
                          : null
                      }
                      field="form.first_meal_period_withdrawal_waiver_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.first_meal_period_withdrawal_waiver_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors[
                      'form.first_meal_period_withdrawal_waiver_date'
                    ] && (
                      <label
                        htmlFor="first_meal_period_withdrawal_waiver_date"
                        className="error_label"
                      >
                        {
                          errors[
                            'form.first_meal_period_withdrawal_waiver_date'
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.first_meal_period_withdrawal_waiver_employee_signature'
                            )}
                            signature={
                              form.first_meal_period_withdrawal_waiver_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.first_meal_period_withdrawal_waiver_employee_signature
                            }
                            name="first_meal_period_withdrawal_waiver_employee_signature"
                            field="form.first_meal_period_withdrawal_waiver_employee_signature"
                          ></input>
                          {errors[
                            'form.first_meal_period_withdrawal_waiver_employee_signature'
                          ] && (
                            <label
                              htmlFor="first_meal_period_withdrawal_waiver_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  'form.first_meal_period_withdrawal_waiver_employee_signature'
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div
                  className="inputs-col"
                  style={{ width: '50%', marginLeft: 'auto' }}
                >
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.first_meal_period_withdrawal_waiver_employee_name'] ? 'error' : ''}`}
                      name="first_meal_period_withdrawal_waiver_employee_name"
                      id="first_meal_period_withdrawal_waiver_employee_name"
                      value={
                        form.first_meal_period_withdrawal_waiver_employee_name
                      }
                      field="form.first_meal_period_withdrawal_waiver_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors[
                      'form.first_meal_period_withdrawal_waiver_employee_name'
                    ] && (
                      <label
                        htmlFor="first_meal_period_withdrawal_waiver_employee_name"
                        className="error_label"
                      >
                        {
                          errors[
                            'form.first_meal_period_withdrawal_waiver_employee_name'
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: '30px' }}>
              <div className="col-md-12">
                <p>
                  <u>Second Meal Period</u>
                </p>
                <p>
                  I hereby withdraw my consent to waive my meal for the second
                  meal period.
                </p>
              </div>
            </div>

            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.second_meal_period_withdrawal_waiver_date'] ? 'error' : ''} w-100`}
                      name="second_meal_period_withdrawal_waiver_date"
                      id="second_meal_period_withdrawal_waiver_date"
                      selected={
                        form.second_meal_period_withdrawal_waiver_date
                          ? moment(
                              form.second_meal_period_withdrawal_waiver_date
                            )
                          : null
                      }
                      field="form.second_meal_period_withdrawal_waiver_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.second_meal_period_withdrawal_waiver_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors[
                      'form.second_meal_period_withdrawal_waiver_date'
                    ] && (
                      <label
                        htmlFor="second_meal_period_withdrawal_waiver_date"
                        className="error_label"
                      >
                        {
                          errors[
                            'form.second_meal_period_withdrawal_waiver_date'
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.second_meal_period_withdrawal_waiver_employee_signature'
                            )}
                            signature={
                              form.second_meal_period_withdrawal_waiver_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.second_meal_period_withdrawal_waiver_employee_signature
                            }
                            name="second_meal_period_withdrawal_waiver_employee_signature"
                            field="form.second_meal_period_withdrawal_waiver_employee_signature"
                          ></input>
                          {errors[
                            'form.second_meal_period_withdrawal_waiver_employee_signature'
                          ] && (
                            <label
                              htmlFor="second_meal_period_withdrawal_waiver_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  'form.second_meal_period_withdrawal_waiver_employee_signature'
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div
                  className="inputs-col"
                  style={{ width: '50%', marginLeft: 'auto' }}
                >
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.second_meal_period_withdrawal_waiver_employee_name'] ? 'error' : ''}`}
                      name="second_meal_period_withdrawal_waiver_employee_name"
                      id="second_meal_period_withdrawal_waiver_employee_name"
                      value={
                        form.second_meal_period_withdrawal_waiver_employee_name
                      }
                      field="form.second_meal_period_withdrawal_waiver_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors[
                      'form.second_meal_period_withdrawal_waiver_employee_name'
                    ] && (
                      <label
                        htmlFor="second_meal_period_withdrawal_waiver_employee_name"
                        className="error_label"
                      >
                        {
                          errors[
                            'form.second_meal_period_withdrawal_waiver_employee_name'
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.allow_submit}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedMealPeriodInformationAndWaiver = compose(
  withRouter,
  connect(mapStateToProps)
)(MealPeriodInformationAndWaiver);
export { connectedMealPeriodInformationAndWaiver as MealPeriodInformationAndWaiver };
