import React, { Component } from 'react';
import { config } from '_helpers/config';
class NoMobileSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { feature } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="onboarding-docs">
            <div className="doc-home">
              <div className="doc-home-info">
                <img
                  src={`${config.assetUrl}icons/no-mobile-support.svg`}
                  className="doc-home-img"
                  alt=""
                />
                {(() => {
                  switch (feature) {
                    case 'docs':
                      return (
                        <p>
                          At this time, NVAM does not support editing your
                          onboarding documents using a mobile device. Please
                          revisit this webpage on a desktop or tablet device.
                        </p>
                      );
                    default:
                      return (
                        <p>
                          At this time, NVAM does not support this feature in a
                          mobile device. Please revisit this webpage on a
                          desktop or tablet device.
                        </p>
                      );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { NoMobileSupport };
