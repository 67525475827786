import moment from 'moment';

function mapEmptyToNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == undefined) {
      data[key] = null;
    }
  });
  return data;
}

export default mapEmptyToNull;

const DATE_FORMAT = 'MM/DD/YYYY';

export const datePickerNullSanitize = (date) =>
  date ? moment(date).format(DATE_FORMAT) : '';
