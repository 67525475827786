import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { usPhone } from '_utils/formatter';
import { toast } from 'react-toastify';
import { FormNav } from './FormNav';
import { navigate, validate } from './formHandlers';
import { SignPad } from 'Docs/Components';
import moment from 'moment';

class EmployeeRehireForm extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: true,
      doc: {},
      user: {
        mailing_address: {
          address: '',
          city: '',
          state: '',
          zip: '',
        },
        emergency_contact: 'keep-prior/update',
        gender: 'Male',
        emgcontact1: {},
        emgcontact2: {},
        emergency_contacts: [
          {
            emergency_contact_name: '',
            emergency_contact_relationship: '',
            emergency_contact_phone: '',
            emergency_contact_phone_alt: '',
          },
        ],
      },
      form: {
        first_name: '',
        middle_name: '',
        last_name: '',
        date: moment().format('MM/DD/YYYY'),
        crew_identifier_id: '',
        company_driver: 1,
        DOL_blue_card: 0,
        DMV_green_card: 0,
        height: '',
        weight: '',
        pant_size: '',
        inseam_pant_size: '',
        shirt_size: '',
        job_type: 'seasonal',
        mailing_address: {
          address: '',
          city: '',
          state: '',
          zip: '',
        },
        phone: '',
        additional_phone: '',
        contact_name: '',
        email: '',
        mailing_address: {
          address: '',
          city: '',
          state: '',
          zip: '',
        },
        emergency_contact: 'keep-prior',
        emgcontact1: {
          fname: '',
          lname: '',
          phone: '',
          alt_phone: '',
          relation: '',
        },
        emgcontact2: {
          fname: '',
          lname: '',
          phone: '',
          alt_phone: '',
          relation: '',
        },
        allergies: '',
        prescriptions: '',
        disability: 'yes',
        employment_off_season: 'worked',
        employer_1: {
          employer_name: 'Ferrari ',
          employer_address: 'Maranello, Italy',
          employer_supervisor: 'Fabizio Marzeneli',
          employer_phone: '1234567890',
          employer_position: 'Driver',
          employer_start_date: moment().format('MM/DD/YYYY'),
          employer_end_date: moment().format('MM/DD/YYYY'),
        },
        employer_2: {
          employer_name: 'Arsenal FC',
          employer_address: 'London Colony, London, UK',
          employer_supervisor: 'Arsene Wenger',
          employer_phone: '1234567890',
          employer_position: 'IT Guy',
          employer_start_date: moment().format('MM/DD/YYYY'),
          employer_end_date: moment().format('MM/DD/YYYY'),
        },
        endurance_capable: 'yes',
        physically_capable: 'yes',
        physically_capable_reason: '',
        under_medications: 'yes',
        under_medications_details: '',
        company_policy_agreement: 'yes',
        drug_test_agreement: 'yes',
        work_eligibility_issue: 'yes',
        required_training_completed: 'yes',
        required_training_completed_reason: '',
        employee_signature: null,
        signed_date: moment().format('MM/DD/YYYY'),
        disclaimer_check: true,
      },
      errors: {},
      form_edited: false,
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.permissions = this.permissions.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.signContainer = React.createRef();
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: '',
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let { nextState, form } = this.state;
      let { value, checked, type, name } = e.target;
      // form specific actions
      let emgcontact_reset = {
        fname: '',
        lname: '',
        phone: '',
        alt_phone: '',
        relation: '',
      };
      if (name === 'emergency_contact' && value === 'keep-prior') {
        form = {
          ...form,
          emgcontact1: this.state.user.emgcontact1,
          emgcontact2: this.state.user.emgcontact2,
        };
      } else if (name === 'emergency_contact') {
        form = {
          ...form,
          emgcontact1: { ...emgcontact_reset },
          emgcontact2: { ...emgcontact_reset },
        };
      }
      nextState = { ...nextState, form };
      type == 'checkbox'
        ? _.set(nextState, field, checked)
        : _.set(nextState, field, value);
      this.setState({
        ...nextState,
        errors: { ...this.state.errors, [field]: '' },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();
    if (err) {
      return;
    }

    let { doc, user, form } = this.state;

    user.middle_name = form.middle_name;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      user,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }

    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      this.props.dispatch(docsActions.getUserDocs(user.hash));
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  permissions(value, permissions) {
    let access = false;
    access = permissions.includes(value);
    return access;
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        const mailing_address = user.mailing_address;
        const phone = user.phone;
        const email = user.email;
        const crew_identifier_id = user.crew_identifier_id;
        const first_name = user.first_name;
        const middle_name = user.middle_name;
        const last_name = user.last_name;
        const height = user.height;
        const weight = user.weight;
        const pant_size = user.pant_size;
        const shirt_size = user.shirt_size;
        const inseam_pant_size = user.inseam_pant_size;
        const job_type = form.job_type || this.state.form.job_type;
        form = {
          ...this.state.form,
          mailing_address,
          phone,
          email,
          crew_identifier_id,
          first_name,
          middle_name,
          last_name,
          weight,
          pant_size,
          shirt_size,
          inseam_pant_size,
          height,
          ...form,
          job_type,
        };
        if (this.state.form.emergency_contact === 'keep-prior') {
          form.emgcontact1 = user.emgcontact1;
          form.emgcontact2 = user.emgcontact2;
        }
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.signed_date = form.signed_date || currentState.form.signed_date;
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    // still linked to state after destructuring
    let { user, form, errors } = this.state;
    let { adminMode } = this.props;
    let {
      classifications,
      pantSize,
      shirtSize,
      race,
      crewsList,
      userDocs,
      inseamPantSize,
    } = this.props.docs;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Employee Re-Hire Form</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form rehire"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="candidate_info_page"
            noValidate
          >
            {/* Full name, Date and crew */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Full Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items group">
                    <div className="grouped">
                      <input
                        className={`${errors['form.first_name'] ? 'error' : ''}`}
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={form.first_name}
                        field="form.first_name"
                        placeholder="First Name"
                        required
                      />
                      {errors['form.first_name'] && (
                        <label htmlFor="first_name" className="error_label">
                          {errors['form.first_name']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.middle_name'] ? 'error' : ''}`}
                        name="middle_name"
                        id="middle_name"
                        value={form.middle_name}
                        field="form.middle_name"
                        placeholder="Middle Name"
                      />
                      {errors['form.middle_name'] && (
                        <label htmlFor="middle_name" className="error_label">
                          {errors['form.middle_name']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.last_name'] ? 'error' : ''}`}
                        name="last_name"
                        id="last_name"
                        value={form.last_name}
                        field="form.last_name"
                        placeholder="Last Name"
                        required
                      />
                      {errors['form.last_name'] && (
                        <label htmlFor="last_name" className="error_label">
                          {errors['form.last_name']}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.date'] ? 'error' : ''} w-100`}
                      name="date"
                      id="date"
                      required
                      selected={moment(form.date)}
                      field="form.date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, 'form.date')}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {/* <input type="text" className={`${errors['form.date'] ? 'error' : ''}`} name="date" id="date" value={form.date} field="form.date" placeholder="mm / dd / yyyy" /> */}
                    {errors['form.date'] && (
                      <label htmlFor="date" className="error_label">
                        {errors['form.date']}
                      </label>
                    )}
                  </div>
                </div>

                <div className="inputs-col">
                  <div className="inputs-head">Crew</div>
                  <div className="inputs-items">
                    <select
                      className={`${errors['form.crew_identifier_id'] ? 'error' : ''}`}
                      name="crew"
                      id="crew"
                      value={form.crew_identifier_id}
                      field="form.crew_identifier_id"
                    >
                      <option value="">- Select -</option>
                      {crewsList.map((crew) => (
                        <option value={crew.id} key={crew.id}>
                          {crew.resource_name}
                        </option>
                      ))}
                    </select>
                    {errors['form.crew_identifier_id'] && (
                      <label htmlFor="crew" className="error_label">
                        {errors['form.crew_identifier_id']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Company Driver */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Company Driver<span className="required-star">&#x2A;</span>
                  </div>

                  <div className="check_radio_group gender_radio">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="company_driver"
                        value={0}
                        field="form.company_driver"
                        id="no_company_driver"
                        checked={form.company_driver == 0}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="no_company_driver">
                        <strong>No</strong>
                      </label>
                    </div>

                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="company_driver"
                        value={1}
                        field="form.company_driver"
                        id="yes_company_driver"
                        checked={form.company_driver == 1}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="yes_company_driver">
                        <strong>Yes</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* Only if True */}
              {form.company_driver == 1 && (
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer dd-check">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="DOL_blue_card"
                          id="DOL_blue_card"
                          field="form.DOL_blue_card"
                          value={1}
                          checked={form.DOL_blue_card == 1}
                        />
                        <span className="checkbox_checkbox"></span>

                        <label htmlFor="DOL_blue_card">
                          Current DOL Blue Card
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Height, weight, shirt pant */}
            <div className="row form-row">
              <div className="col emp-sizes dress-measurements">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Height <span className="required-star">&#x2A;</span>
                    <span>(in cm)</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.height'] ? 'error' : ''}`}
                      name="height"
                      id="height"
                      value={form.height}
                      field="form.height"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors['form.height'] && (
                      <label htmlFor="height" className="error_label">
                        {errors['form.height']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Weight <span className="required-star">&#x2A;</span>
                    <span>(in lbs)</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.weight'] ? 'error' : ''}`}
                      name="weight"
                      id="weight"
                      value={form.weight}
                      field="form.weight"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors['form.weight'] && (
                      <label htmlFor="weight" className="error_label">
                        {errors['form.weight']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Pant Size<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <select
                      className={`${errors['form.pant_size'] ? 'error' : ''}`}
                      name="pant_size"
                      id="pant_size"
                      value={form.pant_size}
                      field="form.pant_size"
                      required
                    >
                      <option value="">- Select -</option>
                      {pantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors['form.pant_size'] && (
                      <label htmlFor="pant_size" className="error_label">
                        {errors['form.pant_size']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Inseam Pant Size
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <select
                      className={`${errors['form.inseam_pant_size'] ? 'error' : ''}`}
                      name="inseam_pant_size"
                      id="inseam_pant_size"
                      value={form.inseam_pant_size}
                      field="form.inseam_pant_size"
                      required
                    >
                      <option value="">- Select -</option>
                      {inseamPantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors['form.inseam_pant_size'] && (
                      <label htmlFor="inseam_pant_size" className="error_label">
                        {errors['form.inseam_pant_size']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Shirt Size<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <select
                      className={`${errors['form.shirt_size'] ? 'error' : ''}`}
                      name="shirt_size"
                      id="shirt_size"
                      value={form.shirt_size}
                      field="form.shirt_size"
                      required
                    >
                      <option value="">- Select -</option>
                      {shirtSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors['form.shirt_size'] && (
                      <label htmlFor="shirt_size" className="error_label">
                        {errors['form.shirt_size']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Job Type Fulltime, Part time, seasonal, Project/temp */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Job Type<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="check_radio_group gender_radio jobType row">
                    {/* Full Time */}
                    {(user.is_admin === 1 ||
                      this.permissions(
                        user.user_type_id,
                        [3, 4, 5, 6, 8, 9, 10, 11]
                      )) && (
                      <div className="col-md-4">
                        <div className="check_radio_item agreement_radio">
                          <input
                            type="radio"
                            name="job_type"
                            value="full-time"
                            field="form.job_type"
                            id="full-time"
                            checked={form.job_type === 'full-time'}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="full-time">
                            <strong>Full-Time</strong>
                          </label>
                        </div>
                      </div>
                    )}

                    {/* Part Time */}
                    {(user.is_admin === 1 ||
                      this.permissions(
                        user.user_type_id,
                        [3, 4, 5, 6, 8, 9, 10, 11]
                      )) && (
                      <div className="col-md-4">
                        <div className="check_radio_item agreement_radio">
                          <input
                            type="radio"
                            name="job_type"
                            value="part-time"
                            field="form.job_type"
                            id="part-time"
                            checked={form.job_type === 'part-time'}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="part-time">
                            <strong>Part-TIme</strong>
                          </label>
                        </div>
                      </div>
                    )}

                    {/* Seasonal */}
                    {(user.user_type_id === 7 ||
                      this.permissions(
                        user.user_type_id,
                        [3, 4, 5, 6, 8, 9, 10, 11]
                      )) && (
                      <div className="col-md-4">
                        <div className="check_radio_item agreement_radio">
                          <input
                            type="radio"
                            name="job_type"
                            value="seasonal"
                            field="form.job_type"
                            id="seasonal"
                            checked={form.job_type === 'seasonal'}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="seasonal">
                            <strong>Seasonal</strong>
                          </label>
                        </div>
                      </div>
                    )}

                    {/* Temp/Project */}
                    {(user.is_admin === 1 ||
                      user.user_type_id === 7 ||
                      this.permissions(
                        user.user_type_id,
                        [3, 4, 5, 6, 8, 9, 10, 11]
                      )) && (
                      <div className="col-md-4">
                        <div className="check_radio_item agreement_radio">
                          <input
                            type="radio"
                            name="job_type"
                            value="temporary/project"
                            field="form.job_type"
                            id="temporary/project"
                            checked={form.job_type === 'temporary/project'}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="temporary/project">
                            <strong>Project/Temporary</strong>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Address */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Mailing Address<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.mailing_address.address'] ? 'error' : ''}`}
                      name="address"
                      id="address"
                      value={form.mailing_address.address}
                      field="form.mailing_address.address"
                      placeholder="Street or Box Number"
                      required
                    />
                    {errors['form.mailing_address.address'] && (
                      <label htmlFor="address" className="error_label">
                        {errors['form.mailing_address.address']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-items group">
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.mailing_address.city'] ? 'error' : ''}`}
                        name="city"
                        id="city"
                        value={form.mailing_address.city}
                        field="form.mailing_address.city"
                        placeholder="City"
                        required
                      />
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.mailing_address.state'] ? 'error' : ''}`}
                        name="state"
                        id="state"
                        value={form.mailing_address.state}
                        field="form.mailing_address.state"
                        placeholder="State"
                        required
                      />
                      {errors['form.mailing_address.state'] && (
                        <label htmlFor="state" className="error_label">
                          {errors['form.mailing_address.state']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.mailing_address.zip'] ? 'error' : ''}`}
                        name="zip"
                        id="zip"
                        value={form.mailing_address.zip}
                        field="form.mailing_address.zip"
                        placeholder="Zip"
                        required
                      />
                      {errors['form.mailing_address.zip'] && (
                        <label htmlFor="zip" className="error_label">
                          {errors['form.mailing_address.zip']}
                        </label>
                      )}
                    </div>
                  </div>
                  <p className="notes">
                    *Paychecks, W-2, memos, etc will be mailed here
                  </p>
                </div>
              </div>
            </div>

            {/* Phone */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Phone <span className="required-star">&#x2A;</span>
                  </div>

                  <div className="inputs-items group two-item">
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.phone'] ? 'error' : ''}`}
                        name="phone"
                        id="phone"
                        value={form.phone}
                        field="form.phone"
                        placeholder="Phone"
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors['form.phone'] && (
                        <label htmlFor="phone" className="error_label">
                          {errors['form.phone']}
                        </label>
                      )}
                    </div>
                  </div>

                  <div className="inputs-items group two-item">
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.additional_phone'] ? 'error' : ''}`}
                        name="additional_phone"
                        id="additional_phone"
                        value={form.additional_phone}
                        field="form.additional_phone"
                        placeholder="Additional Phone"
                        onChange={this.handlePhoneChange}
                      />
                      {errors['form.additional_phone'] && (
                        <label
                          htmlFor="additional_phone"
                          className="error_label"
                        >
                          {errors['form.additional_phone']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.contact_name'] ? 'error' : ''}`}
                        name="contact_name"
                        id="contact_name"
                        value={form.contact_name}
                        field="form.contact_name"
                        placeholder="Contact Name"
                      />
                      {errors['form.contact_name'] && (
                        <label htmlFor="contact_name" className="error_label">
                          {errors['form.contact_name']}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Email */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Email Address<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items group two-item">
                    <div className="grouped">
                      <input
                        type="email"
                        className={`${errors['form.email'] ? 'error' : ''}`}
                        name="email"
                        id="email"
                        value={form.email}
                        field="form.email"
                        placeholder="Enter here"
                        required
                      />
                      {errors['form.email'] && (
                        <label htmlFor="email" className="error_label">
                          {errors['form.email']}
                        </label>
                      )}
                    </div>
                  </div>
                  <p className="notes">
                    Mandatory(Please enter clearly - will be used for MOST
                    communications)
                  </p>
                </div>
              </div>
            </div>

            {/* Emergency Contact */}
            <div className="row form-row checkb_vertical">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head mb-0">
                    Emergency Contact (Preferably next of kin):
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group emergency-contact-select">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="emergency_contact"
                        value="keep-prior"
                        field="form.emergency_contact"
                        id="keep-prior"
                        checked={form.emergency_contact === 'keep-prior'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="keep-prior" className="v-align-sub">
                        <strong>Keep prior contact info</strong>
                      </label>
                    </div>

                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="emergency_contact"
                        value="update"
                        field="form.emergency_contact"
                        id="update"
                        checked={form.emergency_contact === 'update'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="update" className="v-align-sub">
                        <strong>Change to</strong>
                      </label>
                    </div>
                  </div>
                  {errors['form.emergency_contact'] && (
                    <label htmlFor="emergency_contact" className="error_label">
                      {errors['form.emergency_contact']}
                    </label>
                  )}
                </div>
              </div>

              {/* <div className='col'></div> */}
              {/* Emergency Contact 1 */}
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Emergency Contact #1
                    <span className="required-star">&#x2A;</span>
                  </div>

                  <div className="inputs-items group two-item">
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.emgcontact1.fname'] ? 'error' : ''}`}
                        name="emgcontact1-fname"
                        id="emgcontact1-fname"
                        value={form.emgcontact1.fname}
                        field="form.emgcontact1.fname"
                        placeholder="Name"
                        required
                      />
                      {errors['form.emgcontact1.fname'] && (
                        <label
                          htmlFor="emgcontact1-fname"
                          className="error_label"
                        >
                          {errors['form.emgcontact1.fname']}
                        </label>
                      )}
                    </div>

                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.emgcontact1.relation'] ? 'error' : ''}`}
                        name="emgcontact1-relation"
                        id="emgcontact1-relation"
                        value={form.emgcontact1.relation}
                        field="form.emgcontact1.relation"
                        placeholder="Relationship"
                        required
                      />
                      {errors['form.emgcontact1.relation'] && (
                        <label
                          htmlFor="emgcontact1-relation"
                          className="error_label"
                        >
                          {errors['form.emgcontact1.relation']}
                        </label>
                      )}
                    </div>

                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.emgcontact1.phone'] ? 'error' : ''}`}
                        name="emgcontact1-phone"
                        id="emgcontact1-phone"
                        value={form.emgcontact1.phone}
                        field="form.emgcontact1.phone"
                        placeholder="Phone Number"
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors['form.emgcontact1.phone'] && (
                        <label
                          htmlFor="emgcontact1-phone"
                          className="error_label"
                        >
                          {errors['form.emgcontact1.phone']}
                        </label>
                      )}
                    </div>

                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.emgcontact1.alt_phone'] ? 'error' : ''}`}
                        name="emgcontact1-alt_phone"
                        id="emgcontact1-alt_phone"
                        value={form.emgcontact1.alt_phone}
                        field="form.emgcontact1.alt_phone"
                        placeholder="Alternative Phone Number"
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors['form.emgcontact1.alt_phone'] && (
                        <label
                          htmlFor="emgcontact1-alt_phone"
                          className="error_label"
                        >
                          {errors['form.emgcontact1.alt_phone']}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Emergency Contact 2 */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Emergency Contact #2</div>
                  <div className="inputs-items group two-item">
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.emgcontact2.fname'] ? 'error' : ''}`}
                        name="emgcontact2-fname"
                        id="emgcontact2-fname"
                        value={form.emgcontact2.fname}
                        field="form.emgcontact2.fname"
                        placeholder="Name"
                      />
                      {errors['form.emgcontact2.fname'] && (
                        <label
                          htmlFor="emgcontact2-fname"
                          className="error_label"
                        >
                          {errors['form.emgcontact2.fname']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${errors['form.emgcontact2.relation'] ? 'error' : ''}`}
                        name="emgcontact2-relation"
                        id="emgcontact2-relation"
                        value={form.emgcontact2.relation}
                        field="form.emgcontact2.relation"
                        placeholder="Relationship"
                      />
                      {errors['form.emgcontact2.relation'] && (
                        <label
                          htmlFor="emgcontact2-relation"
                          className="error_label"
                        >
                          {errors['form.emgcontact2.relation']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.emgcontact2.phone'] ? 'error' : ''}`}
                        name="emgcontact2-phone"
                        id="emgcontact2-phone"
                        value={form.emgcontact2.phone}
                        field="form.emgcontact2.phone"
                        placeholder="Phone Number"
                        onChange={this.handlePhoneChange}
                      />
                      {errors['form.emgcontact2.phone'] && (
                        <label
                          htmlFor="emgcontact2-phone"
                          className="error_label"
                        >
                          {errors['form.emgcontact2.phone']}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${errors['form.emgcontact2.alt_phone'] ? 'error' : ''}`}
                        name="emgcontact2-alt_phone"
                        id="emgcontact2-alt_phone"
                        value={form.emgcontact2.alt_phone}
                        field="form.emgcontact2.alt_phone"
                        placeholder="Alternative Phone Number"
                        onChange={this.handlePhoneChange}
                      />
                      {errors['form.emgcontact2.alt_phone'] && (
                        <label
                          htmlFor="emgcontact2-alt_phone"
                          className="error_label"
                        >
                          {errors['form.emgcontact2.alt_phone']}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Medical Info */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Medical Info<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <textarea
                      type="text"
                      className={`${errors['form.allergies'] ? 'error' : ''}`}
                      name="allergies"
                      id="allergies"
                      value={form.allergies}
                      field="form.allergies"
                      placeholder="If any Allergies, Illnesses, etc please mention here, if not write N/A"
                      required
                    />
                    {errors['form.allergies'] && (
                      <label htmlFor="allergies" className="error_label">
                        {errors['form.allergies']}
                      </label>
                    )}
                  </div>
                  <div className="inputs-items">
                    <textarea
                      type="text"
                      className={`${errors['form.prescriptions'] ? 'error' : ''}`}
                      name="prescriptions"
                      id="prescriptions"
                      value={form.prescriptions}
                      field="form.prescriptions"
                      placeholder="If any Prescription Medications please mention here, if not write N/A "
                      required
                    />
                    {errors['form.prescriptions'] && (
                      <label htmlFor="prescriptions" className="error_label">
                        {errors['form.prescriptions']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Disability Covered Veteran */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Disability and Covered Veteran Status not changed
                  </div>

                  <div className="check_radio_group gender_radio">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="disability"
                        value="yes"
                        field="form.disability"
                        id="yes_disability"
                        checked={form.disability === 'yes'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="yes_disability">
                        <strong>Yes</strong>
                      </label>
                    </div>
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="disability"
                        value="no"
                        field="form.disability"
                        id="no_disability"
                        checked={form.disability === 'no'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="no_disability">
                        <strong>No</strong>
                      </label>
                    </div>
                    {errors['form.disability'] && (
                      <label htmlFor="disability" className="error_label">
                        {errors['form.disability']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head text-center notify">
                    <span className="asterix">****</span> PLEASE NOTIFY THE
                    OFFICE OF ANY CHANGES TO THE ABOVE INFORMATION{' '}
                    <span className="asterix">****</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Employment Off Season */}
            <div className="row form-row checkb_vertical">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head mb-0">
                    Employment During Off Season
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group emergency-contact-select">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="employment_off_season"
                        value="worked"
                        field="form.employment_off_season"
                        id="worked"
                        checked={form.employment_off_season === 'worked'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="worked" className="v-align-sub">
                        <strong>Worked for the following</strong>
                      </label>
                    </div>

                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="employment_off_season"
                        value="did-not-work"
                        field="form.employment_off_season"
                        id="did-not-work"
                        checked={form.employment_off_season === 'did-not-work'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="did-not-work" className="v-align-sub">
                        <strong>Did not work</strong>
                      </label>
                    </div>
                    {errors['form.employment_off_season'] && (
                      <label
                        htmlFor="employment_off_season"
                        className="error_label"
                      >
                        {errors['form.employment_off_season']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Employee Details */}
            <div>
              {/* Employers */}
              {this.state.form.employment_off_season === 'worked' && (
                <div className="worked-for">
                  {/* Employer 1 */}
                  <div className="row form-row">
                    <div className="col">
                      <div className="inputs-col">
                        <div className="inputs-head">
                          Employer # 1
                          <span className="required-star">&#x2A;</span>
                        </div>

                        <div className="inputs-items">
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_1.employer_name`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_name_1"
                            id="employer_name_1"
                            value={form.employer_1.employer_name}
                            field={`form.employer_1.employer_name`}
                            placeholder="Employer Name"
                            required
                          />
                          {errors[`form.employer_1.employer_name`] && (
                            <label
                              htmlFor="employer_name_1"
                              className="error_label"
                            >
                              {errors[`form.employer_1.employer_name`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-col">
                          <div className="inputs-head">Address</div>
                        </div>

                        <div className="inputs-items">
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_1.employer_address`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_address_1"
                            id="employer_address_1"
                            value={form.employer_1.employer_address}
                            field={`form.employer_1.employer_address`}
                            placeholder="Employer Address"
                            required
                          />
                          {errors[`form.employer_1.employer_address`] && (
                            <label
                              htmlFor="employer_address_1"
                              className="error_label"
                            >
                              {errors[`form.employer_1.employer_address`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-items group two-item">
                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">Supervisor</div>

                              <input
                                type="text"
                                className={`${
                                  errors[`form.employer_1.employer_supervisor`]
                                    ? 'error'
                                    : ''
                                }`}
                                name="employer_supervisor_1"
                                id="employer_supervisor_1"
                                value={form.employer_1.employer_supervisor}
                                field={`form.employer_1.employer_supervisor`}
                                placeholder="Employer Supervisor"
                                required
                              />
                              {errors[
                                `form.employer_1.employer_supervisor`
                              ] && (
                                <label
                                  htmlFor="employer_supervisor_1"
                                  className="error_label"
                                >
                                  {
                                    errors[
                                      `form.employer_1.employer_supervisor`
                                    ]
                                  }
                                </label>
                              )}
                            </div>
                          </div>

                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">
                                Telephone Number
                              </div>
                            </div>

                            <input
                              type="tel"
                              className={`${
                                errors[`form.employer_1.employer_phone`]
                                  ? 'error'
                                  : ''
                              }`}
                              name="employer_phone_1"
                              id="employer_phone_1"
                              value={form.employer_1.employer_phone}
                              field={`form.employer_1.employer_phone`}
                              placeholder="Employer Phone"
                              onChange={this.handlePhoneChange}
                              required
                            />
                            {errors[`form.employer_1.employer_phone`] && (
                              <label
                                htmlFor="employer_phone_1"
                                className="error_label"
                              >
                                {errors[`form.employer_1.employer_phone`]}
                              </label>
                            )}
                          </div>
                        </div>

                        <div className="inputs-items">
                          <div className="inputs-col">
                            <div className="inputs-head">Position Held</div>
                          </div>
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_1.employer_position`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_position_1"
                            id="employer_position_1"
                            value={form.employer_1.employer_position}
                            field={`form.employer_1.employer_position`}
                            placeholder="Position Held"
                            required
                          />
                          {errors[`form.employer_1.employer_position`] && (
                            <label
                              htmlFor="employer_position_1"
                              className="error_label"
                            >
                              {errors[`form.employer_1.employer_position`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-items group two-item">
                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">Start Date</div>
                            </div>
                            <DatePicker
                              className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                              name={`form.employer_1.employer_start_date`}
                              id={`form.employer_1.employer_start_date`}
                              required
                              selected={
                                form.employer_1.employer_start_date
                                  ? moment(form.employer_1.employer_start_date)
                                  : moment()
                              }
                              field={`form.employer_1.employer_start_date`}
                              placeholder="mm/dd/yyyy"
                              onChange={this.handleDateChange.bind(
                                this,
                                `form.employer_1.employer_start_date`
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                            {errors['form.employer_1.employer_start_date'] && (
                              <label
                                htmlFor="employer_start_date"
                                className="error_label"
                              >
                                {errors['form.employer_1.employer_start_date']}
                              </label>
                            )}
                          </div>

                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">End Date</div>
                            </div>
                            <DatePicker
                              className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                              name={`form.employer_1.employer_end_date`}
                              id={`form.employer_1.employer_end_date`}
                              required
                              selected={
                                form.employer_1.employer_end_date
                                  ? moment(form.employer_1.employer_end_date)
                                  : moment()
                              }
                              field={`form.employer_1.employer_end_date`}
                              placeholder="mm/dd/yyyy"
                              onChange={this.handleDateChange.bind(
                                this,
                                `form.employer_1.employer_end_date`
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                            {errors[`form.employer_1.employer_end_date`] && (
                              <label
                                htmlFor="employer_end_date"
                                className="error_label"
                              >
                                {errors[`form.employer_1.employer_end_date`]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row form-row">
                    {/* Employer 2 */}
                    <div className="col">
                      <div className="inputs-col">
                        <div className="inputs-head">Employer # 2</div>

                        <div className="inputs-items">
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_2.employer_name`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_name_2"
                            id="employer_name_2"
                            value={form.employer_2.employer_name}
                            field={`form.employer_2.employer_name`}
                            placeholder="Employer Name"
                          />
                          {errors[`form.employer_2.employer_name`] && (
                            <label
                              htmlFor="employer_name_2"
                              className="error_label"
                            >
                              {errors[`form.employer_2.employer_name`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-col">
                          <div className="inputs-head">Address</div>
                        </div>

                        <div className="inputs-items">
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_2.employer_address`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_address_2"
                            id="employer_address_2"
                            value={form.employer_2.employer_address}
                            field={`form.employer_2.employer_address`}
                            placeholder="Employer Address"
                          />
                          {errors[`form.employer_2.employer_address`] && (
                            <label
                              htmlFor="employer_address_2"
                              className="error_label"
                            >
                              {errors[`form.employer_2.employer_address`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-items group two-item">
                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">Supervisor</div>

                              <input
                                type="text"
                                className={`${
                                  errors[`form.employer_2.employer_supervisor`]
                                    ? 'error'
                                    : ''
                                }`}
                                name="employer_supervisor_2"
                                id="employer_supervisor_2"
                                value={form.employer_2.employer_supervisor}
                                field={`form.employer_2.employer_supervisor`}
                                placeholder="Employer Supervisor"
                              />
                              {errors[
                                `form.employer_2.employer_supervisor`
                              ] && (
                                <label
                                  htmlFor="employer_supervisor_2"
                                  className="error_label"
                                >
                                  {
                                    errors[
                                      `form.employer_2.employer_supervisor`
                                    ]
                                  }
                                </label>
                              )}
                            </div>
                          </div>

                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">
                                Telephone Number
                              </div>
                            </div>

                            <input
                              type="tel"
                              className={`${
                                errors[`form.employer_2.employer_phone`]
                                  ? 'error'
                                  : ''
                              }`}
                              name="employer_phone_2"
                              id="employer_phone_2"
                              value={form.employer_2.employer_phone}
                              field={`form.employer_2.employer_phone`}
                              placeholder="Employer Phone"
                              onChange={this.handlePhoneChange}
                            />
                            {errors[`form.employer_2.employer_phone`] && (
                              <label
                                htmlFor="employer_phone_2"
                                className="error_label"
                              >
                                {errors[`form.employer_2.employer_phone`]}
                              </label>
                            )}
                          </div>
                        </div>

                        <div className="inputs-items">
                          <div className="inputs-col">
                            <div className="inputs-head">Position Held</div>
                          </div>
                          <input
                            type="text"
                            className={`${
                              errors[`form.employer_2.employer_position`]
                                ? 'error'
                                : ''
                            }`}
                            name="employer_position_2"
                            id="employer_position_2"
                            value={form.employer_2.employer_position}
                            field={`form.employer_2.employer_position`}
                            placeholder="Position Held"
                          />
                          {errors[`form.employer_2.employer_position`] && (
                            <label
                              htmlFor="employer_position_2"
                              className="error_label"
                            >
                              {errors[`form.employer_2.employer_position`]}
                            </label>
                          )}
                        </div>

                        <div className="inputs-items group two-item">
                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">Start Date</div>
                            </div>
                            <DatePicker
                              className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                              name={`form.employer_2.employer_start_date`}
                              id={`form.employer_2.employer_start_date`}
                              selected={
                                form.employer_2.employer_start_date
                                  ? moment(form.employer_2.employer_start_date)
                                  : moment()
                              }
                              field={`form.employer_2.employer_start_date`}
                              placeholder="mm/dd/yyyy"
                              onChange={this.handleDateChange.bind(
                                this,
                                `form.employer_2.employer_start_date`
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                            {errors['form.employer_2.employer_start_date'] && (
                              <label
                                htmlFor="employer_start_date"
                                className="error_label"
                              >
                                {errors['form.employer_2.employer_start_date']}
                              </label>
                            )}
                          </div>

                          <div className="grouped">
                            <div className="inputs-col">
                              <div className="inputs-head">End Date</div>
                            </div>
                            <DatePicker
                              className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                              name={`form.employer_2.employer_end_date`}
                              id={`form.employer_2.employer_end_date`}
                              selected={
                                form.employer_2.employer_end_date
                                  ? moment(form.employer_2.employer_end_date)
                                  : moment()
                              }
                              field={`form.employer_2.employer_end_date`}
                              placeholder="mm/dd/yyyy"
                              onChange={this.handleDateChange.bind(
                                this,
                                `form.employer_2.employer_end_date`
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                            {errors[`form.employer_2.employer_end_date`] && (
                              <label
                                htmlFor="employer_end_date"
                                className="error_label"
                              >
                                {errors[`form.employer_2.employer_end_date`]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Employee Questions */}
            <div className="form-row">
              <div className="row form-row bt-1">
                <div className="inputs-head mb-3">
                  <strong>
                    Please answer the following questions with current
                    information.
                  </strong>
                </div>
                <p>
                  1. Are you willing to be on call 24 ours a day, 7 days a week,
                  work long shifts, go weeks without a day off, endure adverse
                  weather conditions, perform strenuous and prolonged physical
                  labor and go extended periods without a bed, shower or phone ?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.endurance_capable'] ? 'error' : ''}`}
                          name="endurance_capable"
                          id="yes_endurance_capable"
                          value="yes"
                          field="form.endurance_capable"
                          checked={form.endurance_capable === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_endurance_capable">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.endurance_capable'] ? 'error' : ''}`}
                          name="endurance_capable"
                          id="no_endurance_capable"
                          value="no"
                          field="form.endurance_capable"
                          checked={form.endurance_capable === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_endurance_capable">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  2. Do you feel that you are physically fit to perform the
                  duties required for your position?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.physically_capable'] ? 'error' : ''}`}
                          name="physically_capable"
                          id="yes_physically_capable"
                          value="yes"
                          field="form.physically_capable"
                          checked={form.physically_capable === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_physically_capable">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.physically_capable'] ? 'error' : ''}`}
                          name="physically_capable"
                          id="no_physically_capable"
                          value="no"
                          field="form.physically_capable"
                          checked={form.physically_capable === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_physically_capable">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                    {/* Reason */}
                    {form.physically_capable === 'no' && (
                      <div className="inputs-items">
                        <p className="mt-1">
                          If <strong>No</strong>, please explain.
                        </p>
                        <input
                          type="text"
                          className={`${errors['form.physically_capable_reason'] ? 'error' : ''}`}
                          name="physically_capable_reason"
                          id="physically_capable_reason"
                          value={form.physically_capable_reason}
                          field="form.physically_capable_reason"
                          placeholder="-Reason-"
                          required
                        />
                        {errors['form.physically_capable_reason'] && (
                          <label
                            htmlFor="physically_capable_reason"
                            className="error_label"
                          >
                            {errors['form.physically_capable_reason']}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  3. Are you currently on any medications or under the care of a
                  doctor?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.under_medications'] ? 'error' : ''}`}
                          name="under_medications"
                          id="yes_under_medications"
                          value="yes"
                          field="form.under_medications"
                          checked={form.under_medications === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_under_medications">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.under_medications'] ? 'error' : ''}`}
                          name="under_medications"
                          id="no_under_medications"
                          value="no"
                          field="form.under_medications"
                          checked={form.under_medications === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_under_medications">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                    {/* Reason */}
                    {form.under_medications === 'yes' && (
                      <div className="inputs-items">
                        <p className="mt-1">
                          If <strong>Yes</strong>, please explain.
                        </p>
                        <input
                          type="text"
                          className={`${errors['form.under_medications_details'] ? 'error' : ''}`}
                          name="under_medications_details"
                          id="under_medications_details"
                          value={form.under_medications_details}
                          field="form.under_medications_details"
                          placeholder="-Reason-"
                          required
                        />
                        {errors['form.under_medications_details'] && (
                          <label
                            htmlFor="under_medications_details"
                            className="error_label"
                          >
                            {errors['form.under_medications_details']}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  4. NVAM is a smoke-free, drug-free, alcohol-free workplace. Do
                  you agree to comply with our company policies as outlined in
                  the Employee Handbook?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.company_policy_agreement'] ? 'error' : ''}`}
                          name="company_policy_agreement"
                          id="yes_company_policy_agreement"
                          value="yes"
                          field="form.company_policy_agreement"
                          checked={form.company_policy_agreement === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_company_policy_agreement">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.company_policy_agreement'] ? 'error' : ''}`}
                          name="company_policy_agreement"
                          id="no_company_policy_agreement"
                          value="no"
                          field="form.company_policy_agreement"
                          checked={form.company_policy_agreement === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_company_policy_agreement">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  5. You are required to provide a pre-employment drug screen
                  and may be subjected to additional testing if deemed necessary
                  under the company policy. Will you agree to such testing as
                  necessary ?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.drug_test_agreement'] ? 'error' : ''}`}
                          name="drug_test_agreement"
                          id="yes_drug_test_agreement"
                          value="yes"
                          field="form.drug_test_agreement"
                          checked={form.drug_test_agreement === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_drug_test_agreement">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.drug_test_agreement'] ? 'error' : ''}`}
                          name="drug_test_agreement"
                          id="no_drug_test_agreement"
                          value="no"
                          field="form.drug_test_agreement"
                          checked={form.drug_test_agreement === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_drug_test_agreement">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  6. Have you had any changes that may affect your work
                  eligibility such as name change, social security number change
                  or expiration of work authorization?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.work_eligibility_issue'] ? 'error' : ''}`}
                          name="work_eligibility_issue"
                          id="yes_work_eligibility_issue"
                          value="yes"
                          field="form.work_eligibility_issue"
                          checked={form.work_eligibility_issue === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_work_eligibility_issue">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.work_eligibility_issue'] ? 'error' : ''}`}
                          name="work_eligibility_issue"
                          id="no_work_eligibility_issue"
                          value="no"
                          field="form.work_eligibility_issue"
                          checked={form.work_eligibility_issue === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_work_eligibility_issue">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <p>
                  7. Have you completed all necessary training, applications
                  and/or procedures required by your job position?
                </p>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group gender_radio">
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.required_training_completed'] ? 'error' : ''}`}
                          name="required_training_completed"
                          id="yes_required_training_completed"
                          value="yes"
                          field="form.required_training_completed"
                          checked={form.required_training_completed === 'yes'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="yes_required_training_completed">
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="check_radio_item agreement_radio">
                        <input
                          type="radio"
                          className={`${errors['form.required_training_completed'] ? 'error' : ''}`}
                          name="required_training_completed"
                          id="no_required_training_completed"
                          value="no"
                          field="form.required_training_completed"
                          checked={form.required_training_completed === 'no'}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="no_required_training_completed">
                          <strong>No</strong>
                        </label>
                      </div>
                    </div>
                    {/* Reason */}
                    {form.required_training_completed === 'no' && (
                      <div className="inputs-items">
                        <p className="mt-1">
                          If <strong>No</strong>, please explain.
                        </p>
                        <input
                          type="text"
                          className={`${
                            errors['form.required_training_completed_reason']
                              ? 'error'
                              : ''
                          }`}
                          name="required_training_completed_reason"
                          id="required_training_completed_reason"
                          value={form.required_training_completed_reason}
                          field="form.required_training_completed_reason"
                          placeholder="-Reason-"
                          required
                        />
                        {errors['form.required_training_completed_reason'] && (
                          <label
                            htmlFor="required_training_completed_reason"
                            className="error_label"
                          >
                            {errors['form.required_training_completed_reason']}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <p>
                I verify that all information provided on this form is true to
                the best of my knowledge. I understand that if any information
                is not true, my candidacy and/or employment will be terminated.
              </p>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.employee_signature'
                            )}
                            signature={form.employee_signature}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors['form.employee_signature'] && (
                            <label
                              htmlFor="employee_signature"
                              className="error_label"
                            >
                              {errors['form.employee_signature']}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                      name="signed_date"
                      id="signed_date"
                      required
                      selected={moment(form.signed_date)}
                      field="form.signed_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.signed_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{' '}
                  <a href="">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>

                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedEmployeeRehireForm = compose(
  withRouter,
  connect(mapStateToProps)
)(EmployeeRehireForm);
export { connectedEmployeeRehireForm as EmployeeRehireForm };
