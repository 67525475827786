import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import { resourceActions } from '../_actions';
import '../assets/styles/resource.css';
import Dropzone from 'react-dropzone';
import { AlertPopup } from '../_components/AlertPopup';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#app');

class CreateResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        resource_type: '',
        resource_name: '',
      },
      resourceTypes: [],
      file_name: '',
      resource_photo: '',
      resource_nameError: '',
      resource_typeError: '',
      imgPreview: '',
      crop: {
        x: 0,
        y: 0,
        // width: 50,
        // height: 50
      },
      pixelCrop: {
        x: '',
        y: '',
        width: '',
        height: '',
      },
      fileName: '',
      image: '',
      alertPop: false,
      statusOpen: false,
      saveClick: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.goBack = this.goBack.bind(this);
    this.apply = this.apply.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(resourceActions.getAll());
    this.props.dispatch(resourceActions.getResourceTypes());
  }

  componentWillReceiveProps(newProps) {
    let { resourceTypes } = newProps.resource;

    if (resourceTypes) {
      this.setState({
        resourceTypes: resourceTypes,
      });
    }
  }

  handleChange(event) {
    this.setState({
      form: { ...this.state.form, [event.target.name]: event.target.value },
      [`${event.target.name}Error`]: '',
    });
  }

  apply(file) {
    this.setState({
      imgPreview: window.URL.createObjectURL(file),
      resource_photo: file,
    });
  }

  onImageDrop(files) {
    /*
    Validation
    */
    var allowed = ['png', 'jpg', 'jpeg'],
      file = files[0] || null;
    if (!file) {
      return this.setState({
        alertPop: true,
        alertHead: 'Error',
        alertMsg: 'Please select image file',
        alertFunction: '',
      });
    }
    var name = file.name,
      extension = name.split('.').pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      // Invalid file format.
      this.setState({
        alertPop: true,
        alertHead: 'Error',
        alertMsg: 'Invalid file format!',
        alertFunction: '',
      });
      return;
    }

    this.setState({
      // resource_photo: files[0],
      imgPreview: files[0].preview,
      fileName: files[0].name,
      image: files[0],
      statusOpen: true,
      // pixelCrop: ""
    });
  }

  statusOpenModal() {
    this.setState({ statusOpen: true });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleFile(e) {
    e.preventDefault();

    // let reader = new FileReader();
    let file = e.target.files[0];
    var name = e.target.name,
      value = e.target.value,
      file_format;
    file_format = value.split('.').pop().toLowerCase();
    // var targetValue = value.toString();
    // var fileName = targetValue.replace("C:\\fakepath\\", "");
    if (
      file_format === 'jpg' ||
      file_format === 'jpeg' ||
      file_format === 'png'
    ) {
      this.setState({
        [name]: value,
        resource_photo: file,
      });
    } else {
      alert(
        'Invalid file types. Please select any valid file types. [eg: .png, .jpeg, .jpg]'
      );
    }
    e.target.setAttribute('errors', '');
  }

  validate(e) {
    let isError = false;

    if (
      this.state.form.resource_name === '' ||
      this.state.form.resource_name === undefined
    ) {
      isError = true;
      this.setState({
        resource_nameError: 'error',
      });
    }

    if (this.state.form.resource_type == '') {
      isError = true;
      this.setState({
        resource_typeError: 'error',
      });
    }

    return isError;
  }

  onCropComplete(crop, pixelCrop) {
    this.setState({
      pixelCrop,
    });
  }

  onCropChange(crop) {
    // const values = this.cropper.values()
    this.setState({ crop });
  }
  _crop() {
    // image in dataUrl
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
  }
  cropImage() {
    // if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
    //     return;
    // }

    this.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        var imgData = this.cropper.getData();
        this.setState({
          cropResult: this.cropper.getCroppedCanvas().toDataURL(),
          statusOpen: false,
          imgData,
          resource_photo: blob,
        });
      },
      'image/jpeg',
      0.45
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const err = this.validate();

    if (!err) {
      this.setState({ saveClick: true });
      let data = new FormData();
      data.append('resource_name', this.state.form.resource_name);
      data.append('resource_type', this.state.form.resource_type);
      if (this.state.resource_photo !== '') {
        data.append('resource_photo', this.state.resource_photo);
        // data.append('x', parseInt(this.state.imgData.x))
        // data.append('y', parseInt(this.state.imgData.y))
        // data.append('width', parseInt(this.state.imgData.width))
        // data.append('height', parseInt(this.state.imgData.height))
      }

      var self = this,
        errMessage = '';
      fetch(config.apiUrl + '/api/resource/resourcesubmit', {
        headers: authHeader(),
        method: 'POST',
        body: data,
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          if (typeof data.message === 'object') {
            for (var key in data.message) {
              errMessage = JSON.parse(JSON.stringify(data.message[key][0]));
              // console.log(JSON.parse(JSON.stringify( inventory.inventory.message[key][0] )))
            }
          } else {
            errMessage = JSON.parse(JSON.stringify(data.message));
          }

          if (data.success) {
            self.setState({
              alertPop: true,
              alertHead: 'Success',
              alertMsg: errMessage,
              alertFunction: self.goBack.bind(this),
              saveClick: false,
            });
            // alert( JSON.stringify(data.message))
            // self.props.history.push('/resources')
          } else {
            self.setState({
              alertPop: true,
              alertHead: 'Error',
              alertMsg: errMessage,
              alertFunction: '',
              saveClick: false,
            });
            // alert( JSON.parse(JSON.stringify(data.message.resource_name)))
          }

          // if(data.success){
          //     self.props.history.push('/resources')
          // }
        });
      // this.props.goBackDetails();
      // const path = '/resources'
      // browserHistory.push(path)
    }
  }
  goBack() {
    this.props.history.push('/resources');
  }

  render() {
    // const { resource } = this.props;
    const form = this.state.form;
    var img = '';

    return (
      <div className="resource_container">
        <div className="breadcrumbs">
          <Link to={'/resources'} className="green">
            Resources
          </Link>{' '}
          / Add New Resources
        </div>
        <div className="page_title float">
          <h1>Add New Resource</h1>
        </div>
        <div className="inner_head" />
        <div className="resource_list create_resource_page">
          {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>
                    <Link to={"/resources"} className="goback">Back</Link>
                    <form className="resourceForm" onSubmit={this.handleSubmit}>*/}
          <div className="resourceForm">
            <div className="formField">
              <label>Resource type *</label>
              <select
                name="resource_type"
                id="resource_type"
                value={form.resource_type}
                onChange={this.handleChange}
                errors={this.state.resource_typeError ? 'error' : ''}
              >
                <option value="">Select Type</option>
                {this.state.resourceTypes.map((type, index) => {
                  return (
                    <option key={index} value={type.id}>
                      {type.value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formField">
              <label>Resource Name *</label>
              <div className="legal_name app_input">
                <input
                  type="text"
                  name="resource_name"
                  errors={this.state.resource_nameError}
                  value={form.resource_name}
                  className="small_input"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="file_div formField">
              <label>Upload file *</label>
              <span className="file-1" />
              <div className="upload_or_drag">
                <Dropzone
                  id="file_name"
                  name="file_name"
                  multiple={false}
                  accept="image/*"
                  onDrop={this.onImageDrop.bind(this)}
                >
                  <p>Upload or Drag/Drop Here</p>
                </Dropzone>

                {/*<ReactCrop src={this.state.imgPreview} crop={this.state.crop} onChange={this.onCropChange} onComplete={this.onCropComplete} onImageLoaded={this.onImageLoaded} />*/}
              </div>

              {/* <Cropper 
                                src={this.state.imgPreview} fixedRatio="false"
                                ref={ ref => { this.cropper = ref }} onChange={this.onCropChange.bind(this)}
                            />

                            <Cropper
                                ref='cropper'
                                src={this.state.cropResult}
                                style={{height: 400, width: '100%'}}
                                // Cropper.js options
                                aspectRatio={16 / 9}
                                guides={false}
                                crop={this._crop.bind(this)} />*/}

              {this.state.cropResult && (
                <img
                  style={{ width: '100%' }}
                  src={this.state.cropResult}
                  alt="cropped image"
                />
              )}
            </div>
            <div className="formField">
              <Link to={'/resources'} className="cancel_res button">
                Cancel
              </Link>
              <div className="right">
                {this.state.saveClick && (
                  <img
                    className="login_loader"
                    alt="loader"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                )}
                <input
                  type="submit"
                  name="submit"
                  onClick={this.handleSubmit}
                  value="Add Resource"
                  className="submit_appln button"
                />
              </div>
            </div>

            {/*</form>*/}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="imageCropPopup resource_create"
        >
          <div className="modal-head">
            <img
              className="closeModal"
              alt="close"
              onClick={this.statusCloseModal.bind(this)}
              src={require('../assets/images/close_green.png').default}
            />
          </div>
          <div id="remove_applnt" className="modal-body">
            <Cropper
              ref={(cropper) => {
                this.cropper = cropper;
              }}
              src={this.state.imgPreview}
              style={{ height: 400, width: '100%' }}
              // Cropper.js options
              aspectRatio={16 / 16}
              guides={false}
              crop={this._crop.bind(this)}
            />
          </div>
          <div className="button_list">
            <button onClick={this.cropImage.bind(this)} className="button">
              Crop Image
            </button>
          </div>
        </ReactModal>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource } = state;
  return {
    resource,
  };
}

const connectedResource = connect(mapStateToProps)(CreateResource);
export { connectedResource as CreateResource };
