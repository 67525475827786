import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { ReportGraphView } from './ReportGraphView';
import { ReportListView } from './ReportListView';
import moment from 'moment';
import { authHeader, config } from '../_helpers';
import '../assets/styles/report.css';

class CrewAssignmentStatusReport extends React.Component {
  constructor(props) {
    super(props);
    let fromDate = this.props?.location?.state?.fromDate
      ? moment(this.props?.location?.state?.fromDate)
      : moment();
    let toDate = this.props?.location?.state?.toDate
      ? moment(this.props?.location?.state?.toDate)
      : moment();
    this.state = {
      selectrdView: 'List',
      typeId: this.props.match.params.id || '3',
      toDate: toDate,
      fromDate: fromDate,
      statusType: '3',
      disableTypeFlag: false,
    };
  }

  SwitchView = (view) => {
    if (view == 'Graph') {
      this.setState({
        disableTypeFlag: false,
        statusType: this.state.statusType || '3',
        typeId: this.state.typeId,
      });
    }
    this.setState({ selectrdView: view });
  };

  printReport() {
    let data = new FormData();
    data.append('from', moment(this.state.fromDate).format('YYYY-MM-DD'));
    data.append('to', moment(this.state.toDate).format('YYYY-MM-DD'));
    data.append('assignment_type_id', this.state.typeId);
    data.append('status', this.state.statusType);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: data,
    };
    var self = this;
    fetch(
      config.apiUrl + '/api/assignment/printreport?type=list',
      requestOptions
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          const pdfLink = data.data;
          var link = document.createElement('a');
          if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i)
          ) {
            link.href = pdfLink;
          } else {
            link.href = pdfLink;
            link.target = '_blank';
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfLink);
          }, 100);
        } else {
        }
      });
  }

  render() {
    return (
      <div>
        <div className="dashboard_container">
          <div className="white">
            <div className="back_to_board">
              <div className="back_button">
                <img src={require('../assets/images/back.svg').default} />{' '}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              <div className="grid top-buttons-wrapper">
                <div
                  className="list-view first"
                  onClick={() => {
                    this.SwitchView('List');
                  }}
                >
                  <img src={require('../assets/images/lists.svg').default} />{' '}
                  <span>List View</span>
                </div>
                <div
                  className="list-view"
                  onClick={() => {
                    this.SwitchView('Graph');
                  }}
                >
                  <img src={require('../assets/images/chart.svg').default} />{' '}
                  <span>Bar Chart</span>
                </div>
              </div>
            </div>
            <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
              <div id="chart">
                <div className="graph_date_wrap">
                  <h5 className="graph_header">Report Summary</h5>
                  <div className="graph_date">
                    <div className="form-group">
                      <span
                        onClick={() => {
                          this.refs.dateoff.setFocus();
                        }}
                        className="datepicker-image"
                      >
                        <img
                          src={
                            require('../assets/images/icon_calendar.svg')
                              .default
                          }
                        />
                      </span>
                      <DatePicker
                        className="txt "
                        ref="dateoff"
                        id="Dateoff"
                        name="assignDate"
                        selected={this.state.fromDate}
                        onChange={(date) => {
                          this.setState({ fromDate: date });
                        }}
                        maxDate={this.state.toDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                      />
                    </div>
                    <div className="form-group">
                      <span
                        onClick={() => {
                          this.refs.dateon.setFocus();
                        }}
                        className="datepicker-image"
                      >
                        <img
                          src={
                            require('../assets/images/icon_calendar.svg')
                              .default
                          }
                        />
                      </span>
                      <DatePicker
                        className="txt "
                        ref="dateon"
                        id="Dateon"
                        name="Dateon"
                        selected={this.state.toDate}
                        onChange={(date) => {
                          this.setState({ toDate: date });
                        }}
                        // popperPlacement='bottom right'
                        // popoverTargetAttachment='bottom right'
                        minDate={this.state.fromDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                      />
                    </div>
                    {this.state.selectrdView == 'List' && (
                      <select
                        value={this.state.statusType}
                        ref="statusType"
                        className="form-control"
                        onChange={(event) => {
                          // if (event.target.value != "") {
                          this.setState({
                            statusType: event.target.value,
                            typeId: '',
                            disableTypeFlag: false,
                          });
                          if (event.target.value != '3') {
                            this.setState({ disableTypeFlag: true });
                          }
                          // }
                        }}
                      >
                        <option value="">Assignment Status</option>
                        <option value="3">Committed</option>
                        <option value="1">Available</option>
                        <option value="2">Unavailable</option>
                      </select>
                    )}
                    <select
                      disabled={this.state.disableTypeFlag}
                      value={this.state.typeId}
                      ref="assignmentType"
                      className="form-control"
                      onChange={(event) => {
                        // if (event.target.value != "") {
                        this.setState({ typeId: event.target.value });
                        // }
                      }}
                    >
                      {/* <option value="">Incident Type</option> */}
                      <option value="3">Project</option>
                    </select>
                    {this.state.selectrdView === 'List' && (
                      <a
                        href=""
                        className="print_button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.printReport();
                        }}
                      >
                        <img
                          alt=""
                          src={require('../assets/images/print.svg').default}
                        />{' '}
                        Print
                      </a>
                    )}
                  </div>
                </div>
                {this.state.selectrdView == 'List' && (
                  <ReportListView
                    typeId={this.state.typeId}
                    toDate={this.state.toDate}
                    statusType={this.state.statusType}
                    fromDate={this.state.fromDate}
                  />
                )}
                {this.state.selectrdView == 'Graph' && (
                  <ReportGraphView
                    typeId={this.state.typeId}
                    toDate={this.state.toDate}
                    fromDate={this.state.fromDate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const assignmentStatus = connect(mapStateToProps)(CrewAssignmentStatusReport);
export { assignmentStatus as CrewAssignmentStatusReport };
