import React, { Component } from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DocsList } from './DocsList';
import { Doc } from './Doc';
class DocsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire:
        new URLSearchParams(this.props.location.search).get('rehire') == 'true',
    };
  }

  componentWillReceiveProps() {
    this.setState({
      rehire:
        new URLSearchParams(this.props.location.search).get('rehire') == 'true',
    });
  }

  render() {
    let { docs } = this.props;
    return (
      <section className="user-docs">
        {docs.userDocs.length > 0 && !this.props.match.isExact && (
          <div className="docs_breadcrumbs">
            <Link className="green" to={'/docs'}>
              Documents List
            </Link>{' '}
            / {docs.userDocs[0].first_name} {docs.userDocs[0].last_name}
          </div>
        )}
        <h1 className="page_title">Documents</h1>
        <div className="onboarding-docs  sidebar-form">
          <Switch>
            <Route
              path={`${this.props.match.path}/:hash/:doc`}
              render={(props) => <Doc {...props} rehire={this.state.rehire} />}
            />
            <Route
              path={`${this.props.match.path}/:hash`}
              render={(props) => <Doc {...props} rehire={this.state.rehire} />}
            />
            <Route path={`${this.props.match.path}`} component={DocsList} />
          </Switch>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  let { docs, user } = state;
  return { docs, user };
}

let connectedDocsContainer = compose(
  withRouter,
  connect(mapStateToProps)
)(DocsContainer);
export { connectedDocsContainer as DocsContainer };
