import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from './Form';
import { Message } from './Message';
import '../assets/styles/application.css';
import { authHeader, config } from '../_helpers';

class ApplicationForm extends React.Component {
  constructor(props) {
    super(props);
    // var step;
    this.state = {
      mainHtml: '',
      step: 'Form',
    };
    this.toMessage = this.toMessage.bind(this);
  }
  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    var self = this;
    fetch(config.apiUrl + '/api/permission/getuserpermissions', {
      headers: authHeader(),
      method: 'GET',
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  toMessage(type, msg) {
    this.setState({ step: 'Message', msg, type }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  // goBack(){
  //     this.setState({ step:'InventoryDetails' },function(){
  //         this.componentWillReceiveProps(this.props)
  //     })
  // }

  componentWillReceiveProps(nextProps) {
    var options;
    if (this.state.step === 'Form') {
      options = <Form toMessage={this.toMessage.bind(this)} />;
    }
    if (this.state.step === 'Message') {
      options = <Message msg={this.state.msg} type={this.state.type} />;
    }

    this.setState({ mainHtml: options });
  }

  render() {
    // const { permissions } = this.state
    return this.state.mainHtml;
  }
}
function mapStateToProps(state) {
  const { permission } = state;
  return {
    // applicants,
    // user,
    permission,
  };
}
const connectedHomePage = connect(mapStateToProps)(ApplicationForm);
export { connectedHomePage as ApplicationForm };
