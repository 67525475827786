import { ReactComponent as DownArrow } from 'assets/icons/down-arrow-black.svg';
import { useState } from 'react';
const DropdownBtn = (props) => {
  const { setPrintOption } = props;
  const [isOpen, setIsOpen] = useState(false);

  const triggerDropDown = () => {
    setIsOpen(!isOpen);
  };
  const onDropDownSelect = (option) => {
    setPrintOption(option);
    setIsOpen(!isOpen);
  };
  return (
    <div className="DropDownBtnWrapper">
      <label class="dropdown">
        <div class="dd-button" onClick={triggerDropDown}>
          Print
          <DownArrow
            className={`down-arrow-icon ${isOpen ? 'arrow-up' : ''}`}
          />
        </div>

        <input type="checkbox" class="dd-input" id="test" />

        <ul class="dd-menu">
          <li onClick={() => onDropDownSelect('this_month')}>This Month</li>
          <li onClick={() => onDropDownSelect('last_6_months')}>
            Last Six Months
          </li>
          <li onClick={() => onDropDownSelect('last_year')}>Last Year</li>
          <li onClick={() => onDropDownSelect('all')}>All</li>
        </ul>
      </label>
    </div>
  );
};

export default DropdownBtn;
