import React, { useEffect, useMemo, useState } from 'react';
import logo from 'assets/images/logo.svg';
import { SignPad } from 'Docs/Components';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { docsActions } from '_actions';
import { handleNavigate } from './formHandlers';
import checkIfDocsShouldBeLocked from '_helpers/lockDocs';
import { FormNav } from './FormNav';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import moment from 'moment';

const GeneralConsentAndReleaseDoc = ({
  adminMode,
  mobileMode,
  lastPage,
  rehire,
  setScreen,
  isSingleDoc,
  doc,
  docs,
  resetForm,
  ...props
}) => {
  const dispatch = useDispatch();
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [formOwner, setFormOwner] = useState('');
  const [formValues, setFormValues] = useState({
    opt_in_employee_name: '',
    opt_in_general_consent_employee_signature: '',
    opt_in_general_consent_date: '',
    opt_out_employee_name: '',
    opt_out_general_consent_date: '',
    opt_out_general_consent_employee_signature: '',
  });
  const isDocumentLocked =
    useMemo(() => {
      return checkIfDocsShouldBeLocked(formOwner, formValues, adminMode);
    }, [formOwner, formValues, adminMode]) || false;

  const allowFormSubmission = () => {
    const optInComplete =
      !!formValues.opt_in_general_consent_employee_signature &&
      !!formValues.opt_in_employee_name;

    const optOutComplete =
      !!formValues.opt_out_general_consent_employee_signature &&
      !!formValues.opt_out_employee_name;

    setAllowSubmit(Boolean(optInComplete ^ optOutComplete));
  };

  useEffect(() => {
    allowFormSubmission();
  }, [formValues]);

  const handleFormChange = (e) => {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      const field = e.target.attributes['field'].value;
      const { value } = e.target;
      setFormValues((prevFormValues) => {
        const nextFormValues = { ...prevFormValues, [field]: value };
        return { ...nextFormValues };
      });
    }
  };

  const handleDateChange = (field, date) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };
      if (date) {
        nextFormValues[field] = date;
        nextFormValues[field] = date;
      }
      return {
        ...nextFormValues,
      };
    });
  };

  const handleSignature = (field, signature) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };
      nextFormValues[field] = signature;
      return nextFormValues;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form: formValues,
      single_doc: isSingleDoc ? true : false,
      rehire: rehire ? true : false,
    };
    dispatch(docsActions.saveDoc(data)).then((res) => {
      if (!res.success) {
        toast.error(res.message);
        return;
      }
      if (lastPage) {
        const redirectPath = adminMode ? '/docs' : '/dashboard';
        return props.history.push(redirectPath);
      }
      if (mobileMode) {
        setScreen('sidebar');
      }
      handleNavigate('next', rehire, docs, doc, props);
    });
  };

  useEffect(() => {
    if (!doc) {
      toast.error('Document not found', {
        position: 'top-right',
        autoClose: 5000,
      });
      return;
    }
    dispatch(docsActions.getForm(doc?.id))
      .then((res) => {
        if (res.success) {
          const formValuesData = res?.data?.form;
          const formOwner = res?.data?.form_owner;
          const userData = res?.data?.user;
          setFormOwner(formOwner);
          setFormValues((prevState) => ({
            ...prevState,
            opt_in_employee_name: userData?.full_name,
            opt_in_general_consent_employee_signature:
              formValuesData?.opt_in_general_consent_employee_signature,
            opt_in_general_consent_date: moment().format('MM/DD/YYYY'),
            opt_out_employee_name: userData?.full_name,
            opt_out_general_consent_date: moment().format('MM/DD/YYYY'),
            opt_out_general_consent_employee_signature:
              formValuesData?.opt_out_general_consent_employee_signature,
          }));
        }
      })
      .catch((err) => {
        toast.error(err?.message ?? 'Error on loading data!', {
          position: 'top-right',
          autoClose: 5000,
        });
      });
  }, []);

  return (
    <div className="doc_info-rh">
      {/* Title */}
      <div className="doc-title meal-title fs-consent-doc-title">
        <img src={logo} alt="fs-logo" className="doc-title-img" />
        <span className="close-button" onClick={() => setScreen('sidebar')}>
          <img src={`${config.assetUrl}icons/back-btn.svg`} />
        </span>
        <h3>
          GENERAL CONSENT AND RELEASE FOR USE OF PHOTOGRAPHS, VIDEO/AUDIO AND
          WRITINGS{' '}
        </h3>
      </div>

      {/* Form */}
      <div className="doc_info-form">
        <form
          className="info-form mpn"
          onChange={handleFormChange}
          onSubmit={handleSubmit}
          name="general_consent"
          noValidate
        >
          <fieldset disabled={isDocumentLocked}>
            <div className="row">
              <div className="col-md-12">
                <p>
                  North Valley Arbor Management (NVAM) may periodically use
                  social media platforms to promote Company activities, events,
                  achievements, or other work-related content. Employees have
                  the right to consent to use their likeness by NVAM or to opt
                  out of using their name, image, or likeness on social media
                  platforms without impacting employment. Additionally,
                  employees can revoke their consent at any time, recognizing
                  that such revocation may require reasonable time to take down
                  images from social media platforms.
                </p>
                <ol className="fs-text-bold">
                  <li>
                    <b>Duration: </b>This permission is valid indefinitely
                    unless otherwise specified in writing. I understand I may
                    revoke this permission by providing written notice to North
                    Valley Arbor Management. I also understand that revocation
                    may require a reasonable time to remove a name, image, or
                    likeness from established social media sites, including the
                    Company’s website.
                  </li>
                  <li>
                    <b>Consent: </b>I consent to the use of my name, likeness,
                    image, and/or information for the purposes outlined in this
                    agreement. I understand that this permission is voluntary
                    and that I will not be compensated for its use.
                  </li>
                  <li>
                    <b>Release: </b>I release North Valley Arbor Management and
                    its agents from any liability arising from using my name,
                    likeness, image, and/or information under this agreement.
                  </li>
                  <li>
                    <b>Modification: </b> I understand that North Valley Arbor
                    Management may modify, edit, or remove any content featuring
                    my name, likeness, image, and/or information as it sees fit
                    without seeking my additional consent.
                  </li>
                  <li>
                    <b>Governing Law: </b>This agreement shall be governed by
                    the laws of California. Any disputes arising from or
                    relating to this agreement shall be resolved through
                    arbitration in Sacramento, California.
                  </li>
                </ol>
                <p>
                  I acknowledge that I have read and understood the terms of
                  this agreement and voluntarily consent to its terms.
                </p>

                <div
                  className="row form-row no_border"
                  style={{ paddingBottom: 0 }}
                >
                  <div className="col" style={{ marginBottom: 0 }}>
                    <div className="inputs-col">
                      <div className="inputs-col">
                        <div className="inputs-items">
                          <div
                            className="row form-row no_border"
                            style={{ paddingTop: 0 }}
                          >
                            <div className="col">
                              <div className="inputs-col">
                                {/*single input */}
                                <div className="inputs-head">Employee Name</div>
                                <div className="inputs-items">
                                  <input
                                    type="text"
                                    name="formValues.opt_in_employee_name"
                                    id="opt_in_employee_name"
                                    value={formValues.opt_in_employee_name}
                                    field="opt_in_employee_name"
                                    placeholder="Employee Name"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inputs-head">Employee Signature</div>
                      <div className="inputs-items">
                        <div className="sign_here">
                          <>
                            <SignPad
                              height={211}
                              width={411}
                              handleSignature={(
                                opt_in_general_consent_employee_signature
                              ) =>
                                handleSignature(
                                  'opt_in_general_consent_employee_signature',
                                  opt_in_general_consent_employee_signature
                                )
                              }
                              signature={
                                formValues.opt_in_general_consent_employee_signature
                              }
                              disabled={adminMode}
                            />
                            <input
                              type="hidden"
                              value={
                                formValues.opt_in_general_consent_employee_signature
                              }
                              name="opt_in_general_consent_employee_signature"
                              field="formValues.opt_in_general_consent_employee_signature"
                            ></input>
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">
                        Date<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <DatePicker
                          name="formValues.opt_in_general_consent_date"
                          id="opt_in_general_consent_date"
                          required={true}
                          selected={
                            formValues.opt_in_general_consent_date
                              ? moment(formValues.opt_in_general_consent_date)
                              : ''
                          }
                          field="formValues.opt_in_general_consent_date"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) =>
                            handleDateChange(
                              'opt_in_general_consent_date',
                              date
                            )
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fs-document-seperator">
              <p>-- OR --</p>
            </div>
            <div className="col-md-12 fs-title-center">
              <h3>OPT-OUT OF THE USE OF IMAGES ON COMPANY SOCIAL MEDIA </h3>
            </div>
            <p>
              After reviewing North Valley Arbor Management Employee Social
              Media Notice and Acknowledgement set forth above, I do not consent
              to the Company’s use of my name, image, or likeness on using
              social media platforms for personal or professional purposes
              during or after my employment with the Company. This includes but
              is not limited to platforms such as the Company Website, Facebook,
              Twitter, Instagram, LinkedIn, TikTok, Snapchat, and other similar
              platforms.
            </p>
            <p>
              I understand that my decision to opt out of the use of my name,
              image, or likeness on the Company's social media platform will
              have no negative impact on my employment.
            </p>

            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div
                    className="row form-row no_border"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="col">
                      <div className="inputs-col">
                        <div className="inputs-head">Employee Name</div>
                        <div className="inputs-items">
                          <input
                            type="text"
                            name="formValues.opt_out_employee_name"
                            id="opt_out_employee_name"
                            value={formValues.opt_out_employee_name}
                            field="opt_out_employee_name"
                            placeholder="Employee Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inputs-head">Employee Signature</div>
                  <div className="inputs-items">
                    <div className="sign_here">
                      <>
                        <SignPad
                          height={211}
                          width={411}
                          handleSignature={(
                            opt_out_general_consent_employee_signature
                          ) =>
                            handleSignature(
                              'opt_out_general_consent_employee_signature',
                              opt_out_general_consent_employee_signature
                            )
                          }
                          signature={
                            formValues.opt_out_general_consent_employee_signature
                          }
                          disabled={adminMode}
                        />
                        <input
                          type="hidden"
                          value={
                            formValues.opt_out_general_consent_employee_signature
                          }
                          name="formValues.opt_out_general_consent_employee_signature"
                          field="form.opt_out_general_consent_employee_signature"
                        ></input>
                      </>
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`w-100`}
                      name="formValues.opt_out_general_consent_date"
                      id="opt_out_general_consent_date"
                      required={true}
                      selected={
                        formValues.opt_out_general_consent_date
                          ? moment(formValues.opt_out_general_consent_date)
                          : ''
                      }
                      field="formValues.opt_out_general_consent_date"
                      placeholder="mm/dd/yyyy"
                      onChange={(date) =>
                        handleDateChange('opt_out_general_consent_date', date)
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={(to) => handleNavigate(to, rehire, docs, doc, props)}
              disclaimer_check={allowSubmit}
              mobileMode={mobileMode}
              lastPage={lastPage}
            />
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default withRouter(GeneralConsentAndReleaseDoc);
