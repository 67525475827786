import React, { Component } from 'react';
import { config } from '_helpers/config';
import { connect } from 'react-redux';
import { docsActions } from '_actions/docs.actions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tab, Row, Col, NavItem, Nav } from 'react-bootstrap';
import { PaycheckDistribution } from 'Docs/Forms/PaycheckDistribution';
import { CandidateInfoPage } from 'Docs/Forms/CandidateInfoPage';
import { W4 } from 'Docs/Forms/W4';
import InterviewForm from 'InterviewForm';
import { getCurrentUserPermissions } from '_helpers/reduxSelector';
import TimeOffRequest from 'Docs/Forms/TimeOffRequest';
import DocStatusTag from '_components/utils/DocStatusTag';

class UserDocsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_doc: 'asc',
      order_at: 'asc',
      documents: [],
      view_doc: false,
      adminMode: true,
      tab: 'list',
      doc: {
        new: null,
        id: null,
        slug: 'merchandise-order-form',
      },
    };
    // Bindings
    this.sortHandler = this.sortHandler.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.generateDocList = this.generateDocList.bind(this);
    this.viewDoc = this.viewDoc.bind(this);
    this.viewList = this.viewList.bind(this);
    this._compareFn = this._compareFn.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
  }
  // Compare function
  _compareFn(a, b, type, by) {
    if (type === 'docs_name') {
      if (by === 'asc') {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return -1;
        } else if (name_1 > name_2) {
          return 1;
        } else return 0;
      } else {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return 1;
        } else if (name_1 > name_2) {
          return -1;
        } else return 0;
      }
    } else if (type === 'updated_at') {
      if (by === 'asc') {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    }
    return 0;
  }
  // Handle sorting in table
  sortHandler(type) {
    let documents = this.state.documents;
    documents.sort();
    switch (type) {
      case 'docs_name':
        if (this.state.order_doc === 'asc') {
          documents.sort((a, b) => this._compareFn(a, b, 'docs_name', 'asc'));
          this.setState({ documents, order_doc: 'desc' });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, 'docs_name', 'desc'));
          this.setState({ documents, order_doc: 'asc' });
        }
        break;
      case 'updated_at':
        if (this.state.order_at === 'asc') {
          documents.sort((a, b) => this._compareFn(a, b, 'updated_at', 'asc'));
          this.setState({ documents, order_at: 'desc' });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, 'updated_at', 'desc'));
          this.setState({ documents, order_at: 'asc' });
        }
        break;
      default:
        return this.state.documents;
    }
  }

  tabChange(tab) {
    this.setState({
      tab,
    });
  }

  viewDoc(doc) {
    this.setState({
      doc: { ...this.state.doc, id: doc.id, slug: doc.slug },
      view_doc: true,
      tab: 'document',
    });
  }

  viewList() {
    this.setState({
      doc: { ...this.state.doc, id: null },
      view_doc: false,
      tab: 'list',
    });
  }

  generateDocList(list, type, other, rehire) {
    // let signature = this.props.chat.settings.signature;
    // let loggedInUser = this.props.user.profile.data.hash;
    let signature;
    let docTypes = this.props.docs.types;
    this.props.chat &&
      this.props.chat.settings &&
      (signature = this.props.chat.settings.signature);
    let loggedInUser;
    this.props.user &&
      this.props.user.profile &&
      (loggedInUser = this.props.user.profile.data.hash);
    let selectedUser = (() => this.props.user && this.props.user.items.data)();
    let empty_list, docs_list;
    let filteredList = list.filter((doc) => doc.docs_categories_id == type);
    (filteredList.length === 0 && type === 1) || type === 4
      ? (empty_list = (
          <p className="doc-list-empty">
            <strong>{selectedUser.full_name}</strong> haven't submitted any
            Onboarding Documents
          </p>
        ))
      : type === 3 || type === 6
        ? (empty_list = (
            <p className="doc-list-empty">
              <strong>{selectedUser.full_name}</strong> haven't added any Other
              Documents
            </p>
          ))
        : (empty_list = (
            <p className="doc-list-empty">
              <strong>{selectedUser.full_name}</strong> haven't added any Rehire
              Documents
            </p>
          ));
    docs_list = empty_list;

    let timeOffRequestDoc = docTypes?.find(
      (doc) => doc.slug === 'time-off-request'
    );
    let timeOffRequestId = timeOffRequestDoc ? timeOffRequestDoc.id : null;

    filteredList.length > 0 &&
      (docs_list = filteredList?.map((doc, index) => {
        let docStatus = '';
        const docStatusColor = doc?.status === 3 ? '#3f7e39' : '#c9c188';

        switch (doc?.status) {
          case 1:
            docStatus = 'Not Filled';
            break;
          case 2:
            docStatus = 'Submitted';
            break;
          case 3:
            docStatus = 'Approved';
            break;
          case 4:
            docStatus = 'Denied';
            break;
          default:
            docStatus = '';
            break;
        }
        return (
          <div className="applicant_item list_item">
            <div className="homeEmp_list">
              <div className="basic_info unavailable_employe">
                <div className="item_cell">
                  <img
                    className="doc-list-img"
                    src={`${config.assetUrl}icons/doc-check-circle.svg`}
                    alt=""
                  />
                  <span>
                    {doc.doc_type_name}
                    {!!(doc.docs_type_id === timeOffRequestId) && (
                      <DocStatusTag
                        status={docStatus}
                        bgColor={docStatusColor}
                        fontColor={doc.status === 3 ? '#fff' : ''}
                      />
                    )}
                  </span>
                </div>
                <div className="item_cell">
                  <span>
                    {moment(doc.updated_at, 'MM-DD-YYYY').format('MM/DD/YY')}
                  </span>
                </div>
                <div className="item_cell  doc_download">
                  <span>
                    <a
                      href={`${config.apiUrl}/docs/pdf/${doc.slug}?id=${doc.id}&signature=${signature}&uid=${loggedInUser}`}
                      target="_blank"
                    >
                      <img
                        src={`${config.assetUrl}icons/doc-admin-download.svg`}
                        alt=""
                      />
                    </a>
                  </span>
                </div>
                <div className="item_cell  doc_download">
                  {other ? (
                    <span>
                      <a onClick={() => this.viewDoc(doc)}>
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </a>
                    </span>
                  ) : rehire ? (
                    <span>
                      <Link
                        to={`/docs/${this.props.userHash}/${doc.slug}?rehire=true`}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </Link>
                    </span>
                  ) : (
                    <span>
                      <Link
                        to={`/docs/${this.props.userHash}/${doc.slug}?rehire=false`}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </Link>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }));

    return docs_list;
  }

  componentDidMount() {
    let hash = this.props.userHash;
    this.props.dispatch(docsActions.getUserDocs(hash)).then((res) => {
      this.setState({ documents: this.props.docs.userDocs });
    });
    this.props.dispatch(docsActions.getDocTypes());
  }
  render() {
    // let signature; this.props.chat && this.props.chat.settings && (signature = this.props.chat.settings.signature);
    // let loggedInUser; this.props.user && this.props.user.profile && (loggedInUser= this.props.user.profile.data.hash);
    let selectedUser = null;
    this.props.user &&
      this.props.user.items &&
      (selectedUser = this.props.user.items.data);

    return (
      <div>
        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="first">
          <Row className="clearfix">
            <Col sm={12}>
              <div className="custom-tabs m-0">
                <Nav bsStyle="tabs">
                  <NavItem eventKey="first" className="custom-tab">
                    <i className="fa fa-file"></i>
                    Onboarding Documents
                  </NavItem>
                  <NavItem eventKey="second" className="custom-tab">
                    <i className="fa fa-file-download"></i>
                    Rehire Documents
                  </NavItem>
                  <NavItem eventKey="third" className="custom-tab">
                    <i className="fa fa-file-alt"></i>
                    Other Documents
                  </NavItem>
                  {this?.props?.currentUserPermissions?.includes(
                    'Manage Interview Forms'
                  ) && (
                    <NavItem eventKey="forth" className="custom-tab">
                      <i className="fa fa-clipboard-list"></i>
                      Interview Forms
                    </NavItem>
                  )}
                </Nav>
              </div>
            </Col>
            <Col sm={12}>
              <div className="custom-tab-content">
                <Tab.Content animation>
                  {/* Onboarding DOCUMENTS */}
                  <Tab.Pane eventKey="first">
                    {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler('docs_name')}
                      >
                        Name
                        <span
                          className={`${
                            this.state.order_doc === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler('updated_at')}
                      >
                        Date Updated
                        <span
                          className={`${
                            this.state.order_at === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(
                        this.state.documents,
                        1,
                        false,
                        false
                      )}
                  </Tab.Pane>

                  {/* Rehire DOCUMENTS */}
                  <Tab.Pane eventKey="second">
                    {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler('docs_name')}
                      >
                        Name
                        <span
                          className={`${
                            this.state.order_doc === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler('updated_at')}
                      >
                        Date Updated
                        <span
                          className={`${
                            this.state.order_at === 'asc' ? 'asc' : 'desc'
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(
                        this.state.documents,
                        2,
                        false,
                        true
                      )}
                  </Tab.Pane>

                  {/* Other Documents */}
                  <Tab.Pane eventKey="third">
                    <section className="content onboarding-docs">
                      {/* LIST */}
                      {this.state.tab === 'list' && (
                        <section className="list">
                          {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                          <div className="appln_list_header">
                            <div
                              className="list_item_head"
                              onClick={() => this.sortHandler('docs_name')}
                            >
                              Name
                              <span
                                className={`${
                                  this.state.order_doc === 'asc'
                                    ? 'asc'
                                    : 'desc'
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div
                              className="list_item_head appln_date"
                              onClick={() => this.sortHandler('updated_at')}
                            >
                              Date Updated
                              <span
                                className={`${
                                  this.state.order_at === 'asc' ? 'asc' : 'desc'
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div className="list_item_head"></div>
                            <div className="list_item_head"></div>
                          </div>
                          {selectedUser &&
                            this.generateDocList(
                              this.state.documents,
                              3,
                              true,
                              false
                            )}
                        </section>
                      )}
                      {/* DOCUMENT */}
                      {this.state.tab === 'document' && (
                        <section className="document">
                          <div className="container doc_info">
                            <p className="button-container doc_info-rh no-border form-top-buttons">
                              <button
                                id="back-button"
                                className="button"
                                onClick={this.viewList}
                              >
                                Back
                              </button>
                            </p>
                          </div>
                          <div className="container doc_info full-width_form">
                            {this.state?.doc?.slug ===
                              'paycheck-distribution-form' && (
                              <PaycheckDistribution
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug ===
                              'candidate-information-page' && (
                              <CandidateInfoPage
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === 'w-4' && (
                              <W4
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === 'time-off-request' && (
                              <TimeOffRequest
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                                docDetails={this.state?.doc}
                                status={this.state.doc.status}
                              />
                            )}
                          </div>
                        </section>
                      )}
                    </section>
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <InterviewForm hash={this.props.userHash} listOnly />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>

        {/* <!--enable this tab contents corresponding to the tab clicked--> */}
        {/* <div className="appln_list_header">
          <div className="list_item_head" onClick={() => this.sortHandler("docs_name")}>
            Name
            <span className={`${this.state.order_doc === "asc" ? "asc" : "desc"} sort-arrow`}></span>
          </div>
          <div className="list_item_head appln_date" onClick={() => this.sortHandler("updated_at")}>
            Date Updated
            <span className={`${this.state.order_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
          </div>
          <div className="list_item_head"></div>
          <div className="list_item_head"></div>
        </div> */}
        {/* {this.state.documents.map((doc) => {
          return (
            <div className="applicant_item list_item">
              <div className="homeEmp_list">
                <div className="basic_info unavailable_employe">
                  <div className="item_cell">
                    <img className="doc-list-img" src={`${config.assetUrl}icons/doc-check-circle.svg`} alt="" />
                    <span>{doc.doc_type_name}</span>
                  </div>
                  <div className="item_cell">
                    <span>{moment(doc.updated_at).format("MM/DD/YY")}</span>
                  </div>
                  <div className="item_cell  doc_download">
                    <span>
                      <a href={`${config.apiUrl}/docs/pdf/${doc.slug}?id=${doc.id}&signature=${signature}&uid=${loggedInUser}`} target="_blank">
                        <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt="" />
                      </a>
                    </span>
                  </div>
                  <div className="item_cell  doc_download">
                    <span>
                      <Link to={`/docs/${this.props.userHash}/${doc.slug}`}>
                        <img src={`${config.assetUrl}icons/doc-admin-view.svg`} alt="" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, chat, user } = state;
  return {
    docs,
    chat,
    user,
    currentUserPermissions: getCurrentUserPermissions(state),
  };
}

let connectedUserDocsList = connect(mapStateToProps)(UserDocsList);
export { connectedUserDocsList as UserDocsList };
