import { applicantStatsConstants } from '../_constants';

export function applicantStats(state = {}, action) {
  switch (action.type) {
    case applicantStatsConstants.GETAPPLNSTATS_REQUEST:
      return {
        loading: true,
      };
    case applicantStatsConstants.GETAPPLNSTATS_SUCCESS:
      return {
        items: action.applicantStats,
      };
    case applicantStatsConstants.GETAPPLNSTATS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
