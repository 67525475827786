import React, { useState, useEffect } from 'react';
import { Tab, Row, Col, NavItem, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { reportList } from '_utils/utils';
import { permissionActions } from '_actions';
import VehicleReportList from 'VehicleReportList';
import EquipmentSoldReport from 'EquipmentSoldReport';
import AllInventoryReport from 'AllInventoryReport';
import NoAccess from './NoAccess';
import styles from './reports.module.scss';
import ExpirationDateReport from 'ExpirationDatesReport';
import DocStatusListReport from 'DocStatusListReport';
import EmployeeBirthdays from 'EmployeeBirthdays';
import MileageHobbsReport from 'MileageHobbsReport';
import ApplicantsReport from 'ApplicantsReport/ApplicantsReport';
import ApplicantsReportContainer from 'ApplicantsReport';

const NO_REPORT_ACCESS = 'no_reports_found';

const ReportTabs = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(NO_REPORT_ACCESS);
  const permissions =
    useSelector((state) => state.permission?.permissions?.data) || [];
  const reports =
    reportList.filter(({ permission }) => permissions.includes(permission)) ||
    [];

  useEffect(() => {
    dispatch(permissionActions.getPermission());
  }, []);

  useEffect(() => {
    let actTab = reports.length ? reports[0].key : NO_REPORT_ACCESS;
    if (!permissions.includes('Manage Reports')) {
      actTab = NO_REPORT_ACCESS;
    }
    setActiveTab(actTab);
  }, [permissions]);

  return (
    <div className={styles.report_container}>
      <section>
        <h1 className={styles.page_title}>Reports</h1>
      </section>
      <section className="d-flex w-100">
        <div className="col-vert pos-rel w-100">
          <div className="doc-list-items">
            <Tab.Container id="tabs-with-dropdown" activeKey={activeTab}>
              <Row>
                <Col sm={12}>
                  {activeTab !== NO_REPORT_ACCESS && (
                    <div className={cx('m-0 custom-tabs', styles.p_custom)}>
                      <Nav bsStyle="tabs">
                        {reports.map((report) => (
                          <NavItem
                            eventKey={report.key}
                            className="custom-tab"
                            key={report.key}
                            onClick={() => setActiveTab(report.key)}
                          >
                            {report.title}
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  )}
                </Col>

                <Col sm={12}>
                  <div className="custom-tab-content">
                    <Tab.Content animation>
                      <Tab.Pane eventKey="vehicle">
                        <VehicleReportList isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipment">
                        <EquipmentSoldReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="inventory">
                        <AllInventoryReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey={NO_REPORT_ACCESS}>
                        <NoAccess />
                      </Tab.Pane>
                      <Tab.Pane eventKey="docs">
                        <DocStatusListReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="expiration">
                        <ExpirationDateReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="birthday">
                        <EmployeeBirthdays isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="mileage-hobbs">
                        <MileageHobbsReport
                          isReportMenu
                          activeTab={activeTab}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="applicants">
                        <ApplicantsReportContainer isReportMenu />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReportTabs;
