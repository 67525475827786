import { chatConstants } from '../_constants';

let startState = {
  count: 0,
  admin: {
    active: false,
  },
  conversation: {
    data: {},
    users: [],
    recipients: [],
    messages: [],
    crews: [],
    currentPage: 1,
    mark: 0,
    loading: false,
  },
  conversations: {
    data: [],
    messages: [],
    loading: false,
    searchList: [],
    crews: [],
  },
  user: {
    data: [],
  },
  crew: {
    data: [],
  },
  settings: {},
};

export function chat(state = startState, action) {
  switch (action.type) {
    // -------------------------------------------------------------------------

    case chatConstants.CONVERSATION_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.CONVERSATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case chatConstants.CONVERSATION_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // -------------------------------------------------------------------------

    case chatConstants.CONVERSATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        conversations: {
          ...state.conversations,
          ...action.payload.conversations,
          loading: false,
        },
      };
    case chatConstants.CONVERSATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // -------------------------------------------------------------------------

    case chatConstants.CONVERSATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.CONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        conversations: {
          ...state.conversations,
          ...action.payload.conversations,
          loading: false,
        },
      };
    case chatConstants.CONVERSATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    // -------------------------------------------------------------------------

    case chatConstants.USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.users,
      };
    case chatConstants.USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.CREWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.CREWS_SUCCESS:
      return {
        ...state,
        loading: false,
        crews: action.crews,
      };
    case chatConstants.CREWS_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.GET_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.GET_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.GET_LATEST_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.GET_LATEST_MESSAGES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.GET_LATEST_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.CREATE_CONVERSATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
        conversation: {
          messages: [],
          ...action.conversation,
          loading: false,
        },
      };
    case chatConstants.CREATE_CONVERSATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.UPDATE_CONVERSATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.ADD_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.ADD_PARTICIPANT_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.REMOVE_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.REMOVE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.REMOVE_PARTICIPANT_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.SEARCH_CONVERSATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.SEARCH_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          searchList: action.payload || [],
          loading: false,
        },
        loading: false,
      };
    case chatConstants.SEARCH_CONVERSATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case chatConstants.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.SEND_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------

    case chatConstants.SEND_FILE_REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case chatConstants.SEND_FILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.SEND_FILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.SET_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.SET_FAVORITE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.SET_FAVORITE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.ALL_COUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.ALL_COUNTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.ALL_COUNTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.RECENT_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.RECENT_MESSAGES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.RECENT_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.SET_ADMIN_MODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.SET_ADMIN_MODE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.SET_ADMIN_MODE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.UPDATE_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatConstants.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case chatConstants.DELETE_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    // -------------------------------------------------------------------------
    case chatConstants.UNREAD_COUNT_UPDATE:
      return {
        ...state,
        loading: false,
        count: action.payload.chatCount,
        conversations: {
          data: state.conversations.data.map((conversation) => {
            if (conversation.id !== state.conversation.id) {
              return {
                ...conversation,
                unreadCount: action.payload.data[conversation.id] || 0,
              };
            }
            return conversation;
          }),
        },
      };
    // -------------------------------------------------------------------------
    case chatConstants.CLEAR_CONVERSATION_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    // -------------------------------------------------------------------------
    case chatConstants.NEW_MESSAGE_UPDATE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    // -------------------------------------------------------------------------
    default:
      return state;
  }
}
