import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { usPhone } from '_utils/formatter';
import { toast } from 'react-toastify';
//import { docsService } from "_services/docs.service";
import { FormNav } from './FormNav';
import { validate } from './formHandlers';
import moment from 'moment';

class DisabilityStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        date: moment().format('MM/DD/YYYY'),
        disability_category: 'no',
        disclaimer_check: false,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.navigate = this.navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
  }

  navigate(to, rehire = this.props.rehire) {
    //common navigation function for all docs in list
    let { doc, hash } = this.props.match.params;
    let userDocs;
    let { page, pages } = this.state;

    if (rehire) {
      userDocs = this.props.docs.userDocs.filter(
        (doc) => doc.docs_categories_id === 2
      ); // Rehire
    } else {
      userDocs = this.props.docs.userDocs.filter(
        (doc) => doc.docs_categories_id === 1
      ); // Onboarding
    }

    let currentDocIndex = userDocs.findIndex((userDoc) => userDoc.slug == doc);
    let path = this.props.match.path;

    if (hash) {
      path = path.replace(':hash', hash);
    }

    let nextDoc = {};
    let prevDoc = {};

    if (currentDocIndex >= 0 && currentDocIndex < userDocs.length - 1) {
      prevDoc = userDocs[currentDocIndex - 1];
      nextDoc = userDocs[currentDocIndex + 1];
    }

    switch (to) {
      case 'next':
        if (nextDoc && nextDoc.id) {
          path = path.replace(':doc', nextDoc.slug);
          this.props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      case 'prev':
        if (pages && page > 1) {
          this.navigatePage('prev');
          break;
        }
        if (prevDoc && prevDoc.id) {
          path = path.replace(':doc', prevDoc.slug);
          this.props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      default:
        this.props.history.push(to);
        break;
    }
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type == 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { user, form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? '';
        }
        if (!form?.phone) {
          form.phone = user?.phone ?? '';
        }
        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    let companyName;
    let { activeForm } = this.props.docs;

    // if (this.props.rehire) {
    //     doc = this.props.docs.userDocs.find(
    //         (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //     );
    // } else {
    //     doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? 'About Trees'
          : 'NVTS';
    }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    this.setState({ doc, companyName });
    this.getFormData(doc.id);
  }

  componentDidUpdate = (prevProps, prevState) => {
    let companyName = '';
    let { activeForm } = this.props.docs;
    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? 'About Trees'
          : 'NVTS';
    }
    if (prevState.companyName !== companyName) {
      this.setState({
        companyName,
      });
    }
  };

  render() {
    let { form, errors, companyName } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Disability Status</h3>
        </div>
        <div className="doc_info-form disability-status">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="candidate_info_page"
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">
                  IMPORTANT: Please complete this form only if you are a current
                  employee, or a job offer has been extended and you have
                  accepted it.
                </h6>
                <p>
                  To enable{' '}
                  {companyName !== 'NVTS'
                    ? companyName
                    : 'North Valley Tree Service'}{' '}
                  to meet government reporting regulations, employees are asked,
                  but not required, to provide information with respect to a
                  disability that might affect their performance. You may
                  provide this information on a voluntary basis, which will be
                  treated as personal and confidential and kept separate from
                  your personnel file. Your decision to provide or refuse to
                  provide the requested information will not subject you to any
                  adverse treatment. Your voluntary cooperation will be
                  appreciated.
                </p>
              </div>
            </div>
            <div className="row form-row">
              <div className="col m-0">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Applicant Data<span className="required-star">&#x2A;</span>
                  </div>
                </div>
              </div>
              <div className="col m-0">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.printed_name'] ? 'error' : ''}`}
                      name="printed_name"
                      id="printed_name"
                      field="form.printed_name"
                      value={form.printed_name}
                      placeholder="Print Name"
                      required
                    />
                    {errors['form.printed_name'] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors['form.printed_name']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.phone'] ? 'error' : ''}`}
                      name="phone"
                      id="phone"
                      field="form.phone"
                      value={form.phone}
                      onChange={this.handlePhoneChange}
                      placeholder="Phone Number"
                      required
                    />
                    {errors['form.phone'] && (
                      <label htmlFor="phone" className="error_label">
                        {errors['form.phone']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.position'] ? 'error' : ''}`}
                      name="position"
                      id="position"
                      field="form.position"
                      value={form.position}
                      placeholder="Position (must specify position)"
                      required
                    />
                    {errors['form.position'] && (
                      <label htmlFor="position" className="error_label">
                        {errors['form.position']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.date'] ? 'error' : ''} w-100`}
                      name="date"
                      id="date"
                      required
                      selected={moment(form.date)}
                      field="form.date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, 'form.date')}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors['form.date'] && (
                      <label htmlFor="date" className="error_label">
                        {errors['form.date']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group agreement_checks">
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="disability_category"
                        id="disability_category1"
                        value="yes"
                        field="form.disability_category"
                        checked={form.disability_category == 'yes'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="disability_category1">
                        <strong>I fall under “Disability Category”</strong>
                      </label>
                    </div>
                    <p className="checkb_detail">
                      If you have a disability which might affect your
                      performance or create a hazard to yourself or others in
                      connection with the job you are performing or for which
                      you are applying, please tell us about the following:
                    </p>
                    <ol className="checkb_lists">
                      <li>
                        The skills and procedures you use or intend to use to
                        perform the job not withstanding the disability, and
                      </li>
                      <li>
                        The accommodations we might make which would enable you
                        to perform properly and safely, including special
                        equipment, changes in the physical layout of the job,
                        elimination of certain duties relating to the job, or
                        other accommodations.
                      </li>
                    </ol>
                    <p className="checkb_detail">
                      If you are disabled we would like to include you under the
                      Affirmative Action Program. It would assist us if you tell
                      us about,
                    </p>
                    <ol className="checkb_lists">
                      <li>
                        Special methods, skills and procedures which qualify you
                        for positions that you might not otherwise be able to do
                        because of your disability, so that you will be
                        considered for any positions of that kind, and
                      </li>
                      <li>
                        The accommodations which we could make which would
                        enable you to perform the job properly and safely,
                        including special equipment, changes in the physical
                        layout of the job, elimination of certain duties
                        relating to the job, or the other accommodations.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group agreement_checks">
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="disability_category"
                        id="disability_category2"
                        value="no"
                        field="form.disability_category"
                        checked={form.disability_category == 'no'}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="disability_category2">
                        <strong>No. I do not fall under this category.</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{' '}
                  <a href="">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedDisabilityStatus = compose(
  withRouter,
  connect(mapStateToProps)
)(DisabilityStatus);
export { connectedDisabilityStatus as DisabilityStatus };
