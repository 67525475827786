import React, { Component } from 'react';
import { config } from '../_helpers/config';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

class DocsThankYou extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let user = this.props.user.items ? this.props.user.items.data : {};
    let profile = this.props.user.profile ? this.props.user.profile.data : {};
    let appliedCompany =
      profile.applying_company === 1
        ? 'About Trees'
        : profile.applying_company === 2
          ? 'North Valley Tree Service'
          : 'North Valley Arbor Management';
    let document;
    user.rehire_docs_status === 2
      ? (document = 'Rehire')
      : user.docs_status === 2 &&
        user.rehire_docs_status === -1 &&
        (document = 'Onboarding');

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="onboarding-docs">
            <div className="doc-home">
              <div className="doc-home-info">
                <img
                  src={`${config.assetUrl}icons/doc-thank.svg`}
                  className="doc-home-img"
                />
                <h3 className="doc-username">
                  Thank you, {user.first_name} {user.last_name}.
                </h3>
                <p>
                  You have successfully completed the {document} documents. You
                  will receive an email when {appliedCompany} approves your
                  documents
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

let connectedDocsThankYou = compose(
  withRouter,
  connect(mapStateToProps)
)(DocsThankYou);

export { connectedDocsThankYou as DocsThankYou };
