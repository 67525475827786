import React from 'react';
import { connect } from 'react-redux';
import { applicantStatsActions } from '../_actions';

class ApplicantStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: '',
      statsHover: 'initial',
      index: 0,
    };
    this.applicantCount = this.applicantCount.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(applicantStatsActions.getStatistics());
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.applicantStats.items) {
      var statistics = nextProps.applicantStats.items.data.statistics;
      statistics.map((item, index) => {
        if (item.days === '3') {
          this.setState({ count: item.count });
        }
      });
    }
  }
  applicantCount(count, index) {
    this.setState({ count, statsHover: 'stats', index });
  }
  render() {
    const applicantStats = this.props.applicantStats;
    var statistics = [],
      source = [];

    if (this.props.applicantStats.items) {
      var stats = applicantStats.items.data;
      var count;
      {
        stats.statistics.map((item, index) => {
          if (index === 0) count = item.count;
          statistics.push(
            <span
              onClick={this.applicantCount.bind(this, item.count, index)}
              className={
                index === this.state.index ? this.state.statsHover : ''
              }
              key={index}
            >
              {item.days === '3' ? '72h' : item.days + 'd'}
            </span>
          );
        });
      }
      {
        stats.source.map((item, index) => {
          source.push(
            <div className="stats_item" key={index}>
              <div className="percent_div">
                <span className="percent">{item.per + '%'} </span>
                <span className="employee_type">{item.value}</span>
              </div>
              <div className="small_grey">{item.count + ' Employees'}</div>
            </div>
          );
        });
      }
    }

    return (
      <div className="applicant_stats_outer">
        <div className="applicant_stats inner_head">
          <h2>
            Applicant Statistics <span className="drag-icon-controller"></span>
          </h2>
          <span
            className="toggle-controller"
            aria-owns="applnstatus_main_details"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          ></span>
        </div>
        <div
          className="applicant_stats_details"
          id="applnstatus_main_details"
          aria-expanded={this.props.visibility == 1 ? 'true' : 'false'}
        >
          <div className="applicants_head">
            <h2>{this.state.count} Applicants</h2>
            <div className="appln_head_data">{statistics}</div>
          </div>
          <div className="app_stats_body">
            <div className="appStats_list">
              <h3>Applicant Sources</h3>
              {source}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicantStats } = state;
  return {
    applicantStats,
  };
}

const connectedHomePage = connect(mapStateToProps)(ApplicantStats);
export { connectedHomePage as ApplicantStats };

// export default ApplicantStats;
