import { authHeader, config } from '../_helpers';

export const createProfileService = {
  // getResources,
  // getPantSize,
  // getShirtSize,
  // getQualification,
  getAll,
  createProfile,
  getAllResource,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/getlistprofile?rehire=false',
    requestOptions
  ).then(handleResponse, handleError);
}

function createProfile(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: data,
  };

  return fetch(config.apiUrl + '/api/register', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getAllResource() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/getlistprofile?all=true',
    requestOptions
  ).then(handleResponse, handleError);
}

// function getResources(res) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(config.apiUrl + '/api/getlist/resource/'+res, requestOptions).then(handleResponse, handleError);
// }

// function getPantSize() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(config.apiUrl + '/api/getlist/pantsize', requestOptions).then(handleResponse, handleError);
// }

// function getShirtSize() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(config.apiUrl + '/api/getlist/shirtsize', requestOptions).then(handleResponse, handleError);
// }

// function getQualification() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(config.apiUrl + '/api/getlist/qualification', requestOptions).then(handleResponse, handleError);
// }

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
