export const chatConstants = {
  CONVERSATION_SETTINGS_REQUEST: 'CONVERSATION_SETTINGS_REQUEST',
  CONVERSATION_SETTINGS_SUCCESS: 'CONVERSATION_SETTINGS_SUCCESS',
  CONVERSATION_SETTINGS_FAIL: 'CONVERSATION_SETTINGS_FAIL',

  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_FAIL: 'USERS_FAIL',

  CREWS_REQUEST: 'CREWS_REQUEST',
  CREWS_SUCCESS: 'CREWS_SUCCESS',
  CREWS_FAIL: 'CREWS_FAIL',

  CONVERSATIONS_REQUEST: 'CONVERSATIONS_REQUEST',
  CONVERSATIONS_SUCCESS: 'CONVERSATIONS_SUCCESS',
  CONVERSATIONS_FAIL: 'CONVERSATIONS_FAIL',

  CONVERSATION_REQUEST: 'CONVERSATION_REQUEST',
  CONVERSATION_SUCCESS: 'CONVERSATION_SUCCESS',
  CONVERSATION_FAIL: 'CONVERSATION_FAIL',

  ALL_COUNTS_REQUEST: 'ALL_COUNTS_REQUEST',
  ALL_COUNTS_SUCCESS: 'ALL_COUNTS_SUCCESS',
  ALL_COUNTS_FAIL: 'ALL_COUNTS_FAIL',

  SEARCH_CONVERSATION_REQUEST: 'SEARCH_CONVERSATION_REQUEST',
  SEARCH_CONVERSATION_SUCCESS: 'SEARCH_CONVERSATION_SUCCESS',
  SEARCH_CONVERSATION_FAIL: 'SEARCH_CONVERSATION_FAIL',

  READ_ALL_MESSAGES_REQUEST: 'READ_ALL_MESSAGES_REQUEST',
  READ_ALL_MESSAGES_SUCCESS: 'READ_ALL_MESSAGES_SUCCESS',
  READ_ALL_MESSAGES_FAIL: 'READ_ALL_MESSAGES_FAIL',

  SELECT_CONVERSATION_REQUEST: 'SELECT_CONVERSATION_REQUEST',
  SELECT_CONVERSATION_SUCCESS: 'SELECT_CONVERSATION_SUCCESS',
  SELECT_CONVERSATION_FAIL: 'SELECT_CONVERSATION_FAIL',

  CREATE_CONVERSATION_REQUEST: 'CREATE_CONVERSATION_REQUEST',
  CREATE_CONVERSATION_SUCCESS: 'CREATE_CONVERSATION_SUCCESS',
  CREATE_CONVERSATION_FAIL: 'CREATE_CONVERSATION_FAIL',

  UPDATE_CONVERSATION_REQUEST: 'UPDATE_CONVERSATION_REQUEST',
  UPDATE_CONVERSATION_SUCCESS: 'UPDATE_CONVERSATION_SUCCESS',
  UPDATE_CONVERSATION_FAIL: 'UPDATE_CONVERSATION_FAIL',

  ADD_PARTICIPANT_REQUEST: 'ADD_PARTICIPANT_REQUEST',
  ADD_PARTICIPANT_SUCCESS: 'ADD_PARTICIPANT_SUCCESS',
  ADD_PARTICIPANT_FAIL: 'ADD_PARTICIPANT_FAIL',

  REMOVE_PARTICIPANT_REQUEST: 'REMOVE_PARTICIPANT_REQUEST',
  REMOVE_PARTICIPANT_SUCCESS: 'REMOVE_PARTICIPANT_SUCCESS',
  REMOVE_PARTICIPANT_FAIL: 'REMOVE_PARTICIPANT_FAIL',

  SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',

  UPDATE_MESSAGE_REQUEST: 'UPDATE_MESSAGE_REQUEST',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAIL: 'UPDATE_MESSAGE_FAIL',

  DELETE_MESSAGE_REQUEST: 'DELETE_MESSAGE_REQUEST',
  DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAIL: 'DELETE_MESSAGE_FAIL',

  GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAIL: 'GET_MESSAGES_FAIL',

  GET_LATEST_MESSAGES_REQUEST: 'GET_LATEST_MESSAGES_REQUEST',
  GET_LATEST_MESSAGES_SUCCESS: 'GET_LATEST_MESSAGES_SUCCESS',
  GET_LATEST_MESSAGES_FAIL: 'GET_LATEST_MESSAGES_FAIL',

  SET_ADMIN_MODE_REQUEST: 'SET_ADMIN_MODE_REQUEST',
  SET_ADMIN_MODE_SUCCESS: 'SET_ADMIN_MODE_SUCCESS',
  SET_ADMIN_MODE_FAIL: 'SET_ADMIN_MODE_FAIL',

  SET_FAVORITE_REQUEST: 'SET_FAVORITE_REQUEST',
  SET_FAVORITE_SUCCESS: 'SET_FAVORITE_SUCCESS',
  SET_FAVORITE_FAIL: 'SET_FAVORITE_FAIL',

  SEND_FILE_REQUEST: 'SEND_FILE_REQUEST',
  SEND_FILE_SUCCESS: 'SEND_FILE_SUCCESS',
  SEND_FILE_FAIL: 'SEND_FILE_FAIL',

  RECENT_MESSAGES_REQUEST: 'RECENT_MESSAGES_REQUEST',
  RECENT_MESSAGES_SUCCESS: 'RECENT_MESSAGES_SUCCESS',
  RECENT_MESSAGES_FAIL: 'RECENT_MESSAGES_FAIL',

  UNREAD_COUNT_UPDATE: 'UNREAD_COUNT_UPDATE',
  NEW_MESSAGE_UPDATE: 'NEW_MESSAGE_UPDATE',
  CLEAR_CONVERSATION_UPDATE: 'CLEAR_CONVERSATION_UPDATE',
};
//
