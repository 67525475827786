import { dashboardConstants } from '../_constants';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  activityLogLoading: false,
  activityLog: {},
  activityLogError: null,
};

export function dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Activity log
    case dashboardConstants.ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        activityLogLoading: true,
      };
    case dashboardConstants.ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLogLoading: false,
        activityLog: action?.data,
      };
    case dashboardConstants.ACTIVITY_LOG_ERROR:
      // toast.error("Something went wrong with fetching activity log!")
      const parsed = JSON.parse(action.error);
      if (
        !typeof parsed.message === 'string' &&
        Object.keys(parsed?.message).length
      ) {
        for (let key in parsed?.message) {
          parsed?.message[key]?.map((msg) => toast.error(msg));
        }
      } else {
        toast.error(parsed?.message);
      }
      return {
        ...state,
        activityLogLoading: false,
        activityLogError: action?.error,
      };
    default:
      return state;
  }
}
