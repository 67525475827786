import React, { useEffect, useState } from 'react';
import styles from './SystemManage.module.scss';
import { ReactComponent as Info } from '.././assets/icons/info-green.svg';
import Modal from 'react-modal';
import BulkImportPreview from './BulkImportPreview';
import { v4 as uuidv4 } from 'uuid';
import { config } from '_helpers';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '_actions';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
const TEMPLATE_FILE_URL = `${config?.assetUrl}samples/downloads/Import-Bypass-Employees.csv`;
const SystemManage = () => {
  const dispatch = useDispatch();
  const addEmployeeLoading = useSelector(
    (state) => state.user.isAddEmployeeLoading
  );
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [bulkImportModal, setBulkImportModal] = useState(false);
  const [error, setError] = useState(false);
  const [isBulkImport, setIsBulkImport] = useState(false);
  const [paginatedData, setPaginatedData] = useState();
  const [rawData, setRawData] = useState([]);
  const [fileError, setFileError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [employee, setEmployee] = useState({
    firstName: '',
    lastName: '',
    email: '',
    applyingCompany: '',
  });
  const handleAddEmployeeOnChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });
  };
  const onAdd = async () => {
    if (!employee?.email || employee?.email.trim() === '')
      return setError(true);
    const data = new FormData();
    data.append('firstname', employee.firstName);
    data.append('lastname', employee.lastName);
    data.append('email', employee.email);
    data.append('applyingcompany', employee.applyingCompany);
    const res = await dispatch(userActions.addEmployeeForBypassing(data));
    if (res.success) {
      onCancelAddEmployee();
      return toast.success('Employee added successfully.');
    } else {
      return toast.error(res.message);
    }
  };
  const onImportBulk = () => {
    if (!rawData.length) return;
    setIsBulkImport(true);
    setBulkImportModal(false);
  };
  const handleFileSelect = (e) => {
    setFileError('');
    const file = e.target.files[0];
    // Parse and check csv data
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Check if the parsed CSV file has the expected column headers
        const expectedHeaders = [
          'First name',
          'Last name',
          'Email',
          'Applying Company',
        ];
        const headers = results.meta.fields;
        if (
          headers.length !== 4 ||
          !headers.every((header) => expectedHeaders.includes(header)) //format checking
        ) {
          setFileError(
            'The file has the wrong formatting. Please check the template file for the correct formatting.'
          );
          return;
        }
        // Check if each row of the parsed CSV file has valid data
        const rows = results.data;
        rows.forEach((row, i) => {
          if (
            !row['First name'] ||
            !row['Last name'] ||
            !row['Email'] ||
            !row['Applying Company']
          ) {
            setFileError(
              `Row ${i + 3} of the uploaded CSV file does not have valid data.`
            );
            return;
          }
        });

        //the uploaded data is valid. Moving forward to process the data
        const reader = new FileReader();
        reader.onload = (event) => {
          const batches = [];
          const batchSize = 30;
          const contents = event.target.result;
          const rows = contents.trim().split('\n').slice(1); // skip first row (headers)
          const data = [];
          let k = 0;
          for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            const [firstname, lastname, email, applyingcompany] =
              row.split(',');
            const updatedApplyingCompany = applyingcompany.includes(
              'North Valley Tree Service'
            )
              ? 2
              : 1;
            const item = {
              firstname,
              lastname,
              email,
              applyingcompany: updatedApplyingCompany,
              id: uuidv4(),
              index,
            };
            data.push(item);

            if (data.length % batchSize === 0) {
              batches.push(data.slice(data.length - batchSize));
              k = k + 1;
            }

            if (index === rows.length - 1) {
              batches.push(data.slice(batchSize * k, data.length));
            }
          }

          setRawData(data);
          setPaginatedData(batches);
        };
        reader.readAsText(file);
      },
      error: function (error) {
        setFileError('An error occurred while parsing the uploaded CSV file.');
        console.error(error);
      },
    });
  };
  const onDownloadTemplate = () => {
    saveAs(TEMPLATE_FILE_URL, '');
  };
  const onCancelAddEmployee = () => {
    setAddEmployeeModal(false);
    setEmployee({
      firstName: '',
      lastName: '',
      email: '',
      applyingCompany: '',
    });
    setError(false);
    setSelectedOption('');
  };
  const onCancelBulkImport = () => {
    setBulkImportModal(false);
    setFileError('');
    setRawData([]);
    setPaginatedData([]);
  };
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (!selectedValue) {
      setErrorMessage('Please select an option');
    } else {
      setErrorMessage('');
    }
    const { name, value } = event.target;
    setEmployee({ ...employee, [name]: value });
  };
  useEffect(() => {
    if (!rawData.length) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [rawData.length]);
  return (
    <div className={styles.SystemManage}>
      {/* ------------------------ADD EMPLOYEE MODAL------------------------- */}
      <Modal
        isOpen={addEmployeeModal}
        overlayClassName="modal-overlay"
        className={styles.system_manage_modal}
      >
        <div className="system-manage-modal-header">
          <h4>ADD EMPLOYEE</h4>
        </div>
        <div className="system-manage-modal-body">
          <label htmlFor="first-name">First Name</label>
          <input
            className="input"
            name="firstName"
            type="text"
            onChange={handleAddEmployeeOnChange}
          />
          <label htmlFor="last-name">Last Name</label>
          <input
            className="input"
            name="lastName"
            type="text"
            onChange={handleAddEmployeeOnChange}
          />
          <label htmlFor="email">
            Email Address<span style={{ color: 'red' }}>*</span>
          </label>
          <input
            className="input"
            name="email"
            type="email"
            onChange={handleAddEmployeeOnChange}
          />
          {error && <p className="error">Email address is required.</p>}
          <label htmlFor="applying-company">
            Applying Company<span style={{ color: 'red' }}>*</span>
          </label>
          <select
            name="applyingCompany"
            value={selectedOption}
            onChange={handleSelect}
          >
            <option value="">Select an option</option>
            <option value={1}>About Trees</option>
            <option value={2}>NVTS</option>
          </select>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
        <div className="system-manage-modal-footer">
          <button className="button grey" onClick={onCancelAddEmployee}>
            Cancel
          </button>
          <button
            className="button"
            onClick={onAdd}
            disabled={addEmployeeLoading}
          >
            Add
          </button>
        </div>
      </Modal>
      {/* ------------------------BULK IMPORT MODAL------------------------- */}
      <Modal
        isOpen={bulkImportModal}
        overlayClassName="modal-overlay"
        className={styles.system_manage_modal}
      >
        <div className="system-manage-modal-header">
          <h4>BULK IMPORT</h4>
        </div>
        <div className="system-manage-modal-body">
          <input type="file" accept=".csv" onChange={handleFileSelect} />
          <p style={{ color: 'red' }}>{fileError}</p>
          <p>
            <span className="download-file" onClick={onDownloadTemplate}>
              Click here
            </span>{' '}
            to download the template file.
          </p>
        </div>
        <div className="system-manage-modal-footer">
          <button className="button grey" onClick={onCancelBulkImport}>
            Cancel
          </button>
          <button
            className="button"
            onClick={onImportBulk}
            disabled={isDisabled || fileError.length}
          >
            Continue
          </button>
        </div>
      </Modal>
      {/* ------------------------------------------------- */}
      <div className="main-container">
        {isBulkImport ? (
          <BulkImportPreview
            setIsBulkImport={setIsBulkImport}
            setPaginatedData={setPaginatedData}
            data={paginatedData}
            rawData={rawData}
            onCancelBulkImport={onCancelBulkImport}
          />
        ) : (
          <>
            <div className="banner">
              <h1>Bypass Employee Application</h1>
            </div>
            <div className="action-container">
              <div className="title">
                <h1>Add employees by overriding job application</h1>
              </div>
              <div className="information-box">
                <span className="info-title">
                  <Info />
                  <h3>Information</h3>
                </span>
                <p>
                  Here you can add new employees without going through the job
                  application process. After accounts are made, employees can
                  log in and submit their documents.
                </p>
              </div>
              <div className="action-buttons">
                <button
                  className="button"
                  onClick={() => setAddEmployeeModal(true)}
                >
                  Add Employee
                </button>
                <p>OR</p>
                <button
                  className="button"
                  onClick={() => setBulkImportModal(true)}
                >
                  Bulk Import
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default SystemManage;
