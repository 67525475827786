import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { assignmentStatsActions } from '../_actions';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
class ReportListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {
        data: [],
      },
      order: true,
      sortedField: 'crew',
      typeId: this.props.typeId,
      toDate: this.props.toDate,
      fromDate: this.props.fromDate,
      statusType: this.props.statusType,
    };

    this.sorting = this.sorting.bind(this);
  }

  componentDidMount() {
    this.loadGraph();
  }

  loadGraph = () => {
    let data = new FormData();
    let order = this.state.order ? 'asc' : 'desc';
    data.append('type', 'list');
    data.append('from', moment(this.state.fromDate).format('YYYY-MM-DD'));
    data.append('to', moment(this.state.toDate).format('YYYY-MM-DD'));
    data.append('assignment_type_id', this.props.typeId);
    data.append('status', this.props.statusType);
    data.append('order', order);
    data.append('sort', this.state.sortedField);
    this.props.dispatch(
      assignmentStatsActions.getAssignemntStatusGraphView(data, 1)
    );
  };

  componentWillReceiveProps(nextProps) {
    let { assignmentStats, toDate, fromDate, typeId, statusType } = nextProps;
    if (
      toDate != this.state.toDate ||
      fromDate != this.state.fromDate ||
      typeId != this.state.typeId ||
      statusType != this.state.statusType
    ) {
      this.setState(
        {
          toDate: toDate,
          fromDate: fromDate,
          typeId: typeId,
          statusType: statusType,
        },
        () => {
          this.loadGraph();
        }
      );
    }
    if (assignmentStats.assignmentStatusGraph) {
      if (assignmentStats.assignmentStatusGraph.success) {
        this.setState({
          list: assignmentStats.assignmentStatusGraph.data,
        });
      }
    }
  }

  sorting(sort, e) {
    if (this.state.sortedField == sort) {
      this.setState(
        {
          order: !this.state.order,
          list: {
            data: [],
          },
        },
        () => {
          this.loadGraph();
        }
      );
    } else {
      this.setState(
        {
          sortedField: sort,
          order: true,
          list: {
            data: [],
          },
        },
        () => {
          this.loadGraph();
        }
      );
    }
  }

  pagination = (pageNum) => {};
  handlePageChange(page) {
    if (!page) return;
    var pageNum = page.selected + 1;
    let order = this.state.order ? 'asc' : 'desc';
    let data = new FormData();
    data.append('type', 'list');
    data.append('from', moment(this.state.fromDate).format('YYYY-MM-DD'));
    data.append('to', moment(this.state.toDate).format('YYYY-MM-DD'));
    data.append('page', pageNum);
    this.props.typeId && data.append('assignment_type_id', this.props.typeId);
    this.state.statusType && data.append('status', this.state.statusType);
    data.append('order', order);
    data.append('sort', this.state.sortedField);
    this.props.dispatch(
      assignmentStatsActions.getAssignemntStatusGraphView(data, pageNum)
    );
  }

  render() {
    var crewName,
      projectName,
      typeofAssignment,
      dateOn,
      dateOff = 'sort-arrow';
    if (this.state.sortedField === 'crew') {
      if (!this.state.order) {
        crewName = 'sort-arrow asc';
      } else {
        crewName = 'sort-arrow desc';
      }
    } else {
      crewName = 'sort-arrow asc not-active';
    }
    if (this.state.sortedField === 'project') {
      if (!this.state.order) {
        projectName = 'sort-arrow asc';
      } else {
        projectName = 'sort-arrow desc';
      }
    } else {
      projectName = 'sort-arrow asc not-active';
    }
    if (this.state.sortedField === 'type_of_assignment') {
      if (!this.state.order) {
        typeofAssignment = 'sort-arrow asc';
      } else {
        typeofAssignment = 'sort-arrow desc ';
      }
    } else {
      typeofAssignment = 'sort-arrow asc not-active';
    }
    if (this.state.sortedField === 'date_on') {
      if (!this.state.order) {
        dateOn = 'sort-arrow asc';
      } else {
        dateOn = 'sort-arrow desc';
      }
    } else {
      dateOn = 'sort-arrow asc not-active';
    }
    if (this.state.sortedField === 'date_off') {
      if (!this.state.order) {
        dateOff = 'sort-arrow asc';
      } else {
        dateOff = 'sort-arrow desc';
      }
    } else {
      dateOff = 'sort-arrow asc not-active';
    }
    let paginationHistoryHtml = [];
    if (this.state.list.data.length > 0) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.list.current_page;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.list.last_page
          ? currentPage + 2
          : this.state.list.last_page;
      let nextPage =
        currentPage + 1 > this.state.list.last_page
          ? this.state.list.last_page
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.pagination(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? 'active' : 'inactive'}
            key={i}
            onClick={() => {
              this.pagination(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (lastPageIndex + 1 < this.state.list.data.last_page) {
        pages.push(
          <li className="inactive" key={this.state.list.data.last_page + 1}>
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.list.data.last_page) {
        pages.push(
          <li
            className="inactive"
            key={this.state.list.data.last_page}
            onClick={() => {
              this.pagination(this.state.list.data.last_page);
            }}
          >
            <a>{this.state.list.data.last_page}</a>
          </li>
        );
      }

      if (pages.length > 0) {
        paginationHistoryHtml.push(
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="customised-pagination right-align">
              <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.list.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange.bind(this)}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="history_lists outer_list">
          <div className="mobile_hide">
            <div className="list_header">
              <div className="list_item_head list_item_head__image"></div>
              <div
                className="list_item_head"
                onClick={this.sorting.bind(this, 'crew')}
              >
                Resource
                <span className={crewName} />
              </div>
              <div
                className="list_item_head"
                onClick={this.sorting.bind(this, 'project')}
              >
                Project Name
                <span className={projectName} />
              </div>
              <div
                className="list_item_head"
                onClick={this.sorting.bind(this, 'date_on')}
              >
                Date On
                <span className={dateOn} />
              </div>
              <div
                className="list_item_head"
                onClick={this.sorting.bind(this, 'date_off')}
              >
                Date Off
                <span className={dateOff} />
              </div>
              <div
                className="list_item_head"
                onClick={this.sorting.bind(this, 'type_of_assignment')}
              >
                Type of Assignment
                <span className={typeofAssignment} />
              </div>
              <div className="list_item_head">No of shifts</div>
              <div className="list_item_head">Notes</div>
            </div>
            <div className="history_items">
              {this.state.list.data.map((item, index) => (
                <div className="status_item" key={index}>
                  <div className="list_item list_item_head__image">
                    {item.assignment_status == 1 && (
                      <img
                        alt=""
                        src={require('../assets/images/Available1.svg').default}
                      />
                    )}
                    {item.assignment_status == 2 && (
                      <img
                        alt=""
                        src={
                          require('../assets/images/unavailable1.svg').default
                        }
                      />
                    )}
                    {item.assignment_status == 3 && (
                      <img
                        alt=""
                        src={
                          require('../assets/images/Committed-one.svg').default
                        }
                      />
                    )}
                  </div>
                  <div className="list_item">
                    <Link to={`/resources/${item.resource_identifier_id}`}>
                      {item.resource_name}
                    </Link>
                  </div>
                  <div className="list_item">
                    {item.name ? item.name : '--'}
                  </div>
                  <div className="list_item">
                    {item.date_on ? item.date_on : '--'}
                  </div>
                  <div className="list_item">
                    {item.date_off ? item.date_off : '--'}
                  </div>
                  <div className="list_item">
                    {item.assignment_status ? (
                      <React.Fragment>
                        {item.assignment_type_id == 3 && 'Project'}
                      </React.Fragment>
                    ) : (
                      '--'
                    )}
                  </div>
                  <div className="list_item">
                    {item.no_of_shifts ? item.no_of_shifts : '--'}
                  </div>

                  <div className="list_item note_item">
                    <div className="notes_ellipsis">{item.notes}</div>
                    <div className="note_full">{item.notes}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {this.state.list.data.map((item, index) => (
          <React.Fragment key={index}>
            <div className="for_mobile_history_list">
              <hr></hr>
              <div className="history_items">
                <div className="status_item">
                  <div className="list_item">
                    <strong>Status</strong>
                  </div>
                  <div className="list_item">
                    {item.assignment_status == 1 && (
                      <img
                        alt=""
                        src={require('../assets/images/Available1.svg').default}
                      />
                    )}
                    {item.assignment_status == 2 && (
                      <img
                        alt=""
                        src={
                          require('../assets/images/unavailable1.svg').default
                        }
                      />
                    )}
                    {item.assignment_status == 3 && (
                      <img
                        alt=""
                        src={
                          require('../assets/images/Committed-one.svg').default
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Crew</strong>
                  </div>
                  <div className="list_item">
                    <Link to={`/resources/${item.resource_identifier_id}`}>
                      {item.resource_name}
                    </Link>
                  </div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Project</strong>
                  </div>
                  <div className="list_item">{item.name ? item.name : '-'}</div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Date On</strong>
                  </div>
                  <div className="list_item">
                    {item.date_on ? item.date_on : '-'}
                  </div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Date Off</strong>
                  </div>
                  <div className="list_item">
                    {item.date_off ? item.date_off : '-'}
                  </div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Type of Assignment</strong>
                  </div>
                  <div className="list_item">
                    {item.assignment_status ? (
                      <React.Fragment>
                        {item.assignment_type_id == 3 && 'Project'}
                      </React.Fragment>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>Notes</strong>
                  </div>
                  <div className="list_item">{item.notes}</div>
                </div>
                <div className="status_item">
                  <div className="list_item">
                    <strong>No of shifts</strong>
                  </div>
                  <div className="list_item">{item.name ? item.name : '-'}</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        {paginationHistoryHtml}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const assignmentStatus = connect(mapStateToProps)(ReportListView);
export { assignmentStatus as ReportListView };
