import * as React from 'react';
import ReactModal from 'react-modal';
import { userActions } from '_actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const ChangePassword = (props) => {
  const { userType, user, className, isAdmin, profile } = props;

  const [password, setPassword] = React.useState();
  const [prevPassword, setPrevPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [type, setType] = React.useState('password');
  const [passwordError, setPasswordError] = React.useState({
    type: '',
    message: '',
  });
  const [loading, setLoading] = React.useState(false);
  const isLoggedInUser = profile?.data?.id === user?.id;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(true);
    let validate = validatePassword();

    if (!validate) {
      return;
    }

    let userId = user?.id;
    let formData = new FormData();
    formData.append('id', userId);
    formData.append('type', userType ? userType : 'user');
    if (isLoggedInUser) {
      formData.append('previous_password', prevPassword);
    }
    formData.append('password', password);

    props
      .changePassword(formData)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
        } else {
          toast.error(
            res?.message?.previous_password
              ? res?.message?.previous_password[0]
              : res?.message
          );
        }
        resetForm();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const closeModal = () => {
    resetForm();
  };

  const resetForm = () => {
    setPassword('');
    setPrevPassword('');
    setConfirmPassword('');
    setModalOpen(false);
    setLoading(false);
    setSubmitted(false);
  };

  const validatePassword = () => {
    let flag = true;
    let message = '';
    let type = '';
    if (password && password.length < 8) {
      flag = false;
      type = 1;
      message = 'Password length must be at least 8 characters';
    } else if (String(password) !== String(confirmPassword)) {
      flag = false;
      type = 2;
      message = 'Password and Confirm Password does not match';
    }

    if (!password || !confirmPassword) {
      flag = false;
    }

    if (isLoggedInUser && !prevPassword) {
      flag = false;
    }

    if (isLoggedInUser && !prevPassword?.trim()) {
      flag = false;
    }

    if (!flag) {
      setPasswordError({
        message,
        type,
      });
      setLoading(false);
    } else {
      setPasswordError({ type: '', message: '' });
    }
    return flag;
  };

  return (
    <div className="change-pwd-container">
      <button
        className={`button ${className}`}
        onClick={() => setModalOpen(true)}
      >
        Change Password
      </button>
      <ReactModal
        isOpen={modalOpen}
        onClose={() => closeModal()}
        contentLabel="Minimal Modal Example"
        overlayClassName="custom-modal"
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">Change Password</h4>
            <img
              className="closeModal"
              onClick={() => closeModal()}
              src={require('../assets/images/close_green.png').default}
            />
          </div>
          <div className="modal-body">
            <div className="reset_pwd_outer">
              <div className="loginForm reset_pwd">
                <form name="form" onSubmit={handleSubmit}>
                  <div
                    className={
                      'form-group' +
                      (submitted && !prevPassword ? ' has-error' : '')
                    }
                  >
                    {isLoggedInUser ? (
                      <>
                        <label htmlFor="email">Current Password</label>
                        <input
                          type="text"
                          className="form-control"
                          name="prevPassword"
                          value={prevPassword}
                          onChange={(e) => setPrevPassword(e.target.value)}
                        />
                        {submitted &&
                          (!prevPassword || !prevPassword?.trim()) && (
                            <div className="help-block">
                              Current Password is required
                            </div>
                          )}
                      </>
                    ) : (
                      ''
                    )}
                    <label htmlFor="email">New Password</label>
                    <div className="inline-login-txt-wrapper">
                      <input
                        type={type}
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="toggle-icon-password"
                        onClick={() => {
                          setType(type === 'password' ? 'text' : 'password');
                        }}
                        aria-hidden={type === 'password' ? 'true' : 'false'}
                      >
                        <img
                          src={require('../assets/images/eye.png').default}
                        />
                        <img
                          src={require('../assets/images/eye-hide.png').default}
                        />
                      </span>
                    </div>
                    {submitted && !password && (
                      <div className="help-block">Password is required</div>
                    )}
                    {submitted && password && passwordError.type === 1 && (
                      <div className="help-block">{passwordError.message}</div>
                    )}
                    <label htmlFor="email">Confirm password</label>
                    <input
                      type={type}
                      className="form-control"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {submitted && !confirmPassword && (
                      <div className="help-block">
                        Confirm password is required
                      </div>
                    )}
                    {submitted &&
                      confirmPassword &&
                      passwordError.type === 2 && (
                        <div className="help-block">
                          {passwordError.message}
                        </div>
                      )}
                  </div>
                  <hr />
                  <div className="form-group btnGroup change-pwd-btn">
                    <button className="btn btn-primary" disabled={loading}>
                      Submit
                    </button>
                    {loading && (
                      <img
                        alt="loader"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { profile } = state.user;
  return {
    profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data) => dispatch(userActions.changePassword(data)),
  };
};

export const ChangePasswordComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
