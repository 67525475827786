import { permissionConstants } from '../_constants';

export function adminpermission(state = {}, action) {
  switch (action.type) {
    case permissionConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.GETALL_SUCCESS:
      return {
        permissions: action.adminpermission,
      };
    case permissionConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };

    case permissionConstants.SETPERM_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.SETPERM_SUCCESS:
      return {
        value: action.value,
      };
    case permissionConstants.SETPERM_FAILURE:
      return {
        error: action.error,
      };

    case permissionConstants.GETADMIN_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.GETADMIN_SUCCESS:
      return {
        admin: action.admin,
      };
    case permissionConstants.GETADMIN_FAILURE:
      return {
        error: action.error,
      };

    case permissionConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.DELETE_SUCCESS:
      return {
        unassign: action.unassign,
      };
    case permissionConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    case permissionConstants.ALLADMIN_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.ALLADMIN_SUCCESS:
      return {
        allAdmin: action.allAdmin,
      };
    case permissionConstants.ALLADMIN_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
