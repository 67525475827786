import { manifestConstants } from '../_constants';

export function superintendent(state = {}, action) {
  switch (action.type) {
    case manifestConstants.GETSUPERINTENDENT_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETSUPERINTENDENT_SUCCESS:
      return {
        list: action.superintendent,
      };
    case manifestConstants.GETSUPERINTENDENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
