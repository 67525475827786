import React, { Fragment } from 'react';
import { config } from '../_helpers';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactModal from 'react-modal';

class TextMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editOptions: false,
      message: this.props.message,
      emojiPopup: false,
      deletePopup: false,
    };

    this.messageEditHandler = this.messageEditHandler.bind(this);
    this.cancelMessageEdit = this.cancelMessageEdit.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.MessageInput = null;
    this.handleKeyStroke = this.handleKeyStroke.bind(this);
    this.enableEditMode = this.enableEditMode.bind(this);
    this.saveBtn = React.createRef();
  }

  messageEditHandler(e) {
    this.setState({
      message: {
        ...this.state.message,
        body: e.target.value,
      },
    });
  }

  handleEmojiClick(e) {
    let selectionStart = this.MessageInput.selectionStart;
    let selectionEnd = this.MessageInput.selectionEnd;
    let message = this.state.message;
    let messageStart = message.body.substr(0, selectionStart);
    let messageEnd = message.body.substr(selectionEnd, message.body.length);

    this.setState(
      {
        emojiPopup: false,
        message: {
          ...this.state.message,
          body: messageStart + e.target.id + messageEnd,
        },
      },
      () => {
        this.MessageInput.focus();
        this.MessageInput.selectionStart = this.MessageInput.selectionEnd =
          selectionStart + 2;
      }
    );
  }

  saveMessage() {
    let message = this.state.message;
    message.body = this.state.message.body.trim();

    if (message.body.length) {
      if (message.body != this.props.message.body) {
        this.props.editMessage(message);
      }
      this.setState({
        editMode: false,
      });
    }
    this.props.toggleEditing();
  }

  deleteMessage() {
    this.props.deleteMessage(this.state.message);
    this.setState({ deletePopup: false });
  }

  enableEditMode() {
    this.props.toggleEditing();
    this.setState({ editMode: true, editOptions: false });
    const interval = setInterval(() => {
      if (this.saveBtn) {
        this.saveBtn.focus();
        clearInterval(interval);
      }
    }, 100);
  }

  cancelMessageEdit() {
    this.props.toggleEditing();
    this.setState({
      message: {
        ...this.state.message,
        body: this.props.message.body || '',
      },
      editMode: false,
    });
  }

  handleKeyStroke(e) {
    if (e.keyCode == 13 && e.shiftKey) {
      let selectionStart = this.MessageInput.selectionStart;
      let selectionEnd = this.MessageInput.selectionEnd;
      let message = this.state.message;
      let messageStart = message.body.substr(0, selectionStart);
      let messageEnd = message.body.substr(selectionEnd, message.body.length);

      this.setState(
        {
          message: {
            ...this.state.message,
            body: messageStart + '\n' + messageEnd,
          },
        },
        () => {
          this.MessageInput.scrollTop = this.MessageInput.scrollHeight;
          this.MessageInput.focus();
          this.MessageInput.selectionStart = this.MessageInput.selectionEnd =
            selectionStart + 1;
        }
      );
      e.preventDefault();
    } else if (e.keyCode == 13) {
      this.saveMessage();
      e.preventDefault();
    }
  }

  componentDidMount() {
    this.setState({
      message: this.props.message,
    });
  }

  componentDidUpdate(oldProps) {
    if (!this.state.editMode && this.props.message != oldProps.message) {
      this.setState({
        message: this.props.message,
      });
    }
  }

  render() {
    let { sender, body, created_at, updated_at, deleted_at } =
      this.state.message;

    let { profile } = this.props.user;
    let permissions = this.props.permissions;
    let admin = this.props.admin;
    let conversation = this.props.conversation;

    let edited = created_at == updated_at ? false : true;

    return (
      <div className={`msg-users ${this.state.editMode && 'editing'}`}>
        <ReactModal
          isOpen={this.state.deletePopup}
          className="delete-modal"
          overlayClassName="delete-modal-overlay"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="deleteMsg">
                Are you sure you want to delete this message?
              </h4>
            </div>
            <div className="modal-footer">
              <div className="modal-btn-actions">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ deletePopup: false, editOptions: false });
                  }}
                  className="btn msg-cancel-btn"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn msg-btn"
                  onClick={this.deleteMessage}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        <div className="msg-user-pic">
          <img
            alt=""
            src={`${config.baseUrl}${sender.profile_photo}`}
            onError={(e) => {
              e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
            }}
          />
        </div>
        <div className="msg-messageData">
          <div className="msg-name">
            {profile && sender.id == profile.data.id
              ? 'You'
              : `${sender.first_name} ${sender.last_name}`}
          </div>
          <div className="time">{moment(created_at).format('h:mm A')}</div>
          {this.state.editMode ? (
            <div className="msg-full edit-mode">
              <div className="row msg-mob-messageheader">
                <div className="col-xs-8">
                  <a className="mob-backbtn">
                    <img src={`${config.assetUrl}icons/close.svg`} alt="" />
                  </a>
                </div>
              </div>
              <form
                id="editmessage"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {this.state.emojiPopup && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      this.setState({ emojiPopup: false });
                    }}
                  >
                    <div className="emojis">
                      <a onClick={this.handleEmojiClick} id="&#x2764;&#xFE0F;">
                        ❤️
                      </a>
                      <a onClick={this.handleEmojiClick} id="&#x1F604;">
                        😄
                      </a>
                      <a onClick={this.handleEmojiClick} id="&#x1F614;">
                        😞
                      </a>
                      <a onClick={this.handleEmojiClick} id="&#x1F602;">
                        😂
                      </a>
                      <a onClick={this.handleEmojiClick} id="&#x1F44D;">
                        👍
                      </a>
                    </div>
                  </OutsideClickHandler>
                )}

                <textarea
                  ref={(ref) => (this.MessageInput = ref)}
                  type="text"
                  id="editMsg"
                  name="editMsg"
                  className="form-control edtiMsg"
                  value={body}
                  onChange={this.messageEditHandler}
                  placeholder="This message needs to be edited"
                  onKeyDown={this.handleKeyStroke}
                ></textarea>

                <div className="edit_emoji">
                  <button className="sendBtn">
                    <img src={`${config.assetUrl}icons/save-tick.svg`} />
                  </button>
                  <button
                    onClick={() => {
                      this.setState({ emojiPopup: !this.state.emojiPopup });
                    }}
                    className={`sendBtn emoji ${this.state.emojiPopup && 'emoji-added'}`}
                  >
                    <img src={`${config.assetUrl}icons/add_emoji.svg`} />
                  </button>
                </div>

                <div className="editButtons pull-right">
                  <button
                    onClick={this.cancelMessageEdit}
                    className="btn msg-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    ref={(ref) => (this.saveBtn = ref)}
                    onClick={this.saveMessage}
                    className="btn msg-btn"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="msg-full">
              {body}
              {edited && !deleted_at ? (
                <span className="editedMsg">
                  (Edited at {moment(updated_at).format('h:mm A')})
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        {this.state.editOptions ? (
          admin.active && permissions.includes('Delete messages') ? (
            <OutsideClickHandler
              onOutsideClick={() => {
                this.setState({ editOptions: false });
              }}
            >
              <div className="msg-action">
                <button
                  onClick={() => {
                    this.setState({ deletePopup: true, editOptions: false });
                  }}
                  className="btn btn-default btn-delete"
                >
                  <i className="fas fa-trash-alt"></i> Delete Message
                </button>
              </div>
            </OutsideClickHandler>
          ) : (
            <OutsideClickHandler
              onOutsideClick={() => {
                this.setState({ editOptions: false });
              }}
            >
              <div className="msg-action">
                <button
                  onClick={this.enableEditMode}
                  className="btn btn-default btn-delete"
                >
                  <i className="fas fa-edit"></i> Edit Message
                </button>
                {/* <button onClick={ this.deleteMessage } className="btn btn-default btn-delete"><i className="fas fa-trash-alt"></i> Delete Message</button> */}
              </div>
            </OutsideClickHandler>
          )
        ) : (
          <Fragment>
            {!this.state.editMode &&
            !this.props.isEditing &&
            profile &&
            sender.id == profile.data.id &&
            !deleted_at &&
            conversation.users.some(
              (_user) => _user.hash == profile.data.hash
            ) ? (
              <div className="msg-modify">
                <a
                  onClick={() => {
                    this.setState({ editOptions: true });
                  }}
                  id="msg-edit"
                >
                  <img src={`${config.assetUrl}icons/msg-modify.svg`} />
                </a>
              </div>
            ) : !deleted_at && admin.active ? (
              <div className="msg-modify">
                <a
                  onClick={() => {
                    this.setState({ editOptions: true });
                  }}
                  id="msg-edit"
                >
                  <img src={`${config.assetUrl}icons/msg-modify.svg`} />
                </a>
              </div>
            ) : (
              ''
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default TextMessage;
