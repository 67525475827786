import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { config } from '_helpers';
import { ListItem } from './ListItem';
import { docsActions } from '_actions/docs.actions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tab, Row, Col, NavItem, Nav } from 'react-bootstrap';
import { PaycheckDistribution } from 'Docs/Forms/PaycheckDistribution';
import { CandidateInfoPage } from 'Docs/Forms/CandidateInfoPage';
import { W4 } from 'Docs/Forms/W4';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_doc: 'asc',
      order_user: 'asc',
      adminMode: false,
      order_at: 'desc',
      onboarding_docs: [],
      other_docs: [],
      rehire_docs: [],
      type: '',
      view_doc: false,
      outerTab: 'onboarding',
      tab: 'list',
      uid: null,
      signature: null,
      doc: {
        new: null,
        id: null,
        slug: null, // single_doc
      },
    };
    // handler bindings
    this.handlePageChange = this.handlePageChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.generateDocList = this.generateDocList.bind(this);
    this.viewDoc = this.viewDoc.bind(this);
    this.viewList = this.viewList.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.tabChangeOuter = this.tabChangeOuter.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(docsActions.getDocCategory());
    this.props.dispatch(docsActions.getDocTypes());
    // @param type 'Onboarding = 1,Rehire = 2,Other = 3'
    this.props.dispatch(docsActions.getCategoryDocs(3));
    this.setState({ adminMode: this.props.adminMode });
    this.props.dispatch(docsActions.getFormOptions());
  }

  //   change list and view document
  tabChange(tab) {
    this.setState({ tab });
  }
  //   change onboarding and other docs
  tabChangeOuter(outerTab) {
    this.setState({ outerTab });
    switch (outerTab) {
      case 'onboarding':
        this.props.tabChangeOuter(1);
        break;
      case 'rehire':
        this.props.tabChangeOuter(2);
        break;
      case 'other':
        this.props.tabChangeOuter(3);
        break;
      default:
        this.props.tabChangeOuter(1);
    }
    // outerTab === "onboarding" ? this.props.tabChangeOuter(1) : this.props.tabChangeOuter(3);
  }

  // Handle sorting in table
  sortHandler(key, type, doc_category) {
    switch (type) {
      case 'docs_name':
        this.state.order_doc === 'asc'
          ? this.setState({ type: 'docs_name', order_doc: 'desc' }, () =>
              this.props.onChange(
                key,
                { sort: 'docs_name', order: 'desc' },
                doc_category
              )
            )
          : this.setState({ type: 'docs_name', order_doc: 'asc' }, () =>
              this.props.onChange(
                key,
                { sort: 'docs_name', order: 'asc' },
                doc_category
              )
            );
        break;
      case 'user_name':
        this.state.order_user === 'asc'
          ? this.setState({ type: 'user_name', order_user: 'desc' }, () =>
              this.props.onChange(
                key,
                { sort: 'user_name', order: 'desc' },
                doc_category
              )
            )
          : this.setState({ type: 'user_name', order_user: 'asc' }, () =>
              this.props.onChange(
                key,
                { sort: 'user_name', order: 'asc' },
                doc_category
              )
            );
        break;
      case 'updated_at':
        this.state.order_at === 'asc'
          ? this.setState({ type: 'updated_at', order_at: 'desc' }, () =>
              this.props.onChange(
                key,
                { sort: 'updated_at', order: 'desc' },
                doc_category
              )
            )
          : this.setState({ type: 'updated_at', order_at: 'asc' }, () =>
              this.props.onChange(
                key,
                { sort: 'updated_at', order: 'asc' },
                doc_category
              )
            );
        break;
      default:
        this.setState({ order: '', type: '' }, () =>
          this.props.onChange(
            key,
            { sort: 'docs_name', order: 'desc' },
            doc_category
          )
        );
    }
  }
  generateDocList(list, type, flag) {
    let filteredList = list.filter((doc) => doc.docs_categories_id == type);
    let docsList = filteredList.map((doc, index) => {
      return (
        <div className="applicant_item list_item" key={index}>
          <div className="homeEmp_list">
            <div className="basic_info unavailable_employe">
              <div className="item_cell">
                <img
                  className="doc-list-img"
                  src={`${config.assetUrl}icons/doc-check-circle.svg`}
                  alt=""
                />
                <span>{doc.doc_type_name}</span>
              </div>
              <div className="item_cell">
                <span>{moment(doc.updated_at).format('MM/DD/YY')}</span>
              </div>
              <div className="item_cell  doc_download">
                {flag ? (
                  <span>
                    <button
                      className="button plain"
                      onClick={() => this.viewDoc(doc)}
                    >
                      <img
                        src={`${config.assetUrl}icons/doc-admin-view.svg`}
                        alt=""
                      />
                    </button>
                  </span>
                ) : (
                  <span>
                    <Link to={`/dashboard/docs/${doc.slug}`}>
                      <img
                        src={`${config.assetUrl}icons/doc-admin-view.svg`}
                        alt=""
                      />
                    </Link>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return docsList;
  }
  //  view document
  viewDoc(doc) {
    this.props.dispatch(docsActions.getUserDocs(doc.hash)).then((res) => {
      this.setState({
        doc: { ...this.state.doc, id: doc.id, slug: doc.slug },
        view_doc: true,
        tab: 'document',
      });
    });
  }
  //  view list
  viewList() {
    this.setState({
      doc: { ...this.state.doc, id: null },
      view_doc: false,
      tab: 'list',
    });
  }

  // Handle pagination clicks
  handlePageChange(key, page, doc_category) {
    this.props.onPageChange(key, { page: page.selected + 1, doc_category });
  }

  componentWillReceiveProps(props) {
    this.setState({ uid: props.uid, signature: props.signature });
  }

  componentDidUpdate() {
    this.props.tabChangeInner(this.state.tab);
  }

  render() {
    let doc_list = this.props.docs.all.data;
    const { uid, signature } = this.props;

    return (
      <div className="doc-list-items">
        {/* filter was here, moved to filter component */}
        <Tab.Container
          id="tabs-with-dropdown"
          defaultActiveKey={this.state.outerTab}
        >
          <Row className="clearfix">
            {/* Tab Navigation */}
            <Col sm={12}>
              <div className="custom-tabs m-0">
                <Nav bsStyle="tabs">
                  <NavItem
                    eventKey="onboarding"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('onboarding')}
                  >
                    <i className="fa fa-file"></i>
                    Onboarding Documents
                  </NavItem>
                  <NavItem
                    eventKey="rehire"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('rehire')}
                  >
                    <i className="fa fa-file"></i>
                    Rehire Documents
                  </NavItem>
                  <NavItem
                    eventKey="other"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter('other')}
                  >
                    <i className="fa fa-file-alt"></i>
                    Other Documents
                  </NavItem>
                </Nav>
              </div>
            </Col>

            {/* Tab Content */}
            <Col sm={12}>
              <div className="custom-tab-content">
                <Tab.Content animation>
                  {/* Onboarding Documents */}
                  <Tab.Pane eventKey="onboarding">
                    <div className="doc_table-wrapper emp_view">
                      <div className="doc_table-items ">
                        {/* Table Sort */}
                        <div className="appln_list_header">
                          <div className="list_item_head first_item">
                            {/* <input type="checkbox" /> */}
                          </div>
                          <div
                            className="list_item_head"
                            onClick={() =>
                              this.sortHandler(
                                'onboarding_docs',
                                'docs_name',
                                1
                              )
                            }
                          >
                            Document
                            <span
                              className={`${
                                this.state.order_doc === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div
                            className="list_item_head"
                            onClick={() =>
                              this.sortHandler(
                                'onboarding_docs',
                                'user_name',
                                1
                              )
                            }
                          >
                            Employee
                            <span
                              className={`${
                                this.state.order_user === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div
                            className="list_item_head appln_date"
                            onClick={() =>
                              this.sortHandler(
                                'onboarding_docs',
                                'updated_at',
                                1
                              )
                            }
                          >
                            Last Updated
                            <span
                              className={`${
                                this.state.order_at === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div className="list_item_head"></div>
                        </div>

                        {/* List */}
                        {/* Loading */}
                        {!this.props.onboarding_docs.data && (
                          <div
                            key="noResult"
                            className="applicant_item light-box"
                          >
                            <div className="basic_info">
                              <p className="noResult">Loading</p>
                            </div>
                          </div>
                        )}
                        {/* List Items */}
                        {this.props.onboarding_docs.data &&
                          this.props.onboarding_docs.data.map((doc, index) => {
                            return (
                              <ListItem
                                {...this.props}
                                uid={this.state.uid}
                                signature={this.state.signature}
                                key={index}
                                url={this.props.match.url}
                                {...doc}
                                rehire={false}
                              />
                            );
                          })}
                        {/* No Result */}
                        {this.props.onboarding_docs.data &&
                          this.props.onboarding_docs.data.length === 0 && (
                            <div
                              key="noResult"
                              className="applicant_item light-box"
                            >
                              <div className="basic_info">
                                <p className="noResult">
                                  No search results were found
                                </p>
                              </div>
                            </div>
                          )}
                      </div>

                      {/* Pagination */}
                      {this.props.onboarding_docs.data && (
                        <div className="customised-pagination">
                          <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.props.onboarding_docs.last_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(page) =>
                              this.handlePageChange('onboarding_docs', page, 1)
                            }
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />
                        </div>
                      )}
                    </div>
                  </Tab.Pane>

                  {/* Rehire Documents */}
                  <Tab.Pane eventKey="rehire">
                    <div className="doc_table-wrapper emp_view">
                      <div className="doc_table-items ">
                        {/* Table Sort */}
                        <div className="appln_list_header">
                          <div className="list_item_head first_item">
                            {/* <input type="checkbox" /> */}
                          </div>
                          <div
                            className="list_item_head"
                            onClick={() =>
                              this.sortHandler('rehire_docs', 'docs_name', 2)
                            }
                          >
                            Document
                            <span
                              className={`${
                                this.state.order_doc === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div
                            className="list_item_head"
                            onClick={() =>
                              this.sortHandler('rehire_docs', 'user_name', 2)
                            }
                          >
                            Employee
                            <span
                              className={`${
                                this.state.order_user === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div
                            className="list_item_head appln_date"
                            onClick={() =>
                              this.sortHandler('rehire_docs', 'updated_at', 2)
                            }
                          >
                            Last Updated
                            <span
                              className={`${
                                this.state.order_at === 'asc' ? 'asc' : 'desc'
                              } sort-arrow`}
                            ></span>
                          </div>
                          <div className="list_item_head"></div>
                        </div>

                        {/* List */}
                        {/* Loading */}
                        {!this.props.rehire_docs.data && (
                          <div
                            key="noResult"
                            className="applicant_item light-box"
                          >
                            <div className="basic_info">
                              <p className="noResult">Loading</p>
                            </div>
                          </div>
                        )}
                        {/* List Items */}
                        {this.props.rehire_docs.data &&
                          this.props.rehire_docs.data.map((doc, index) => {
                            return (
                              <ListItem
                                {...this.props}
                                uid={this.state.uid}
                                signature={this.state.signature}
                                key={index}
                                url={this.props.match.url}
                                {...doc}
                                rehire={true}
                              />
                            );
                          })}
                        {/* No Result */}
                        {this.props.rehire_docs.data &&
                          this.props.rehire_docs.data.length === 0 && (
                            <div
                              key="noResult"
                              className="applicant_item light-box"
                            >
                              <div className="basic_info">
                                <p className="noResult">
                                  No search results were found
                                </p>
                              </div>
                            </div>
                          )}
                      </div>

                      {/* Pagination */}
                      {this.props.rehire_docs.data && (
                        <div className="customised-pagination">
                          <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.props.rehire_docs.last_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(page) =>
                              this.handlePageChange('rehire_docs', page, 2)
                            }
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />
                        </div>
                      )}
                    </div>
                  </Tab.Pane>

                  {/* Other Documents */}
                  <Tab.Pane eventKey="other">
                    <section className="content">
                      {/* LIST */}
                      {this.state.tab === 'list' && (
                        <div className="doc_table-wrapper emp_view">
                          <div className="doc_table-items ">
                            {/* Table Sort */}
                            <div className="appln_list_header">
                              <div className="list_item_head first_item">
                                {/* <input type="checkbox" /> */}
                              </div>
                              <div
                                className="list_item_head"
                                onClick={() =>
                                  this.sortHandler('other_docs', 'docs_name', 3)
                                }
                              >
                                Document
                                <span
                                  className={`${
                                    this.state.order_doc === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  } sort-arrow`}
                                ></span>
                              </div>
                              <div
                                className="list_item_head"
                                onClick={() =>
                                  this.sortHandler('other_docs', 'user_name', 3)
                                }
                              >
                                Employee
                                <span
                                  className={`${
                                    this.state.order_user === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  } sort-arrow`}
                                ></span>
                              </div>
                              <div
                                className="list_item_head appln_date"
                                onClick={() =>
                                  this.sortHandler(
                                    'other_docs',
                                    'updated_at',
                                    3
                                  )
                                }
                              >
                                Last Updated
                                <span
                                  className={`${
                                    this.state.order_at === 'asc'
                                      ? 'asc'
                                      : 'desc'
                                  } sort-arrow`}
                                ></span>
                              </div>
                              <div className="list_item_head"></div>
                            </div>

                            {/* List */}
                            {/* Loading */}
                            {!this.props.other_docs.data && (
                              <div
                                key="noResult"
                                className="applicant_item light-box"
                              >
                                <div className="basic_info">
                                  <p className="noResult">Loading</p>
                                </div>
                              </div>
                            )}
                            {/* List Items */}
                            {this.props.other_docs.data &&
                              this.props.other_docs.data.map((doc, index) => {
                                return (
                                  <ListItem
                                    uid={this.state.uid}
                                    signature={this.state.signature}
                                    key={index}
                                    url={this.props.match.url}
                                    {...doc}
                                    doc={doc}
                                    viewDoc={this.viewDoc}
                                    view={true}
                                  />
                                );
                              })}
                            {/* No Result */}
                            {this.props.other_docs.data &&
                              this.props.other_docs.data.length === 0 && (
                                <div
                                  key="noResult"
                                  className="applicant_item light-box"
                                >
                                  <div className="basic_info">
                                    <p className="noResult">
                                      No search results were found
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>

                          {/* Pagination */}
                          {this.props.other_docs.data && (
                            <div className="customised-pagination">
                              <ReactPaginate
                                previousLabel={''}
                                nextLabel={''}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.props.other_docs.last_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(page) =>
                                  this.handlePageChange('other_docs', page, 3)
                                }
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {/* DOCUMENT */}
                      {this.state.tab === 'document' && (
                        <section className="document">
                          <div className="container doc_info">
                            <p className="button-container doc_info-rh no-border">
                              <button
                                id="back-button"
                                className="button"
                                onClick={this.viewList}
                              >
                                Back
                              </button>
                            </p>
                          </div>
                          <div className="container doc_info full-width_form">
                            {this.state?.doc?.slug ===
                              'paycheck-distribution-form' && (
                              <PaycheckDistribution
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug ===
                              'candidate-information-page' && (
                              <CandidateInfoPage
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === 'w-4' && (
                              <W4
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                          </div>
                        </section>
                      )}
                    </section>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>

        {/*
                <!--doc table wrapper ends--> */}
      </div>
    );
  }
}

export { List };
