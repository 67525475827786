import { docsService } from '../_services/docs.service';
import { docsConstants, userConstants } from '../_constants';

export const docsActions = {
  generateDocs,
  getDocs,
  getUserDocs,
  getAllDocs,
  filterDocs,
  saveDoc,
  getDocTypes,
  getForm,
  getFormOptions,
  getDocPdf,
  getDocsStats,
  getDocCategory,
  getCategoryDocs,
};

function generateDocs(rehire = false) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.generateDocs(rehire).then(
        (res) => {
          dispatch(success(res));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.GENERATE_DOCS_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.GENERATE_DOCS_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.GENERATE_DOCS_FAILURE,
      error,
    };
  }
}

function getDocs() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getDocs().then(
        (res) => {
          if (res.success) {
            let payload = {
              userDocs: res.data,
            };
            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_LIST_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_LIST_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_LIST_FAILURE,
      error,
    };
  }
}

function getDocTypes() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getDocTypes().then(
        (res) => {
          let payload = {
            types: res,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_TYPE_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_TYPE_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_TYPE_FAILURE,
      error,
    };
  }
}

function getDocCategory() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getDocCategories().then(
        (res) => {
          let payload = {
            categories: res,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_TYPE_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_TYPE_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_TYPE_FAILURE,
      error,
    };
  }
}

function getCategoryDocs(type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getCategoryDocs(type).then(
        (res) => {
          let payload = {
            category_docs: res.data,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_TYPE_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_TYPE_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_TYPE_FAILURE,
      error,
    };
  }
}

function getAllDocs(params = {}) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getAllDocs(params).then(
        (res) => {
          if (res.success) {
            let payload = {
              all: res.data,
            };
            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_LIST_ALL_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_LIST_ALL_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_LIST_ALL_FAILURE,
      error,
    };
  }
}
// not using, replaced with getAllDocs (with params)
function filterDocs(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.filterDocs(data).then(
        (res) => {
          if (res.success) {
            let payload = {
              all: res.data,
            };
            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_LIST_FILTER_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_LIST_FILTER_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_LIST_FILTER_FAILURE,
      error,
    };
  }
}

function saveDoc(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.saveDoc(data).then(
        (res) => {
          if (res.success) {
            let { userDocs } = getState().docs;
            userDocs = userDocs.map((doc) => {
              if (doc.id == data.id) {
                return {
                  ...doc,
                  status: 2,
                };
              }
              return doc;
            });

            let payload = {
              userDocs,
            };

            if (res.data && res.data.docs_status) {
              dispatch({
                type: userConstants.UPDATE_DOCS_STATUS,
                payload: {
                  ...res.data,
                },
              });
            }

            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_SAVE_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_SAVE_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_SAVE_FAILURE,
      error,
    };
  }
}

// getting user docs based on hash
function getUserDocs(hash) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getUserDocs(hash).then(
        (res) => {
          if (res.success) {
            let payload = {
              userDocs: res.data,
            };
            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_LIST_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_LIST_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_LIST_FAILURE,
      error,
    };
  }
}

// getting form based on id
function getForm(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getForm(id).then(
        (res) => {
          if (res.success) {
            let payload = {
              activeForm: res.data,
            };
            dispatch(success(payload));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_FORM_GET_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_FORM_GET_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_FORM_GET_FAILURE,
      error,
    };
  }
}

function getFormOptions(isRehire) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getFormOptions(isRehire).then(
        (res) => {
          let {
            ShirtSize,
            PantSize,
            ListInseamPantSize,
            Classification,
            Race,
            AdminStaff,
            NVTS,
            AboutTrees,
          } = res;
          let payload = {
            shirtSize: ShirtSize,
            pantSize: PantSize,
            inseamPantSize: ListInseamPantSize,
            classifications: Classification,
            crewsList: [...AdminStaff, ...NVTS, ...AboutTrees],
            race: Race,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.FORM_OPTIONS_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: docsConstants.FORM_OPTIONS_SUCCESS,
      payload,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.FORM_OPTIONS_FAILURE,
      error,
    };
  }
}

// get pdf as downloadable
function getDocPdf(data, slug) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getDocPdf(data, slug).then(
        (res) => {
          let payload = {
            pdf: res,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_PDF_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_PDF_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_PDF_FAILURE,
      error,
    };
  }
}

// get docs statistics

function getDocsStats() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      docsService.getDocsStats().then(
        (res) => {
          let payload = {
            ...res.data,
          };
          dispatch(success(payload));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });

  function request() {
    return {
      type: docsConstants.DOCS_STATS_REQUEST,
    };
  }

  function success(res) {
    return {
      type: docsConstants.DOCS_STATS_SUCCESS,
      payload: res,
    };
  }

  function failure(error) {
    return {
      type: docsConstants.DOCS_STATS_FAILURE,
      error,
    };
  }
}
