import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store, config } from './_helpers';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: config.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);
