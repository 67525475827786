import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'react-modal';

import styles from './signaturePad.module.css';

const CustomSignaturePad = ({ onSave, disabled, error, value }) => {
  const signCanvasRef = useRef({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');

  React.useEffect(() => {
    if (value !== selectedImage) {
      setSelectedImage(value);
    }
  }, [value]);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    if (selectedImage && onSave) {
      onSave(selectedImage);
    }
  }, [selectedImage]);

  const handleOnSubmit = () => {
    setSelectedImage(
      signCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
    );
    closeModal();
  };

  const clearCanvas = () => {
    signCanvasRef.current.clear();
  };

  return (
    <React.Fragment>
      <div
        className={`${styles.signatureWrap} ${error ? styles.signatureWrapError : ''}`}
        onClick={() => (disabled ? {} : openModal())}
      >
        {/* Signature View */}
        <img src={selectedImage} />
        {!selectedImage && <p className={styles.label}>Sign here</p>}
      </div>
      {!disabled && selectedImage && (
        <div style={{ marginBottom: 16 }}>
          <button
            type="button"
            className="primary"
            onClick={() => {
              setSelectedImage('');
              onSave('');
            }}
          >
            Clear
          </button>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="SignatureModal"
        contentLabel="Example Modal"
      >
        <section>
          <div className="modal-header">
            <p className="sign_close">Draw your signature.</p>
            <div className="close-btn" onClick={() => closeModal()}>
              <span>&times;</span>
            </div>
          </div>
          <div style={{ border: '1px solid #ccc' }}>
            <SignatureCanvas
              style={{ border: 'none' }}
              ref={signCanvasRef}
              canvasProps={{ width: 760, height: 215 }}
            />
          </div>
          <p className="sign_buttons">
            <button
              type="button"
              className="primary"
              onClick={() => handleOnSubmit()}
            >
              Confirm
            </button>
            <button
              type="button"
              className={`primary`}
              onClick={() => clearCanvas()}
            >
              Clear
            </button>
          </p>
        </section>
      </Modal>
    </React.Fragment>
  );
};

export default CustomSignaturePad;
