export const resourceConstants = {
  GETALL_REQUEST: 'RESOURCES_GETALL_REQUEST',
  GETALL_SUCCESS: 'RESOURCES_GETALL_SUCCESS',
  GETALL_FAILURE: 'RESOURCES_GETALL_FAILURE',

  GETSINGLERESOURCE_REQUEST: 'RESOURCES_GETALL_REQUEST',
  GETSINGLERESOURCE_SUCCESS: 'RESOURCES_GETALL_SUCCESS',
  GETSINGLERESOURCE_FAILURE: 'RESOURCES_GETALL_FAILURE',

  GET_RESOURCE_TYPE_REQUEST: 'RESOURCE_TYPES_GET_REQUEST',
  GET_RESOURCE_TYPE_SUCCESS: 'RESOURCE_TYPES_GET_SUCCESS',
  GET_RESOURCE_TYPE_FAILURE: 'RESOURCE_TYPES_GET_FAILURE',

  GETDRIVER_REQUEST: 'RESOURCES_GETDRIVER_REQUEST',
  GETDRIVER_SUCCESS: 'RESOURCES_GETDRIVER_SUCCESS',
  GETDRIVER_FAILURE: 'RESOURCES_GETDRIVER_FAILURE',

  DELETE_REQUEST: 'VEHICLE_DELETE_REQUEST',
  DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'VEHICLE_DELETE_FAILURE',

  GETVEHICLES_REQUEST: 'VEHICLES_GETALL_REQUEST',
  GETVEHICLES_SUCCESS: 'VEHICLES_GETALL_SUCCESS',
  GETVEHICLES_FAILURE: 'VEHICLES_GETALL_FAILURE',

  GETVEHICLE_REQUEST: 'VEHICLE_GET_REQUEST',
  GETVEHICLE_SUCCESS: 'VEHICLE_GET_SUCCESS',
  GETVEHICLE_FAILURE: 'VEHICLE_GET_FAILURE',

  GETEMPLOYEES_REQUEST: 'EMPLOYEES_GETALL_REQUEST',
  GETEMPLOYEES_SUCCESS: 'EMPLOYEES_GETALL_SUCCESS',
  GETEMPLOYEES_FAILURE: 'EMPLOYEES_GETALL_FAILURE',

  GETARCHIVEDRESOURCES_REQUEST: 'GET_ARCHIVED_RESOURCES_REQUEST',
  GETARCHIVEDRESOURCES_SUCCESS: 'GET_ARCHIVED_RESOURCES_SUCCESS',
  GETARCHIVEDRESOURCES_FAILURE: 'GET_ARCHIVED_RESOURCES_FAILURE',

  EMPVER_REQUEST: 'EMPVER_REQUEST',
  EMPVER_SUCCESS: 'EMPVER_SUCCESS',
  EMPVER_FAILURE: 'EMPVER_FAILURE',

  SETPOS_REQUEST: 'SETPOS_REQUEST',
  SETPOS_SUCCESS: 'SETPOS_SUCCESS',
  SETPOS_FAILURE: 'SETPOS_FAILURE',

  SEARCHUNIVERSE_REQUEST: 'SEARCHUNIVERSE_REQUEST',
  SEARCHUNIVERSE_SUCCESS: 'SEARCHUNIVERSE_SUCCESS',
  SEARCHUNIVERSE_FAILURE: 'SEARCHUNIVERSE_FAILURE',

  GETEMAILEMPLOYEE_REQUEST: 'SEARCHEMPLOYEE_REQUEST',
  GETEMAILEMPLOYEE_SUCCESS: 'SEARCHEMPLOYEE_SUCCESS',
  GETEMAILEMPLOYEE_FAILURE: 'SEARCHEMPLOYEE_FAILURE',

  UNARCHIVE_REQUEST: 'UNARCHIVE_REQUEST',
  UNARCHIVE_SUCCESS: 'UNARCHIVE_SUCCESS',
  UNARCHIVE_FAILURE: 'UNARCHIVE_FAILURE',

  UNARCHIVE_RESOURCE_REQUEST: 'UNARCHIVE_RESOURCE_REQUEST',
  UNARCHIVE_RESOURCE_SUCCESS: 'UNARCHIVE_RESOURCE_SUCCESS',
  UNARCHIVE_RESOURCE_FAILURE: 'UNARCHIVE_RESOURCE_FAILURE',

  REMOVERES_REQUEST: 'REMOVERES_REQUEST',
  REMOVERES_SUCCESS: 'REMOVERES_SUCCESS',
  REMOVERES_FAILURE: 'REMOVERES_FAILURE',

  ASSIGNSTAFF_REQUEST: 'ASSIGNSTAFF_REQUEST',
  ASSIGNSTAFF_SUCCESS: 'ASSIGNSTAFF_SUCCESS',
  ASSIGNSTAFF_FAILURE: 'ASSIGNSTAFF_FAILURE',

  GETPROJECTS_REQUEST: 'PROJECTS_GETALL_REQUEST',
  GETPROJECTS_SUCCESS: 'PROJECTS_GETALL_SUCCESS',
  GETPROJECTS_FAILURE: 'PROJECTS_GETALL_FAILURE',

  DELETE_RESOURCE_IMAGE_REQUEST: 'DELETE_RESOURCE_IMAGE_REQUEST',
  DELETE_RESOURCE_IMAGE_SUCCESS: 'DELETE_RESOURCE_IMAGE_SUCCESS',
  DELETE_RESOURCE_IMAGE_FAILURE: 'DELETE_RESOURCE_IMAGE_FAILURE',

  GET_EMPLOYMENT_HISTORY_REQUEST: 'GET_EMPLOYMENT_HISTORY_REQUEST',
  GET_EMPLOYMENT_HISTORY_SUCCESS: 'GET_EMPLOYMENT_HISTORY_SUCCESS',
  GET_EMPLOYMENT_HISTORY_FAILURE: 'GET_EMPLOYMENT_HISTORY_FAILURE',
};
