import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { reportsActions } from '_actions';
import { reportsService } from '../_services';
import VehicleReportList from './VehicleReportList';
import { exportToCSV } from '_utils/export';
import { getVehicleReport as getVehicleReportStore } from '_helpers/reduxSelector';

import '../assets/styles/report.css';

const FILE_NAME = 'Vehicle Report';

const VEHICLE_REPORT_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Unassigned', value: 'Unassigned' },
];

const VehicleReportListContainer = (props) => {
  const dispatch = useDispatch();
  const [currentFilters, setCurrentFilters] = React.useState(
    props?.location?.state?.currentFilters || VEHICLE_REPORT_OPTIONS[0]
  );

  const vehicleReport = useSelector((state) => getVehicleReportStore(state));

  const [globalSort, setGlobalSort] = React.useState('division');
  const [globalOrder, setGlobalOrder] = React.useState('asc');

  React.useEffect(() => {
    if (currentFilters?.value) {
      getVehicleReport();
    }
  }, [currentFilters]);

  const getVehicleReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append('option', currentFilters?.value);
    data.append('page', newPage || 0);
    data.append('sort', sort || 'division');
    data.append('order', order || 'asc');
    dispatch(reportsActions.getVehicleReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? 'asc' : 'desc';
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const handleOnChange = (newSelectedOptions) => {
    setCurrentFilters(newSelectedOptions);
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append('option', currentFilters?.value);
    data.append('sort', globalSort || 'division');
    data.append('order', globalOrder || 'asc');
    reportsService.generateEmployeeVehicleReport(data);
  };

  const exportToExcel = (res) => {
    if (res.success) {
      exportToCSV(res.data, FILE_NAME);
    }
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement('a');
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = '_blank';
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  return (
    <div>
      <div className={props.isReportMenu ? '' : 'dashboard_container'}>
        <div className="white report_table_container">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require('../assets/images/back.svg').default} />{' '}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {vehicleReport?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head_vehicle">
                <h5 className="graph_header">Vehicle Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? '10px' : '0px'}` }}
                  className="report_head_btn_grp"
                >
                  {/* <div className="report_multi_btn_grp">
                    <div className="inp_sub_container">
                    <ReactSelect value={currentFilters} onChange={handleOnChange} options={VEHICLE_REPORT_OPTIONS} />
                    </div>
                  </div> */}
                  {props.isReportMenu && vehicleReport?.data?.length ? (
                    <button
                      className="print_button"
                      onClick={(event) => {
                        onPrintClick();
                      }}
                    >
                      {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                      Export
                    </button>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <VehicleReportList
                currentFilters={currentFilters}
                data={vehicleReport?.data ?? []}
                lastPage={vehicleReport?.last_page}
                getVehicleReport={getVehicleReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleReportListContainer;
