export const getCompanyName = (companyId) => {
  let id = Number(companyId);
  let companyName = '';
  switch (id) {
    case 1:
      companyName = 'About Trees';
      break;
    case 2:
      companyName = 'North Valley Tree Service';
      break;
    default:
      companyName = 'NVAM';
  }
  return companyName;
};

export const unAvailableReason = (reason, other) => {
  let unavailableReason = 'No Reason';
  switch (reason) {
    case 0:
      unavailableReason = '';
      break;
    case 1:
      unavailableReason = 'Time off';
      break;
    case 2:
      unavailableReason = 'Sick';
      break;
    case 3:
      unavailableReason = 'Injury';
      break;
    case 4:
      unavailableReason = other;
      break;
    default:
      unavailableReason = '';
  }
  return unavailableReason;
};

export const getApplicantStatus = {
  0: 'Not Selected & Archive',
  1: 'Received',
  2: 'Was Hired',
  3: 'In Review',
  4: 'Offered Position',
  10: 'Do Not Hire & Archive',
};

export const reportList = [
  { title: 'Vehicle Report', key: 'vehicle', permission: 'Vehicle Report' },
  {
    title: 'All Inventory Report',
    key: 'inventory',
    permission: 'All Inventory Reports',
  },
  {
    title: 'Expiration Date Report',
    key: 'expiration',
    permission: 'All Inventory Reports',
  },
  {
    title: 'Equipment Sold Report',
    key: 'equipment',
    permission: 'Equipment Sold Report',
  },
  {
    title: 'Doc Status List',
    key: 'docs',
    permission: 'Doc Status List Report',
  },
  {
    title: 'Employee Birthdays',
    key: 'birthday',
    permission: 'Employee Birthdays',
  },
  {
    title: 'Mileage/Hobbs List',
    key: 'mileage-hobbs',
    permission: 'Mileage Report',
  },
  {
    title: "Applicant's Report",
    key: 'applicants',
    permission: 'Manage Resources',
  },
];
