import React from 'react';
import { connect } from 'react-redux';

class Message extends React.Component {
  render() {
    return (
      <div className="applnSubmitStatus">
        <div>
          <h1>{this.props.type}</h1>
          <p>{this.props.msg}</p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { application } = state.application;
  return {
    application,
  };
}

const connectedApplicationForm = connect(mapStateToProps)(Message);
export { connectedApplicationForm as Message };
