import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
//import { docsService } from "_services/docs.service";
import { toast } from 'react-toastify';
import moment from 'moment';
import { SignPad } from 'Docs/Components';
import { FormNav } from './FormNav';
import { validate } from './formHandlers';
class W4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isPrivacyOpen: false,

      page: 1,
      pages: [1, 2, 3],
      form: {
        signed_date: moment().format('MM/DD/YYYY'),
        employment_first_date: moment().format('MM/DD/YYYY'),
        disclaimer_check: false,
        employee_signature: null,
      },
      user: {
        mailing_address: {},
        emgcontact1: {},
        emgcontact2: {},
        gender: 'Male',
      },
      errors: {},
      form_edited: false,
    };
    // this.switchForm = this.switchForm.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = this.navigate.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.skipHandler = this.skipHandler.bind(this);
    this.validate = validate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleList(list) {
    switch (list) {
      case 'privacy':
        this.state.isPrivacyOpen
          ? this.setState({ isPrivacyOpen: false })
          : this.setState({ isPrivacyOpen: true });
        break;
      default:
        this.setState({
          isPrivacyOpen: false,
        });
        break;
    }
  }

  navigatePage(to) {
    let { page } = this.state;

    switch (to) {
      case 'next':
        this.setState({ page: page + 1 });
        return;
      case 'prev':
        this.setState({ page: page - 1 });
        return;
      default:
        return;
    }
  }

  navigate(to, rehire = this.props.rehire) {
    //common navigation function for all docs in list
    let { doc, hash } = this.props.match.params;
    let userDocs;
    let { page, pages } = this.state;

    if (rehire) {
      userDocs = this.props.docs.userDocs.filter(
        (doc) => doc.docs_categories_id === 2
      ); // Rehire
    } else {
      userDocs = this.props.docs.userDocs.filter(
        (doc) => doc.docs_categories_id === 1
      ); // Onboarding
    }

    let currentDocIndex = userDocs.findIndex((userDoc) => userDoc.slug == doc);
    let path = this.props.match.path;

    if (hash) {
      path = path.replace(':hash', hash);
    }

    let nextDoc = {};
    let prevDoc = {};

    if (currentDocIndex >= 0 && currentDocIndex <= userDocs.length - 1) {
      prevDoc = userDocs[currentDocIndex - 1];
      nextDoc = userDocs[currentDocIndex + 1];
    }
    switch (to) {
      case 'next':
        if (nextDoc && nextDoc.id) {
          path = path.replace(':doc', nextDoc.slug);
          this.props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      case 'prev':
        if (pages && page > 1) {
          this.navigatePage('prev');
          break;
        }
        if (prevDoc && prevDoc.id) {
          path = path.replace(':doc', prevDoc.slug);
          this.props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      default:
        this.props.history.push(to);
        break;
    }
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type === 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: '',
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
  }

  skipHandler() {
    let { form, doc, user, page, pages } = this.state;

    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form,
      user,
      skippedPages: [3],
    };

    let { adminMode } = this.props;

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    if (pages && page < pages.length) {
      this.navigatePage('next');
      return;
    }

    if (!this.props.lastPage) {
      this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
        if (res.success) {
          if (this.props.mobileMode) {
            this.props.setScreen('sidebar');
            this.navigate('next', this.props.rehire);
          } else {
            this.navigate('next', this.props.rehire);
          }
        } else {
          toast.error(res.message);
        }
      });
    } else {
      this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
        res.success
          ? adminMode
            ? this.props.history.push(`/docs`)
            : this.props.history.push(`/dashboard`)
          : toast.error(res.message);
      });
    }
  }

  submitForm(e) {
    let { form, doc, user, page, pages } = this.state;
    e.preventDefault();
    let err = this.validate();

    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form,
      user,
    };
    let { adminMode } = this.props;

    let { hash } = this.props.match.params;

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }

    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    if (err) {
      return;
    }

    if (pages && page < pages.length) {
      this.navigatePage('next');
      return;
    }

    // if (!this.props.lastPage) {
    //   this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
    //     if (res.success) {
    //       this.navigate("next", this.props.rehire);
    //       return;
    //     } else {
    //       toast.error(res.message);
    //     }
    //   });
    // }

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.employment_first_date =
          form.employment_first_date || currentState.form.employment_first_date;
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug
      ? this.props.slug
      : this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
    //   );
    // }

    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
        );
      }
    }

    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    if (doc?.id) {
      this.getFormData(doc.id);
    }
  }

  render() {
    let { user, form, errors, page } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh form-w4">
        {/* <div className='doc-title'>
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className='doc-title-img'
            alt=''
            alt=''
          />
          <h3>
            Form W-4 (2021) &nbsp;&nbsp;
            {this.state.page == 3 && <span className='opt_text'>OPTIONAL</span>}
          </h3>

          <div className='doc-page-count'>
            <span className='current_page'>{this.state.page}</span>
            <span>of {this.state.pages.length}</span>
          </div>
        </div> */}
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>
            Form W-4 (2023) &nbsp;&nbsp;
            {this.state.page == 3 && <span className="opt_text">OPTIONAL</span>}
          </h3>

          <div
            className={
              this.props.single_doc
                ? 'doc-page-count left_non_auto'
                : 'doc-page-count'
            }
          >
            <span className="current_page">{this.state.page}</span>
            <span>of {this.state.pages.length}</span>
          </div>
        </div>

        <div className="custom-pagination-nav">
          <div className="pagination-wrapper">
            {this.state.page > 1 && (
              <a
                href="#"
                className="previous"
                onClick={() => {
                  this.navigatePage('prev');
                }}
              >
                <svg
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.41406 10.6094L5.88281 10.1641C5.97656 10.0469 5.97656 9.85938 5.88281 9.76562L1.64062 5.5L5.88281 1.25781C5.97656 1.16406 5.97656 0.976562 5.88281 0.859375L5.41406 0.414062C5.29688 0.296875 5.13281 0.296875 5.01562 0.414062L0.09375 5.3125C0 5.42969 0 5.59375 0.09375 5.71094L5.01562 10.6094C5.13281 10.7266 5.29688 10.7266 5.41406 10.6094Z"
                    fill="black"
                    fill-opacity="0.87"
                  />
                </svg>
              </a>
            )}

            <p>
              Page {this.state.page} <span>of {this.state.pages.length}</span>
            </p>
            {this.state.page < this.state.pages.length && (
              <a
                href="#"
                className="next"
                onClick={() => {
                  this.navigatePage('next');
                }}
              >
                <svg
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5625 0.414062L0.09375 0.859375C0 0.976562 0 1.16406 0.09375 1.25781L4.33594 5.5L0.09375 9.76562C0 9.85938 0 10.0469 0.09375 10.1641L0.5625 10.6094C0.679688 10.7266 0.84375 10.7266 0.960938 10.6094L5.88281 5.71094C5.97656 5.59375 5.97656 5.42969 5.88281 5.3125L0.960938 0.414062C0.84375 0.296875 0.679688 0.296875 0.5625 0.414062Z"
                    fill="black"
                    fill-opacity="0.8"
                  />
                </svg>
              </a>
            )}
          </div>
        </div>
        <div className="doc_info-form">
          {(() => {
            switch (page) {
              case 1:
                return (
                  <form
                    className="info-form"
                    ref={this.form}
                    onChange={this.handleFormChange}
                    onSubmit={this.submitForm}
                    noValidate
                  >
                    <div className="row form-row no_border mb-0">
                      <div className="col">
                        <div className="inputs-col">
                          <div className="inputs-head font-mob">
                            Step 1: Enter Personal Information
                          </div>
                          <div className="inputs-head">
                            Full Name
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items group">
                            {/*input group */}
                            <div className="grouped">
                              <input
                                className={`${
                                  errors['user.first_name'] ? 'error' : ''
                                }`}
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={user.first_name}
                                field="user.first_name"
                                placeholder="First Name"
                                required
                              />
                              {errors['user.first_name'] && (
                                <label
                                  htmlFor="first_name"
                                  className="error_label"
                                >
                                  {errors['user.first_name']}
                                </label>
                              )}
                            </div>
                            <div className="grouped">
                              <input
                                type="text"
                                className={`${
                                  errors['user.middle_name'] ? 'error' : ''
                                }`}
                                name="middle_name"
                                id="middle_name"
                                value={user.middle_name}
                                field="user.middle_name"
                                placeholder="Middle Name"
                              />
                              {errors['user.middle_name'] && (
                                <label
                                  htmlFor="middle_name"
                                  className="error_label"
                                >
                                  {errors['user.middle_name']}
                                </label>
                              )}
                            </div>
                            <div className="grouped">
                              <input
                                type="text"
                                className={`${
                                  errors['user.last_name'] ? 'error' : ''
                                }`}
                                name="last_name"
                                id="last_name"
                                value={user.last_name}
                                field="user.last_name"
                                placeholder="Last Name"
                                required
                              />
                              {errors['user.last_name'] && (
                                <label
                                  htmlFor="last_name"
                                  className="error_label"
                                >
                                  {errors['user.last_name']}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col m-0">
                        <div className="inputs-col">
                          <div className="inputs-head">
                            Home Address
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items">
                            <input
                              type="text"
                              placeholder="Street or Box Number"
                              className={`${
                                errors['user.mailing_address.address']
                                  ? 'error'
                                  : ''
                              }`}
                              name="mailing_address.address"
                              id="mailing_address.address"
                              value={user.mailing_address.address}
                              field="user.mailing_address.address"
                              required
                            />
                            {errors['user.mailing_address.address'] && (
                              <label
                                htmlFor="mailing_address.address"
                                className="error_label"
                              >
                                {errors['user.mailing_address.address']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="inputs-col">
                          <div className="inputs-items group">
                            <div className="grouped">
                              <input
                                type="text"
                                placeholder="City"
                                className={`${
                                  errors['user.mailing_address.city']
                                    ? 'error'
                                    : ''
                                }`}
                                name="city"
                                id="city"
                                value={user.mailing_address.city}
                                field="user.mailing_address.city"
                                required
                              />
                              {errors['user.mailing_address.city'] && (
                                <label htmlFor="city" className="error_label">
                                  {errors['user.mailing_address.city']}
                                </label>
                              )}
                            </div>
                            <div className="grouped">
                              <input
                                type="text"
                                placeholder="State"
                                className={`${
                                  errors['user.mailing_address.state']
                                    ? 'error'
                                    : ''
                                }`}
                                name="state"
                                id="state"
                                value={user.mailing_address.state}
                                field="user.mailing_address.state"
                                required
                              />
                              {errors['user.mailing_address.state'] && (
                                <label htmlFor="state" className="error_label">
                                  {errors['user.mailing_address.state']}
                                </label>
                              )}
                            </div>
                            <div className="grouped">
                              <input
                                type="text"
                                placeholder="Zip"
                                className={`${
                                  errors['user.mailing_address.zip']
                                    ? 'error'
                                    : ''
                                }`}
                                name="zip"
                                id="zip"
                                value={user.mailing_address.zip}
                                field="user.mailing_address.zip"
                                required
                              />
                              {errors['user.mailing_address.zip'] && (
                                <label htmlFor="zip" className="error_label">
                                  {errors['user.mailing_address.zip']}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col mb-0">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            Your Social Security Number
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items">
                            <input
                              type="text"
                              name="ssn"
                              id="ssn"
                              placeholder="Social Security Number"
                              className={`${errors['form.ssn'] ? 'error' : ''}`}
                              value={form.ssn}
                              field="form.ssn"
                              required
                            />
                            {errors['form.ssn'] && (
                              <label htmlFor="ssn" className="error_label">
                                {errors['form.ssn']}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="inputs-col">
                          <div className="inputs-head">
                            Marriage Status
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items">
                            <select
                              className={`${
                                errors['form.marital_status'] ? 'error' : ''
                              }`}
                              name="marital_status"
                              id="marital_status"
                              value={form.marital_status}
                              field="form.marital_status"
                              required
                            >
                              <option value="">- Select -</option>
                              <option value="single_or_married_filing_separately">
                                Single or Married filing separately
                              </option>
                              <option value="married_filing_jointly_or_qualifying_widow">
                                Married filing jointly (or Qualifying widow(er))
                              </option>
                              <option value="head_of_household">
                                Head of household
                              </option>
                            </select>
                            {errors['form.marital_status'] && (
                              <label
                                htmlFor="marital_status"
                                className="error_label"
                              >
                                {errors['form.marital_status']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no-padding">
                      <div className="col">
                        <div className="disclaimers dis-notes txt-black">
                          <strong>
                            Does your name match the name on your social
                            security card?
                          </strong>{' '}
                          If not, to ensure you get credit for your earnings,
                          contact SSA at 800-772-1213 or go to www.ssa.gov.
                        </div>
                      </div>
                    </div>
                    <div className="row form-row f-row">
                      <div className="col">
                        <div className="disclaimers dis-notes txt-black">
                          <strong>
                            Complete Steps 2–4 ONLY if they apply to you;
                            otherwise, skip to Step 5.
                          </strong>{' '}
                          See page 2 for more information on each step, who can
                          claim exemption from withholding, when to use the
                          online estimator at{' '}
                          <a href="http://www.irs.gov/W4App" target="_blank">
                            www.irs.gov/W4App
                          </a>{' '}
                          and privacy.
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no_padding">
                      <div className="col col-md-12">
                        <div className="inputs-col">
                          <div className="inputs-head  font-mob">
                            Step 2: Multiple Jobs or Spouse Works
                          </div>
                          <p>
                            Complete this step if you (1) hold more than one job
                            at a time, or (2) are married filing jointly and
                            your spouse also works. The correct amount of
                            withholding depends on income earned from all of
                            these jobs.
                          </p>
                          <ol type="a">
                            <li>
                              Use the estimator at www.irs.gov/W4App for most
                              accurate withholding for this step (and Steps 3–4)
                            </li>
                            <li>
                              Use the Multiple Jobs Worksheet on page 3 and
                              enter the result in Step 4(c) below for roughly
                              accurate withholding
                            </li>
                            <li>
                              <div className="col li-parentcol">
                                <div className="col li-col">
                                  <div className="inputs-col">
                                    <div className="check_radio_group agreement_checks">
                                      <div className="check_radio_item">
                                        <input
                                          type="checkbox"
                                          name="multiple_paychecks"
                                          id="multiple_paychecks"
                                          field="form.multiple_paychecks"
                                          checked={form.multiple_paychecks}
                                        />
                                        <span className="checkbox_checkbox"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  If there are only two jobs total, you may
                                  check this box. Do the same on Form W-4 for
                                  the other job. This option is generally more
                                  accurate than (b) if pay at the lower paying
                                  job is more than half of the pay at the higher
                                  paying job. Otherwise, (b) is more accurate
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no_padding">
                      <div className="col">
                        <div className="disclaimers dis-notes txt-black">
                          <strong>TIP </strong>
                          If you have self-employment income, see page 2.
                        </div>
                      </div>
                    </div>
                    <div className="row form-row f-row">
                      <div className="col">
                        <div className="disclaimers dis-notes txt-black">
                          <strong>
                            Complete Steps 3–4(b) on Form W-4 for only ONE of
                            these jobs.
                          </strong>
                          Leave those steps blank for the other jobs. (Your
                          withholding will be most accurate if you complete
                          Steps 3–4(b) on the Form W-4 for the highest paying
                          job.)
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no_padding no_margin">
                      <div className="col col-md-12">
                        <div className="inputs-col">
                          <div className="inputs-head  font-mob">
                            Step 3: Claim Dependents
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <p className="mb-0">
                            If your total income will be $200,000 or less
                            ($400,000 or less if married filing jointly):
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border no_padding">
                      <div className="col mb-1">
                        <div className="inputs-col mbp-1">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul className="none">
                              <li>
                                Multiply the number of qualifying children under
                                age 17 by $2,000
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items">
                            <input
                              type="number"
                              placeholder="- Enter here -"
                              className={`${
                                errors['form.dependent_children'] ? 'error' : ''
                              } price_input`}
                              name="dependent_children"
                              id="dependent_children"
                              value={form.dependent_children}
                              field="form.dependent_children"
                              required
                            />
                            {errors['form.dependent_children'] && (
                              <label
                                htmlFor="dependent_children"
                                className="error_label"
                              >
                                {errors['form.dependent_children']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border float_border no-padding pb-0">
                      <div className="col">
                        <div className="inputs-col mbp-0">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul className="none">
                              <li>
                                Multiply the number of other dependents by $500
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items">
                            <input
                              type="number"
                              placeholder="- Enter here -"
                              className={`${
                                errors['form.other_dependent'] ? 'error' : ''
                              } price_input`}
                              name="other_dependent"
                              id="other_dependent"
                              value={form.other_dependent}
                              field="form.other_dependent"
                              required
                            />
                            {errors['form.other_dependent'] && (
                              <label
                                htmlFor="other_dependent"
                                className="error_label"
                              >
                                {errors['form.other_dependent']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border pb-0">
                      <div className="col ">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul className="none">
                              <li>
                                Add the amounts above and enter the total here
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">3</div>

                            <input
                              type="number"
                              placeholder="- Enter here -"
                              className={`${
                                errors['form.total_dependent_amount']
                                  ? 'error'
                                  : ''
                              } price_input step_count`}
                              name="total_dependent_amount"
                              id="total_dependent_amount"
                              value={form.total_dependent_amount}
                              field="form.total_dependent_amount"
                              required
                            />
                            {errors['form.total_dependent_amount'] && (
                              <label
                                htmlFor="total_dependent_amount"
                                className="error_label"
                              >
                                {errors['form.total_dependent_amount']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col col-md-12 mb-0">
                        <div className="inputs-col mbp-1">
                          <div className="inputs-head  font-mob mb-0">
                            Step 4(optional): Other Adjustments
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* LIST */}
                    <div className="row">
                      <div className="col col-md-12 mb-0">
                        <ol type="a" className="none">
                          <li>
                            <div className="inputs-col mbp-0">
                              {/*single input */}
                              <div className="inputs-head">
                                <ul className="none">
                                  <li>
                                    <strong>
                                      Other income (not from jobs).
                                    </strong>{' '}
                                    If you want tax withheld for other income
                                    you expect this year that won’t have
                                    withholding, enter the amount of other
                                    income here. This may include interest,
                                    dividends, and retirement income
                                  </li>
                                </ul>
                              </div>
                              <div className="inputs-items steps_input">
                                <div className="stepcounter">4(a)</div>
                                <input
                                  type="number"
                                  placeholder="- Enter here -"
                                  className={`${
                                    errors['form.other_income'] ? 'error' : ''
                                  } price_input step_count`}
                                  name="other_income"
                                  id="other_income"
                                  value={form.other_income}
                                  field="form.other_income"
                                />
                                {errors['form.other_income'] && (
                                  <label
                                    htmlFor="other_income"
                                    className="error_label"
                                  >
                                    {errors['form.other_income']}
                                  </label>
                                )}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inputs-col mbp-0">
                              {/*single input */}
                              <div className="inputs-head">
                                <ul className="none">
                                  <li>
                                    <strong>Deductions.</strong> If you expect
                                    to claim deductions other than the standard
                                    deduction and want to reduce your
                                    withholding, use the Deductions Worksheet on
                                    page 3 and enter the result here
                                  </li>
                                </ul>
                              </div>
                              <div className="inputs-items steps_input">
                                <div className="stepcounter">4(b)</div>
                                <input
                                  type="number"
                                  placeholder="- Enter here -"
                                  className={`${
                                    errors['form.deductions'] ? 'error' : ''
                                  } price_input step_count`}
                                  name="deductions"
                                  id="deductions"
                                  value={form.deductions}
                                  field="form.deductions"
                                />
                                {errors['form.deductions'] && (
                                  <label
                                    htmlFor="deductions"
                                    className="error_label"
                                  >
                                    {errors['form.deductions']}
                                  </label>
                                )}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inputs-col mpb-0">
                              {/*single input */}
                              <div className="inputs-head">
                                <ul className="none">
                                  <li>
                                    <strong>Extra withholding.</strong> Enter
                                    any additional tax you want withheld each{' '}
                                    <strong>pay period</strong>
                                  </li>
                                </ul>
                              </div>
                              <div className="inputs-items steps_input">
                                <div className="stepcounter">4(c)</div>
                                <input
                                  type="number"
                                  placeholder="- Enter here -"
                                  className={`${
                                    errors['form.extra_withholding']
                                      ? 'error'
                                      : ''
                                  } price_input step_count`}
                                  name="extra_withholding"
                                  id="extra_withholding"
                                  value={form.extra_withholding}
                                  field="form.extra_withholding"
                                />
                                {errors['form.extra_withholding'] && (
                                  <label
                                    htmlFor="extra_withholding"
                                    className="error_label"
                                  >
                                    {errors['form.extra_withholding']}
                                  </label>
                                )}
                              </div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>

                    {/* STEP 5 */}
                    <div className="row form-row   no_margin">
                      <div className="col col-md-12">
                        <div className="inputs-col">
                          <div className="inputs-head  font-mob">
                            Step 5: Sign Here
                          </div>
                          <p>
                            Under penalties of perjury, I declare that this
                            certificate, to the best of my knowledge and belief,
                            is true, correct, and complete.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col">
                        <div className="inputs-col">
                          <div className="inputs-head">
                            Employee Signature
                            <span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items">
                            <div
                              ref={(element) => {
                                this.signContainer = element;
                              }}
                              className="sign_here"
                            >
                              {this.state.sign && (
                                <Fragment>
                                  <SignPad
                                    height={this.state.sign.height}
                                    width={this.state.sign.width}
                                    handleSignature={this.handleSignature.bind(
                                      this,
                                      'form.employee_signature'
                                    )}
                                    signature={form.employee_signature}
                                  />
                                  <input
                                    type="hidden"
                                    value={form.employee_signature}
                                    name="employee_signature"
                                    field="form.employee_signature"
                                    required
                                  ></input>
                                  {errors['form.employee_signature'] && (
                                    <label
                                      htmlFor="employee_signature"
                                      className="error_label"
                                    >
                                      {errors['form.employee_signature']}
                                    </label>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="inputs-col">
                          <div className="inputs-head">
                            Date<span className="required-star">&#x2A;</span>
                          </div>
                          <div className="inputs-items">
                            <DatePicker
                              className={`${
                                errors['form.signed_date'] ? 'error' : ''
                              } w-100`}
                              name="signed_date"
                              id="signed_date"
                              required
                              selected={moment(form.signed_date)}
                              field="form.signed_date"
                              placeholder="mm/dd/yyyy"
                              onChange={this.handleDateChange.bind(
                                this,
                                'form.signed_date'
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormNav
                      currentPage={this.state.page}
                      noOfPages={this.state.pages.length}
                      navigate={this.navigate.bind(this)}
                      adminMode={adminMode}
                      // skip={true}
                      // skipHandler={this.skipHandler.bind(this)}
                      disclaimer_check={
                        page == 3 ? form.disclaimer_check : true
                      }
                      lastPage={page == 3 ? true : false}
                      selectedUser={user}
                      mobileMode={this.props.mobileMode}
                    />
                  </form>
                );
              case 2:
                return (
                  <form
                    className="info-form"
                    ref={this.form}
                    onChange={this.handleFormChange}
                    onSubmit={this.submitForm}
                    noValidate
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="mt-0">General Instructions</h3>
                        <p>
                          {/* <strong>Future developments</strong> */}

                          <strong>Duties and Responsibilities</strong>
                        </p>
                        <p>
                          For the latest information about developments related
                          to Form W-4, such as legislation enacted after it was
                          published, go to www.irs.gov/FormW4.
                        </p>
                        <p>
                          <strong>Purpose of Form</strong>
                        </p>
                        <p>
                          Complete Form W-4 so that your employer can withhold
                          the correct federal income tax from your pay. If too
                          little is withheld, you will generally owe tax when
                          you file your tax return and may owe a penalty. If too
                          much is withheld, you will generally be due a refund.
                          Complete a new Form W-4 when changes to your personal
                          or financial situation would change the entries on the
                          form. For more information on withholding and when you
                          must furnish a new Form W-4, see Pub. 505, Tax
                          Withholding and Estimated Tax
                        </p>
                        <p>
                          {' '}
                          <strong>Exemption from withholding- </strong>You may
                          claim exemption from withholding for 2023 if you meet
                          both of the following conditions: you had no federal
                          income tax liability in 2022 and you expect to have no
                          federal income tax liability in 2023. You had no
                          federal income tax liability in 2022 if (1) your total
                          tax on line 24 on your 2022 Form 1040 or 1040-SR is
                          zero (or less than the sum of lines 27, 28, and 29),
                          or (2) you were not required to file a return because
                          your income was below the filing threshold for your
                          correct filing status. If you claim exemption, you
                          will have no income tax withheld from your paycheck
                          and may owe taxes and penalties when you file your
                          2023 tax return. To claim exemption from withholding,
                          certify that you meet both of the conditions above by
                          writing “Exempt” on Form W-4 in the space below Step
                          4(c). Then, complete Steps 1(a), 1(b), and 5. Do not
                          complete any other steps. You will need to submit a
                          new Form W-4 by February 15, 2024.
                        </p>
                        <p>
                          <strong>Your privacy-</strong> If you prefer to limit
                          information provided in. Steps 2 through 4, use the
                          online estimator, which will also increase accuracy.
                        </p>
                        <p>
                          As an alternative to the estimator: if you have
                          concerns with Step 2(c), you may choose Step 2(b); if
                          you have concerns with Step 4(a), you may enter an
                          additional amount you want withheld per pay period in
                          Step 4(c). If this is the only job in your household,
                          you may instead check the box in Step 2(c), which will
                          increase your withholding and significantly reduce
                          your paycheck (often by thousands of dollars over the
                          year)
                        </p>
                        <p>
                          <strong>When to use the Estimator- </strong>Consider
                          using the estimator at www.irs.gov/W4App if you:
                        </p>
                        <ul>
                          <li>
                            <p>Expect to work only part of the year;</p>
                          </li>
                          <li>
                            <p>
                              Have dividend or capital gain income, or are
                              subject to additional taxes, such as the
                              additional Medicare tax;
                            </p>
                          </li>
                          <li>
                            <p>Have self-employment income (see below); or</p>
                          </li>
                          <li>
                            <p>
                              Prefer the most accurate withholding for multiple
                              job situations.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <strong>Self-employment-</strong> Generally, you will
                          owe both income and self-employment taxes on any
                          self-employment income you receive separate from the
                          wages you receive as an employee. If you want to pay
                          these taxes through withholding from your wages, use
                          the estimator at www.irs.gov/W4App to figure the
                          amount to have withheld.
                        </p>
                        <p>
                          <strong>Nonresident Alien-</strong> If you’re a
                          nonresident alien, see Notice 1392, Supplemental Form
                          W-4 Instructions for Nonresident Aliens, before
                          completing this form.
                        </p>
                      </div>
                    </div>
                    <div className="separator" />
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Specific Instructions</h3>
                        <p>
                          <strong>Step 1(c)-</strong> Check your anticipated
                          filing status. This will determine the standard
                          deduction and tax rates used to compute your
                          withholding.
                        </p>
                        <p>
                          <strong>Step 2-</strong> Use this step if you (1) have
                          more than one job at the same time, or (2) are married
                          filing jointly and you and your spouse both work.
                        </p>
                        <p>
                          Option <b>(a)</b> most accurately calculates the
                          additional tax you need to have withheld, while option{' '}
                          <b>(b)</b> does so with a little less accuracy.
                        </p>
                        <p>
                          If you (and your spouse) have a total of only two
                          jobs, you may instead check the box in option{' '}
                          <b>(c).</b> The box must also be checked on the Form
                          W-4 for the other job. If the box is checked, the
                          standard deduction and tax brackets will be cut in
                          half for each job to calculate withholding. This
                          option is roughly accurate for jobs with similar pay;
                          otherwise, more tax than necessary may be withheld,
                          and this extra amount will be larger the greater the
                          difference in pay is between the two jobs
                        </p>
                        <p>
                          <strong>Multiple Jobs-</strong> Complete Steps 3
                          through 4(b) on only one Form W-4. Withholding will be
                          most accurate if you do this on the Form W-4 for the
                          highest paying job.
                        </p>
                        <p>
                          <strong>Step 3-</strong> Step 3 of Form W-4 provides
                          instructions for determining the amount of the child
                          tax credit and the credit for other dependents that
                          you may be able to claim when you file your tax
                          return. To qualify for the child tax credit, the child
                          must be under age 17 as of December 31, must be your
                          dependent who generally lives with you for more than
                          half the year, and must have the required social
                          security number. You may be able to claim a credit for
                          other dependents for whom a child tax credit can’t be
                          claimed, such as an older child or a qualifying
                          relative. For additional eligibility requirements for
                          these credits, see Pub. 972, Child Tax Credit and
                          Credit for Other Dependents. You can also include
                          <strong> other tax credits</strong> in this step, such
                          as education tax credits and the foreign tax credit.
                          To do so, add an estimate of the amount for the year
                          to your credits for dependents and enter the total
                          amount in Step 3. Including these credits will
                          increase your paycheck and reduce the amount of any
                          refund you may receive when you file your tax return.
                        </p>
                        <p>
                          <strong>Step 4 (optional)</strong>
                        </p>
                        <p>
                          <strong>Step 4(a)-</strong> Enter in this step the
                          total of your other estimated income for the year, if
                          any. You shouldn’t include income from any jobs or
                          self-employment. If you complete Step 4(a), you likely
                          won’t have to make estimated tax payments for that
                          income. If you prefer to pay estimated tax rather than
                          having tax on other income withheld from your
                          paycheck, see Form 1040-ES, Estimated Tax for
                          Individuals.
                        </p>
                        <p>
                          <strong>Step 4(b)-</strong> Enter in this step the
                          amount from the Deductions Worksheet, line 5, if you
                          expect to claim deductions other than the basic
                          standard deduction on your 2023 tax return and want to
                          reduce your withholding to account for these
                          deductions. This includes both itemized deductions and
                          other deductions such as for student loan interest and
                          IRAs.
                        </p>
                        <p>
                          <strong>Step 4(c)-</strong> Enter in this step any
                          additional tax you want withheld from your pay{' '}
                          <b>each pay period</b>, including any amounts from the
                          Multiple Jobs Worksheet, line 4. Entering an amount
                          here will reduce your paycheck and will either
                          increase your refund or reduce any amount of tax that
                          you owe.
                        </p>
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col">
                        <div className="disclaimers">
                          <strong className="diss">Disclaimer: </strong>You must
                          read and carefully understand the documents that you
                          are filling out and signing. These are legally binding
                          documents and must be accurate. By filling these forms
                          out, you are agreeing to our{' '}
                          <a href="">
                            Terms of Use, Privacy Policy and Employee
                            Agreements.
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row last">
                      <div className="col">
                        <div className="inputs-col">
                          <div className="check_radio_group check_disclaimer">
                            <div className="check_radio_item">
                              <input
                                type="checkbox"
                                name="disclaimer_check"
                                id="disclaimer_check"
                                field="form.disclaimer_check"
                                checked={form.disclaimer_check}
                                required
                              />
                              <span className="checkbox_checkbox"></span>
                              <label htmlFor="disclaimer_check">
                                I have confirmed the above details are true and
                                valid.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormNav
                      currentPage={this.state.page}
                      noOfPages={this.state.pages.length}
                      navigate={this.navigate.bind(this)}
                      adminMode={adminMode}
                      // skip={true}
                      // skipHandler={this.skipHandler.bind(this)}
                      disclaimer_check={form.disclaimer_check}
                      lastPage={page == 3 ? true : false}
                      selectedUser={user}
                      mobileMode={this.props.mobileMode}
                    />
                  </form>
                );
              case 3:
                return (
                  <form
                    className="info-form"
                    ref={this.form}
                    onChange={this.handleFormChange}
                    onSubmit={this.submitForm}
                    noValidate
                  >
                    <div className="row form-row no_border no_padding">
                      <div className="col">
                        <div className="inputs-col">
                          <h3 className=" mb-2">
                            Step 2(b)—Multiple Jobs Worksheet
                          </h3>
                          <p>
                            If you choose the option in Step 2(b) on Form W-4,
                            complete this worksheet (which calculates the total
                            extra tax for all jobs) on <strong>only ONE</strong>{' '}
                            Form W-4. Withholding will be most accurate if you
                            complete the worksheet and enter the result on the
                            Form W-4 for the highest paying job.
                          </p>

                          <p className="mb-0">
                            <strong>Note: </strong> If more than one job has
                            annual wages of more than $120,000 or there are more
                            than three jobs, see Pub. 505 for additional tables;
                            or, you can use the online withholding estimator at{' '}
                            <i>
                              <a
                                href="http://www.irs.gov/W4App"
                                target="_blank"
                              >
                                www.irs.gov/W4App
                              </a>
                            </i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no_padding">
                      <div className="col">
                        <div className="disclaimers dis-notes txt-black">
                          <strong>
                            Complete Steps 2–4 ONLY if they apply to you;
                            otherwise, skip to Step 5.
                          </strong>{' '}
                          See page 2 for more information on each step, who can
                          claim exemption from withholding, when to use the
                          online estimator, and privacy.
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>1</strong> <b>Two jobs.</b> If you have
                              two jobs or you’re married filing jointly and you
                              and your spouse each have one job, find the amount
                              from the appropriate table on page 4. Using the
                              “Higher Paying Job” row and the “Lower Paying Job”
                              column, find the value at the intersection of the
                              two household salaries and enter that value on
                              line 1. Then, skip to line 3
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">1</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.double_job'] ? 'error' : ''
                              }`}
                              type="number"
                              name="double_job"
                              id="double_job"
                              value={form.double_job}
                              field="form.double_job"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.double_job'] && (
                              <label
                                htmlFor="double_job"
                                className="error_label"
                              >
                                {errors['form.double_job']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-0">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>2</strong> <b>Three jobs.</b> If you
                              and/or your spouse have three jobs at the same
                              time, complete lines 2a, 2b, and 2c below.
                              Otherwise, skip to line 3.
                            </p>
                          </div>
                          <div className="inputs-items">
                            {/* intentionaly left blank */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border no_padding">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul>
                              <li>
                                Find the amount from the appropriate table on
                                page 4 using the annual wages from the highest
                                paying job in the “Higher Paying Job” row and
                                the annual wages for your next highest paying
                                job in the “Lower Paying Job” column. Find the
                                value at the intersection of the two household
                                salaries and enter that value on line 2a.
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">2a</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.tripple_job_high'] ? 'error' : ''
                              }`}
                              type="number"
                              name="tripple_job_high"
                              id="tripple_job_high"
                              value={form.tripple_job_high}
                              field="form.tripple_job_high"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.tripple_job_high'] && (
                              <label
                                htmlFor="tripple_job_high"
                                className="error_label"
                              >
                                {errors['form.tripple_job_high']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border no_padding">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul>
                              <li>
                                Add the annual wages of the two highest paying
                                jobs from line 2a together and use the total as
                                the wages in the “Higher Paying Job” row and use
                                the annual wages for your third job in the
                                “Lower Paying Job” column to find the amount
                                from the appropriate table on page 4 and enter
                                this amount on line 2b.
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter ">2b</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.tripple_job_low'] ? 'error' : ''
                              }`}
                              type="number"
                              name="tripple_job_low"
                              id="tripple_job_low"
                              value={form.tripple_job_low}
                              field="form.tripple_job_low"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.tripple_job_low'] && (
                              <label
                                htmlFor="tripple_job_low"
                                className="error_label"
                              >
                                {errors['form.tripple_job_low']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no_border no_padding mb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <ul>
                              <li>
                                Add the amounts from lines 2a and 2b and enter
                                the result on line 2c.
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">2c</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.tripple_job_total'] ? 'error' : ''
                              }`}
                              type="number"
                              name="tripple_job_total"
                              id="tripple_job_total"
                              value={form.tripple_job_total}
                              field="form.tripple_job_total"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.tripple_job_total'] && (
                              <label
                                htmlFor="tripple_job_total"
                                className="error_label"
                              >
                                {errors['form.tripple_job_total']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet no-padding">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>3</strong> Enter the number of pay periods
                              per year for the highest paying job. For example,
                              if that job pays weekly, enter 52; if it pays
                              every other week, enter 26; if it pays monthly,
                              enter 12, etc.
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">3</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.job_pay_period'] ? 'error' : ''
                              }`}
                              type="number"
                              name="job_pay_period"
                              id="job_pay_period"
                              value={form.job_pay_period}
                              field="form.job_pay_period"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.job_pay_period'] && (
                              <label
                                htmlFor="job_pay_period"
                                className="error_label"
                              >
                                {errors['form.job_pay_period']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>4</strong> <b>Divide</b> the annual amount
                              on line 1 or line 2c by the number of pay periods
                              on line 3. Enter this amount here and in Step 4(c)
                              of Form W-4 for the highest paying job (along with
                              any other additional amount you want withheld).
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">4</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.montly_pay'] ? 'error' : ''
                              }`}
                              type="number"
                              name="montly_pay"
                              id="montly_pay"
                              value={form.montly_pay}
                              field="form.montly_pay"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.montly_pay'] && (
                              <label
                                htmlFor="montly_pay"
                                className="error_label"
                              >
                                {errors['form.montly_pay']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row no_border no_padding">
                      <div className="col">
                        <div className="inputs-col">
                          <h3>Step 4(b)—Deductions Worksheet</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>1.</strong> Enter an estimate of your 2023
                              itemized deductions (from Schedule A (Form 1040)).
                              Such deductions may include qualifying home
                              mortgage interest, charitable contributions, state
                              and local taxes (up to $10,000), and medical
                              expenses in excess of 7.5% of your income
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">1</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.basic_deductions'] ? 'error' : ''
                              }`}
                              type="number"
                              name="basic_deductions"
                              id="basic_deductions"
                              value={form.basic_deductions}
                              field="form.basic_deductions"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.basic_deductions'] && (
                              <label
                                htmlFor="basic_deductions"
                                className="error_label"
                              >
                                {errors['form.basic_deductions']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>2.</strong> Enter
                            </p>
                            <ul>
                              <li>
                                $25,100 if you’re married filing jointly or
                                qualifying widow(er)
                              </li>
                              <li>$18,800 if you’re head of household</li>
                              <li>
                                $12,550 if you’re single or married filing
                                separately
                              </li>
                            </ul>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">2</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.preset_deductions'] ? 'error' : ''
                              }`}
                              type="number"
                              name="preset_deductions"
                              id="preset_deductions"
                              value={form.preset_deductions}
                              field="form.preset_deductions"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.preset_deductions'] && (
                              <label
                                htmlFor="preset_deductions"
                                className="error_label"
                              >
                                {errors['form.preset_deductions']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>3.</strong> If line 1 is greater than line
                              2, subtract line 2 from line 1. If line 2 is
                              greater than line 1, enter “-0-”
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">3</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.calculated_deductions']
                                  ? 'error'
                                  : ''
                              }`}
                              type="number"
                              name="calculated_deductions"
                              id="calculated_deductions"
                              value={form.calculated_deductions}
                              field="form.calculated_deductions"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.calculated_deductions'] && (
                              <label
                                htmlFor="calculated_deductions"
                                className="error_label"
                              >
                                {errors['form.calculated_deductions']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>4.</strong>Enter an estimate of your
                              student loan interest, deductible IRA
                              contributions, and certain other adjustments (from
                              Part II of Schedule 1 (Form 1040)). See Pub. 505
                              for more information.
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">4</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.student_loan'] ? 'error' : ''
                              }`}
                              type="number"
                              name="student_loan"
                              id="student_loan"
                              value={form.student_loan}
                              field="form.student_loan"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.student_loan'] && (
                              <label
                                htmlFor="student_loan"
                                className="error_label"
                              >
                                {errors['form.student_loan']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row worksheet pb-2">
                      <div className="col">
                        <div className="inputs-col">
                          {/*single input */}
                          <div className="inputs-head">
                            <p className="w-list">
                              <strong>5.</strong>
                              <b>Add</b> lines 3 and 4. Enter the result here
                              and in <b>Step 4(b)</b> of Form W-4.
                            </p>
                          </div>
                          <div className="inputs-items steps_input">
                            <div className="stepcounter">5</div>
                            <input
                              className={`price_input step_counter ${
                                errors['form.total_deductions'] ? 'error' : ''
                              }`}
                              type="number"
                              name="total_deductions"
                              id="total_deductions"
                              value={form.total_deductions}
                              field="form.total_deductions"
                              placeholder="- Enter here -"
                              required
                            />
                            {errors['form.total_deductions'] && (
                              <label
                                htmlFor="total_deductions"
                                className="error_label"
                              >
                                {errors['form.total_deductions']}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-row">
                      <div className="col">
                        <div className="disclaimers dis-notes">
                          {/*add class toggled*/}
                          <div className="inputs-head">
                            PRIVACY ACT AND PAPERWORK REDUCTION ACT NOTICE
                            <button
                              type="button"
                              className={`toggler_btn ${
                                this.state.isPrivacyOpen ? 'toggled' : ''
                              }`}
                              onClick={() => this.toggleList('privacy')}
                            ></button>
                          </div>
                          {this.state.isPrivacyOpen && (
                            <Fragment>
                              <p>
                                We ask for the information on this form to carry
                                out the Internal Revenue laws of the United
                                States. Internal Revenue Code sections
                                3402(f)(2) and 6109 and their regulations
                                require you to provide this information; your
                                employer uses it to determine your federal
                                income tax withholding. Failure to provide a
                                properly completed form will result in your
                                being treated as a single person with no other
                                entries on the form; providing fraudulent
                                information may subject you to penalties.
                                Routine uses of this information include giving
                                it to the Department of Justice for civil and
                                criminal litigation; to cities, states, the
                                District of Columbia, and U.S. commonwealths and
                                possessions for use in administering their tax
                                laws; and to the Department of Health and Human
                                Services for use in the National Directory of
                                New Hires. We may also disclose this information
                                to other countries under a tax treaty, to
                                federal and state agencies to enforce federal
                                nontax criminal laws, or to federal law
                                enforcement and intelligence agencies to combat
                                terrorism.
                              </p>
                              <p>
                                You are not required to provide the information
                                requested on a form that is subject to the
                                Paperwork Reduction Act unless the form displays
                                a valid OMB control number. Books or records
                                relating to a form or its instructions must be
                                retained as long as their contents may become
                                material in the administration of any Internal
                                Revenue law. Generally, tax returns and return
                                information are confidential, as required by
                                Code section 6103.
                              </p>
                              <p>
                                The average time and expenses required to
                                complete and file this form will vary depending
                                on individual circumstances. For estimated
                                averages, see the instructions for your income
                                tax return.
                              </p>
                              <p>
                                If you have suggestions for making this form
                                simpler, we would be happy to hear from you. See
                                the instructions for your income tax return.
                              </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {adminMode && (
                      <div className="row form-row">
                        <div className="col">
                          <div className="inputs-col">
                            <div className="inputs-head">Employer’s Name</div>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className={`${
                                  errors['form.employer_name'] ? 'error' : ''
                                }`}
                                name="employer_name"
                                id="employer_name"
                                placeholder="Enter Here"
                                value={form.employer_name}
                                field="form.employer_name"
                                required
                              />
                              {errors['form.employer_name'] && (
                                <label
                                  htmlFor="employer_name"
                                  className="error_label"
                                >
                                  {errors['form.employer_name']}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col m-0">
                          <div className="inputs-col m-0">
                            <div className="inputs-head">Address</div>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className={`${
                                  errors['form.employer_address_street']
                                    ? 'error'
                                    : ''
                                }`}
                                name="employer_address_street"
                                id="employer_address_street"
                                value={form.employer_address_street}
                                field="form.employer_address_street"
                                required
                                placeholder="Street Address"
                              />
                              {errors['form.employer_address_street'] && (
                                <label
                                  htmlFor="employer_address_street"
                                  className="error_label"
                                >
                                  {errors['form.employer_address_street']}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="inputs-col">
                            <div className="inputs-items group two-item">
                              <div className="grouped">
                                <input
                                  type="text"
                                  className={`${
                                    errors['form.employer_address_city']
                                      ? 'error'
                                      : ''
                                  }`}
                                  name="employer_address_city"
                                  id="employer_address_city"
                                  value={form.employer_address_city}
                                  field="form.employer_address_city"
                                  required
                                  placeholder="City"
                                />
                                {errors['form.employer_address_city'] && (
                                  <label
                                    htmlFor="employer_address_city"
                                    className="error_label"
                                  >
                                    {errors['form.employer_address_city']}
                                  </label>
                                )}
                              </div>
                              <div className="grouped">
                                <input
                                  type="text"
                                  className={`${
                                    errors['form.employer_address_state']
                                      ? 'error'
                                      : ''
                                  }`}
                                  name="employer_address_state"
                                  id="employer_address_state"
                                  placeholder="State"
                                  value={form.employer_address_state}
                                  field="form.employer_address_state"
                                  required
                                />
                                {errors['form.employer_address_state'] && (
                                  <label
                                    htmlFor="employer_address_state"
                                    className="error_label"
                                  >
                                    {errors['form.employer_address_state']}
                                  </label>
                                )}
                              </div>
                              <div className="grouped">
                                <input
                                  type="text"
                                  className={`${
                                    errors['form.employer_address_country']
                                      ? 'error'
                                      : ''
                                  }`}
                                  name="employer_address_country"
                                  id="employer_address_country"
                                  value={form.employer_address_country}
                                  field="form.employer_address_country"
                                  required
                                  placeholder="Country"
                                />
                                {errors['form.employer_address_country'] && (
                                  <label
                                    htmlFor="employer_address_country"
                                    className="error_label"
                                  >
                                    {errors['form.employer_address_country']}
                                  </label>
                                )}
                              </div>
                              <div className="grouped">
                                <input
                                  type="text"
                                  className={`${
                                    errors['form.employer_address_zip']
                                      ? 'error'
                                      : ''
                                  }`}
                                  name="employer_address_zip"
                                  id="employer_address_zip"
                                  value={form.employer_address_zip}
                                  field="form.employer_address_zip"
                                  required
                                  placeholder="Zip"
                                />
                                {errors['form.employer_address_zip'] && (
                                  <label
                                    htmlFor="employer_address_zip"
                                    className="error_label"
                                  >
                                    {errors['form.employer_address_zip']}
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="inputs-col">
                            <div className="inputs-head">EIN</div>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className={`${
                                  errors['form.ein'] ? 'error' : ''
                                }`}
                                name="ein"
                                id="ein"
                                placeholder="Enter Here"
                                value={form.ein}
                                field="form.ein"
                                required
                              />
                              {errors['form.ein'] && (
                                <label htmlFor="ein" className="error_label">
                                  {errors['form.ein']}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="inputs-col">
                            <div className="inputs-head">
                              First date of employment
                            </div>
                            <div className="inputs-items">
                              <DatePicker
                                className={`${
                                  errors['form.employment_first_date']
                                    ? 'error'
                                    : ''
                                } w-100`}
                                name="employment_first_date"
                                id="employment_first_date"
                                required
                                selected={moment(form.employment_first_date)}
                                field="form.employment_first_date"
                                placeholder="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(
                                  this,
                                  'form.employment_first_date'
                                )}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                              />
                              {errors['form.employment_first_date'] && (
                                <label
                                  htmlFor="employment_first_date"
                                  className="error_label"
                                >
                                  {errors['form.employment_first_date']}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <FormNav
                      currentPage={this.state.page}
                      noOfPages={this.state.pages.length}
                      navigate={this.navigate.bind(this)}
                      adminMode={adminMode}
                      disclaimer_check={true}
                      skip={true}
                      skipHandler={this.skipHandler.bind(this)}
                      lastPage={page == 3 && this.props.lastPage ? true : false}
                      selectedUser={user}
                      rehire={this.props.rehire}
                      mobileMode={this.props.mobileMode}
                    />
                  </form>
                );
              default:
                return;
            }
          })()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedW4 = compose(withRouter, connect(mapStateToProps))(W4);
export { connectedW4 as W4 };
