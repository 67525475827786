import { assignmentStatsConstants } from '../_constants';
import { assignmentStatsService } from '../_services';

export const assignmentStatsActions = {
  getAssignemntStatusTypes,
  getAssignemntStatus,
  getAssignemntHistory,
  saveStatus,
  getAssignemntStatusGraphView,
  getAssignemntStatusProgress,
  historyDelete,
};

function getAssignemntStatusTypes() {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusTypes().then(
      (applicantStats) => dispatch(success(applicantStats)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_REQUEST };
  }
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_FAILURE,
      error,
    };
  }
}
function getAssignemntStatus(crewId) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatus(crewId).then(
      (applicantStats) => {
        dispatch(success(applicantStats));
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUS_REQUEST };
  }
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUS_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUS_FAILURE,
      error,
    };
  }
}
function historyDelete(id) {
  return (dispatch) => {
    assignmentStatsService.historyDelete(id).then(
      (applicantStats) => dispatch(success(applicantStats)),
      (error) => dispatch(failure(error))
    );
  };
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.DELETEHISTORY_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return { type: assignmentStatsConstants.DELETEHISTORY_FAILURE, error };
  }
}
function getAssignemntHistory(crewId, pageNum) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntHistory(crewId, pageNum).then(
      (assignmentHistory) => dispatch(success(assignmentHistory)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTHISTORY_REQUEST };
  }
  function success(assignmentHistory) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTHISTORY_SUCCESS,
      assignmentHistory,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTHISTORY_FAILURE,
      error,
    };
  }
}

function saveStatus(data) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.saveStatus(data).then(
      (assignmentStatusSaved) => dispatch(success(assignmentStatusSaved)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_REQUEST };
  }
  function success(assignmentStatusSaved) {
    return {
      type: assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_SUCCESS,
      assignmentStatusSaved,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_FAILURE,
      error,
    };
  }
}
function getAssignemntStatusGraphView(data, pageNum = 0) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusGraphView(data, pageNum).then(
      (assignmentStatusGraph) => dispatch(success(assignmentStatusGraph)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_REQUEST };
  }
  function success(assignmentStatusGraph) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_SUCCESS,
      assignmentStatusGraph,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_FAILURE,
      error,
    };
  }
}
function getAssignemntStatusProgress() {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusProgress().then(
      (assignemntStatusProgress) => dispatch(success(assignemntStatusProgress)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_REQUEST,
    };
  }
  function success(assignemntStatusProgress) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_SUCCESS,
      assignemntStatusProgress,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_FAILURE,
      error,
    };
  }
}
