import React from 'react';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import '../assets/styles/applicants.css';
import { ViewApplicant } from './ViewApplicant';
import ApplicantTab from 'ApplicantTab';
import { ApplicationHistory } from './ApplicationHistory';
import { ApplicantList } from './ApplicantList';
import { EmailApplicant } from './EmailApplicant';
import { ArchivedApplicant } from './ArchivedApplicant';
import { HiredApplicant } from './HiredApplicant';
import { EmailFilter } from './EmailFilter';
import { Filter } from './Filter';
import { EditApplicantion } from './EditApplicantion';

class Applicants extends React.Component {
  constructor(props) {
    super(props);
    var hash, step;
    if (this.props.match.path === '/applicant-view/:hash') {
      hash = this.props.match.params.hash;
      step = 'ViewApplicant';
    } else {
      hash = '';
      step = 'ApplicantList';
    }
    this.state = {
      mainHtml: '',
      step: step,
      from: 'Applicants',
      email: '',
      permissions: [],
      hash: hash,
    };
    this.ApplicantListing = this.ApplicantListing.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.archived = this.archived.bind(this);
    this.hired = this.hired.bind(this);
    this.emailFilter = this.emailFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.filterList = this.filterList.bind(this);
    this.editApplicant = this.editApplicant.bind(this);
    this.filtering = this.filtering.bind(this);
    this.viewHistory = this.viewHistory.bind(this);
  }

  ApplicantListing() {
    this.setState({ step: 'ApplicantList', from: 'Applicants' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  viewDetails(hash, applnType = '') {
    this.setState(
      { step: 'ViewApplicant', hash: hash, applnType },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  viewHistory(hash, applnType = '') {
    this.setState(
      { step: 'ApplicationHistory', hash: hash, applnType },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  editApplicant(hash) {
    this.setState({ step: 'EditApplicantion', hash: hash }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  sendMail(email) {
    this.setState({ step: 'EmailApplicant', email: email }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  archived() {
    this.setState({ step: 'ArchivedApplicant' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  hired() {
    this.setState({ step: 'HiredApplicant' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  emailFilter(email) {
    this.setState({ step: 'EmailFilter' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  filter() {
    this.setState({ step: 'Filter' }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  filtering(param) {
    this.setState(
      { step: 'ApplicantList', param: param, from: 'filter' },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  filterList(data) {
    this.setState({ step: 'FilterList', data }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    var self = this;
    fetch(config.apiUrl + '/api/permission/getuserpermissions', {
      headers: authHeader(),
      method: 'GET',
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      });
  }

  componentWillReceiveProps(nextProps) {
    var options;
    if (this.state.step === 'ApplicantList') {
      options = (
        <div>
          <ApplicantList
            action={this.ApplicantListing}
            viewDetails={this.viewDetails}
            sendMail={this.sendMail}
            archived={this.archived}
            hired={this.hired}
            emailFilter={this.emailFilter}
            filter={this.filter}
            param={this.state.param}
            from={this.state.from}
          />
        </div>
      );
    }
    if (this.state.step === 'ArchivedApplicant') {
      options = (
        <div>
          <ArchivedApplicant
            goBack={this.ApplicantListing}
            viewDetails={this.viewDetails}
          />
        </div>
      );
    }
    if (this.state.step === 'HiredApplicant') {
      options = (
        <div>
          <HiredApplicant
            goBack={this.ApplicantListing}
            viewDetails={this.viewDetails}
          />
        </div>
      );
    }

    if (this.state.step === 'ViewApplicant') {
      options = (
        <div>
          <ApplicantTab
            hash={this.state.hash}
            applnType={this.state.applnType}
            goBack={this.ApplicantListing}
            email={this.state.email}
            sendMail={this.sendMail}
            editApplicant={this.editApplicant}
            viewHistory={this.viewHistory}
          />
        </div>
      );
    }

    if (this.state.step === 'ApplicationHistory') {
      options = (
        <div>
          <ApplicationHistory
            hash={this.state.hash}
            applnType={this.state.applnType}
            goBack={this.ApplicantListing}
            viewDetails={this.viewDetails}
          />
        </div>
      );
    }

    if (this.state.step === 'EditApplicantion') {
      options = (
        <div>
          <EditApplicantion
            hash={this.state.hash}
            goBack={this.ApplicantListing}
          />
        </div>
      );
    }

    if (this.state.step === 'EmailApplicant') {
      options = (
        <div>
          <EmailApplicant
            email={this.state.email}
            goBack={this.ApplicantListing}
          />
        </div>
      );
    }
    if (this.state.step === 'EmailFilter') {
      options = (
        <div>
          <EmailFilter
            email={this.state.email}
            goBack={this.ApplicantListing}
          />
        </div>
      );
    }
    if (this.state.step === 'Filter') {
      options = (
        <div>
          <Filter
            goBack={this.ApplicantListing}
            filterList={this.filterList}
            filtering={this.filtering}
          />
        </div>
      );
    }
    if (this.state.step === 'FilterList') {
      options = <div />;
    }
    this.setState({ mainHtml: options });
  }
  //
  render() {
    const { permissions } = this.state;
    return (
      permissions.length > 0 &&
      (!permissions.includes('Manage Applicants') ? (
        <div className="content_div">
          <div className="no_permission">No permission to this page</div>
        </div>
      ) : (
        this.state.mainHtml
      ))
    );
  }
}

function mapStateToProps(state) {
  const { applicants } = state;
  return {
    applicants,
  };
}

const connectedHomePage = connect(mapStateToProps)(Applicants);
export { connectedHomePage as Applicants };
