import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { applicantsActions } from '_actions';
import { selectJobApplicationHistory } from '_helpers/reduxSelector';
import JobApplicationHistoryList from './JobApplicationHistoryList';

export default function JobApplicationHistory({ hash }) {
  const dispatch = useDispatch();

  const JobApplicationHistory = useSelector(selectJobApplicationHistory);

  const getJobApplicationHistory = (params = {}) => {
    const urlParams = new URLSearchParams(params)?.toString() ?? '';
    dispatch(applicantsActions.getJobApplicationHistory(hash, urlParams));
  };

  return (
    <>
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
        <h3 className="left">Applicaiton History</h3>
        <div style={{ marginTop: 30 }}>
          <JobApplicationHistoryList
            lastPage={JobApplicationHistory?.last_page}
            getJobApplicationHistory={getJobApplicationHistory}
            data={JobApplicationHistory?.data}
          />
        </div>
      </div>
    </>
  );
}
