import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { reportsActions } from '_actions';
import { reportsService } from '../_services';
import MileageHobbsReport from './MileageHobbsReport';
import { redirectToPdf } from '_helpers/redirectToPdf';
import '../assets/styles/report.css';
import { mileageHobbsReport, getCurrentUserInfo } from '_helpers/reduxSelector';

const MileageHobbsReportContainer = (props) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => mileageHobbsReport(state));
  const currentUserInfo = useSelector((state) => getCurrentUserInfo(state));
  const DATE_FORMAT = currentUserInfo?.date_formats?.ISO_DATE_FORMAT;

  const [fromDate, setFromDate] = useState(
    moment(`1/1/${new Date().getFullYear()}`)
  );
  const [toDate, setToDate] = useState(moment());

  const [globalSort, setGlobalSort] = useState('equipment_name');
  const [globalOrder, setGlobalOrder] = useState('asc');

  const getMileageHobbsReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append(
      'from_date',
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ''
    );
    data.append('to_date', toDate ? moment(toDate).format(DATE_FORMAT) : '');
    data.append('page', newPage || 0);
    data.append('sort', sort || 'equipment_name');
    data.append('order', order || 'asc');
    dispatch(reportsActions.getMileageHobbsReport(data));
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append(
      'from_date',
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ''
    );
    data.append('to_date', toDate ? moment(toDate).format(DATE_FORMAT) : '');
    data.append('sort', globalSort || 'equipment_name');
    data.append('order', globalOrder || 'desc');
    data.append('report_type', 'csv');
    reportsService.generateMileageHobbsReport(data);
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? 'asc' : 'desc';
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onDateFilter = () => {
    let data = new FormData();
    data.append(
      'from_date',
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ''
    );
    data.append('to_date', toDate ? moment(toDate).format(DATE_FORMAT) : '');
    data.append('page', 0);
    dispatch(reportsActions.getMileageHobbsReport(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append(
      'from_date',
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ''
    );
    data.append('to_date', toDate ? moment(toDate).format(DATE_FORMAT) : '');
    data.append('sort', globalSort || 'equipment_name');
    data.append('order', globalOrder || 'desc');
    data.append('report_type', 'pdf');
    reportsService
      .generateMileageHobbsReportPDF(data)
      .then((res) => redirectToPdf(res));
  };

  useEffect(() => {
    /**To reset date filter back to initial dates when tab is changed */
    setFromDate(moment(`1/1/${new Date().getFullYear()}`));
    setToDate(moment());
    getMileageHobbsReport(0, 'equipment_name', 'desc');
  }, [props.activeTab]);

  return (
    <div>
      <div className={props.isReportMenu ? '' : 'dashboard_container'}>
        <div className="white report_table_container">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require('../assets/images/back.svg').default} />{' '}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {reportData?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    // onPrintClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Mileage/Hobbs Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? '10px' : '0px'}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp">
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="From"
                        id="fromDate"
                        name="fromDate"
                        selected={fromDate}
                        onChange={(e) => setFromDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="To"
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        onChange={(e) => setToDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    <button
                      className="print_button"
                      onClick={() => {
                        onDateFilter();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  {props.isReportMenu && reportData?.data?.length ? (
                    <>
                      <button
                        href=""
                        className="print_button"
                        onClick={(event) => {
                          onExportClick();
                        }}
                      >
                        Export
                      </button>
                      <button
                        href=""
                        className="print_button"
                        onClick={onPrintClick}
                      >
                        Print
                      </button>
                    </>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <MileageHobbsReport
                fromDate={fromDate}
                toDate={toDate}
                data={reportData?.data ?? []}
                lastPage={reportData?.last_page}
                getMileageHobbsReport={getMileageHobbsReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MileageHobbsReportContainer;
