import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { inventoryActions } from '_actions';
import { selectInventoryNotes } from '_helpers/reduxSelector';
import { inventoryService } from '_services';
import styles from './inventoryNotes.module.css';

const InventoryNotes = React.forwardRef(
  ({ inventoryId, inventoryTypeId, equipmentId, onComplete }, ref) => {
    const dispatch = useDispatch();

    const [note, setNote] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const inventoryNotes = useSelector(selectInventoryNotes);

    const handleOnSubmit = async () => {
      try {
        setIsSubmitting(true);
        let data = new FormData();
        data.append('note', note);
        data.append('id', inventoryId);
        data.append('type', inventoryTypeId);
        const response = await inventoryService?.addNote(data);
        if (response?.success) {
          setNote('');
          getNotes();
          setIsSubmitting(false);
          onComplete?.();
        } else {
          throw response;
        }
      } catch (error) {
        toast?.error(error?.message);
        setIsSubmitting(false);
      }
    };

    React.useEffect(() => {
      if (inventoryTypeId && equipmentId) {
        getNotes();
      }
    }, [inventoryTypeId, equipmentId]);

    const getNotes = async () => {
      try {
        let data = new FormData();
        data.append('id', equipmentId);
        data.append('type', inventoryTypeId);
        await dispatch(inventoryActions?.getInventoryNotes(data));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    React.useImperativeHandle(ref, () => ({
      getInventoryNotes() {
        getNotes();
      },
    }));

    return (
      <div className={styles.container}>
        {/* Create Note */}
        <div className={styles.noteInputContainer}>
          <label>Notes</label>
          <textarea
            placeholder="Write notes here..."
            className="small_input"
            name="notes"
            value={note}
            onChange={(event) => setNote(event?.target?.value)}
            disabled={isSubmitting}
          />
          {note?.trim() && (
            <div>
              <button
                disabled={isSubmitting}
                className="button mr-1"
                onClick={() => handleOnSubmit()}
              >
                Save Note
              </button>
              <button
                disabled={isSubmitting}
                className="button grey"
                onClick={() => setNote('')}
              >
                Cancel
              </button>
            </div>
          )}
        </div>

        {/* Note List */}

        {isLoading ? (
          <Loader />
        ) : (
          inventoryNotes?.map((item) => (
            <InventoryNoteItem key={item?.id} data={item} />
          ))
        )}
      </div>
    );
  }
);

export default InventoryNotes;

const InventoryNoteItem = ({ data }) => {
  return (
    <div className={styles.noteItem}>
      <p className={styles.noteItemTitle}>
        {moment(data?.created_at).format('ddd MMM Do, YYYY  h:mm A')}
      </p>
      {
        <div>
          {data.type === 1 ? (
            ''
          ) : (
            <>
              <p>
                <span>Assigned Resource:</span> {data.resource_name}
              </p>
              <p>
                <span>Assigned Driver/Employee:</span> {data.first_name}{' '}
                {data.last_name}
              </p>
            </>
          )}
          {!!data.assigned_by && data.type === 1 ? (
            <p>
              <span>Note written by: </span>
              {data.assigned_by}
            </p>
          ) : (
            data.assigned_by && (
              <p>
                <span>Assigned by:</span>
                {data.assigned_by}
              </p>
            )
          )}
        </div>
      }
      <p className={styles.noteItemDescription}>{data?.note}</p>
    </div>
  );
};

const Loader = () => (
  <div
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <img
      alt="loader"
      className="login_loader"
      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
    />
  </div>
);
