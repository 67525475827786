import { createProfileConstants } from '../_constants';

export function createProfile(state = {}, action) {
  switch (action.type) {
    // case createProfileConstants.GETRES_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case createProfileConstants.GETRES_SUCCESS:
    //   return {
    //     items: action.createProfile
    //   };
    // case createProfileConstants.GETRES_FAILURE:
    //   return {
    //     error: action.error
    //   };
    // case createProfileConstants.GETPANT_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case createProfileConstants.GETPANT_SUCCESS:
    //   return {
    //     list: action.pantSize
    //   };
    // case createProfileConstants.GETPANT_FAILURE:
    //   return {
    //     error: action.error
    //   };
    // case createProfileConstants.GETSHIRT_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case createProfileConstants.GETSHIRT_SUCCESS:
    //   return {
    //     shirtSize: action.shirtSize
    //   };
    // case createProfileConstants.GETSHIRT_FAILURE:
    //   return {
    //     error: action.error
    //   };
    // case createProfileConstants.GETQUALIFY_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case createProfileConstants.GETQUALIFY_SUCCESS:
    //   return {
    //     qualification: action.qualification
    //   };
    // case createProfileConstants.GETQUALIFY_FAILURE:
    //   return {
    //     error: action.error
    //   };

    case createProfileConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case createProfileConstants.GETALL_SUCCESS:
      return {
        listAll: action.listAll,
      };
    case createProfileConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };

    case createProfileConstants.CREATE_REQUEST:
      return { registering: true };
    case createProfileConstants.CREATE_SUCCESS:
      return {};
    case createProfileConstants.CREATE_FAILURE:
      return {};
    default:
      return state;
  }
}
