import { applicantsConstants } from '../_constants';
import { applicantsService } from '../_services';

export const applicantsActions = {
  getAll,
  sortApplicant,
  viewDetails,
  removeApplicant,
  getArchived,
  sortArchived,
  getHired,
  sortHired,
  updateNote,
  searchApplicants,
  getFilters,
  filterApplicants,
  sortFilterApplicant,
  updateNoteSingle,
  getFieldAll,
  editApplication,
  updateArchivedNote,
  updateHiredNote,
  getApplicantDetails,
  getJobApplicationHistory,
};

function getAll(perpage) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.getAll(perpage || 30).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function sortApplicant(sort, order) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.sortApplicant(sort, order).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function getFieldAll() {
  return (dispatch) => {
    dispatch(request());

    applicantsService.getFieldAll().then(
      (fields) => dispatch(success(fields)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETFIELD_REQUEST };
  }
  function success(fields) {
    return { type: applicantsConstants.GETFIELD_SUCCESS, fields };
  }
  function failure(error) {
    return { type: applicantsConstants.GETFIELD_FAILURE, error };
  }
}

function viewDetails(hash) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.viewDetails(hash).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.VIEWAPPLN_REQUEST };
  }
  function success(list) {
    return { type: applicantsConstants.VIEWAPPLN_SUCCESS, list };
  }
  function failure(error) {
    return { type: applicantsConstants.VIEWAPPLN_FAILURE, error };
  }
}

function removeApplicant(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // dispatch(request());

      applicantsService.removeApplicant(data).then(
        (list) => {
          if (list?.success) {
            dispatch(getAll());
          }
          resolve(list);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: applicantsConstants.VIEWAPPLN_REQUEST };
  }
  // function success(list) { return { type: applicantsConstants.VIEWAPPLN_SUCCESS, list } }
  function failure(error) {
    return { type: applicantsConstants.VIEWAPPLN_FAILURE, error };
  }
}

function getArchived() {
  return (dispatch) => {
    dispatch(request());

    applicantsService.getArchived().then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function sortArchived(sort, order) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.sortArchived(sort, order).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function getHired() {
  return (dispatch) => {
    dispatch(request());

    applicantsService.getHired().then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function sortHired(sort, order) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.sortHired(sort, order).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function updateNote(data) {
  return (dispatch) => {
    applicantsService.updateNote(data).then(
      (applicants) => {},
      (error) => {}
    );
  };
}

function updateArchivedNote(data) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.updateNote(data).then(
      (applicants) => dispatch(getArchived()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  // function success(applicants) { return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants } }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function updateHiredNote(data) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.updateNote(data).then(
      (applicants) => dispatch(getHired()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  // function success(applicants) { return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants } }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

// function updateFilterNote(data) {
//     return dispatch => {
//         dispatch(request());
//         debugger
//         applicantsService.updateNote(data)
//             .then(
//                 notes => dispatch(success(notes)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: applicantsConstants.UPDATEFNOTE_REQUEST } }
//     function success(notes) { return { type: applicantsConstants.UPDATEFNOTE_SUCCESS, notes } }
//     function failure(error) { return { type: applicantsConstants.UPDATEFNOTE_FAILURE, error } }
// }

function updateNoteSingle(data, hash) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.updateNote(data, hash).then(
      (applicants) => dispatch(viewDetails(hash)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  // function success(applicants) { return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants } }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function searchApplicants(data) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.searchApplicants(data).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETAPPLN_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.GETAPPLN_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.GETAPPLN_FAILURE, error };
  }
}

function getFilters() {
  return (dispatch) => {
    dispatch(request());

    applicantsService.getFilters().then(
      (filters) => dispatch(success(filters)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.GETFILTER_REQUEST };
  }
  function success(filters) {
    return { type: applicantsConstants.GETFILTER_SUCCESS, filters };
  }
  function failure(error) {
    return { type: applicantsConstants.GETFILTER_FAILURE, error };
  }
}

function filterApplicants(data) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.filterApplicants(data).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.FILTERS_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.FILTERS_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.FILTERS_FAILURE, error };
  }
}

function sortFilterApplicant(data, sort, order) {
  return (dispatch) => {
    dispatch(request());

    applicantsService.sortFilterApplicant(data, sort, order).then(
      (applicants) => dispatch(success(applicants)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: applicantsConstants.FILTERS_REQUEST };
  }
  function success(applicants) {
    return { type: applicantsConstants.FILTERS_SUCCESS, applicants };
  }
  function failure(error) {
    return { type: applicantsConstants.FILTERS_FAILURE, error };
  }
}

function editApplication(data) {
  return (dispatch) => {
    dispatch(request(data));

    applicantsService.editApplication(data).then(
      (application) => dispatch(success(application)),
      (error) => dispatch(failure(error))
    );
  };

  function request(application) {
    return { type: applicantsConstants.EDITAPP_REQUEST, application };
  }
  function success(application) {
    return { type: applicantsConstants.EDITAPP_SUCCESS, application };
  }
  function failure(error) {
    return { type: applicantsConstants.EDITAPP_FAILURE, error };
  }
}

function getApplicantDetails(jobApplicantHash, callback) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      applicantsService.getApplicantDetails(jobApplicantHash).then(
        (res) => {
          dispatch(success(res?.data));
          resolve(res?.data);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: applicantsConstants.GET_APPLICANT_DETAILS_REQUEST };
  }
  function success(data) {
    return { type: applicantsConstants.GET_APPLICANT_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: applicantsConstants.GET_APPLICANT_DETAILS_REQUEST, error };
  }
}

function getJobApplicationHistory(jobApplicantHash, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      applicantsService.getJobApplicationHistory(jobApplicantHash, params).then(
        (res) => {
          dispatch(success(res?.data));
          resolve(res?.data);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: applicantsConstants.GET_JOB_APPLICATION_HISTORY_REQUEST };
  }
  function success(data) {
    return {
      type: applicantsConstants.GET_JOB_APPLICATION_HISTORY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: applicantsConstants.GET_JOB_APPLICATION_HISTORY_ERROR,
      error,
    };
  }
}
