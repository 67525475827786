import { authHeader, config } from '../_helpers';

export const userService = {
  login,
  logout,
  register,
  getAll,
  update,
  getProfile,
  changeStatus,
  lockUserToggle,
  removeEmployee,
  getEmployeeList,
  getAllEmployeeList,
  editProfile,
  resetPassword,
  newPassword,
  assignStaff,
  employeeStats,
  updateEmployeeNote,
  getSingleBulletin,
  deleteBulletinFile,
  getWidgets,
  updateWidgets,
  changePassword,
  getEmployeeListForDocumentFilter,
  importEmployees,
  addEmployeeForBypassing,
  sendDDForm,
  submitDirectDepositAuthorization,
};

function updateWidgets(data) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetch(
    config.apiUrl + '/api/stats/setwidgetsattribute',
    requestOptions
  ).then(handleResponse, handleError);
}
function getWidgets() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/stats/getwidgetslist',
    requestOptions
  ).then(handleResponse, handleError);
}
function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(config.apiUrl + '/api/login', requestOptions)
    .then(handleResponse, handleError)
    .then((res) => {
      // login successful if there's a jwt token in the response
      if (res.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify({ success: res.data }));
      }
      return res;
    });
}

function resetPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { Accept: 'application/json' },
    // headers: { 'Accept': 'application/json' },
    body: data,
  };

  return fetch(config.apiUrl + '/api/password/email', requestOptions).then(
    handleResponse,
    handleError
  );
}

function newPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { Accept: 'application/json' },
    // headers: { 'Accept': 'application/json' },
    body: data,
  };

  return fetch(config.apiUrl + '/api/password/reset', requestOptions).then(
    handleResponse,
    handleError
  );
}

function changePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/updatepassword',
    requestOptions
  ).then(handleResponse, handleError);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(config.apiUrl + '/api/user', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getProfile(hash) {
  // hash should be provide as ?hash=value; f**king idiots
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(config.apiUrl + '/api/user' + hash, requestOptions).then(
    handleResponse,
    handleError
  );
}

function changeStatus(data) {
  const requestOptions = {
    headers: authHeader(),
    method: 'POST',
    body: data,
  };

  return fetch(config.apiUrl + '/api/statusupdate', requestOptions).then(
    handleResponse,
    handleError
  );
}

function lockUserToggle(data) {
  const requestOptions = {
    headers: authHeader(),
    method: 'POST',
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/status ', requestOptions).then(
    handleResponse,
    handleError
  );
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: user,
  };

  return fetch(config.apiUrl + '/api/register', requestOptions).then(
    handleResponse,
    handleError
  );
}

function editProfile(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/updateprofile',
    requestOptions
  ).then(handleResponse, handleError);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(config.apiUrl + '/users/' + user.id, requestOptions).then(
    handleResponse,
    handleError
  );
}

function removeEmployee(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/remove', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getEmployeeList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/getlist', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getAllEmployeeList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/getlist?doc_filter=true',
    requestOptions
  ).then(handleResponse, handleError);
}

function assignStaff(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/assignstaff',
    requestOptions
  ).then(handleResponse, handleError);
}

function employeeStats() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/stats/employee_stats',
    requestOptions
  ).then(handleResponse, handleError);
}

function updateEmployeeNote(data, hash) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/updateemployeetnote',
    requestOptions
  ).then(handleResponse, handleError);
}

function getSingleBulletin(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/bulletin/getsinglebulletin/' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function deleteBulletinFile(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: id,
  };

  return fetch(config.apiUrl + '/api/bulletin/deletefile', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getEmployeeListForDocumentFilter(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/docfilteremployees',
    requestOptions
  ).then(handleResponse, handleError);
}

/**
 * Workaround for employee bypass - import employees
 * @param {*} data
 * @returns promise
 */
function importEmployees(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/system-management/bypass/bulk-import',
    requestOptions
  ).then(handleResponse, handleError);
}

/**
 * Workaround for employee bypass - import employees
 * @param {*} data first name, last name, email, applying company
 * @returns promise
 */
function addEmployeeForBypassing(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/system-management/bypass/employee',
    requestOptions
  ).then(handleResponse, handleError);
}

function sendDDForm(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/direct-deposit-form/send',
    requestOptions
  ).then(handleResponse, handleError);
}

function submitDirectDepositAuthorization(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/direct-deposit-form/submit',
    requestOptions
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  if (response.status == 401) {
    localStorage.removeItem('user');
    window.location.href = '/login';
  } else {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        // return json if it was returned in the response
        var contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          response.json().then((json) => resolve(json));
        } else {
          resolve();
        }
      } else {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    });
  }
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
