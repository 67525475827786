import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import footerLogo from '../assets/images/logo.svg';
import { AlertPopup } from '../_components/AlertPopup';

import { userActions } from '../_actions';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    // this.props.dispatch(userActions.logout());

    this.state = {
      username: '',
      password: '',
      submitted: false,
      alertPop: false,
      redirect: '',
      type: 'password',
      loader: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    // this.componentWillReceiveProps(this.props)
    let user = localStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
      user = user.success;
    }

    if (user && user.type == 'employee') {
      this.setState({ redirect: 'dashboard' });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authentication) {
      if (
        nextProps.authentication.error &&
        !nextProps.authentication.loggedIn
      ) {
        // console.log(JSON.parse(nextProps.authentication.error).error)
        let err = nextProps.authentication.error;
        let alertMsg = '';

        if (typeof err === 'object') {
          alertMsg = err.error;
        } else if (typeof err === 'string') {
          try {
            alertMsg = JSON.parse(err).error;
          } catch (e) {
            alertMsg = 'Unknown error, please contact support team.';
          }
        } else {
          alertMsg = 'Unknown error, please contact support team.';
        }

        this.setState({
          alertPop: true,
          alertHead: 'Error',
          alertMsg,
          alertFunction: '',
        });
      } else {
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      this.setState({ loader: true });
      dispatch(userActions.login(username, password)).then((res) => {
        if (res.success) {
          if (this.props.location?.state?.from) {
            if (res?.data?.type != 'applicant') {
              this.props?.history?.push(this.props.location?.state?.from);
            }
          }
        } else {
          this.setState({
            alertPop: true,
            alertHead: 'Error',
            alertMsg: res.message,
            alertFunction: '',
          });
        }
        this.setState({ loader: false });
      });
      // this.componentWillReceiveProps(this.props)
    }
  }
  togglePassword() {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    });
  }
  render() {
    const { loggingIn } = this.props;
    const { username, password, submitted, loader } = this.state;
    if (this.state.redirect === 'dashboard') {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="loginForm">
        <div className="login_header">
          <img src={footerLogo} alt="NVAM" />
        </div>

        {/*<h2>NVAM Employee Management System</h2>*/}
        <form name="form" onSubmit={this.handleSubmit}>
          <p className="ems-title">Employee Management System</p>
          <div
            className={
              'form-group' + (submitted && !username ? ' has-error' : '')
            }
          >
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={this.handleChange}
            />
            {submitted && !username && (
              <div className="help-block">Username is required</div>
            )}
          </div>
          <div
            className={
              'form-group' + (submitted && !password ? ' has-error' : '')
            }
          >
            <div className="login-label-wrapper">
              <label htmlFor="password">Password</label>
            </div>
            <div className="inline-login-txt-wrapper">
              <input
                type={this.state.type}
                className="form-control"
                name="password"
                value={password}
                onChange={this.handleChange}
              />

              <span
                className="toggle-icon-password"
                onClick={(event) => {
                  this.togglePassword(event);
                }}
                aria-hidden={this.state.type === 'password' ? 'true' : 'false'}
              >
                <img src={require('../assets/images/eye.png').default} />
                <img src={require('../assets/images/eye-hide.png').default} />
              </span>
            </div>
            {submitted && !password && (
              <div className="help-block">Password is required</div>
            )}
          </div>
          <div className="form-group btnGroup loginBtn">
            <button className="btn btn-primary">Login</button>
            {loader && (
              <img
                className="login_loader"
                alt="loader"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}

            {/*<Link to="/register" className="btn btn-link">Register</Link>*/}
          </div>
          <div className="clearFix"></div>
          <hr className="login-sep-line" />
          <div className="loginFormBottomLinks">
            <Link to="/forgot_password">Forgot your password?</Link>
          </div>
        </form>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  const { authentication } = state;
  return {
    loggingIn,
    alert,
    authentication,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
