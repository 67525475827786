import React from 'react';
import { connect } from 'react-redux';
import { config } from '../_helpers';
import { resourceActions } from '../_actions';
import '../assets/styles/resource.css';

class NVAMOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_list: [],
    };
    this.onImageError = this.onImageError.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(resourceActions.getAll('?stats=true'));
    // this.componentWillReceiveProps(this.props)
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }

  componentWillReceiveProps(nextProps) {
    const { resource } = nextProps;
    var resource_list = [];

    if (this.props.resource != resource) {
      if (resource.items) {
        Object.keys(resource.items.data).map((key) => {
          if (key && key != '') {
            resource.items.data[key].map((item, index) => {
              var sup;
              if (item.Superintendent) {
                sup =
                  item.Superintendent.length > 0
                    ? item.Superintendent[0].first_name +
                      ' ' +
                      item.Superintendent[0].last_name
                    : '';
              }
              resource_list.push(
                <div className="crew_item col-2-items" key={item.id}>
                  <section className="top">
                    <div className="prof_pic">
                      <img
                        alt={item.resource_name}
                        src={
                          item.resource_photo
                            ? item.resource_photo
                            : config.apiUrl + '/' + config.altImg
                        }
                        onError={this.onImageError.bind(this)}
                      />
                    </div>
                    <div className="detail_div">
                      {/* remove name to wrap text */}
                      <p className="name">{item.resource_name} </p>
                      <p>{sup ? sup : '-'}</p>
                      <p className="total">
                        {item.avail + item.unavailable + ' Total Crew'}
                      </p>
                    </div>
                  </section>
                  <section className="availability">
                    {/* <span className="available">{item.avail} Available</span>
                                        <span className="unavailable">{item.unavailable} Unavailable</span></p> */}
                    <p>
                      <span></span>
                      {item.avail}
                    </p>
                    <p>
                      <span></span>
                      {item.unavailable}
                    </p>
                    <p>
                      <span></span>
                      {item.locked}
                    </p>
                  </section>
                </div>
              );
            });
          }
        });
        this.setState({ resource_list });
      }
    }
  }

  render() {
    return (
      <div className="employee_stats_outer nvam_overview">
        <div className="inner_head">
          <h2>
            NVAM Overview <span className="drag-icon-controller"></span>
          </h2>
          <span
            className="toggle-controller"
            aria-owns="overview_main_details"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          ></span>
        </div>
        <div
          className="crew_details content_div"
          aria-expanded={this.props.visibility == 1 ? 'true' : 'false'}
          id="overview_main_details"
        >
          <div className="crew_list">{this.state.resource_list}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource } = state;
  return {
    resource,
  };
}

const connectedHomePage = connect(mapStateToProps)(NVAMOverview);
export { connectedHomePage as NVAMOverview };
