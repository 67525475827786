import React, { Component } from 'react';
import './Accordian.scss';
class Accordian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
    this.resizeTimeout = null;
    this.panel = React.createRef();
    this.collapseHandler = this.collapseHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  collapseHandler() {
    this.setState(
      {
        collapsed: !this.state.collapsed,
      },
      () => {
        if (this.props.persistKey)
          localStorage.setItem(
            `accordion_${this.props.persistKey}`,
            this.state.collapsed
          );
      }
    );
  }

  resizeHandler() {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    this.resizeTImeout = setTimeout(() => {
      if (this.state.collapsed) {
        let panel = this.panel.current;
        if (panel) {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      }
    }, 300);
  }

  componentDidUpdate() {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    this.resizeTImeout = setTimeout(() => {
      let panel = this.panel.current;
      if (panel) {
        if (this.state.collapsed) {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        } else {
          panel.style.maxHeight = null;
        }
      }
    }, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);

    let isCollapsedBefore = this.props.persistKey
      ? Boolean(
          localStorage.getItem(`accordion_${this.props.persistKey}`) === 'true'
        )
      : false;

    this.setState({
      collapsed: isCollapsedBefore,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    let { collapsed } = this.state;
    let { title } = this.props;
    return (
      <div className="accordion-wrapper">
        <button
          onClick={this.collapseHandler.bind(this)}
          className={`accordion${collapsed ? ' active' : ''}`}
        >
          <span>{title}</span>
          <span className="toggle-controller" aria-owns="in_v_details"></span>
        </button>
        <div ref={this.panel} className="panel">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Accordian;
