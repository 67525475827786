export const inventoryConstants = {
  PRINTURL_REQUEST: 'INVENTORY_PRINTURL_REQUEST',
  PRINTURL_SUCCESS: 'INVENTORY_PRINTURL_SUCCESS',
  PRINTURL_FAILURE: 'INVENTORY_PRINTURL_FAILURE',

  GETEQP_REQUEST: 'INVENTORY_GETEQP_REQUEST',
  GETEQP_SUCCESS: 'INVENTORY_GETEQP_SUCCESS',
  GETEQP_FAILURE: 'INVENTORY_GETEQP_FAILURE',

  GET_PAGINATED_LIST_REQUEST: 'INVENTORY_GET_PAGINATED_LIST_REQUEST',
  GET_PAGINATED_LIST_SUCCESS: 'INVENTORY_GET_PAGINATED_LIST_SUCCESS',
  GET_PAGINATED_LIST_FAILURE: 'INVENTORY_GET_PAGINATED_LIST_FAILURE',

  EQUPLOAD_REQUEST: 'INVENTORY_EQUPLOAD_REQUEST',
  EQUPLOAD_SUCCESS: 'INVENTORY_EQUPLOAD_SUCCESS',
  EQUPLOAD_FAILURE: 'INVENTORY_EQUPLOAD_FAILURE',

  GETSTATS_REQUEST: 'INVENTORY_GETSTATS_REQUEST',
  GETSTATS_SUCCESS: 'INVENTORY_GETSTATS_SUCCESS',
  GETSTATS_FAILURE: 'INVENTORY_GETSTATS_FAILURE',

  SETINV_REQUEST: 'INVENTORY_SETINV_REQUEST',
  SETINV_SUCCESS: 'INVENTORY_SETINV_SUCCESS',
  SETINV_FAILURE: 'INVENTORY_SETINV_FAILURE',

  GETLIST_REQUEST: 'INVENTORY_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'INVENTORY_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'INVENTORY_GETLIST_FAILURE',

  EQUIP_REQUEST: 'INVENTORY_EQUIP_REQUEST',
  EQUIP_SUCCESS: 'INVENTORY_EQUIP_SUCCESS',
  EQUIP_FAILURE: 'INVENTORY_EQUIP_FAILURE',

  GETUSER_REQUEST: 'INVENTORY_GETUSER_REQUEST',
  GETUSER_SUCCESS: 'INVENTORY_GETUSER_SUCCESS',
  GETUSER_FAILURE: 'INVENTORY_GETUSER_FAILURE',

  ASSIGN_REQUEST: 'INVENTORY_ASSIGN_REQUEST',
  ASSIGN_SUCCESS: 'INVENTORY_ASSIGN_SUCCESS',
  ASSIGN_FAILURE: 'INVENTORY_ASSIGN_FAILURE',

  AVAIL_REQUEST: 'INVENTORY_AVAIL_REQUEST',
  AVAIL_SUCCESS: 'INVENTORY_AVAIL_SUCCESS',
  AVAIL_FAILURE: 'INVENTORY_AVAIL_FAILURE',

  INVENT_REQUEST: 'INVENTORY_INVENT_REQUEST',
  INVENT_SUCCESS: 'INVENTORY_INVENT_SUCCESS',
  INVENT_FAILURE: 'INVENTORY_INVENT_FAILURE',

  DLTINV_REQUEST: 'INVENTORY_DLTINV_REQUEST',
  DLTINV_SUCCESS: 'INVENTORY_DLTINV_SUCCESS',
  DLTINV_FAILURE: 'INVENTORY_DLTINV_FAILURE',

  FORCE_DLTINV_REQUEST: 'INVENTORY_FORCE_DLTINV_REQUEST',
  FORCE_DLTINV_SUCCESS: 'INVENTORY_FORCE_DLTINV_SUCCESS',
  FORCE_DLTINV_FAILURE: 'INVENTORY_FORCE_DLTINV_FAILURE',

  DELETEFILE_REQUEST: 'INVENTORY_DELETEFILE_REQUEST',
  DELETEFILE_SUCCESS: 'INVENTORY_DELETEFILE_SUCCESS',
  DELETEFILE_FAILURE: 'INVENTORY_DELETEFILE_FAILURE',

  ARCHIVED_LIST_REQUEST: 'ARCHIVED_LIST_REQUEST',
  ARCHIVED_LIST_SUCCESS: 'ARCHIVED_LIST_SUCCESS',
  ARCHIVED_LIST_ERROR: 'ARCHIVED_LIST_ERROR',

  UNARCHIVE_EQUIPMENT_REQUEST: 'UNARCHIVE_EQUIPMENT_REQUEST',
  UNARCHIVE_EQUIPMENT_SUCCESS: 'UNARCHIVE_EQUIPMENT_SUCCESS',
  UNARCHIVE_EQUIPMENT_ERROR: 'UNARCHIVE_EQUIPMENT_ERROR',

  DELETE_MILEAGE_FILE_REQUEST: 'DELETE_MILEAGE_FILE_REQUEST',
  DELETE_MILEAGE_FILE_SUCCESS: 'DELETE_MILEAGE_FILE_SUCCESS',
  DELETE_MILEAGE_FILE_ERROR: 'DELETE_MILEAGE_FILE_ERROR',

  GET_INVENTORY_NOTES_REQUEST: 'GET_INVENTORY_NOTES_REQUEST',
  GET_INVENTORY_NOTES_SUCCESS: 'GET_INVENTORY_NOTES_SUCCESS',
  GET_INVENTORY_NOTES_ERROR: 'GET_INVENTORY_NOTES_ERROR',
};
