import { authHeader, config } from '../_helpers';

export const resourceService = {
  getAll,
  getVerificationDetails,
  getAllVehicles,
  searchResource,
  searchDriver,
  deleteVehicle,
  getVehicleDetails,
  getResourceVehicles,
  removeResource,
  employeeList,
  archivedEmployees,
  archivedResources,
  unarchiveEmployee,
  unarchiveResource,
  getResourceTypes,
  getResourceDetails,
  resourcePosition,
  universalSearch,
  employeeEmailList,
  assignStaffList,
  assignStaff,
  getProjects,
  deleteResourceImageById,
  getEmploymentHistory,
};

function getProjects() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    config.apiUrl + '/api/assignment/getincidentproject',
    requestOptions
  ).then(handleResponse, handleError);
}

function getAll(stats) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/resource/getresourcelist' + stats,
    requestOptions
  ).then(handleResponse, handleError);
}

function getResourceTypes() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/getlist/resourcetype',
    requestOptions
  ).then(handleResponse, handleError);
}

function assignStaffList() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/resource/assignstafflist',
    requestOptions
  ).then(handleResponse, handleError);
}

function getVerificationDetails(hash) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/getverificationdetails/' + hash,
    requestOptions
  ).then(handleResponse, handleError);
}

function getAllVehicles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/vehicle/getvehiclelist',
    requestOptions
  ).then(handleResponse, handleError);
}

function searchResource(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/search/resource', requestOptions).then(
    handleResponse,
    handleError
  );
}

function resourcePosition(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/resource/resourceposition',
    requestOptions
  ).then(handleResponse, handleError);
}

function searchDriver(param) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: param,
  };

  return fetch(config.apiUrl + '/api/search/drivers', requestOptions).then(
    handleResponse,
    handleError
  );
}

function deleteVehicle(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/vehicle/delete/' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function getVehicleDetails(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/vehicle/vehicledetails/' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function getResourceVehicles(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/vehicle/getvehiclelist?id=' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function removeResource(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/resource/delete/' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function employeeList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/getlist', requestOptions).then(
    handleResponse,
    handleError
  );
}

function archivedEmployees() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + '/api/getquery/archived_employees',
    requestOptions
  ).then(handleResponse, handleError);
}

function archivedResources(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = '/api/resource/getresourcearchivelist';
  let first = true;

  if (params) {
    Object.keys(params).map((key, index) => {
      if (params && params[key]) {
        if (first) {
          url += `?${key}=${params[key]}`;
          first = false;
        } else {
          url += `&${key}=${params[key]}`;
        }
      }
    });
  }

  return fetch(config.apiUrl + url, requestOptions).then(
    handleResponse,
    handleError
  );
}

function unarchiveEmployee(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/unarchive', requestOptions).then(
    handleResponse,
    handleError
  );
}

function unarchiveResource(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + `/api/resource/unarchive/${id}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function getResourceDetails(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    // body: data
  };

  return fetch(
    config.apiUrl + '/api/resource/getresource/' + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function universalSearch(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/search/universal', requestOptions).then(
    handleResponse,
    handleError
  );
}

function employeeEmailList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/employee/getlist', requestOptions).then(
    handleResponse,
    handleError
  );
}

function assignStaff(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/assignstaff',
    requestOptions
  ).then(handleResponse, handleError);
}

function getEmploymentHistory(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/employmenthistory',
    requestOptions
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}

function deleteResourceImageById(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + '/api/resource/deletefile', requestOptions).then(
    handleResponse,
    handleError
  );
}
