import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './datePicker.css';
const CustomDatePicker = ({ label, error, placeholderText, ...props }) => {
  return (
    <div className="datePickerWrap">
      <label className="control-label">
        {label}
        {label && props?.required && <span class="required-star">*</span>}
      </label>
      <DatePicker
        {...props}
        placeholderText={placeholderText ? placeholderText : 'mm/dd/yyyy'}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        autoComplete="on"
        className={`${error ? 'error' : ''} custom_date_picker`}
      />
    </div>
  );
};

export default CustomDatePicker;

CustomDatePicker.propTypes = {
  error: PropTypes.bool,
};
