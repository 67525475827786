import React from 'react';
import { connect } from 'react-redux';

import { notificationActions } from '../_actions';
import '../assets/styles/notification.css';
import { config } from '../_helpers';
import moment from 'moment';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      new_list_today: [],
      new_list_prev_7: [],
      new_list_prev_30: [],
      new_list_forever: [],
      scroll_new_list_prev_7: [],
      scroll_new_list_prev_30: [],
      scroll_new_list_forever: [],
      scroll_new_list_today: [],
      notification: [],
      current_page: 1,
      last_page: 1,
      keyword: '',
      filter: '',
      noResult: null,
      loader: false,
      scrolled: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handlekeywordFilter = this.handlekeywordFilter.bind(this);
    this.delayTimer = null;
  }

  componentDidMount() {
    this.props.dispatch(
      notificationActions.getByFilter({
        filter: this.state.filter,
        keyword: this.state.keyword,
        page: this.state.current_page,
      })
    );

    window.addEventListener('scroll', this.handleScroll);
    //this.props.dispatch(notificationActions.getAll());
  }

  componentWillReceiveProps(nextProps) {
    const { notification } = nextProps;

    this.setState({ loader: notification.loading });

    if (notification.list) {
      let current_page = parseInt(notification.list.data.current_page);

      //this.setState({ notification })

      this.setState({
        notification: notification.list,
        last_page: notification.list.data.last_page,
        current_page: current_page,
      });

      const data = notification.list.data.data;

      var new_list_prev_7 = [],
        new_list_today = [],
        new_list_prev_30 = [],
        new_list_forever = [],
        link = [],
        noResult = null;

      if (data.old && data.old.length > 0) {
        var notification_link = '';

        data.old.forEach((item, index) => {
          let text = item.data.body.toLowerCase();
          let rehire = text.includes('rehire');
          let onboarding = text.includes('onboarding');

          link = [];

          if (
            item.data.type === 7 ||
            item.data.type === 8 ||
            item.data.type === 9
          ) {
            notification_link = item.data.link.replace(
              '/inventory-view',
              '/inventory/inventory-view'
            );

            link.push(
              <div className="left" key={item.id}>
                <a className="equipment" href={notification_link}>
                  <span className="name">{item.data.name + ' '}</span>
                  {item.data.body}
                </a>
              </div>
            );
          } else {
            if (item.data.type === 6) {
              notification_link =
                config.baseUrl + 'applicant-view/' + item.data.hash;
            } else {
              notification_link = item.data.link;
              if (rehire && item.data.type === 16) {
                notification_link = item.data.link.replace(
                  '?rehire=true',
                  '/employee-re-hire-form?rehire=true'
                );
              } else if (rehire) {
                notification_link = item.data.link.concat(
                  '/employee-re-hire-form?rehire=true'
                );
              }
              onboarding &&
                (notification_link = item.data.link.concat(
                  '/candidate-information-page?rehire=false'
                ));
            }

            link.push(
              <div className="left" key={item.id}>
                <a className="notif_name" href={notification_link}>
                  {item.data.name}
                </a>{' '}
                {item.data.body}
              </div>
            );
          }

          if (item.category === 'TODAY') {
            new_list_today.push(
              <div className="list_item" key={item.id}>
                <div className="basic_info">
                  {link}
                  <div className="right">{item.at}</div>
                </div>
              </div>
            );
          }

          if (item.category === 'PREVIOUS 7 DAYS') {
            new_list_prev_7.push(
              <div className="list_item" key={item.id}>
                <div className="basic_info">
                  {link}
                  <div className="right">
                    {moment
                      .utc(item.created_at)
                      .subtract(8, 'hours')
                      .format('lll')}
                  </div>
                </div>
              </div>
            );
          }

          if (item.category === 'PREVIOUS 30 DAYS') {
            new_list_prev_30.push(
              <div className="list_item" key={item.id}>
                <div className="basic_info">
                  {link}
                  <div className="right">
                    {moment
                      .utc(item.created_at)
                      .subtract(8, 'hours')
                      .format('lll')}
                  </div>
                </div>
              </div>
            );
          }

          if (item.category === 'FOREVER') {
            new_list_forever.push(
              <div className="list_item" key={item.id}>
                <div className="basic_info">
                  {link}
                  <div className="right">
                    {moment
                      .utc(item.created_at)
                      .subtract(8, 'hours')
                      .format('lll')}
                  </div>
                </div>
              </div>
            );
          }
        });

        noResult = null;
      } else {
        noResult = <h2>No notifications</h2>;
      }

      if (current_page !== 1) {
        this.setState({
          noResult: noResult,
          new_list_prev_7: [...this.state.new_list_prev_7, ...new_list_prev_7],
          new_list_prev_30: [
            ...this.state.new_list_prev_30,
            ...new_list_prev_30,
          ],
          new_list_forever: [
            ...this.state.new_list_forever,
            ...new_list_forever,
          ],
          new_list_today: [...this.state.new_list_today, ...new_list_today],
        });
      } else {
        this.setState({
          noResult: noResult,
          new_list_today,
          new_list_prev_7,
          new_list_prev_30,
          new_list_forever,
        });
      }
    }
  }

  handlekeywordFilter(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
        current_page: 1,
      },
      () => {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(
          function () {
            this.props.dispatch(
              notificationActions.getByFilter({
                filter: this.state.filter,
                keyword: this.state.keyword,
                page: this.state.current_page,
              })
            );
          }.bind(this),
          1000
        );
      }
    );
  }

  handleScroll(event) {
    var wrap = document.getElementById('right_sidebar'),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight,
      scroll_new_list_today = [],
      scroll_new_list_prev_7 = [],
      scroll_new_list_prev_30 = [],
      scroll_new_list_forever = [];

    if (y >= contentHeight) {
      let current_page = this.state.current_page;

      if (current_page <= this.state.last_page - 1) {
        this.setState(
          {
            current_page: current_page + 1,
            scrolled: true,
          },
          () => {
            this.props.dispatch(
              notificationActions.getByFilter({
                filter: this.state.filter,
                keyword: this.state.keyword,
                page: this.state.current_page,
              })
            );
          }
        );
      }

      // let data_initial = this.state.notification;
      // if(data_initial.data){
      //     if((data_initial.data.current_page < data_initial.data.last_page) && this.state.flag !== false) {
      //         const requestOptions = {
      //             method: 'GET',
      //             headers: authHeader()
      //         };
      //         fetch(data_initial.data.next_page_url, requestOptions)
      //         .then((res) => {
      //             return res.json() })
      //         .then((data) =>{
      //             this.setState({ notification: data })
      //             if(data.data.current_page === data.data.last_page){
      //                 this.setState({ flag: false})
      //             }

      //             if(data.data.data.old.length > 0){

      //             data.data.data.old.map((item, index) => {
      //                 var link = [], notification_link = "";
      //                 if(item.data.type === 7 || item.data.type === 8 || item.data.type === 9){
      //                     notification_link = item.data.link.replace("/inventory-view", "/inventory/inventory-view")
      //                     link.push(
      //                         <div className="left">
      //                             <a className="equipment" href={notification_link}><span className="name">{item.data.name+" "}</span>{item.data.body}</a>
      //                         </div>
      //                     )
      //                 } else{
      //                     if(item.data.type === 6){
      //                         notification_link = config.baseUrl+"applicant-view/"+item.data.hash
      //                     } else{
      //                         notification_link = item.data.link
      //                     }
      //                     link.push(
      //                         <div className="left">
      //                             <a className="notif_name" href={notification_link}>{item.data.name}</a> {item.data.body}</div>
      //                     )
      //                 }
      //                 if(item.category === "TODAY") {
      //                     scroll_new_list_today.push(
      //                         <div className="list_item" key={data.data.current_page+"-"+index} >
      //                             <div className="basic_info">
      //                                 {link}
      //                                 <div className="right">{item.at}</div>
      //                             </div>
      //                         </div>
      //                     )
      //                 }
      //                 if(item.category === "PREVIOUS 7 DAYS") {
      //                     scroll_new_list_prev_7.push(
      //                         <div className="list_item" key={data.data.current_page+"-"+index} >
      //                             <div className="basic_info">
      //                                 {link}
      //                                 <div className="right">{moment.utc(item.created_at).subtract(8, "hours").format("lll")}</div>
      //                             </div>
      //                         </div>
      //                     )
      //                 }
      //                 if(item.category === "PREVIOUS 30 DAYS") {
      //                     scroll_new_list_prev_30.push(
      //                         <div className="list_item" key={data.data.current_page+"-"+index} >
      //                             <div className="basic_info">
      //                                 {link}
      //                                 <div className="right">{moment.utc(item.created_at).subtract(8, "hours").format("lll")}</div>
      //                             </div>
      //                         </div>
      //                     )
      //                 }
      //                 if(item.category === "FOREVER") {
      //                     scroll_new_list_forever.push(
      //                         <div className="list_item" key={data.data.current_page+"-"+index} >
      //                             <div className="basic_info">
      //                                 {link}
      //                                 <div className="right">{moment.utc(item.created_at).subtract(8, "hours").format("lll")}</div>
      //                             </div>
      //                         </div>
      //                     )
      //                 }
      //             })

      //             this.setState({ scroll_new_list_prev_7:[...this.state.scroll_new_list_prev_7, ...scroll_new_list_prev_7 ],
      //                 scroll_new_list_prev_30:[...this.state.scroll_new_list_prev_30, ...scroll_new_list_prev_30 ],
      //                 scroll_new_list_forever:[...this.state.scroll_new_list_forever, ...scroll_new_list_forever ],
      //                 scroll_new_list_today:[...this.state.scroll_new_list_today, ...scroll_new_list_today ] })

      //             // this.setState({ scroll_new_list_prev_7, scroll_new_list_prev_30, scroll_new_list_forever, scroll_new_list_today })
      //             }

      //     }).catch((err)=>console.log(err))
      //     }
      //}
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { notification } = this.props;
    return (
      <div className="notification_container">
        <h1 className="page_title">Notifications</h1>
        <div className="row">
          <div className="col-md-12">
            <div className="inner_head">
              <div className="loader-wrapper">
                {this.state.loader && (
                  <div
                    className="notfication-loader"
                    style={{
                      backgroundImage:
                        'url(' +
                        require('../assets/images/loader.gif').default +
                        ')',
                    }}
                  ></div>
                )}
              </div>
              <select
                name="filter"
                id=""
                value={this.state.filter}
                onChange={this.handlekeywordFilter}
              >
                <option value="">All</option>
                <option value="1">New Employee</option>
                <option value="2">Employee Available</option>
                <option value="3">Employee Unavailable</option>
                <option value="4">Employee Verification</option>
                <option value="5">Assign User To Resource</option>
                <option value="6">New Application</option>
                <option value="7">New Equipment Add</option>
                <option value="8">Equipment Assigned to Resource</option>
                <option value="9">Equipment Assigned to User</option>
                <option value="10">Resource Assignment Status</option>
                <option value="11">
                  New user submitted the Docs for admin approval
                </option>
                <option value="12">Employee Account is Locked</option>
                <option value="13">Employee Account is Unlocked</option>
                <option value="14">
                  Employee verification Accept and Skip docs
                </option>
                <option value="15">
                  Single Docs submission for the admin approval
                </option>
                <option value="16">
                  User submitted the Rehire Docs for admin approval
                </option>
                <option value="17">Docs approval notification to HR</option>
              </select>
              <input
                name="keyword"
                value={this.state.keyword}
                placeholder="Search..."
                type="text"
                onChange={this.handlekeywordFilter}
              />
            </div>
            <div className="content_div" id="notification_div">
              <div className="col-lg-12" id="applicants_inner">
                <div
                  className="notification_list"
                  id="notification_list"
                  onScroll={this.handleScroll}
                >
                  {/* <div className="appln_list_header">
                                    <div className="list_item_head appln_name">Unique Name</div>
                                </div> */}
                  {this.state.noResult}
                  {(this.state.new_list_today.length > 0 ||
                    this.state.scroll_new_list_today.length > 0) && (
                    <div className="list_header">
                      <h2 className="new">Today</h2>
                    </div>
                  )}
                  {this.state.new_list_today}
                  {this.state.scroll_new_list_today}

                  {(this.state.new_list_prev_7.length > 0 ||
                    this.state.scroll_new_list_prev_7.length > 0) && (
                    <div className="list_header">
                      <h2 className="new">Previous 7 days</h2>
                    </div>
                  )}
                  {this.state.new_list_prev_7}
                  {this.state.scroll_new_list_prev_7}

                  {(this.state.new_list_prev_30.length > 0 ||
                    this.state.scroll_new_list_prev_30.length > 0) && (
                    <div className="list_header">
                      <h2 className="new">Previous 30 days</h2>
                    </div>
                  )}
                  {this.state.new_list_prev_30}
                  {this.state.scroll_new_list_prev_30}

                  {(this.state.new_list_forever.length > 0 ||
                    this.state.scroll_new_list_forever.length > 0) && (
                    <div className="list_header">
                      <h2 className="new">Forever</h2>
                    </div>
                  )}
                  {this.state.new_list_forever}
                  {this.state.scroll_new_list_forever}

                  {/* this.state.applicationItem */}
                </div>
              </div>
            </div>
          </div>
          {this.state.loader && this.state.scrolled && (
            <div
              className="scrollLoader"
              style={{
                backgroundImage:
                  'url(' + require('../assets/images/loader.gif').default + ')',
              }}
            ></div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { notification } = state;
  return {
    notification,
  };
}

const connectedHomePage = connect(mapStateToProps)(Notifications);
export { connectedHomePage as Notifications };
