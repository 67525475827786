export const assignmentStatsConstants = {
  GETASSIGNMENTSTATUSTYPE_REQUEST: 'ASSIGNMENT_GETAPPLNSTATUSTYPE_REQUEST',
  GETASSIGNMENTSTATUSTYPE_SUCCESS: 'ASSIGNMENT_GETAPPLNSTATUSTYPE_SUCCESS',
  GETASSIGNMENTSTATUSTYPE_FAILURE: 'ASSIGNMENT_GETAPPLNSTATUSTYPE_FAILURE',

  GETASSIGNMENTSTATUS_REQUEST: 'ASSIGNMENT_GETAPPLNSTATUS_REQUEST',
  GETASSIGNMENTSTATUS_SUCCESS: 'ASSIGNMENT_GETAPPLNSTATUS_SUCCESS',
  GETASSIGNMENTSTATUS_FAILURE: 'ASSIGNMENT_GETAPPLNSTATUS_FAILURE',

  GETASSIGNMENTHISTORY_REQUEST: 'ASSIGNMENT_GETASSIGNMENTHISTORY_REQUEST',
  GETASSIGNMENTHISTORY_SUCCESS: 'ASSIGNMENT_GETASSIGNMENTHISTORY_SUCCESS',
  GETASSIGNMENTHISTORY_FAILURE: 'ASSIGNMENT_GETASSIGNMENTHISTORY_FAILURE',

  POSTASSIGNMENTSTATUSSAVED_REQUEST:
    'ASSIGNMENT_POSTASSIGNMENTSTATUSSAVED_REQUEST',
  POSTASSIGNMENTSTATUSSAVED_SUCCESS:
    'ASSIGNMENT_POSTASSIGNMENTSTATUSSAVED_SUCCESS',
  POSTASSIGNMENTSTATUSSAVED_FAILURE:
    'ASSIGNMENT_POSTASSIGNMENTSTATUSSAVED_FAILURE',

  GETASSIGNMENTSTATUSGRAPH_REQUEST:
    'ASSIGNMENT_GETASSIGNMENTSTATUSGRAPH_REQUEST',
  GETASSIGNMENTSTATUSGRAPH_SUCCESS:
    'ASSIGNMENT_GETASSIGNMENTSTATUSGRAPH_SUCCESS',
  GETASSIGNMENTSTATUSGRAPH_FAILURE:
    'ASSIGNMENT_GETASSIGNMENTSTATUSGRAPH_FAILURE',

  GETASSIGNMENTSTATUSPROGRESS_REQUEST:
    'ASSIGNMENT_GETASSIGNMENTSTATUSPROGRESS_REQUEST',
  GETASSIGNMENTSTATUSPROGRESS_SUCCESS:
    'ASSIGNMENT_GETASSIGNMENTSTATUSPROGRESS_SUCCESS',
  GETASSIGNMENTSTATUSPROGRESS_FAILURE:
    'ASSIGNMENT_GETASSIGNMENTSTATUSPROGRESS_FAILURE',

  DELETEHISTORY_SUCCESS: 'ASSIGNMENT_DELETEHISTORY_SUCCESS',
  DELETEHISTORY_FAILURE: 'ASSIGNMENT_DELETEHISTORY_FAILURE',
};
//
