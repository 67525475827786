import React from 'react';
import { connect } from 'react-redux';
import '../assets/styles/inventory.css';
// Gallery Integration EMS PHOTOS
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';

import {
  inventoryActions,
  permissionActions,
  resourceActions,
  userActions,
} from '../_actions';
import { config } from '../_helpers';
import SlidingPane from 'react-sliding-pane';
import { AlertPopup } from '../_components/AlertPopup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import ReactModal from 'react-modal';
import moment from 'moment';
import InventoryQuickAssign from '_components/InventoryQuickAssign';
import FileUpload from '_components/FileUpload';
import InventoryNotes from './InventoryNotes';

const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => {
  // some basic styles to make the items look a bit nicer
  return {
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    // background: isDragging ? "#fff" : "#d6d6d6",
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

function LeftArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function RightArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#d6d6d6' : '#d6d6d6',
  padding: 0,
});

class InventoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesImages: [],
      isOpen: false,
      photoIndex: 0,
      mainHtml: '',
      type_list: [],
      equip_type: '',
      subPackages: [],
      conditionList: [],
      imgPreview: '',
      crop: { x: 20, y: 10, width: 150, height: 150 },
      permList: [],
      search_text: '',
      isPaneOpen: false,
      equipment_list: [],
      equip_id: '',
      equip_detail: [],
      EquipmentType: [],
      CurrentCondition: [],
      assigStatus: false,
      crew_view: false,
      nonAdmin: false,
      dragFlag: false,
      not_admin: 'hide',
      memberSearch: 'hide',
      type: '',
      div_filter: '',
      option: 'All',
      invKey: '',
      inv_id: '',
      invType: '',
      alertPop: false,
      inventoryObj: {},
      masterData: '',
      available_list: '',
      _inventory: [],
      _inventory_count: 0,
      _master_list: {
        one: [],
        two: [],
      },
      keyFlag: false,
      specialCateg: false,
      mspa_eq: false,
      license_eq: false,
      gvw_eq: false,
      inventoryOpen: false,
      assignDate: moment(),
      dateSave: false,
      user_hash: '',
      toTeam: false,
      notMaster: false,
      printLoading: false,
      timeout: null,
      emptyMessage: '',
      INVENTORY_VIEW_DATE_FORMAT: '',
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.assignCrew = this.assignCrew.bind(this);
    this.keyUpFnInventory = this.keyUpFnInventory.bind(this);
    this.inventoryOpenModal = this.inventoryOpenModal.bind(this);
    this.inventoryCloseModal = this.inventoryCloseModal.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.sliderImageChange = this.sliderImageChange.bind(this);
    this.closeSidepane = this.closeSidepane.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.setList = this.setList.bind(this);

    // Refs
    this.searchInput = React.createRef();
    this.statusInput = React.createRef();
    this.typeInput = React.createRef();
    this.memberInput = React.createRef();
    this.noteHistoryRef = React.createRef();

    // Return an array with unique values
    !Array.prototype.unique &&
      (Array.prototype.unique = function () {
        var arr = [],
          excludes = [],
          ele = null;
        for (var i = 0; i < this.length; i++) {
          ele = this[i];
          if (excludes.indexOf(ele.id) === -1) {
            arr.push(ele);
            excludes.push(ele.id);
          }
        }
        return arr;
      });
  }

  id2List = {
    masterData: 'masterData',
    available_list: 'available_list',
  };

  sliderImageChange(index) {
    this.setState({ photoIndex: index });
  }

  // Opens model
  inventoryOpenModal() {
    this.setState({ inventoryOpen: true });
  }

  // Close the popup
  inventoryCloseModal() {
    this.setState({ inventoryOpen: false });
  }
  // Set assign date
  handleDateChange(date) {
    this.setState({
      assignDate: date,
    });
  }

  // Set dropbox values
  handleSelect(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
        equipment_list: [],
        dragFlag: true,
      },
      function () {
        this.handleFilter();
      }
    );
  }

  getList = (id) => this.state[this.id2List[id]];

  keyUpFnInventory(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.setState({ invKey: value }, function () {
          this.handleFilter();
        });
      }.bind(this),
      1000
    );
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleFilter() {
    let data = new FormData();
    if (this.state.option !== '') data.append('option', this.state.option);
    if (this.state.type !== '') data.append('type', this.state.type);
    if (this.state.invKey !== '') data.append('keyword', this.state.invKey);
    if (this.state.div_filter !== '')
      data.append('division', this.state.div_filter);

    this.props.setFilter({
      option: this.state.option,
      type: this.state.type,
      keyword: this.state.invKey,
      division: this.state.div_filter,
    });
    this.props.dispatch(inventoryActions.getList(data));
  }

  onDragStart(ev) {
    ev.dataTransfer.setData('text', ev.target.id);
    this.setState({ dragElement: ev.target.getAttribute('data-id') });
  }

  onTouchStart(ev, id, res) {
    this.setState({ touch_id: id, touch_last: res });
  }

  onTouchMove(e) {
    if (e && e.touches) {
      e = e.touches[0];
    }
    this.onDrag(e);
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }
  getImage(path) {
    return config.apiUrl + '/' + (path ? path : 'images/inv_img.jpeg');
  }
  _onerrorImage(e) {
    e.target.setAttribute('src', config.apiUrl + '/images/inv_img.jpeg');
  }
  openDetails() {
    this.toggleDetailsMenu(true);
  }
  closeDetails() {
    this.toggleDetailsMenu(false);
  }
  toggleDetailsMenu(action) {
    var body = document.body,
      cls = 'inv-sidebar-opened';
    !action || action === 'close'
      ? body.classList.remove(cls)
      : body.classList.add(cls);
  }
  showDetails(element) {
    this.setState(
      {
        sidebarItem: {
          name: element['unique_id'],
          meta: this.mapData(element),
        },
      },
      () => {
        this.openDetails();
      }
    );
  }
  mapData(obj) {
    var _map = {
        type: 'Category',
        divison: null,
        subcategory: null,
        unique_id: 'Unique ID',
        resource: null,
        driver: null,
        description: null,
        pic: 'pic',
      },
      res = {};
    for (var key in _map) {
      if (obj.hasOwnProperty(key)) {
        res[key] = {
          label: _map[key],
          value: obj[key],
        };
      }
    }
    return res;
  }

  keyUpFn(event) {
    var value = event.target.value;
    this.props.setSearchKeyword(value);
    if (event.target.name !== 'employee_name') {
      if (value === '') {
        this.setState({
          avail_list: [],
          users_list: [],
          assigStatus: false,
          crew_id: '',
          memberSearch: 'hide',
        });
      }
      clearTimeout(this.state.timeout);
      this.state.timeout = setTimeout(
        function () {
          this.handleSearch(value);
        }.bind(this),
        1000
      );
    } else {
      clearTimeout(this.state.timeout);
      this.state.timeout = setTimeout(
        function () {
          this.handleUserSearch(value);
          this.setState({ dragFlag: true, keyFlag: true });
        }.bind(this),
        1000
      );
    }
  }
  // opening the side-pane
  viewDetails(id, iid, type, itype) {
    if (
      type === 1 || //Vehicle
      type === 5 || //Stump-grinder
      type === 6 || //Fork-lift
      type === 7 || //Tractor/Loaders
      type === 8 || //Trailer/Dump
      type === 9 || //Generator
      type === 10 || // Lift
      type === 14 || //Chipper
      type === 20 || //Trailer/Flat
      type === 21 || //Trailer/Lift
      type === 22 || //Trailer/Stump-Grinder
      type === 25 //Trailer
    ) {
      this.setState({ specialCateg: true });
    }

    if (
      type === 1 || // Vehicle
      type === 5 || // Stump-grinder
      type === 6 || // Fork-lift
      type === 7 || // Tractor/Loader
      type === 8 || // Trailer/Dump
      type === 9 || // Generator
      type === 10 // Lift
    ) {
      this.setState({ mspa_eq: true });
    }

    if (
      type === 1 || // Vehicle
      type === 5 || // Stump-grinder
      type === 6 || // Fork-lift
      type === 7 || // Tractor/Loader
      type === 8 || // Trailer/Dump
      type === 9 || // Generator
      type === 10 || // Lift
      type === 14 // Chipper
    ) {
      this.setState({ license_eq: true });
    }

    if (
      type === 1 || // Vehicle
      type === 5 || // Stump-grinder
      type === 6 || // Fork-lift
      type === 7 || // Tractor/Loader
      type === 8 || // Trailer/Dump
      type === 10 || // Lift
      type === 14 || // Chipper
      type === 25 //Trailer
    ) {
      this.setState({ gvw_eq: true });
    }
    if (
      this.state.permList &&
      this.state.permList.includes('Search/List Equipment')
    ) {
      this.setState(
        {
          isPaneOpen: true,
          equip_id: id,
          inv_id: iid,
          invType: type,
          dragFlag: true,
        },
        function () {
          this.props.dispatch(inventoryActions.equipmentDetails(id));
        }
      );
      this.searchInput.current.blur();
      this.statusInput.current.blur();
      this.typeInput.current.blur();
      this.memberInput.current.blur();
    }
  }

  // close side-pane
  closeSidepane() {
    this.setState({
      isPaneOpen: false,
      specialCateg: false,
      mspa_eq: false,
      license_eq: false,
      gvw_eq: false,
    });
  }
  assignCrew(id, name) {
    this.setState({
      rid: id,
      users_list: [],
      avail_list: [],
      crewMemberData: [],
      assigStatus: true,
      memberSearch: 'show',
      dragFlag: false,
    });
    let data = new FormData();
    data.append('crew_identifier_id', id);
    this.setState({ crew_id: id, crew_name: name });
    this.props.dispatch(inventoryActions.getMemberList(data));
    this.props.dispatch(inventoryActions.getAvailableList(data));
    this.props.setCrewId(id);
  }

  handleSearch(text) {
    let data = new FormData();
    if (text !== '') {
      this.setState({
        search_text: text,
        crew_list_array: [],
        emptyMessage: '',
      });
      data.append('keyword', text);
      this.props.dispatch(resourceActions.searchResource(data)).then((res) => {
        if (res.data && res.data.length === 0) {
          this.setState({
            emptyMessage: 'No crews found.',
          });
        }
      });
    } else {
      this.setState({
        search_text: '',
        crew_list_array: [],
        emptyMessage: '',
      });
    }
  }

  handleUserSearch(text) {
    let data = new FormData();
    data.append('crew_identifier_id', this.state.crew_id);
    if (this.state.assigStatus === true) {
      this.setState({ avail_list: [], users_list: [] });
      if (text !== '') {
        data.append('keyword', text);
        this.props.dispatch(inventoryActions.getMemberList(data));
      } else {
        this.props.dispatch(inventoryActions.getMemberList(data));
      }
      this.props.dispatch(inventoryActions.getAvailableList(data));
    }
  }

  onDragEnd = (result) => {
    this.setState({ dragResult: result });
    const { source, destination } = result;
    if (!source || !destination) return;
    if (!destination) {
      return;
    }
    if (!source || !destination) return;

    var startkey = source.droppableId, // master_one or master_two
      endkey = destination.droppableId, // master_two or master_one
      startindex = source.index, // 1
      endindex = destination.index, // 4
      issame = startkey === endkey,
      _master = ['master_one', 'master_two'];

    if (!this.state.dateSave && _master.indexOf(endkey) === -1) {
      this.setState({ inventoryOpen: true });
    } else {
      if (issame) {
        return;
      }
      var slice = null;
      if (_master.indexOf(startkey) > -1 && _master.indexOf(endkey) > -1)
        return;
      /* Moving inventory...
            Inventory moved from master section */

      if (_master.indexOf(startkey) > -1) {
        var key = 'master_two' === startkey ? 'two' : 'one',
          arr = this.state._master_list;
        try {
          slice = arr[key].splice(startindex, 1);
          slice = slice[0];
          var mainarr = this.merge2Array(arr.one || [], arr.two || []);
          this.splitArrayTo2(mainarr);
        } catch (err) {}
      } else if ('available_list' === startkey) {
        /* Inventory moved from available section */
        var arr = this.state.available_list || [];
        slice = arr.splice(startindex, 1);
        slice = slice[0];
        this.setState({
          available_list: arr,
        });
      } else {
        /* Inventory moved from team member's section */
        /*Obj*/
        var obj = Object.assign({}, this.state.inventoryObj);
        try {
          slice = obj[startkey]['inventory'].splice(startindex, 1);
          slice = slice[0];
          this.setState({
            inventoryObj: obj,
          });
        } catch (err) {}
      }

      /*Move drag element to target master section */
      if (slice && _master.indexOf(endkey) > -1) {
        this.setState({ inventoryOpen: false });
        slice.status = 1;
        if (this.hasFilter()) {
          this.setState({
            alertPop: true,
            alertHead: '',
            alertMsg:
              'Inventory Master list is in filter state , Equipment has been moved to inventory master successfully.',
            alertFunction: '',
          });
        } else {
          var arr = Object.assign({}, this.state._master_list);
          try {
            if ('master_one' === endkey) {
              arr['one'].splice(endindex, 0, slice);
            } else {
              arr['two'].splice(endindex, 0, slice);
            }
            this.splitArrayTo2(this.merge2Array(arr.one || [], arr.two || []));
          } catch (err) {}
        }
      } else if (slice && 'available_list' === endkey) {
        /** Moved into available section */
        var list = this.state.available_list;
        list.splice(endindex, 0, slice);
        this.setState({
          available_list: list,
        });
      } else {
        /** Moved into team member's section */
        if (slice) {
          var obj = Object.assign({}, this.state.inventoryObj);
          try {
            obj[endkey]['inventory'].splice(endindex, 0, slice);
            this.setState({
              inventoryObj: obj,
            });
          } catch (err) {}
        }
      }

      var dragElement, hash;

      if (_master.indexOf(startkey) > -1) {
        dragElement = slice.id;
      } else if (source.droppableId === 'available_list') {
        dragElement = parseInt(result.draggableId.replace('avail', ''));
      } else {
        dragElement = result.draggableId;
      }
      if (
        _master.indexOf(endkey) === -1 &&
        destination.droppableId !== 'available_list'
      ) {
        hash = destination.droppableId.substring(
          0,
          destination.droppableId.indexOf('_')
        );
      }
      this.setState({ dragElement: dragElement, dragFlag: true });

      let data = new FormData();
      data.append('id', dragElement);
      if (_master.indexOf(endkey) === -1) {
        data.append('rid', this.state.rid);
        data.append('assign_date', this.state.assignDate);
      }
      if (
        _master.indexOf(endkey) === -1 &&
        destination.droppableId !== 'available_list'
      ) {
        data.append('hash', hash);
      }

      this.props.dispatch(inventoryActions.assignEquipment(data));

      setTimeout(() => {
        this.handleFilter();
      }, 1200);

      return;
    }
  };
  saveDate() {
    this.setState({ inventoryOpen: false, dateSave: true }, function () {
      this.onDragEnd(this.state.dragResult);
    });
  }
  generateCategorwise(data) {
    var obj = {};
    data &&
      data.length &&
      data.map((element) => {
        var id = element.hasOwnProperty('equipment_type_id')
          ? element.equipment_type_id.toString()
          : null;
        if (!id) return;
        if (!obj.hasOwnProperty(id)) {
          obj[id] = {
            label: element.type || '',
            id: id,
            items: [],
          };
        }
        obj[id]['items'].push(element);
      });
    this.setState({
      categoryList: obj,
    });
  }

  getGalleryImages() {
    var pics = [];
    if (
      !this.state.equip_detail ||
      !this.state.equip_detail.equipment_files ||
      !this.state.equip_detail.equipment_files.length
    ) {
      pics = [];
    } else {
      var files = this.state.equip_detail.equipment_files;
      files.forEach((item) => {
        pics.push({
          thumb: config.s3BucketUrl + item.file_hash,
          orginalImage: config.s3BucketUrl + item.file_original_hash,
        });
      });
    }

    this.setState({
      resourcesImages: pics,
      photoIndex: 0,
    });
  }

  hasFilter() {
    var ele = document.querySelectorAll('[data-filter]'),
      len = ele.length;
    for (var i = 0; i < len; i++) {
      var val = (ele[i].value || '').trim();
      if (ele[i].nodeName.toLowerCase() === 'select') {
        if (val && 'All' !== val) return !0;
      } else {
        if (val) return !0;
      }
    }
  }

  toggleGallery() {
    var status = this.state.isOpen;
    this.setState({
      isOpen: status ? false : true,
    });
  }

  editInventory = (inventory_id, equipment_id) => {
    this.setState(
      {
        isPaneOpen: false,
        specialCateg: false,
        mspa_eq: false,
        license_eq: false,
        gvw_eq: false,
      },
      () => this.props.editInventory(inventory_id, equipment_id)
    );
  };

  componentDidMount() {
    this?._componentDidMount();
  }

  _componentDidMount() {
    this.props.dispatch(userActions.getAll());
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getListEquipments());
    this?.getAllList();
  }

  getAllList() {
    let dataOption = new FormData();
    dataOption.append('option', 'All');
    this.props.dispatch(inventoryActions.getList(dataOption));
  }

  merge2Array = function (array1, array2) {
    if (!Array.isArray(array1) || !Array.isArray(array2)) return [];
    var length2 = array2.length,
      start = 0,
      pos = 1;
    for (; start < length2; start++) {
      array1.splice(pos, 0, array2[start]);
      pos += 2;
    }
    return array1;
  };

  splitArrayTo2(list) {
    var one = [],
      two = [],
      list = list || [],
      length = list.length;
    for (var i = 0; i < length; i++) {
      var obj = list[i];
      (i + 1) % 2 == 0 ? two.push(obj) : one.push(obj);
    }
    this.setState({
      _master_list: {
        one: one,
        two: two,
      },
    });
  }

  loadMore(list, reset) {
    const increment = 50;
    const master_list =
      this.state._master_list.one.length + this.state._master_list.two.length;
    const new_list = list.slice(0, master_list + increment);
    this.splitArrayTo2(new_list);
  }

  // Get full list of inventories
  setList(list) {
    this.setState(
      { _inventory: list },
      () => {
        const master_list =
          this.state._master_list.one.length +
          this.state._master_list.two.length;
        const new_list = list.slice(
          0,
          master_list > list.length ? master_list : 50
        );
        this.splitArrayTo2(new_list);
      }
      // this.state._master_list.one.length + this.state._master_list.two.length === 0 &&
      // this.loadMore(this.state._inventory),
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      equipments,
      list,
      equipList,
      available,
      assign,
      printLoading,
      printUrl,
    } = nextProps.inventory;

    const { permission, user } = nextProps;
    const crewMember = nextProps.inventory.users;

    this.setState({
      INVENTORY_VIEW_DATE_FORMAT:
        user?.items?.data?.date_formats?.INVENTORY_VIEW_DATE_FORMAT,
    });

    var type_list = [],
      division_list = [],
      subPackages = [],
      conditionList = [],
      equip_detail = [],
      obj,
      crew_list_array = [];
    this.setState({ printLoading });
    if (printUrl) {
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        window.open(printUrl);
      } else {
        window.open(printUrl, '_blank');
      }

      nextProps.inventory.printUrl = null;
      nextProps.inventory.printLoading = false;
    }

    if (!this.state.dragFlag || this.state.keyFlag) {
      obj = {};
    } else obj = Object.assign({}, this.state.inventoryObj);

    if (available && this.state.assigStatus) {
      this.setState({ available_list: available.data });
    }
    if (crewMember) {
      if (crewMember && crewMember.data && crewMember.data.length) {
        crewMember.data.forEach((item, index) => {
          obj[item.hash + '_' + item.id] = item;
        });
      } else {
        obj = {};
      }

      this.setState({
        inventoryObj: obj,
      });
    }

    if (assign) {
      if (assign.success) {
        this.setState({ dateSave: false, inventoryOpen: false });
        clearTimeout(this.state.timeout_assign);
        this.state.timeout_assign = setTimeout(
          function () {
            let data = new FormData();
            data.append('crew_identifier_id', this.state.crew_id);
            this.props.dispatch(inventoryActions.getMemberList(data));
            this.props.dispatch(inventoryActions.getAvailableList(data));
          }.bind(this),
          1000
        );
      }
    }

    if (user.items) {
      var user_type = user.items.data.user_type;
      this.setState({ user_type, search_crew: user.items.data.crew_name });

      //(user.items.data.user_type_id !== 1 && user.items.data.user_type_id !== 2 && user.items.data.user_type_id !== 3)
      if (
        user.items.data.is_admin === 0 &&
        user.items.data.designation !== 'Division Manager' &&
        user.items.data.designation !== 'Operations Manager' &&
        user.items.data.designation !== 'Arborist/Estimator' &&
        user.items.data.designation !== 'Crew Lead' &&
        user.items.data.designation !== 'Climber'
      ) {
        if (this.state.crew_view === false) {
          this.setState({
            crew_view: true,
            nonAdmin: true,
            not_admin: 'hide',
            inv_crew_id: user.items.data.crew_identifier_id,
            inv_crew_name: user.items.data.crew_name,
          });
          let data = new FormData();
          data.append('keyword', user.items.data.crew_name);
          this.props.dispatch(resourceActions.searchResource(data));
        }
      } else {
        this.setState({ not_admin: 'show' });
      }
    }
    if (equipList) {
      if (equipList.data) {
        this.setState({ equip_detail: equipList.data }, () => {
          this.getGalleryImages();
        });
      }
    }
    if (list) {
      if (list && list.data && list.data.length) {
        // if (this.state._inventory.length !== nextProps.inventory.list.data.length) {
        /*Update master list data*/
        var data = list.data;
        // this.splitArrayTo2(data);
        // Initial Call
        this.setList(data);
        this.generateCategorwise(data);
        // }
      } else {
        if (list && list.data && !list.data.length) {
          /*List is empty*/
          this.setState({
            _master_list: {
              one: [],
              two: [],
            },
          });
        }
      }
    }
    if (equipments) {
      equipments.EquipmentType.forEach((item, index) => {
        type_list.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>
        );
      });
      equipments.SubPackages.forEach((item, index) => {
        subPackages.push(<option>{item.value}</option>);
      });
      equipments.CurrentCondition.forEach((item, index) => {
        conditionList.push(<option>{item.value}</option>);
      });
      if (equipments.Division) {
        equipments.Division.map((item, index) => {
          division_list.push(
            <option key={index} value={item.id}>
              {item.value}
            </option>
          );
          return division_list;
        });
      }
      this.setState({
        divisionList: equipments.Division,
        type_list,
        division_list,
        subPackages,
        conditionList,
        EquipmentType: equipments.EquipmentType,
        CurrentCondition: equipments.CurrentCondition,
        allEquipItems: equipments,
      });
    }

    const { resource } = nextProps;

    if (resource.items) {
      const { data } = resource.items;
      let crew_list_array = [];
      var inlineStyle;
      if (data.AdminStaff) {
        this.setState({ adminList: resource.items.data.AdminStaff });
      }

      let getCrews = (item, index) => {
        inlineStyle = {
          background: 'url(' + item.resource_photo + ')',
        };
        crew_list_array.push(
          <div className="resource_item" key={item.resource_name + Date.now()}>
            <img
              alt={item.resource_name}
              src={item.resource_photo}
              style={inlineStyle}
              onError={this.onImageError.bind(this)}
            />
            <p>{item.resource_name}</p>
            <button
              className="btn-assign button"
              onClick={
                this.state.crew_id !== item.id
                  ? this.assignCrew.bind(this, item.id, item.resource_name)
                  : () => {}
              }
            >
              Assign
            </button>
          </div>
        );
        if (this.state.nonAdmin === true) {
          this.setState({ nonAdmin: false });
          this.assignCrew(item.id, item.resource_name);
        }
      };

      if (data.AboutTrees) {
        data.AboutTrees.forEach(getCrews);
      }
      if (data.AdminStaff) {
        data.AdminStaff.forEach(getCrews);
      }
      if (data.NVTS) {
        data.NVTS.forEach(getCrews);
      }

      this.setState({
        aboutTreesList: resource.items.data.AboutTrees,
        crew_list_array: crew_list_array,
      });
    }
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({ permList });
    }

    if (this.state.nonAdmin === true) {
      this.setState({ nonAdmin: false, ...this.props.inventory_state });
      this.assignCrew(this.state.inv_crew_id, this.state.inv_crew_name);
    }
  }

  componentWillUnmount() {
    this.props.setInventoryState(this.state);
  }

  handleAssignModal() {
    this.setState((state) => ({ showAssignModal: !state?.showAssignModal }));
  }

  onAssignComplete() {
    this?._componentDidMount();
    this.props.dispatch(
      inventoryActions.equipmentDetails(this?.state?.equip_id)
    );
  }

  render() {
    var pic,
      picClass,
      divisionValue,
      engineCert,
      subCateg,
      mkModel = '';

    let loadMore =
      this.state._inventory.length > 50 &&
      this.state._master_list.one.length + this.state._master_list.two.length <
        this.state._inventory.length;

    if (this.state.equip_detail.type_id === 1) {
      mkModel = this.state.equip_detail.vehicle_model_or_make;
    } else {
      mkModel = this.state.equip_detail.model_or_make;
    }
    if (this.state.divisionList) {
      this.state.divisionList.forEach((item, index) => {
        if (item.id === this.state.equip_detail.division_id) {
          divisionValue = item.value;
        }
      });
    }
    if (this.state.allEquipItems) {
      if (this.state.allEquipItems.EngineCertification) {
        this.state.allEquipItems.EngineCertification.forEach((item, index) => {
          if (
            item.id === parseInt(this.state.equip_detail.engine_certification)
          ) {
            engineCert = item.value;
          }
        });
      }
      if (this.state.allEquipItems) {
        this.state.allEquipItems.SubPackages.forEach((item, index) => {
          if (item.id === this.state.equip_detail.subpackage_id) {
            subCateg = item.value;
          }
        });
      }
    }
    if (this.state.equip_detail) {
      if (
        this.state.equip_detail.pic !== null &&
        this.state.equip_detail.pic !== ''
      ) {
        pic = {
          background:
            'url(' +
            config.apiUrl +
            '/' +
            this.state.equip_detail.pic +
            ') no-repeat',
          backgroundSize: '47px',
          backgroundPosition: '50% 50%',
        };
        picClass = '';
      } else {
        pic = {
          background:
            'url(' + config.apiUrl + '/' + config.altInvImg + ') no-repeat',
          backgroundSize: '70px',
          backgroundPosition: '50% 50%',
        };
        picClass = 'alternative';
      }
    }
    var onlyMiddle = '';
    if (
      this.state.permList &&
      this.state.permList.includes('Assign Inventory') &&
      !this.state.permList.includes('Add/Edit/Archive Equipments')
    ) {
      onlyMiddle = 'onlyMiddle';
    }
    var i = '100m',
      j = '500a';
    return (
      <div
        className={
          this.state.not_admin === 'hide'
            ? 'equipment_container notAdmin'
            : 'equipment_container'
        }
      >
        <div className="page_title float">
          <h1>Inventory</h1>
          {this.state.permList &&
            this.state.permList.includes('Add/Edit/Archive Equipments') && (
              <div className="btn_list">
                <FileUpload
                  onComplete={() => {
                    this?.getAllList();
                  }}
                  className="button add_equip"
                />
                <div
                  className="button add_equip"
                  onClick={this.props.archivedEquipments}
                >
                  Archived Equipments
                </div>
                <div className="button add_equip" onClick={this.props.addEquip}>
                  + Add Equipment
                </div>
              </div>
            )}
        </div>

        <div className="inv-desktop-wrapper">
          <div>
            {this.state.permList &&
              this.state.permList.includes('Assign Inventory') && (
                <div className="top_option">
                  {/* Left Side Filters */}
                  <div className={'inputField ' + this.state.not_admin}>
                    {/* Search */}
                    <input
                      data-filter
                      type="text"
                      ref={this.searchInput}
                      name="invenoryKeyword"
                      onKeyUp={this.keyUpFnInventory.bind(this)}
                      placeholder="Search..."
                      autoComplete="off"
                    />
                    {/* Division */}
                    <select
                      ref={this.statusInput}
                      data-filter
                      name="div_filter"
                      id="divfilter"
                      value={this.state.div_filter}
                      className="small_input"
                      onChange={this.handleSelect}
                    >
                      <option value=""> - Division - </option>
                      {this.state.division_list}
                    </select>
                    {/* Status */}
                    <select
                      ref={this.statusInput}
                      data-filter
                      name="option"
                      id="typefilter"
                      value={this.state.option}
                      className="small_input invHalf left"
                      onChange={this.handleSelect}
                    >
                      <option value="All">All</option>
                      <option value="Assigned">Assigned</option>
                      <option value="Unassigned">Unassigned</option>
                    </select>
                    {/* Type */}
                    <select
                      ref={this.typeInput}
                      data-filter
                      name="type"
                      value={this.state.type}
                      className="small_input invHalf right"
                      onChange={this.handleSelect}
                    >
                      <option value=""> - Equipment Type - </option>
                      {this.state.type_list}
                    </select>
                  </div>

                  {/* Right Side Filters */}
                  {this.state.permList &&
                    this.state.permList.includes('Assign Inventory') && (
                      <div className="inputField">
                        {/* Crew */}
                        <input
                          ref={this.memberInput}
                          type="text"
                          name="employee_name"
                          className={this.state.memberSearch}
                          onKeyUp={this.keyUpFn.bind(this)}
                          placeholder="Type member name..."
                          autoComplete="off"
                        />
                      </div>
                    )}
                  <div className={'inputField ' + this.state.not_admin}>
                    <input
                      type="text"
                      name="keyword"
                      onKeyUp={this.keyUpFn.bind(this)}
                      placeholder="Type crew name..."
                      autoComplete="off"
                    />
                  </div>
                </div>
              )}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="inv_detail_outer">
                {this.state.permList &&
                  this.state.permList.includes('Search/List Equipment') && (
                    <div className="master_section inv_section in_section">
                      <div className="head_sec">Master</div>
                      <div
                        id="master_section_outer"
                        className="master-list-outer"
                      >
                        <div
                          className="is-list list-one"
                          style={{ paddingRight: 4 }}
                        >
                          {/* First List */}
                          <Droppable
                            key={'master_one_drop'}
                            droppableId={'master_one'}
                          >
                            {(_provided, snapshot) => (
                              <div
                                className="drop-zone-vertical"
                                ref={_provided.innerRef}
                              >
                                {this.state._master_list &&
                                  this.state._master_list.one &&
                                  this.state._master_list.one.map(
                                    (ele, index) => (
                                      <Draggable
                                        key={'draggable_' + index}
                                        draggableId={'list_one_' + index}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="outer_drag drag-box"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div
                                              className="inventory_element inventory_element_extended"
                                              onClick={this.viewDetails.bind(
                                                this,
                                                ele.id,
                                                ele.equipment_id,
                                                ele.equipment_type_id
                                              )}
                                            >
                                              <div
                                                className={
                                                  'rgtImage innerDrag status-' +
                                                  ele.status
                                                }
                                              >
                                                {(() => {
                                                  var image = '',
                                                    inlinestyle = {};
                                                  if (ele && ele.pic) {
                                                    inlinestyle = {
                                                      background:
                                                        'url(' +
                                                        config.s3BucketUrl +
                                                        ele.pic +
                                                        ') no-repeat',
                                                      backgroundSize: '40px',
                                                      backgroundPosition:
                                                        '50% 50%',
                                                    };
                                                  } else {
                                                    inlinestyle = {
                                                      background:
                                                        'url(' +
                                                        config.apiUrl +
                                                        '/' +
                                                        config.altInvImg +
                                                        ') no-repeat',
                                                      backgroundSize: '70px',
                                                      backgroundPosition:
                                                        '50% 50%',
                                                    };
                                                    image = 'alternative';
                                                  }
                                                  return (
                                                    <div className="invImageContainer">
                                                      <div
                                                        className={
                                                          'invImg ' + image
                                                        }
                                                        style={inlinestyle}
                                                      />
                                                    </div>
                                                  );
                                                })()}

                                                <div className="inv_field_type">
                                                  <div className="rightToImg">
                                                    {/* <label>Unique ID</label> */}
                                                    <span>{ele.unique_id}</span>
                                                  </div>
                                                  <label>Type</label>
                                                  <span>{ele.type}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                {_provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {/* End of First List */}
                        </div>

                        {/* Second List */}
                        <div
                          className="is-list list-two"
                          style={{ paddingLeft: 4 }}
                        >
                          <Droppable
                            key={'master_two_drop'}
                            droppableId={'master_two'}
                          >
                            {(_provided, snapshot) => (
                              <div
                                className="drop-zone-vertical"
                                ref={_provided.innerRef}
                              >
                                {this.state._master_list &&
                                  this.state._master_list.two &&
                                  this.state._master_list.two.map(
                                    (ele, index) => (
                                      <Draggable
                                        key={'draggable_' + index}
                                        draggableId={'list_two_' + index}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="outer_drag drag-box"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div
                                              className="inventory_element inventory_element_extended"
                                              onClick={this.viewDetails.bind(
                                                this,
                                                ele.id,
                                                ele.equipment_id,
                                                ele.equipment_type_id
                                              )}
                                            >
                                              <div
                                                className={
                                                  'rgtImage innerDrag status-' +
                                                  ele.status
                                                }
                                              >
                                                {(() => {
                                                  var image = '',
                                                    inlinestyle = {};
                                                  if (ele && ele.pic) {
                                                    inlinestyle = {
                                                      background:
                                                        'url(' +
                                                        config.s3BucketUrl +
                                                        ele.pic +
                                                        ') no-repeat',
                                                      backgroundSize: '40px',
                                                      backgroundPosition:
                                                        '50% 50%',
                                                    };
                                                  } else {
                                                    inlinestyle = {
                                                      background:
                                                        'url(' +
                                                        config.apiUrl +
                                                        '/' +
                                                        config.altInvImg +
                                                        ') no-repeat',
                                                      backgroundSize: '70px',
                                                      backgroundPosition:
                                                        '50% 50%',
                                                    };
                                                    image = 'alternative';
                                                  }
                                                  return (
                                                    <div className="invImageContainer">
                                                      <div
                                                        className={
                                                          'invImg ' + image
                                                        }
                                                        style={inlinestyle}
                                                      />
                                                    </div>
                                                  );
                                                })()}

                                                <div className="inv_field_type">
                                                  <div className="rightToImg">
                                                    <span>{ele.unique_id}</span>
                                                  </div>
                                                  <label>Type</label>
                                                  <span>{ele.type}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                {_provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </div>
                      {loadMore && (
                        <button
                          className="button head_sec"
                          onClick={() => this.loadMore(this.state._inventory)}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  )}
                {this.state.permList &&
                  this.state.permList.includes('Assign Inventory') && (
                    <div className={'in_section user_area ' + onlyMiddle}>
                      {this.state.assigStatus && (
                        <div className="available_section inv_section">
                          <div className="head_sec">Available</div>
                          <Droppable droppableId="available_list">
                            {(provided, snapshot) => (
                              <div
                                className="avail_equip"
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {this.state.available_list &&
                                  this.state.available_list
                                    .unique()
                                    .map((item, index) => (
                                      <Draggable
                                        key={j + item.id}
                                        draggableId={item.id + 'avail'}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="inventory_element drag-box"
                                            onClick={this.viewDetails.bind(
                                              this,
                                              item.id,
                                              item.equipment_id,
                                              item.equipment_type_id
                                            )}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div className="rgtImage innerDrag">
                                              <div className="invImageContainer">
                                                <div
                                                  className={
                                                    item.pic !== null &&
                                                    item.pic !== ''
                                                      ? 'invImg noPicMaster'
                                                      : 'invImg withPicMaster'
                                                  }
                                                  style={{
                                                    background:
                                                      item.pic !== null &&
                                                      item.pic !== ''
                                                        ? 'url(' +
                                                          config.s3BucketUrl +
                                                          item.pic +
                                                          ') no-repeat'
                                                        : 'url(' +
                                                          config.apiUrl +
                                                          '/' +
                                                          config.altInvImg +
                                                          ') 50% 50% / 70px no-repeat',
                                                  }}
                                                />
                                              </div>
                                              <div className="inv_field_type">
                                                <div className="rightToImg">
                                                  <span>{item.unique_id}</span>
                                                </div>
                                                <label>Type</label>
                                                <span>{item.type}</span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                      {this.state.assigStatus && (
                        <div className="team_section inv_section">
                          <div className="head_sec">Team Members</div>
                          <div className="crewName">{this.state.crew_name}</div>
                          <div>
                            {this.state.inventoryObj &&
                              Object.keys(this.state.inventoryObj).map(
                                (ele, index) => {
                                  var group = this.state.inventoryObj[ele];
                                  return (
                                    <div key={index} className="team_member">
                                      <div
                                        className="user_pic left"
                                        onError={this.onImageError.bind(this)}
                                      >
                                        <img
                                          alt={
                                            group['first_name'] +
                                            ' ' +
                                            group['last_name']
                                          }
                                          src={
                                            config.apiUrl +
                                            '/' +
                                            group['profile_photo']
                                          }
                                          onError={this.onImageError.bind(this)}
                                        />
                                      </div>
                                      <div className="user_pic_right">
                                        <p className="name">
                                          {group['first_name'] +
                                            ' ' +
                                            group['last_name']}
                                        </p>
                                        <p className="type">
                                          {group['user_type']}
                                        </p>
                                      </div>

                                      <Droppable droppableId={ele}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                              snapshot.isDraggingOver
                                            )}
                                            className="inventory_list"
                                            id={'team_member' + index}
                                            data-boxtype="non-element"
                                          >
                                            {group['inventory']
                                              .unique()
                                              .map((inv, invIndex) => (
                                                <Draggable
                                                  className="drag-box"
                                                  key={inv.id}
                                                  draggableId={inv.id}
                                                  index={invIndex}
                                                >
                                                  {(provided, snapshot) => (
                                                    <div
                                                      className="drag-box"
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps
                                                          .style
                                                      )}
                                                    >
                                                      <div
                                                        className="inventory_element"
                                                        data-id={inv.id}
                                                        id={
                                                          'inventory_element' +
                                                          inv.id
                                                        }
                                                        onDragStart={(e) =>
                                                          this.onDragStart(e)
                                                        }
                                                        data-appendto="non-element"
                                                        onClick={this.viewDetails.bind(
                                                          this,
                                                          inv.id,
                                                          inv.equipment_id,
                                                          inv.equipment_type_id,
                                                          inv.type_id
                                                        )}
                                                        draggable
                                                      >
                                                        <div className="rgtImage innerDrag">
                                                          <div className="invImageContainer">
                                                            <div
                                                              className={
                                                                inv.pic !==
                                                                  null &&
                                                                inv.pic !== ''
                                                                  ? 'invImg noPicMaster'
                                                                  : 'invImg withPicMaster'
                                                              }
                                                              style={{
                                                                background:
                                                                  inv.pic !==
                                                                    null &&
                                                                  inv.pic !== ''
                                                                    ? 'url(' +
                                                                      config.s3BucketUrl +
                                                                      inv.pic +
                                                                      ') no-repeat'
                                                                    : 'url(' +
                                                                      config.apiUrl +
                                                                      '/' +
                                                                      config.altInvImg +
                                                                      ') 50% 50% / 70px no-repeat',
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="inv_field_type">
                                                            <div className="rightToImg">
                                                              <span>
                                                                {inv.unique_id}
                                                              </span>
                                                            </div>
                                                            <label>Type</label>
                                                            <span>
                                                              {inv.type}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                {this.state.permList &&
                  this.state.permList.includes('Assign Inventory') && (
                    <div
                      className={
                        'inv_crew_list in_section ' + this.state.not_admin
                      }
                    >
                      <div
                        className={
                          'list-right ' +
                          ((this.state.crew_list_array || []).length % 2 === 0
                            ? ''
                            : 'align-last-child')
                        }
                      >
                        {this.state.search_text !== '' ? (
                          this.state.crew_list_array &&
                          this.state.crew_list_array.length > 0 ? (
                            this.state.crew_list_array
                          ) : (
                            <p className="epmty_crew">
                              {this.state.emptyMessage}
                            </p>
                          )
                        ) : (
                          <p className="epmty_crew">
                            Type a name above to add a new crew.
                          </p>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </DragDropContext>
          </div>
        </div>
        {/** Desktop wrapper end top
              MOBILE WRAPPER STARTS BOTTOM */}

        <div className="inv-mobile-wrapper">
          {/*New DESIGN STARTS HERE */}

          {/*NEW DESIGN ENDS HERE*/}
        </div>
        <SlidingPane
          className="sliding_inventory"
          overlayClassName="sliding_inventory_overlay"
          isOpen={this.state.isPaneOpen}
          closeIcon={<div></div>}
          onRequestClose={this.closeSidepane}
        >
          {/* Slider from Right */}
          <div className="sliding_content">
            {this.state.equip_detail && (
              <div>
                <div className="overflow-row">
                  <div className="inventory-slider-top border-1">
                    <div className="inventory-slidebar-image">
                      <div className="image-box">
                        {this.state.resourcesImages &&
                          this.state.resourcesImages.length > 0 && (
                            <div
                              className="main-image-top"
                              style={{
                                backgroundImage:
                                  'url(' +
                                  this.state.resourcesImages[
                                    this.state.photoIndex
                                  ].thumb +
                                  ')',
                              }}
                              onClick={() => {
                                this.toggleGallery();
                              }}
                            >
                              {/* <img src={this.state.resourcesImages[this.state.photoIndex]} onClick={() => {this.toggleGallery();}} /> */}
                            </div>
                          )}
                      </div>
                      <div className="inventory-select-slider">
                        <Slider
                          {...{
                            infinite: true,
                            speed: 500,
                            prevArrow: <LeftArrow />,
                            nextArrow: <RightArrow />,
                            slidesToShow:
                              this.state.resourcesImages.length > 3
                                ? 3
                                : this.state.resourcesImages.length,
                            slidesToScroll: 1,
                          }}
                        >
                          {this.state.resourcesImages &&
                            this.state.resourcesImages.length > 0 &&
                            this.state.resourcesImages.map((item, index) => {
                              return (
                                <div className="inv-slick-item-box" key={index}>
                                  <div
                                    style={{
                                      backgroundImage:
                                        'url(' + item.thumb + ')',
                                      border:
                                        this.state.photoIndex === index
                                          ? '3px solid #00703C'
                                          : '',
                                    }}
                                    className="item-bg"
                                    onClick={() => {
                                      this.sliderImageChange(index);
                                    }}
                                  >
                                    <img
                                      alt="mail"
                                      src={
                                        require('../assets/images/placeholder.png')
                                          .default
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </Slider>
                      </div>
                    </div>
                  </div>

                  <div className="inventory-slider-middle details">
                    <div className="row">
                      <div className="col-md-4 col-sm-12 col-xs-4">
                        <div className="invField">
                          <label>Division</label>
                          <p>{divisionValue}</p>
                        </div>

                        <div className="invField">
                          <label>Unique ID</label>
                          <p>{this.state.equip_detail.unique_id}</p>
                        </div>

                        <div className="invField">
                          <label>Description</label>
                          <p>
                            {this.state.equip_detail.description ? (
                              this.state.equip_detail.description
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        <div className="invField">
                          <label>Serial Number / VIN</label>
                          <p>
                            {this.state.equip_detail.serial_vin_number ? (
                              this.state.equip_detail.serial_vin_number
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        {this.state.equip_detail.type_id === 25 && (
                          <div className="invField">
                            <label>License Number</label>
                            <p>
                              {this.state.equip_detail.license_number ? (
                                this.state.equip_detail.license_number
                              ) : (
                                <div></div>
                              )}
                            </p>
                          </div>
                        )}

                        {(this.state.equip_detail.type_id === 1 ||
                          this.state.equip_detail.type_id === 5 ||
                          this.state.equip_detail.type_id === 6 ||
                          this.state.equip_detail.type_id === 7 ||
                          this.state.equip_detail.type_id === 8 ||
                          this.state.equip_detail.type_id === 9 ||
                          this.state.equip_detail.type_id === 10 ||
                          this.state.equip_detail.type_id === 14) && (
                          <div className="invField">
                            <label>Engine Family</label>
                            <p>
                              {this.state.equip_detail.engine_family ? (
                                this.state.equip_detail.engine_family
                              ) : (
                                <div></div>
                              )}
                            </p>
                          </div>
                        )}

                        <div className="invField">
                          <label>Year Purchased</label>
                          <p>
                            {this.state.equip_detail.year_purchased ? (
                              this.state.equip_detail.year_purchased
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        <div className="invField">
                          <label>Purchase Value</label>
                          <p>
                            {this.state.equip_detail.cost ? (
                              this.state.equip_detail.cost
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        <div className="invField">
                          <label>Category</label>
                          <p>{this.state.equip_detail.type}</p>
                        </div>

                        {subCateg && (
                          <div className="invField">
                            <label>Sub-category</label>
                            <p>{subCateg}</p>
                          </div>
                        )}
                        {this.state.type_id === '18' &&
                          this.state.type_list.length != 0 && (
                            <p>
                              {
                                this.state.type_list.find(
                                  (item) => item.id == this.state.type_id
                                ).type_desc
                              }
                            </p>
                          )}

                        <div className="invField">
                          <label>Resource</label>
                          <p>
                            {this.state.equip_detail.resource &&
                            this.state.equip_detail.resource.resource_name ? (
                              this.state.equip_detail.resource.resource_name
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        <div className="invField">
                          <label>Year</label>
                          <p>
                            {this.state.equip_detail.year ? (
                              this.state.equip_detail.year
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 col-xs-4">
                        <div className="invField">
                          <label>Driver / Employee</label>
                          <p>
                            {this.state.equip_detail.user ? (
                              this.state.equip_detail.user.first_name +
                              ' ' +
                              this.state.equip_detail.user.last_name
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>Assigned Date</label>
                          <p>{this?.state?.equip_detail?.assign_date ?? ''}</p>
                        </div>
                        <div className="invField">
                          <label>Make / Model</label>
                          <p>{mkModel ? mkModel : <div></div>}</p>
                        </div>

                        {this.state.permList &&
                          this.state.permList.includes(
                            'Inventory Sold Info'
                          ) && (
                            <React.Fragment>
                              <div className="invField">
                                <label>Sold Date</label>
                                <p>
                                  {this.state.equip_detail.sold_date ? (
                                    this.state.equip_detail.sold_date
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              </div>

                              <div className="invField">
                                <label>Sold To</label>
                                <p>
                                  {this.state.equip_detail.sold_to ? (
                                    this.state.equip_detail.sold_to
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              </div>
                              <div className="invField">
                                <label>Sold Value</label>
                                <p>
                                  {this.state.equip_detail.sold_value ? (
                                    this.state.equip_detail.sold_value
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              </div>
                            </React.Fragment>
                          )}

                        <div className="invField">
                          <label>Current Condition</label>
                          {this.state.CurrentCondition &&
                          this.state.equip_detail.current_condition ? (
                            this.state.CurrentCondition.map(
                              (item, index) =>
                                item.id ==
                                  this.state.equip_detail.current_condition && (
                                  <p key={index} className="field">
                                    {item.value}
                                  </p>
                                )
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>

                        <div className="invField">
                          <label>Mileage/Hobbs Meter</label>
                          <p>
                            {this.state.equip_detail.mileage ? (
                              this.state.equip_detail.mileage
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        <div className="invField">
                          <label>Mileage/Hobbs Date</label>
                          <p>
                            {this.state.equip_detail.mileage_date ? (
                              moment(
                                this.state.equip_detail.mileage_date
                              ).format(this?.state?.INVENTORY_VIEW_DATE_FORMAT)
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>

                        {this.state.specialCateg && (
                          <div>
                            {this.state.license_eq && (
                              <div className="invField">
                                <label>License Number</label>
                                <p>
                                  {this.state.equip_detail.vehicle_license ? (
                                    this.state.equip_detail.vehicle_license
                                  ) : this.state.equip_detail.license_number ? (
                                    this.state.equip_detail.license_number
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              </div>
                            )}
                            {this.state.mspa_eq && (
                              <div className="invField">
                                <label>MSPA Exp. Date</label>
                                <p>
                                  {this.state.equip_detail.mspa_exp_date ? (
                                    moment(
                                      this.state.equip_detail.mspa_exp_date
                                    ).format('MM/DD/YY')
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 col-sm-12 col-xs-4">
                        {this.state.gvw_eq && (
                          <div>
                            <div className="invField">
                              <label>GVW</label>
                              <p>
                                {this.state.equip_detail.GVW ? (
                                  this.state.equip_detail.GVW
                                ) : (
                                  <div></div>
                                )}
                              </p>
                            </div>
                            {this.state.license_eq && (
                              <>
                                <div className="invField">
                                  <label>Engine Model</label>
                                  <p>
                                    {this.state.equip_detail.engine_model ? (
                                      this.state.equip_detail.engine_model
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                </div>
                                <div className="invField">
                                  <label>Engine Serial</label>
                                  <p>
                                    {this.state.equip_detail.engine_serial ? (
                                      this.state.equip_detail.engine_serial
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                </div>
                                <div className="invField">
                                  <label>Engine Description</label>
                                  <p>
                                    {this.state.equip_detail
                                      .engine_description ? (
                                      this.state.equip_detail.engine_description
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                </div>
                                {/* <div className='invField'>
                                    <label>Engine Certification</label>
                                    <p>{engineCert ? engineCert : <div></div>}</p>
                                  </div> */}
                                <div className="invField">
                                  <label>ARB #</label>
                                  <p>
                                    {this.state.equip_detail.ARB ? (
                                      this.state.equip_detail.ARB
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                </div>
                                <div className="invField">
                                  <label>Non-Op</label>
                                  <p>
                                    {this.state.equip_detail.non_op ? (
                                      this.state.equip_detail.non_op
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div className="invField">
                          <label>State of Licensure</label>
                          <p>
                            {this.state.equip_detail.state_of_licensure ? (
                              this.state.equip_detail.state_of_licensure
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>Owner of Equipment</label>
                          <p>
                            {this.state.equip_detail.equipment_owner ? (
                              this.state.equip_detail.equipment_owner
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>Insurance</label>
                          <p>
                            {this.state.equip_detail.insurance ? (
                              this.state.equip_detail.insurance
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>Job Code</label>
                          <p>
                            {this.state.equip_detail.job_code ? (
                              this.state.equip_detail.job_code
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>Class Code</label>
                          <p>
                            {this.state.equip_detail.class_code ? (
                              this.state.equip_detail.class_code
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                        <div className="invField">
                          <label>ARB Program</label>
                          <p>
                            {this.state.equip_detail.arb_program ? (
                              this.state.equip_detail.arb_program
                            ) : (
                              <div></div>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <InventoryNotes
                          ref={this?.noteHistoryRef}
                          onComplete={() => this?.onAssignComplete()}
                          inventoryTypeId={this.state.equip_detail?.type_id}
                          inventoryId={this?.state?.inv_id}
                          equipmentId={this?.state?.equip_id}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inventory-slider-bottom">
                  {this.state.permList &&
                    this.state.permList.includes(
                      'Add/Edit/Archive Equipments'
                    ) && (
                      <div
                        className="button edit_inventory"
                        onClick={() => {
                          this.editInventory(
                            this.state.inv_id,
                            this.state.equip_id
                          );
                        }}
                      >
                        Edit
                      </div>
                    )}
                  {this.state.permList &&
                    this.state.permList.includes('Assign Staff') && (
                      <div
                        className="button edit_inventory"
                        onClick={() => this.handleAssignModal()}
                      >
                        Assign
                      </div>
                    )}
                  {this.state.permList &&
                    this.state.permList.includes(
                      'Add/Edit/Archive Equipments'
                    ) && (
                      <div
                        className="button  print-details"
                        onClick={() => {
                          this.props.dispatch(
                            inventoryActions.printInventory({
                              id: this.state.inv_id,
                              equipment_type_id: this.state.invType,
                            })
                          );
                        }}
                      >
                        {this.state.printLoading && (
                          <div
                            className="print-loader"
                            style={{
                              backgroundImage:
                                'url(' +
                                require('../assets/images/loader.gif').default +
                                ')',
                            }}
                          ></div>
                        )}
                        Print
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </SlidingPane>
        <ReactModal
          isOpen={this.state.inventoryOpen}
          onClose={this.inventoryCloseModal.bind(this)}
          contentLabel="inventory Popup"
          overlayClassName="inventoryPopup"
        >
          <div id="inventoryModal" className="inventoryModal">
            <div className="modal-head">
              <h4 className="modal-title">Choose Assign Date</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.inventoryCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
              />
            </div>
            <div className="modal-body">
              <div className="inv_popup_field">
                <label>Assign Date</label>
                <DatePicker
                  className="txt "
                  id="assignDate"
                  name="assignDate"
                  errors={this.state.assignDate_dateError}
                  selected={this.state.assignDate}
                  onChange={this.handleDateChange.bind(this)}
                  maxDate={moment()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  onClick={this.saveDate.bind(this)}
                  className="saveDate right button"
                />
              </div>
            </div>
          </div>
        </ReactModal>
        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
        {
          // Gallery
        }
        {this.state.isOpen && this.state.resourcesImages.length > 0 && (
          <div
            className="gallery-pop-wrapper"
            onTouchMove={(e) => {
              e.preventDefault();
            }}
          >
            <Lightbox
              mainSrc={
                this.state.resourcesImages[this.state.photoIndex].orginalImage
              }
              imageLoadErrorMessage={<span>Loading...</span>}
              nextSrc={
                this.state.resourcesImages[
                  (this.state.photoIndex + 1) %
                    this.state.resourcesImages.length
                ].orginalImage
              }
              prevSrc={
                this.state.resourcesImages[
                  (this.state.photoIndex +
                    this.state.resourcesImages.length -
                    1) %
                    this.state.resourcesImages.length
                ].orginalImage
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.resourcesImages.length -
                      1) %
                    this.state.resourcesImages.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.resourcesImages.length,
                })
              }
            />
          </div>
        )}
        <ReactModal
          isOpen={this.state?.showAssignModal}
          onClose={() => this.handleAssignModal()}
          contentLabel="inventory Popup"
          overlayClassName="inventoryPopup"
        >
          <InventoryQuickAssign
            equipmentId={this?.state?.equip_id}
            defaultData={this.state?.equip_detail}
            onClose={() => this.handleAssignModal()}
            onComplete={() => this?.onAssignComplete()}
          />
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { permission, inventory, resource, user } = state;
  return {
    permission,
    inventory,
    resource,
    user,
    // currentUserInfo: getCurrentUserInfo(state)
  };
}

const connectedHomePage = connect(mapStateToProps)(InventoryDetails);
export { connectedHomePage as InventoryDetails };
