import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ActivityLog from './ActivityLog';
import {
  isActivityLogLoading,
  activityLog,
  filteredEmployees,
} from '_helpers/reduxSelector';

function ActivityLogContainer() {
  const activityLogRef = useRef();
  const [refresh, setRefresh] = useState(false);

  const isLoading = useSelector((state) => isActivityLogLoading(state));
  const _data = useSelector(activityLog);
  const employees = useSelector((state) => filteredEmployees(state));

  const onFilterBtnClick = () => {
    activityLogRef.current?.triggerFilterModal();
  };

  const onRefreshBtnClick = () => {
    setRefresh(!refresh);
    activityLogRef.current?.onRefresh();
  };

  return (
    <div className="activity-log">
      <div className="header">
        <div className="report_tbl_head sold_rpt_mbl_flx">
          <h4 className="graph_header">Activity Log</h4>
          <div className="buttons">
            {activityLogRef.current?.isFilterON && (
              <button className="button filter-btn" onClick={onRefreshBtnClick}>
                Reset Filters
              </button>
            )}
            <button className="button filter-btn" onClick={onRefreshBtnClick}>
              Refresh
            </button>
            <button className="button" onClick={onFilterBtnClick}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="white">
        <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
          <div>
            <ActivityLog
              data={_data?.data}
              lastPage={_data?.last_page}
              ref={activityLogRef}
              employees={employees}
              refresh={refresh}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityLogContainer;
