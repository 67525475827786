import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class FormNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeToBottom: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    let { closeToBottom } = this.state;
    if (
      window.scrollY + window.screen.height >
      document.body.scrollHeight - 170
    ) {
      this.setState({ closeToBottom: true });
    } else {
      this.setState({ closeToBottom: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    let { closeToBottom } = this.state;
    let single_doc = this.props.single_doc ? true : false;
    let { disclaimer_check, docs, lastPage, adminMode, skip, selectedUser } =
      this.props;
    let { doc } = this.props.match.params;
    let { userDocs } = docs;
    let activeDocIndex = userDocs.findIndex((item) => item.slug === doc);

    return (
      <div
        className={`action_buttons ${closeToBottom ? 'close-to-bottom' : ''}`}
      >
        {/* MOBILE MODE */}
        {this.props.mobileMode && (
          // FOR w4 with multiple pages
          <Fragment>
            {skip && (
              <button
                type="button"
                onClick={() => {
                  this.props.skipHandler();
                }}
                className="aplcn-next-btn m-0"
              >
                Skip{' '}
              </button>
            )}
            {doc === 'w-4' ? (
              <Fragment>
                <button
                  type="button"
                  onClick={() => {
                    const navigateTo = 'prev';
                    this.props.rehire //passing as default props in functional components else the prop values would be undefined in navigate function
                      ? this.props.navigate(
                          navigateTo,
                          this.props.rehire,
                          this.props
                        )
                      : this.props.navigate(navigateTo);
                  }}
                  className="aplcn-prev-button"
                  disabled={activeDocIndex == 0}
                  style={{
                    display: this.props.currentPage > 1 ? 'block' : 'none',
                  }}
                >
                  Previous
                </button>
                <div className="pagination-number">
                  <p>
                    {this.props.currentPage} of {this.props.noOfPages}
                  </p>
                </div>
                {this.props.currentPage < this.props.noOfPages ? (
                  <button
                    disabled={!disclaimer_check}
                    type="submit"
                    className="aplcn-next-btn"
                    style={{ 'margin-left': 0 }}
                  >
                    {lastPage
                      ? adminMode
                        ? 'Save'
                        : selectedUser &&
                            selectedUser.docs_status >= 2 &&
                            selectedUser.rehire_docs_status !== 1
                          ? 'Update Documents'
                          : 'Submit Documents'
                      : 'Next'}
                  </button>
                ) : (
                  <button
                    disabled={!disclaimer_check}
                    type="submit"
                    className="aplcn-next-btn"
                    style={{ 'margin-left': 0 }}
                  >
                    {lastPage ? 'Submit Documents' : 'Save & Close'}
                  </button>
                )}
              </Fragment>
            ) : (
              <button
                disabled={!disclaimer_check}
                type="submit"
                className="aplcn-next-btn"
              >
                {lastPage ? 'Submit Documents' : 'Save & Close'}
              </button>
            )}
          </Fragment>
        )}

        {/* DESKTOP MODE */}
        {!this.props.mobileMode && (
          <Fragment>
            {!this.props.single_doc && (
              <button
                type="button"
                onClick={() => {
                  const navigateTo = 'prev';
                  this.props.rehire //passing as default props in functional components else the prop values would be undefined in navigate function
                    ? this.props.navigate(navigateTo, this.props.rehire, this)
                    : this.props.navigate(navigateTo);
                }}
                className="aplcn-prev-button"
                disabled={activeDocIndex == 0}
              >
                Previous
              </button>
            )}
            {doc === 'w-4' && (
              <div className="pagination-number">
                <p>
                  {this.props.currentPage} of {this.props.noOfPages}
                </p>
              </div>
            )}
            <div className="btn-grouped">
              {skip && (
                <button
                  type="button"
                  onClick={() => {
                    this.props.skipHandler();
                  }}
                  className="aplcn-next-btn"
                >
                  Skip{' '}
                </button>
              )}
              {single_doc ? (
                <button
                  disabled={!disclaimer_check}
                  type="submit"
                  className="aplcn-next-btn"
                >
                  {lastPage
                    ? adminMode
                      ? 'Save'
                      : selectedUser &&
                          selectedUser.docs_status >= 2 &&
                          selectedUser.rehire_docs_status !== 1
                        ? 'Update Document'
                        : 'Submit Document'
                    : 'Next'}
                </button>
              ) : (
                <button
                  disabled={!disclaimer_check}
                  type="submit"
                  className="aplcn-next-btn"
                >
                  {lastPage
                    ? adminMode
                      ? 'Save'
                      : selectedUser &&
                          selectedUser.docs_status >= 2 &&
                          selectedUser.rehire_docs_status !== 1
                        ? 'Update Documents'
                        : 'Submit Documents'
                    : 'Next'}
                </button>
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedFormNav = compose(withRouter, connect(mapStateToProps))(FormNav);
export { connectedFormNav as FormNav };
