export const manifestConstants = {
  GETTYPE_REQUEST: 'MANIFEST_GETTYPE_REQUEST',
  GETTYPE_SUCCESS: 'MANIFEST_GETTYPE_SUCCESS',
  GETTYPE_FAILURE: 'MANIFEST_GETTYPE_FAILURE',

  GETUSERS_REQUEST: 'MANIFEST_GETUSERS_REQUEST',
  GETUSERS_SUCCESS: 'MANIFEST_GETUSERS_SUCCESS',
  GETUSERS_FAILURE: 'MANIFEST_GETUSERS_FAILURE',

  GETSUPERINTENDENT_REQUEST: 'MANIFEST_GETSUPERINTENDENT_REQUEST',
  GETSUPERINTENDENT_SUCCESS: 'MANIFEST_GETSUPERINTENDENT_SUCCESS',
  GETSUPERINTENDENT_FAILURE: 'MANIFEST_GETSUPERINTENDENT_FAILURE',

  GETVEH_REQUEST: 'MANIFEST_GETVEH_REQUEST',
  GETVEH_SUCCESS: 'MANIFEST_GETVEH_SUCCESS',
  GETVEH_FAILURE: 'MANIFEST_GETVEH_FAILURE',

  GETMANIFEST_REQUEST: 'MANIFEST_DETAIL_REQUEST',
  GETMANIFEST_SUCCESS: 'MANIFEST_DETAIL_SUCCESS',
  GETMANIFEST_FAILURE: 'MANIFEST_DETAIL_FAILURE',

  GETMANIFESTLIST_REQUEST: 'MANIFEST_GET_REQUEST',
  GETMANIFESTLIST_SUCCESS: 'MANIFEST_GET_SUCCESS',
  GETMANIFESTLIST_FAILURE: 'MANIFEST_GET_FAILURE',

  GETDISPATCHMAILLIST_REQUEST: 'DISPATCHMAIL_GET_REQUEST',
  GETDISPATCHMAILLIST_SUCCESS: 'DISPATCHMAIL_GET_SUCCESS',
  GETDISPATCHMAILLIST_FAILURE: 'DISPATCHMAIL_GET_FAILURE',

  SAVEMANIFEST_REQUEST: 'MANIFEST_SAVE_REQUEST',
  SAVEMANIFEST_SUCCESS: 'MANIFEST_SAVE_SUCCESS',
  SAVEMANIFEST_FAILURE: 'MANIFEST_SAVE_FAILURE',

  PRINTMANIFEST_REQUEST: 'MANIFEST_PRINT_REQUEST',
  PRINTMANIFEST_SUCCESS: 'MANIFEST_PRINT_SUCCESS',
  PRINTMANIFEST_FAILURE: 'MANIFEST_PRINT_FAILURE',

  EMAILMANIFEST_REQUEST: 'MANIFEST_EMAIL_REQUEST',
  EMAILMANIFEST_SUCCESS: 'MANIFEST_EMAIL_SUCCESS',
  EMAILMANIFEST_FAILURE: 'MANIFEST_EMAIL_FAILURE',

  DELETEMANIFEST_REQUEST: 'MANIFEST_DELETE_REQUEST',
  DELETEMANIFEST_SUCCESS: 'MANIFEST_DELETE_SUCCESS',
  DELETEMANIFEST_FAILURE: 'MANIFEST_DELETE_FAILURE',
};
