import { applicantsConstants } from '../_constants';

export function applicants(state = {}, action) {
  switch (action.type) {
    case applicantsConstants.GETAPPLN_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.GETAPPLN_SUCCESS:
      return {
        items: action.applicants,
      };
    case applicantsConstants.GETAPPLN_FAILURE:
      return {
        error: action.error,
      };
    case applicantsConstants.VIEWAPPLN_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.VIEWAPPLN_SUCCESS:
      return {
        ...state,
        list: action.list,
      };
    case applicantsConstants.VIEWAPPLN_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.GETFILTER_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.GETFILTER_SUCCESS:
      return {
        filters: action.filters,
      };
    case applicantsConstants.GETFILTER_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.FILTERS_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.FILTERS_SUCCESS:
      return {
        items: action.applicants,
      };
    case applicantsConstants.FILTERS_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.GETFIELD_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.GETFIELD_SUCCESS:
      return {
        fields: action.fields,
      };
    case applicantsConstants.GETFIELD_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.EDITAPP_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.EDITAPP_SUCCESS:
      return {
        application: action.application,
      };
    case applicantsConstants.EDITAPP_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.UPDATEFNOTE_REQUEST:
      return {
        loading: true,
      };
    case applicantsConstants.UPDATEFNOTE_SUCCESS:
      return {
        notes: action.notes,
      };
    case applicantsConstants.UPDATEFNOTE_FAILURE:
      return {
        error: action.error,
      };

    case applicantsConstants.GET_APPLICANT_DETAILS_REQUEST:
      return {
        ...state,
        applicantDetailsLoading: true,
      };
    case applicantsConstants.GET_APPLICANT_DETAILS_SUCCESS:
      return {
        ...state,
        applicantDetailsLoading: false,
        applicantDetails: action?.data,
      };
    case applicantsConstants.GET_APPLICANT_DETAILS_ERROR:
      return {
        ...state,
        applicantDetailsLoading: false,
      };

    case applicantsConstants.GET_JOB_APPLICATION_HISTORY_REQUEST:
      return {
        ...state,
        jobApplicationHistoryLoading: true,
      };
    case applicantsConstants.GET_JOB_APPLICATION_HISTORY_SUCCESS:
      return {
        ...state,
        jobApplicationHistoryLoading: false,
        jobApplicationHistory: action?.data,
      };
    case applicantsConstants.GET_JOB_APPLICATION_HISTORY_ERROR:
      return {
        ...state,
        jobApplicationHistoryLoading: false,
      };

    default:
      return state;
  }
}
