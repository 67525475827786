import React, { useEffect, useState } from 'react';
import styles from './BulkImportPreview.module.scss';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '_actions/user.actions';
import { toast } from 'react-toastify';
const BulkImportPreview = (props) => {
  const dispatch = useDispatch();
  const importLoading = useSelector(
    (state) => state.user.isImportEmployeesLoading
  );
  const {
    setIsBulkImport,
    data,
    setPaginatedData,
    rawData,
    onCancelBulkImport,
  } = props;
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState([]);
  const onCancel = () => {
    setIsBulkImport(false);
    setPaginatedData([]);
    setEmployees([]);
    onCancelBulkImport();
  };
  const onImportEmployees = async () => {
    const data = new FormData();
    data.append('employees', JSON.stringify(employees));
    const res = await dispatch(userActions.importEmployees(data));
    if (res.success) {
      toast.success('Successfully imported employees.');
      onCancel();
    } else {
      toast.error('Unable to prcoess your request.');
    }
  };
  const onCheckboxClick = (emp) => {
    if (employees.some((item) => item.id === emp.id)) {
      setEmployees(employees.filter((item) => item.id !== emp.id));
      return;
    }
    setEmployees([...employees, emp]);
  };
  const onInputChange = (event, index, key) => {
    const { value } = event.target;
    setPage((prevState) => {
      const updatedUsers = [...prevState];
      updatedUsers[index][key] = value;
      return updatedUsers;
    });
  };
  const handlePageChange = (pageNum) => {
    setCurrentPageNumber(pageNum.selected);
    setPage(data[pageNum.selected]);
  };
  useEffect(() => {
    setEmployees(rawData);
    if (data?.length) {
      setPage(data[0]);
    }
  }, []);
  return (
    <>
      {importLoading ? (
        <center id="feedLoader">
          <img
            alt="Loader"
            src={require('../assets/images/loader.gif').default}
          />
        </center>
      ) : (
        <div className={`${styles.BulkImportPreview} bulk_import_privew`}>
          <div className="banner">
            <h1>Bypass Employee Application</h1>
          </div>
          <div className="preview-section">
            <div className="preview-header">
              <h3>Bulk Import Preview</h3>
              <div className="header-buttons">
                <button className="button btn__close" onClick={onCancel}>
                  Cancel
                </button>
                {!!employees.length && (
                  <button className="button" onClick={onImportEmployees}>
                    Import {employees.length} Employees
                  </button>
                )}
              </div>
            </div>
            <div className="preview-table">
              <div class="tbl-header">
                <table cellpadding="0" cellSpacing="0" border="0">
                  <thead>
                    <tr>
                      <th width="100" align="right">
                        #
                      </th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>
                        Email Address<span style={{ color: 'red' }}>*</span>
                      </th>
                      <th>
                        Applying Company<span style={{ color: 'red' }}>*</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="tbl-content">
                <table cellpadding="0" cellSpacing="0" border="0">
                  <tbody>
                    {page.map((emp, index) => (
                      <tr key={emp.id}>
                        <td valign="center" width="100">
                          <div className="checkbox_wrap">
                            <input
                              type="checkbox"
                              className={`checkbox_input`}
                              onChange={() => onCheckboxClick(emp)}
                              checked={employees.some(
                                (item) => item.id === emp.id
                              )}
                            />{' '}
                            <label className="checkbox_label"></label>
                          </div>
                          <span>{emp.index + 1}</span>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="firstname"
                            value={emp.firstname}
                            onChange={(e) =>
                              onInputChange(e, index, 'firstname')
                            }
                            id={emp.id}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="lastname"
                            value={emp.lastname}
                            onChange={(e) =>
                              onInputChange(e, index, 'lastname')
                            }
                            id={emp.id}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="email"
                            value={emp.email}
                            onChange={(e) => onInputChange(e, index, 'email')}
                            id={emp.id}
                          />
                          {!emp.email.length && (
                            <span className="bypass__error">
                              Email address is required.
                            </span>
                          )}
                        </td>
                        <td>
                          <select
                            type="text"
                            name="applyingCompany"
                            value={emp.applyingcompany}
                            onChange={(e) =>
                              onInputChange(e, index, 'applyingcompany')
                            }
                            id={emp.id}
                          >
                            <option value={1}>About Trees</option>
                            <option value={2}>NVTS</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* pagination */}
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align">
                <ReactPaginate
                  forcePage={currentPageNumber}
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={data.length}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BulkImportPreview;
