import React from 'react';

const DocStatusTag = (props) => {
  const { bgColor, status, fontColor, className = 'null' } = props;
  return (
    <div
      className={`doc-status-tag ${className}`}
      style={{ backgroundColor: bgColor, color: fontColor }}
    >
      {status}
    </div>
  );
};

export default DocStatusTag;
