import { applicationConstants } from '../_constants';

export function application(state = {}, action) {
  switch (action.type) {
    case applicationConstants.ANTILOC_REQUEST:
      return {
        loading: true,
      };
    case applicationConstants.ANTILOC_SUCCESS:
      return {
        items: action.application,
      };
    case applicationConstants.ANTILOC_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
