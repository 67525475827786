import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { SignPad } from 'Docs/Components';
import { FormNav } from './FormNav';
import { navigate, validate } from './formHandlers';

class RightOfWorkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isListOpen: false,
      form: {
        signed_date: moment().format('MM/DD/YYYY'),
        disclaimer_check: false,
        employee_signature: null,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }
  toggleList(list) {
    switch (list) {
      case 'list':
        this.state.isListOpen
          ? this.setState({ isListOpen: false })
          : this.setState({ isListOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }
  handleFormChange(e) {
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: '',
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;

        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? '';
        }

        // spread the data coming from API into state
        this.setState({
          form: {
            ...this.state.form,
            ...form,
          },
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
    //   );
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    let { form, errors } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Rights of Workers</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Labor Contractor Law</h6>
                <p className="mb-10">
                  There are laws in Oregon which regulate the activities of
                  construction, janitorial, and farm and forest labor
                  contractors. Under these laws, contractors are required to:
                </p>
                <ol className="checkb_lists mb-6">
                  <li>
                    Have a license and show it to persons with whom he or she
                    contracts.
                  </li>
                  <li>
                    Give to each worker the contractor hires, recruits, solicits
                    or supplies, a written agreement which describes the terms
                    and conditions of employment. This form must be written in
                    English and in any other language used to communicate with
                    workers.
                  </li>
                  <li>
                    Give to each worker a written form which describes the
                    rights of employees. This form must be in English and in any
                    other language used to communicate with workers.
                  </li>
                  <li>
                    Post a notice in a conspicuous place which says the
                    contractor has the required bond or deposit. This notice
                    must state that if the contractor owes wages to a worker and
                    does not pay, the worker may make a claim against the bond
                    or deposit
                  </li>
                </ol>
                <p>
                  Each worker has the right to take legal action against a
                  contractor if that contractor violates certain laws regulating
                  the contractor's activities. For information about your right
                  to take legal action, call any office of the Bureau of Labor
                  and Industries (see listing of offices on next page).
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">The Minimum Wage</h6>
                <p>
                  Labor contractors are required to pay their employees (except
                  certain agricultural hand harvest laborers and a few others)
                  no less than the applicable minimum wage for all hours worked,
                  regardless of the method used to compute wages. These laws do
                  not apply to all workers. If you have questions, contact any
                  office of the Bureau of Labor and Industries or visit
                  www.oregon.gov/BOLI for more information.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Rest and Meal Periods</h6>
                <p>
                  Most employees (including agricultural employees) in Oregon
                  must receive rest breaks and meal periods. Employers must
                  provide workers with a paid, uninterrupted 10-minute rest
                  break for every four-hour segment or major portion thereof in
                  the work period. Employers must provide employees with at
                  least a 30-minute unpaid meal period when the work period is
                  six hours or greater. There are some exceptions and special
                  rules apply to minor employees. For more information, contact
                  any office of the Bureau of Labor and Industries.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Wage Claims</h6>
                <p>
                  If an employer owes wages to a worker and does not pay, the
                  worker may file a claim for back wages. In order to file a
                  claim, contact any office of the Bureau of Labor and
                  Industries. It will be necessary to fill out a form and to
                  provide other information about what you are owed.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Laws Prohibiting Discrimination</h6>
                <p>
                  Oregon and federal civil rights laws forbid an employer or
                  landlord to discriminate against a worker or tenant because of
                  race, color, sex, national origin, or religion. An employer
                  may not discriminate against a worker who has been injured on
                  the job. Civil rights laws protect workers from additional
                  kinds of discrimination and also give workers certain rights.
                  For more information, call the Civil Rights Division of any
                  office of the Oregon Bureau of Labor and Industries.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">On-the-Job Accidents</h6>
                <p>
                  Your employer is required to maintain an insurance policy
                  which covers on-the-job accidents. Your employer should post a
                  notice which provides information about this insurance. The
                  insurance company will pay the cost of medical treatment. It
                  will also pay wages to the worker if he or she is unable to
                  work because of the accident. The employer is required to have
                  a form which is used to notify the insurance company of the
                  accident. Get one of these forms from your employer, fill it
                  out and return it to him or her. He or she will send it to the
                  insurance company. If you do not have a form or cannot get one
                  from your employer, call the
                  <strong> Workers Compensation Department</strong> at
                  1-800-452-0288 to obtain one.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Field Sanitation &amp; Housing</h6>
                <p>
                  Labor contractors that employ workers must comply with the
                  provisions of ORS 654.174 relating to field sanitation,
                  including the provision of clean and sanitary toilet
                  facilities; handwashing facilities with clean water, soap or
                  other suitable cleansing agent, paper towels and a method for
                  disposal of used towels and wash water; and clean, potable
                  drinking water served in a sanitary manner. Labor contractors
                  must keep conspicuously posted a notice describing these
                  requirements and advising workers where complaints may be
                  filed. The notice must be in the English language and in the
                  language spoken by the majority of the employees.
                </p>
                <p>
                  Labor contractors that owns or control housing furnished to
                  workers in connection with recruitment or employment must
                  ensure that the housing substantially complies with any
                  applicable law relating to the health, safety or habitability
                  of the housing. Contact <strong>Oregon OSHA</strong> at
                  1-800-922-2689 with questions.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Federal Government Contracts</h6>
                <p>
                  If you are working under a federal government contract, the
                  contractor for whom you work must pay you no less than the
                  applicable minimum wage, except when a higher rate has been
                  established. The contractor must post a notice in a
                  conspicuous place which gives the minimum wage or the higher
                  wage if it has been established.
                </p>
                <p>
                  There are other rights for employees that work under federal
                  contracts. For information, call the Federal Department of
                  Labor. The telephone number is (503) 326-3057, or write: U.S.
                  Department of Labor, Wage & Hour Division, 620 SW Main, Room
                  423, Portland OR 97205.
                </p>
              </div>
            </div>
            <div className="separator mb-2" />
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Unemployment Benefits</h6>
                <p>
                  Oregon law provides benefits to persons who work, lose their
                  jobs and are not able to find another one. These unemployed
                  persons may receive payments from the State of Oregon for a
                  limited amount of time while looking for a job. This law is
                  complicated and is not detailed here. It is sufficient to say
                  that some agricultural and forest workers have certain rights.
                  If you are able to look for work, you may qualify for these
                  benefits. Check with an office of the{' '}
                  <strong>Oregon Employment Department </strong> at
                  1-800-237-3710.
                </p>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers dis-notes">
                  <div className="inputs-head">
                    BUREAU OF LABOUR AND INDUSTRIES
                    {/* <span className={`heading_toggle ${this.isListOpen ? 'open' : 'close'}`} onClick={() => this.toggleList('list')}>{`Open ˅`} </span>  */}
                    <button
                      className={`toggler_btn ${this.isListOpen ? 'toggled' : ''}`}
                      onClick={() => this.toggleList('list')}
                    ></button>
                  </div>
                  {this.state.isListOpen && (
                    <Fragment>
                      <ul>
                        <li>
                          <strong>Eugene</strong>
                          <p>
                            1400 Executive Parkway, Suite 200, Eugene, OR 97401
                          </p>
                          <p>(541) 686-7623</p>
                          <p>TTY: (541) 686-7847</p>
                        </li>
                        <div className="separator" />
                        <li>
                          <strong>Portland</strong>
                          <p>
                            800 NE Oregon St., #1045, Portland, OR 97232-2180
                          </p>
                          <p>(971) 673-0761</p>
                          <p>TTY: (971) 673-0766</p>
                        </li>
                        <li>
                          <strong>Salem</strong>
                          <p>
                            3865 Wolverine St. NE, Building E-1, Salem, OR
                            97305-1268
                          </p>
                          <p>(503) 378-3292</p>
                        </li>
                      </ul>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Printed Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.printed_name'] ? 'error' : ''}`}
                      name="printed_name"
                      id="printed_name"
                      value={form.printed_name}
                      field="form.printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors['form.printed_name'] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors['form.printed_name']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.signed_date'] ? 'error' : ''} w-100`}
                      name="signed_date"
                      id="signed_date"
                      required
                      selected={moment(form.signed_date)}
                      field="form.signed_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.signed_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors['form.signed_date'] && (
                      <label htmlFor="signed_date" className="error_label">
                        {errors['form.signed_date']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col w-50">
                <div className="inputs-col ">
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.employee_signature'
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors['form.employee_signature'] && (
                            <label
                              htmlFor="employee_signature"
                              className="error_label"
                            >
                              {errors['form.employee_signature']}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                    {/* <div className="sign_here">
                                            <div className="sign_canvas">
                                                <div className="sign_placeholder">- Sign Here -</div>
                                                <canvas id="sign_here" />
                                            </div>
                                            <div className="sign_btn-group">
                                                add class "accept-sign" to the button to enable
                                                <button type="submit" className="aplcn-prev-button">Clear</button>
                                                <button type="submit" className="aplcn-next-btn accept-sign">Click to
                    Accept Signature</button>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{' '}
                  <a href="">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedRightOfWorkers = compose(
  withRouter,
  connect(mapStateToProps)
)(RightOfWorkers);
export { connectedRightOfWorkers as RightOfWorkers };
