import React from 'react';
import { config } from '../_helpers';
import { connect } from 'react-redux';
import { chatActions } from '../_actions';
import uuid from 'uuid/v1';
import OutsideClickHandler from 'react-outside-click-handler';
import imageCompression from 'browser-image-compression';

class MessageBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emojiPopup: false,
      message: '',
      activeKeyCodes: [],
    };

    this.sendTextMessage = this.sendTextMessage.bind(this);
    this.handleTextBox = this.handleTextBox.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
    this.handleKeyStroke = this.handleKeyStroke.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.textArea = React.createRef();
    this.fileInput = React.createRef();
  }

  getFileExtension(file) {
    let fileDots = file.name.split('.');
    return fileDots[fileDots.length - 1];
  }

  handleKeyStroke(e) {
    if (e.keyCode == 13 && e.shiftKey) {
      let selectionStart = this.textArea.selectionStart;
      let selectionEnd = this.textArea.selectionEnd;
      let message = this.state.message;
      let messageStart = message.substr(0, selectionStart);
      let messageEnd = message.substr(selectionEnd, message.length);

      this.setState(
        {
          message: messageStart + '\n' + messageEnd,
        },
        () => {
          this.textArea.scrollTop = this.textArea.scrollHeight;
          this.textArea.focus();
          this.textArea.selectionStart = this.textArea.selectionEnd =
            selectionStart + 1;
        }
      );
      e.preventDefault();
    } else if (e.keyCode == 13) {
      this.sendTextMessage();
      e.preventDefault();
    }
  }

  validateFile(file) {
    let validFileTypes = [
      'jpg',
      'jpeg',
      'png',
      'rar',
      'doc',
      'docx',
      'pdf',
      'zip',
      'txt',
      'eps',
      'mp3',
      'mov',
      'ppt',
      'avi',
      'wav',
    ];

    let maxFileSize = 41943040;

    let fileType = file.name.split('.').pop().toLowerCase();

    if (!validFileTypes.includes(fileType)) {
      return 'Invalid File';
    }

    if (file.size > maxFileSize) {
      return 'File size must be less than 40 MB';
    }

    return null;
  }

  async handleFileChange(e) {
    let files = e.target.files;

    let err = this.validateFile(files[0]);

    if (err) {
      alert(err);
      return;
    }

    let { user, chat } = this.props;

    let { conversation } = chat;

    let validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (files.length && conversation.id && user.profile && user.profile.data) {
      let file = files[0];
      let now = new Date();
      let temp_id = uuid();

      let newMessage = {
        temp_id,
        conversation_id: conversation.id,
        sender: user.profile.data,
        created_at: now,
        updated_at: now,
        deleted_at: null,
        read_at: null,
        type: 'file',
        status: 'uploading',
      };

      let body = {
        file,
        file_name: file.name,
        extension: this.getFileExtension(file),
        size: file.size,
        type: file.type,
      };

      if (validImageTypes.includes(file.type)) {
        body.thumb = await imageCompression.getDataUrlFromFile(file);

        let image = new Image();

        image.onload = () => {
          body.width = image.width;
          body.height = image.height;
          newMessage.body = body;
          this.props.dispatch(chatActions.uploadFile(newMessage));
        };

        image.src = body.thumb;
      } else {
        newMessage.body = body;
        this.props.dispatch(chatActions.uploadFile(newMessage));
      }
    }

    this.fileInput.value = null;
  }

  handleTextBox(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleEmojiClick(e) {
    //console.log(this.textArea.selectionStart, this.textArea.selectionEnd);
    let selectionStart = this.textArea.selectionStart;
    let selectionEnd = this.textArea.selectionEnd;
    let message = this.state.message;
    let messageStart = message.slice(0, selectionStart);
    let messageEnd = message.slice(selectionEnd, message.length);
    this.setState(
      {
        emojiPopup: false,
        message: messageStart + e.target.id + messageEnd,
      },
      () => {
        this.textArea.focus();
        this.textArea.selectionStart = this.textArea.selectionEnd =
          selectionStart + 2;
      }
    );
    //console.log(this.textArea.selectionStart, this.textArea.selectionEnd);
  }

  sendTextMessage() {
    let { user, chat } = this.props;

    let { conversation } = chat;

    let message = this.state.message.trim();

    if (
      message.length &&
      conversation.id &&
      user.profile &&
      user.profile.data
    ) {
      let now = new Date();

      let newMessage = {
        temp_id: uuid(),
        conversation_id: conversation.id,
        body: this.state.message,
        sender: user.profile.data,
        created_at: now,
        updated_at: now,
        deleted_at: null,
        read_at: null,
        type: 'text',
      };

      this.props.dispatch(chatActions.sendMessage(newMessage));

      this.setState({ message: '' });

      this.props.forceScrollBottom();
      //Added below line to resolve the keyboard issue in mobile
      this.textArea.focus();
    }
  }

  render() {
    return (
      <div id="message-form" className="msg-form">
        <a href="#" className="attach-doc">
          <input
            type="file"
            onChange={this.handleFileChange.bind(this)}
            ref={(ref) => (this.fileInput = ref)}
          />
          <i className="fas fa-paperclip"></i>
        </a>
        <div className="msg-input">
          {this.state.emojiPopup && (
            <OutsideClickHandler
              onOutsideClick={() => {
                this.setState({ emojiPopup: false });
              }}
            >
              <div className="emojis">
                <a onClick={this.handleEmojiClick} id="&#x2764;&#xFE0F;">
                  ❤️
                </a>
                <a onClick={this.handleEmojiClick} id="&#x1F604;">
                  😄
                </a>
                <a onClick={this.handleEmojiClick} id="&#x1F614;">
                  😞
                </a>
                <a onClick={this.handleEmojiClick} id="&#x1F602;">
                  😂
                </a>
                <a onClick={this.handleEmojiClick} id="&#x1F44D;">
                  👍
                </a>
              </div>
            </OutsideClickHandler>
          )}

          <textarea
            ref={(ref) => (this.textArea = ref)}
            placeholder="Type Message..."
            name="message"
            className="messagesend"
            value={this.state.message}
            onChange={this.handleTextBox}
            onKeyDown={this.handleKeyStroke}
            autoComplete="new-password"
          />

          <button onClick={this.sendTextMessage} className="sendBtn">
            <i className="fas fa-paper-plane"></i>
          </button>

          <button
            onClick={() => {
              this.setState({ emojiPopup: !this.state.emojiPopup });
            }}
            className={`sendBtn emoji ${
              this.state.emojiPopup && 'emoji-added'
            }`}
          >
            <img src={`${config.assetUrl}icons/add_emoji.svg`} />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user } = state;
  return { chat, user };
}

export default connect(mapStateToProps)(MessageBox);
