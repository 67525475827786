import { notificationConstants } from '../_constants';
import { notificationService } from '../_services';

export const notificationActions = {
  getAll,
  getByFilter,
  getBulletin,
  deleteFile,
  uploadFile,
  setLastSeen,
  markAsRead,
  setRead,
  getCount,
  pinnedPost,
  deleteBulletin,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    notificationService.getAll().then(
      (notification) => {
        dispatch(success(notification));
        dispatch(setLastSeen());
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETALL_REQUEST };
  }
  function success(notification) {
    return { type: notificationConstants.GETALL_SUCCESS, notification };
  }
  function failure(error) {
    return { type: notificationConstants.GETALL_FAILURE, error };
  }
}

function getByFilter(params) {
  return (dispatch) => {
    dispatch(request());
    notificationService.getByFilter(params).then(
      (notification) => {
        dispatch(success(notification));
        dispatch(setLastSeen());
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETALL_REQUEST };
  }
  function success(notification) {
    return { type: notificationConstants.GETALL_SUCCESS, notification };
  }
  function failure(error) {
    return { type: notificationConstants.GETALL_FAILURE, error };
  }
}

function setLastSeen() {
  return (dispatch) => {
    dispatch(request());

    notificationService.setLastSeen().then(
      (lastSeen) => dispatch(success(lastSeen)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETLASTSEEN_REQUEST };
  }
  function success(lastSeen) {
    return { type: notificationConstants.GETLASTSEEN_SUCCESS, lastSeen };
  }
  function failure(error) {
    return { type: notificationConstants.GETLASTSEEN_FAILURE, error };
  }
}

function markAsRead() {
  return (dispatch) => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      dispatch(request());
      notificationService.markAsRead().then(
        (markall) => {
          dispatch(getAll());
          resolve(markall);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: notificationConstants.MARKALL_REQUEST };
  }
  // function success(markall) { return { type: notificationConstants.MARKALL_SUCCESS, markall } }
  function failure(error) {
    return { type: notificationConstants.MARKALL_FAILURE, error };
  }
}

function setRead(id) {
  return (dispatch) => {
    dispatch(request(id));

    notificationService.setRead(id).then(
      (read) => {
        dispatch(success(read));
        dispatch(getAll());
      },
      // read => dispatch(getAll()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.SETMARK_REQUEST };
  }
  function success(read) {
    return { type: notificationConstants.SETMARK_SUCCESS, read };
  }
  function failure(error) {
    return { type: notificationConstants.SETMARK_FAILURE, error };
  }
}

function getCount() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      notificationService.getCount().then(
        (count) => {
          dispatch(success(count));
          resolve(count);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: notificationConstants.GETCOUNT_REQUEST };
  }
  function success(count) {
    return { type: notificationConstants.GETCOUNT_SUCCESS, count };
  }
  function failure(error) {
    return { type: notificationConstants.GETCOUNT_FAILURE, error };
  }
}

function getBulletin() {
  return (dispatch) => {
    dispatch(request());

    notificationService.getBulletin().then(
      (bulletin) => dispatch(success(bulletin)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETBULLET_REQUEST };
  }
  function success(bulletin) {
    return { type: notificationConstants.GETBULLET_SUCCESS, bulletin };
  }
  function failure(error) {
    return { type: notificationConstants.GETBULLET_FAILURE, error };
  }
}

function deleteFile(data) {
  return (dispatch) => {
    dispatch(request());

    notificationService.deleteFile(data).then(
      (bulletin) => dispatch(getBulletin()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETBULLET_REQUEST };
  }
  // function success(bulletin) { return { type: notificationConstants.GETBULLET_SUCCESS, bulletin } }
  function failure(error) {
    return { type: notificationConstants.GETBULLET_FAILURE, error };
  }
}

function deleteBulletin(id) {
  return (dispatch) => {
    dispatch(request());

    notificationService.deleteBulletin(id).then(
      (dltBulletin) => {
        // alert(dltBulletin.message)
        dispatch(getBulletin());
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.DELETEBULLET_REQUEST };
  }
  // function success(dltBulletin) { return { type: notificationConstants.DELETEBULLET_SUCCESS, dltBulletin } }
  function failure(error) {
    return { type: notificationConstants.DELETEBULLET_FAILURE, error };
  }
}

function uploadFile(data) {
  return (dispatch) => {
    dispatch(request());

    notificationService.uploadFile(data).then(
      (bulletin) => dispatch(getBulletin()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETBULLET_REQUEST };
  }
  // function success(bulletin) { return { type: notificationConstants.GETBULLET_SUCCESS, bulletin } }
  function failure(error) {
    return { type: notificationConstants.GETBULLET_FAILURE, error };
  }
}

function pinnedPost(id) {
  return (dispatch) => {
    dispatch(request());

    notificationService.pinnedPost(id).then(
      (pinned) => dispatch(getBulletin()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.PINNED_REQUEST };
  }
  // function success(pinned) { return { type: notificationConstants.PINNED_SUCCESS, pinned } }
  function failure(error) {
    return { type: notificationConstants.PINNED_FAILURE, error };
  }
}
