import { exportToCSV, getBdayFilename } from '_utils/export';
import { authHeader, config } from '../_helpers';

export const reportsService = {
  getVehicleReport,
  getEquipmentSoldReport,
  generateEmployeeVehicleReport,
  generateEquipmentSoldReport,
  getAllInventoryReport,
  generateAllInventoryReportCSV,
  generateAllInventoryReportPDF,
  getDocStatusList,
  generateDocStatusListPdf,
  generateDocStatusListCsv,
  askToFillDocumentsByUsersHash,
  getExpirationDateReport,
  generateExpDateReportCSV,
  generateExpDateReportPDF,
  getEmployeesBirthday,
  generateBdayReport,
  printEmployeeBirthdaysReportPdf,
  getMileageHobbsReport,
  generateMileageHobbsReport,
  generateMileageHobbsReportPDF,
  fetchApplicantsReport,
  fetchApplicantsReportCsv,
  fetchApplicantsReportPdf,
};

function getVehicleReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + '/api/vehicle/report', requestOptions).then(
    handleResponse,
    handleError
  );
}

function getEquipmentSoldReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/equipmentsoldreport',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateEmployeeVehicleReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/vehicle/vehiclereportcsv',
    requestOptions
  ).then((res) => exportToCSV(res, 'vehicle_report'), handleError);
}

function generateEquipmentSoldReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/equipmentsoldreportcsv',
    requestOptions
  ).then((res) => exportToCSV(res, 'equipment_sold_report'), handleError);
}

function getAllInventoryReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/allinventoryreport',
    requestOptions
  ).then(handleResponse, handleError);
}

function getExpirationDateReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/reports/expiration',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateAllInventoryReportCSV(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/allinventoryreportcsv',
    requestOptions
  ).then((res) => exportToCSV(res, 'inventory_report'), handleError);
}

function generateAllInventoryReportPDF(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/allinventoryreportcsv',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateExpDateReportCSV(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/reports/expiration/print',
    requestOptions
  ).then((res) => exportToCSV(res, 'expiration_report'), handleError);
}

function generateExpDateReportPDF(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/employee/reports/expiration/print',
    requestOptions
  ).then(handleResponse, handleError);
}

function getDocStatusList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/employee/reports/doc-list/details',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateDocStatusListPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/employee/reports/doc-list/generate/report',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateDocStatusListCsv(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/employee/reports/doc-list/generate/report',
    requestOptions
  ).then((res) => exportToCSV(res, 'Docs_status_report'), handleError);
}

function askToFillDocumentsByUsersHash(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/employee/filldocuments ',
    requestOptions
  ).then(handleResponse, handleError);
}

function getEmployeesBirthday(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/birthday/details',
    requestOptions
  ).then(handleResponse, handleError);
}

function generateBdayReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  const filename = getBdayFilename(data.get('date'));
  return fetch(
    config.apiUrl + '/api/employee/birthday/reportcsv',
    requestOptions
  ).then((res) => exportToCSV(res, filename, false), handleError);
}

function printEmployeeBirthdaysReportPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/employee/birthday/reportcsv',
    requestOptions
  ).then(handleResponse, handleError);
}

function getMileageHobbsReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + '/api/inventory/mileage', requestOptions).then(
    handleResponse,
    handleError
  );
}

function generateMileageHobbsReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/mileageCsv',
    requestOptions
  ).then((res) => exportToCSV(res, 'mileage_hobbs_report'), handleError);
}

function generateMileageHobbsReportPDF(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/inventory/mileageCsv',
    requestOptions
  ).then(handleResponse, handleError);
}

// applicants report
function fetchApplicantsReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + '/api/reports/applicants', requestOptions).then(
    handleResponse,
    handleError
  );
}

function fetchApplicantsReportCsv(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + '/api/reports/applicants/export',
    requestOptions
  ).then((res) => exportToCSV(res, 'applicants'), handleError);
}

function fetchApplicantsReportPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + '/api/reports/applicants/export',
    requestOptions
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
