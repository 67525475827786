import React from 'react';

import './customToolTip.css';

function CustomToolTip({ label, children, position }) {
  const tooltipClassName = `custom_tooltip ${position}`;
  return (
    <div className="tooltip_root">
      <div className="custom_tooltip_children">
        {label && <p className={tooltipClassName}>{label}</p>}

        {children}
      </div>
    </div>
  );
}

export default CustomToolTip;
