import { resourceConstants, permissionConstants } from '../_constants';

export function search(state = {}, action) {
  switch (action.type) {
    case resourceConstants.SEARCHUNIVERSE_REQUEST:
      return {
        loading: true,
      };
    case resourceConstants.SEARCHUNIVERSE_SUCCESS:
      return {
        items: action.search,
      };
    case resourceConstants.SEARCHUNIVERSE_FAILURE:
      return {
        error: action.error,
      };
    case permissionConstants.ALLADMIN_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.ALLADMIN_SUCCESS:
      return {
        allAdmin: action.allAdmin,
      };
    case permissionConstants.ALLADMIN_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
