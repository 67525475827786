import { interviewConstants } from '../_constants';

const initialState = {
  loading: false,
  list: [],
  form: {},
  savedForm: [],
};
export function interview(state = initialState, action) {
  switch (action.type) {
    case interviewConstants.INT_FORM_LIST_REQUEST:
      return { ...state, loading: true };
    case interviewConstants.INT_FORM_LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload };
    case interviewConstants.INT_FORM_LIST_FAILURE:
      return { ...state, loading: false };
    case interviewConstants.INT_FORM_GET_REQUEST:
      return { ...state, loading: true };
    case interviewConstants.INT_FORM_GET_SUCCESS:
      return { ...state, loading: false, form: action.payload };
    case interviewConstants.INT_FORM_GET_FAILURE:
      return { ...state, loading: false };
    case interviewConstants.INT_FORM_SAVE_REQUEST:
      return { ...state, loading: true };
    case interviewConstants.INT_FORM_SAVE_SUCCESS:
      return { ...state, loading: false, savedForm: action.payload };
    case interviewConstants.INT_FORM_SAVE_FAILURE:
      return { ...state, loading: false };
    case interviewConstants.INT_FORM_DELETE_REQUEST:
      return { ...state, loading: true };
    case interviewConstants.INT_FORM_DELETE_SUCCESS:
      return { ...state, loading: false };
    case interviewConstants.INT_FORM_DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
