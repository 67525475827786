import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { toast } from 'react-toastify';
import { SignPad } from 'Docs/Components';
import moment from 'moment';
import { FormNav } from './FormNav';
import { navigate, validate } from './formHandlers';

class MPN extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        disclaimer_check: false,
        signed_date: moment().format('MM/DD/YYYY'),
        employee_signature: null,
      },
      errors: {},
      form_edited: false,
      companyName: '',
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  // Handle Datepicker changes
  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: '',
      },
    });
  }

  // Handle Form Change
  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: '',
      },
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;

        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? '';
        }
        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    let companyName;
    let { activeForm } = this.props.docs;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    //
    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? 'About Trees'
          : 'NVTS';
    }
    //

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
      companyName,
    });

    this.getFormData(doc.id);
  }

  componentDidUpdate = (prevProps, prevState) => {
    let companyName = '';
    let { activeForm } = this.props.docs;
    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? 'About Trees'
          : 'NVTS';
    }
    if (prevState.companyName !== companyName) {
      this.setState({
        companyName,
      });
    }
  };

  render() {
    let { form, errors, companyName } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>MPN</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form mpn"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="mpn"
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">
                  {String(
                    companyName !== 'NVTS'
                      ? companyName
                      : 'North Valley Tree Service'
                  ).toUpperCase()}{' '}
                  MPN IMPLEMENTATION NOTICE
                </h6>
                <p>
                  Unless you predesignate a physician or medical group, your new
                  work injuries arising on or after 1/ 20/ 2005 will be treated
                  by providers in the State Fund Medical Provider Network. If
                  you have an existing injury, you should continue treatment
                  with your current primary treating physician. If you sustain a
                  new work injury, treatment for this injury should be obtained
                  through the State Fund Medical Provider Network. You may
                  obtain more information about the MPN from the worker’s
                  compensation poster or from your employer.
                </p>
                <p>
                  I declare under penalty of perjury that I have reviewed and
                  received a copy of the MPN Implementation Notice{' '}
                </p>
              </div>
            </div>
            <div className="row form-row no_border">
              <div className="col">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-head">
                    Printed Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['form.printed_name'] ? 'error' : ''}`}
                      name="printed_name"
                      id="printed_name"
                      value={form.printed_name}
                      field="form.printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors['form.printed_name'] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors['form.printed_name']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['form.date'] ? 'error' : ''} w-100`}
                      name="signed_date"
                      id="signed_date"
                      selected={moment(form.signed_date)}
                      field="form.signed_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        'form.signed_date'
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      required
                    />
                    {errors['form.full_name'] && (
                      <label htmlFor="full_name" className="error_label">
                        {errors['form.full_name']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col w-50">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              'form.employee_signature'
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors['form.employee_signature'] && (
                            <label
                              htmlFor="employee_signature"
                              className="error_label"
                            >
                              {errors['form.employee_signature']}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our <a href="">Terms and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedMPN = compose(withRouter, connect(mapStateToProps))(MPN);
export { connectedMPN as MPN };
