import React, { useEffect, useState, useRef, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { docsActions } from '_actions/docs.actions';
import { toast } from 'react-toastify';
import { SignPad } from 'Docs/Components';
import moment from 'moment';
import { FormNav } from './FormNav';
import { navigateTo, validateForm } from './formHandlers';
import { getSelectedUserInfo } from '_helpers/reduxSelector';

const NVTSEmployeeHandbook = (props) => {
  let { adminMode, currentUserInfo } = props;

  const [doc, setDoc] = useState({});
  const [form, setFormData] = useState({});
  const [formEdited, setFormDataEdited] = useState(false);
  const [errors, setErrors] = useState({});
  const [sign, setSign] = useState();
  const [readDocuments, setReadDocuments] = useState(false);

  const formRef = useRef(null);
  const signContainer = useRef(null);

  useEffect(() => {
    let docSlug = props.match.params.doc;
    let doc;
    if (props.rehire) {
      doc = props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 1
      );
    }

    const height = signContainer.current.clientHeight;
    const width = signContainer.current.clientWidth;

    setSign({
      height,
      width,
    });

    setDoc(doc);
    getFormData(doc.id);
  }, []);

  // Handle Datepicker changes
  const handleDateChange = (field, date) => {
    let nextState = form;
    if (date) {
      _.set(nextState, field, date.format('MM/DD/YYYY'));
    }
    setFormData((prevState) => ({ ...prevState, ...nextState }));
  };

  // Handle Form Change
  const handleFormChange = (e) => {
    // safety check for datepicker
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = form;
      let { value, checked, type } = e.target;
      if (type === 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      setFormData((prevState) => ({ ...prevState, ...nextState }));
    }
    !formEdited && setFormDataEdited(true);
  };

  const submitForm = (e) => {
    e.preventDefault();
    let err = validateForm(e.target, errors);

    if (err && !_.isEmpty(err)) {
      setErrors(err);
      return;
    }

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (props.adminMode) {
      data.admin_mode = props.adminMode;
    }
    props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    props.rehire ? (data.rehire = true) : (data.rehire = false);

    props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (props.mobileMode && !props.lastPage) {
          props.setScreen('sidebar');
          navigateTo('next', props.rehire, props);
        } else if (props.lastPage) {
          if (props.adminMode) {
            props.history.push(`/docs`);
          } else {
            props.history.push(`/dashboard`);
          }
        } else {
          navigateTo('next', props.rehire, props);
        }
      } else {
        if (props.mobileMode) props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  };

  const handleSignature = (field, signature) => {
    let nextState = form;
    _.set(nextState, field, signature);
    setFormData((prevState) => ({ ...prevState, ...nextState }));
  };

  // GET FORM data from api with id passed in props
  const getFormData = (id) => {
    props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        let { form, user } = res.data;

        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? '';
        }
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || moment().format('MM/DD/YYYY');
        // spread the data coming from API into state
        setFormData((prevState) => ({
          ...prevState,
          ...form,
        }));
      }
    });
  };

  const goBack = () => {
    if (formEdited) {
      props.toggleCloseConfirmation(true);
    } else {
      props.setScreen('sidebar');
    }
  };

  return (
    <div className="doc_info-rh">
      {/* Title */}
      <div className="doc-title">
        <img
          src={`${config.assetUrl}icons/doc-title-img.svg`}
          className="doc-title-img"
          alt=""
        />
        <span className="close-button" onClick={() => goBack}>
          <img src={`${config.assetUrl}icons/back-btn.svg`} alt="" />
        </span>
        <h3>NVTS Employee Handbook Acknowledgement of Receipt</h3>
      </div>
      <div className="doc_info-form">
        <form
          className="info-form mpn"
          ref={formRef}
          onChange={handleFormChange}
          onSubmit={submitForm}
          name="NVTSEmployeeHandbook"
          noValidate
        >
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">
                  Employee Handbook Acknowledgement of Receipt
                </h6>
                {/*row ends */}
                <div className="row form-row">
                  <div className="col">
                    <div className="imp__notes_handbook">
                      <div>
                        Please click the link below to read and review the
                        Employee Handbook. Once you have read and understood,
                        please sign the acknowledgment below and submit your
                        documents.
                      </div>
                      <div className="handbook_read_text">
                        <a
                          href={currentUserInfo?.handbook_link}
                          onClick={() => setReadDocuments(true)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read Handbook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  I understand that the Employee Handbook has been prepared as a
                  guide for policies and general information, which should
                  assist me during my employment. However, this handbook is not
                  all inclusive. These guidelines are not, and should not be
                  considered as, a contract. They are not conditions of
                  employment. NVTS reserves the right to make changes in content
                  or application as it deems appropriate, and these changes may
                  be implemented even if they have not been communicated,
                  represented, or substituted in this handbook. I also
                  understand that nothing in the Employee Handbook changes the
                  fact that my employment is At-Will, meaning that either I or
                  NVTS may terminate my employment at any time for any reason.
                </p>
                <p>
                  I acknowledge receipt of the NVTS Employee Handbook via the
                  hiring documents and the EMS. I agree to read and become
                  familiar with its contents. I understand that I may obtain a
                  printed copy upon written request at no cost.{' '}
                </p>
              </div>
            </div>
            <div className="row form-row no_border">
              <div className="col">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-head">
                    Printed Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors['printed_name'] ? 'error' : ''}`}
                      name="printed_name"
                      id="printed_name"
                      value={form.printed_name}
                      field="printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors['printed_name'] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors['printed_name']}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors['date'] ? 'error' : ''} w-100`}
                      name="signed_date"
                      id="signed_date"
                      selected={moment(form.signed_date)}
                      field="signed_date"
                      placeholder="mm/dd/yyyy"
                      onChange={(date) => handleDateChange('signed_date', date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      required
                    />
                    {errors['full_name'] && (
                      <label htmlFor="full_name" className="error_label">
                        {errors['full_name']}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col w-50">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div ref={signContainer} className="sign_here">
                      {sign && (
                        <Fragment>
                          <SignPad
                            id="mpnEmpSignPad"
                            height={sign.height}
                            width={sign.width}
                            handleSignature={(empSign) =>
                              handleSignature('signature', empSign)
                            }
                            signature={form.signature}
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={form.signature}
                            name="signature"
                            field="signature"
                            required
                          ></input>
                          {errors['signature'] && (
                            <label htmlFor="signature" className="error_label">
                              {errors['signature']}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our <a href="">Terms and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={() => navigateTo('prev', props.rehire, props)}
              disclaimer_check={form.disclaimer_check && readDocuments}
              mobileMode={props.mobileMode}
              lastPage={props.lastPage}
            />
          </fieldset>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getSelectedUserInfo(state) };
}

let connectedNVTSEmployeeHandbook = compose(
  withRouter,
  connect(mapStateToProps)
)(NVTSEmployeeHandbook);
export { connectedNVTSEmployeeHandbook as NVTSEmployeeHandbook };
