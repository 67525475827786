import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { getApplicantStatus, getCompanyName } from '_utils/utils';

const ASC_CLASS = 'sort-arrow asc';

const DESC_CLASS = 'sort-arrow desc';

const initialStyleState = {
  companyClass: ASC_CLASS,
  dateClass: ASC_CLASS,
  statusClass: ASC_CLASS,
};

const noDataFoundStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 150,
};

export default function JobApplicationHistoryList({
  getJobApplicationHistory,
  data,
  lastPage,
}) {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState('applying_company');
  const [page, setPage] = useState(0);
  const isLoading = false;
  const [initialStyleStateTypes, setStyleState] = useState(initialStyleState);
  const { companyClass, dateClass, statusClass } = initialStyleStateTypes;

  useEffect(() => {
    getJobApplicationHistory(getParams());
  }, [order, sortItem, page]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage?.selected + 1);
  };

  const getParams = () => {
    const params = {
      page,
      sort: sortItem,
      order: order ? 'asc' : 'desc',
    };
    return params;
  };

  const noDataAvailable = !isLoading && !data?.length;

  return (
    <>
      <div className="history_lists outer_list">
        <table className="mobile_hide report_list_width_full">
          <thead>
            <tr className="report_list_table_row">
              <th
                onClick={() =>
                  sortList('companyClass', companyClass, 'applying_company')
                }
              >
                <span>Company</span>
                <span className={companyClass} />
              </th>
              <th onClick={() => sortList('dateClass', dateClass, 'date')}>
                <span>Date of Submission</span>
                <span className={dateClass} />
              </th>
              <th
                onClick={() =>
                  sortList('statusClass', statusClass, 'job_status')
                }
              >
                <span>Status</span>
                <span className={statusClass} />
              </th>
              <th>
                <span>Notes</span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody className="report_list_table_tbody">
            {noDataAvailable ? (
              <tr>
                <td colSpan="5">
                  <div
                    className="dashboard_expiration_date_no_data"
                    style={noDataFoundStyle}
                  >
                    No Data Found
                  </div>
                </td>
              </tr>
            ) : (
              data?.map((item, index) => (
                <tr key={index}>
                  <td>{getCompanyName(item?.applying_company) ?? '-'}</td>
                  <td>{item?.date ?? '-'}</td>
                  <td>{getApplicantStatus[item?.status] ?? '-'}</td>
                  <td>{item?.notes ?? '-'}</td>
                  <td>
                    <Link to={'/applicant-view/' + item?.hash} target="_blank">
                      <img
                        alt="view"
                        src={require('../../assets/images/view.png').default}
                        className="applicant_history_icon"
                      />
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {noDataAvailable ? (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        ) : (
          <JobApplicationHistoryListMobileView
            data={data}
            sortList={sortList}
            initialStyleStateTypes={initialStyleStateTypes}
          />
        )}

        {!isLoading && data?.length ? (
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="customised-pagination right-align">
              <ReactPaginate
                forcePage={page}
                previousLabel={''}
                nextLabel={''}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={lastPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(data) => handlePageChange(data)}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

function JobApplicationHistoryListMobileView({
  data,
  initialStyleStateTypes,
  sortList,
  setSortParams,
}) {
  const { companyClass, dateClass, statusClasss } = initialStyleStateTypes;

  return data?.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList('companyClass', companyClass, 'applying_company')
              }
            >
              <strong>Company</strong>
              <span className={companyClass} />
            </div>
            <div className="list_item">
              {getCompanyName(item?.applying_company) ?? '-'}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList('dateClass', dateClass, 'date')}
            >
              <strong>Date of Submission</strong>
              <span className={dateClass} />
            </div>
            <div className="list_item">{item?.date ?? '-'}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList('statusClasss', statusClasss, 'job_status')
              }
            >
              <strong>Status</strong>
              <span className={statusClasss} />
            </div>
            <div className="list_item">
              {getApplicantStatus[item?.status] ?? '-'}
            </div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Notes</strong>
            </div>
            <div className="list_item">{item?.notes ?? '-'}</div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <Link to={'/applicant-view/' + item?.hash} target="_blank">
                <img
                  alt="view"
                  src={require('../../assets/images/view.png').default}
                  className="applicant_history_icon"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
}
