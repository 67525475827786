export const createProfileConstants = {
  // GETRES_REQUEST: 'CREATEPROFILE_GETRES_REQUEST',
  // GETRES_SUCCESS: 'CREATEPROFILE_GETRES_SUCCESS',
  // GETRES_FAILURE: 'CREATEPROFILE_GETRES_FAILURE',

  // GETPANT_REQUEST: 'CREATEPROFILE_GETPANT_REQUEST',
  // GETPANT_SUCCESS: 'CREATEPROFILE_GETPANT_SUCCESS',
  // GETPANT_FAILURE: 'CREATEPROFILE_GETPANT_FAILURE',

  // GETSHIRT_REQUEST: 'CREATEPROFILE_GETSHIRT_REQUEST',
  // GETSHIRT_SUCCESS: 'CREATEPROFILE_GETSHIRT_SUCCESS',
  // GETSHIRT_FAILURE: 'CREATEPROFILE_GETSHIRT_FAILURE',

  // GETQUALIFY_REQUEST: 'CREATEPROFILE_GETQUALIFY_REQUEST',
  // GETQUALIFY_SUCCESS: 'CREATEPROFILE_GETQUALIFY_SUCCESS',
  // GETQUALIFY_FAILURE: 'CREATEPROFILE_GETQUALIFY_FAILURE',

  GETALL_REQUEST: 'CREATEPROFILE_GETALL_REQUEST',
  GETALL_SUCCESS: 'CREATEPROFILE_GETALL_SUCCESS',
  GETALL_FAILURE: 'CREATEPROFILE_GETALL_FAILURE',

  CREATE_REQUEST: 'CREATEPROFILE_CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATEPROFILE_CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATEPROFILE_CREATE_FAILURE',
};
