import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { authHeader, config } from '../_helpers';
import { userActions } from '../_actions/user.actions';
import Modal from 'react-modal';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 1;

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#eef0f2' : '#eef0f2',

  // styles we need to apply on draggables
  ...draggableStyle,
});

function formatPhoneNumber(s) {
  var s2 = ('' + s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + '-' + m[2] + '-' + m[3];
}

const customStyles = {
  content: {
    width: '420px', // ;)
  },
};

class EmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fIndex: 0,
      unlockModal: false,
      lockModal: false,
      archiveModal: false,
      requireDocs: false,
      employeeList: [],
      selectedEmployees: [],
      selectedEmployee: null,
      selectAll: false,
      errors: [],
      errorMessage: '',
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.getDocsStatus = this.getDocsStatus.bind(this);
    this.selectAllEmployees = this.selectAllEmployees.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
    this.statusToggle = this.statusToggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.unlockEmployees = this.unlockEmployees.bind(this);
    this.lockEmployees = this.lockEmployees.bind(this);
    this.archive = this.archive.bind(this);
    this.requireDocs = this.requireDocs.bind(this);
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let employeeList = reorder(
      this.state.employeeList,
      result.source.index,
      result.destination.index
    );

    let fIndex = this.state.fIndex;
    employeeList = employeeList.map((employee, index) => {
      employee.index = fIndex + index;
      return employee;
    });

    this.setState(
      {
        employeeList: [...employeeList],
      },
      function () {
        let data = new FormData();
        data.append('resource_id', this.props.resourceId);
        this.state.employeeList.forEach((item, index) => {
          data.append('user_hashs[' + index + ']', item.hash);
        });
        fetch(config.apiUrl + '/api/employee/employeeposition', {
          headers: authHeader(),
          method: 'POST',
          body: data,
        });
      }
    );
  }

  getDocsStatus(status, doc_type) {
    let status_code;
    if (doc_type === 'onboarding') {
      switch (status) {
        case -1:
          status_code = {
            className: 'fas fa-times-circle text-red',
            title: 'Onboarding Document not filled',
          };
          break;
        case 0:
          status_code = {
            className: 'fas fa-check-circle text-gray',
            title: 'Asked to fill Onboarding Document',
          };
          break;
        case 1:
          status_code = {
            className: 'fas fa-check-circle text-gray',
            title: 'Started filling Onboarding Document',
          };
          break;
        case 2:
          status_code = {
            className: 'fas fa-check-circle text-yellow',
            title: 'Submitted For Review',
          };
          break;
        case 3:
          status_code = {
            className: 'fas fa-check-circle text-green',
            title: 'Approved Onboarding Document',
          };
          break;
      }
    } else if (doc_type === 'rehire') {
      switch (status) {
        case -1:
          status_code = {
            className: 'fas fa-times-circle text-red',
            title: 'Rehire Document not filled',
          };
          break;
        case 0:
          status_code = {
            className: 'fas fa-check-circle text-gray',
            title: 'Asked to fill Rehire Document',
          };
          break;
        case 1:
          status_code = {
            className: 'fas fa-check-circle text-gray',
            title: 'Started filling Rehire Document',
          };
          break;
        case 2:
          status_code = {
            className: 'fas fa-check-circle text-yellow',
            title: 'Submitted For Review',
          };
          break;
        case 3:
          status_code = {
            className: 'fas fa-check-circle text-green',
            title: 'Approved Rehire Document',
          };
          break;
      }
    }
    return status_code;
  }

  selectAllEmployees(event) {
    const selectAll = event.target.checked;
    const allEmployees = this.state.employeeList.map((item) => item.hash); // get item hash from each employees
    this.setState({
      selectedEmployees: selectAll ? allEmployees : [],
      selectAll,
    });
  }

  selectEmployee(item) {
    let selectedEmployee = null;
    let newArray = [...this.state.selectedEmployees, item.hash];
    if (this.state.selectedEmployees.includes(item.hash)) {
      newArray = newArray.filter((user_hash) => user_hash !== item.hash);
    }
    newArray.length === 1 &&
      (selectedEmployee = this.state.employeeList.find(
        (employee) => employee.hash === newArray[0]
      ));

    this.setState({
      selectedEmployees: newArray,
      selectedEmployee,
    });
  }

  statusToggle(lock) {
    lock
      ? this.setState({ lockModal: true })
      : this.setState({ unlockModal: true });
  }

  archiveToggle(archive) {
    archive
      ? this.setState({ archiveModal: true })
      : this.setState({ unArchiveModal: true });
  }

  lockEmployees() {
    let data = new FormData();
    this.state.selectedEmployees.forEach((item, index) => {
      data.append(`hash[${index}]`, item);
    });
    data.append('status', 'Locked');
    this.props.dispatch(userActions.lockToggle(data)).then((res) => {
      this.props.updateList();
      this.setState({ selectedEmployees: [], lockModal: false });
    });
  }

  unlockEmployees() {
    let data = new FormData();
    this.state.selectedEmployees.forEach((item, index) => {
      data.append(`hash[${index}]`, item);
    });
    data.append('status', 'Unlocked');
    data.append('document', this.state.requireDocs);
    this.props.dispatch(userActions.lockToggle(data)).then((res) => {
      this.props.updateList();
      this.setState({
        selectedEmployees: [],
        unlockModal: false,
        requireDocs: false,
      });
    });
  }

  archive() {
    // previously remove
    let data = new FormData();
    this.state.selectedEmployees.forEach((item, index) => {
      data.append(`hash[${index}]`, item);
    });
    this.props
      .dispatch(userActions.removeEmployee(data))
      .then((res) => {
        let { success } = res;
        if (success) {
          this.setState({ selectedEmployees: [], archiveModal: false });
          this.props.updateList();
        }
      })
      .catch((res) => {
        let { success, message } = res;
        !success &&
          this.setState({ errors: ['archiveModal'], errorMessage: message });
      });
    // this.props.goBack(this.props.resourceDetails);
  }

  requireDocs(e) {
    this.setState({ requireDocs: e.target.checked });
  }

  closeModal(modal) {
    this.setState({ [modal]: false, errors: [], errorMessage: '' });
  }

  componentDidMount() {
    let { employeeList } = this.props;
    this.setState({
      fIndex: employeeList[0] ? employeeList[0].index : 0,
      employeeList,
    });
  }

  componentDidUpdate(oldProps) {
    let { employeeList } = this.props;
    if (oldProps.employeeList !== employeeList) {
      this.setState({
        fIndex: employeeList[0] ? employeeList[0].index : 0,
        employeeList,
      });
    }
    employeeList.length === 0 &&
      this.state.selectAll &&
      this.setState({ selectAll: false });
  }

  render() {
    let { employeeList } = this.state;
    let { className } = this.props;
    var permList = this.props.permList || [];

    return (
      <div
        className={`employee_list bulk-select outer_list ${
          className ? className : ''
        }`}
      >
        {/* Modals */}
        {/* Unlock */}
        <Modal
          isOpen={this.state.unlockModal}
          contentLabel="Unloack Employees"
          style={customStyles}
          overlayClassName="RemoveApplntPopup emp_status_modal"
        >
          <div className="modal-head">
            <h4>Unlock Employees</h4>
            <img
              alt="close"
              className="closeModal"
              onClick={() => this.closeModal('unlockModal')}
              src={require('../assets/images/close_green.png').default}
            />
          </div>
          <div className="modal-body emp_status">
            <section className="d-flex flex-column checkb">
              <h6 className="text-bold">
                Are you sure you want to Unlock the selected{' '}
                {this.state.selectedEmployees.length > 1
                  ? 'employees'
                  : 'employee'}{' '}
                ?
              </h6>
              {this.state.selectedEmployees.length === 1 &&
                this.state.selectedEmployee &&
                (this.state.selectedEmployee.docs_status === -1 ||
                  this.state.selectedEmployee.rehire_docs_status === -1) && (
                  <label>
                    <input
                      type="checkbox"
                      onChange={this.requireDocs}
                      defaultChecked={this.state.requireDocs}
                    />
                    <span>Ask to fill Documents</span>
                  </label>
                )}
              {this.state.selectedEmployees.length > 1 && (
                <label>
                  <input
                    type="checkbox"
                    onChange={this.requireDocs}
                    defaultChecked={this.state.requireDocs}
                  />
                  <span>Ask to fill Documents</span>
                </label>
              )}
            </section>
            <section className="emp_button-group">
              <button
                className="button grey"
                onClick={() => this.closeModal('unlockModal')}
              >
                Cancel
              </button>
              <button className="button" onClick={this.unlockEmployees}>
                Unlock
              </button>
            </section>
          </div>
        </Modal>

        {/* Lock */}
        <Modal
          isOpen={this.state.lockModal}
          contentLabel="Unloack Employees"
          style={customStyles}
          overlayClassName="RemoveApplntPopup emp_status_modal lock"
        >
          <div className="modal-head">
            <h4>Lock Employees</h4>
            <img
              alt="close"
              className="closeModal"
              onClick={() => this.closeModal('lockModal')}
              src={require('../assets/images/close_green.png').default}
            />
          </div>
          <div className="modal-body emp_status">
            <section className="d-flex flex-column checkb">
              <h6 className="text-bold">
                Are you sure you want to Lock the selected{' '}
                {this.state.selectedEmployees.length > 1
                  ? 'employees'
                  : 'employee'}{' '}
                ?
              </h6>
            </section>
            <section className="emp_button-group">
              <button
                className="button grey"
                onClick={() => this.closeModal('lockModal')}
              >
                Cancel
              </button>
              <button className="button" onClick={this.lockEmployees}>
                Lock
              </button>
            </section>
          </div>
        </Modal>

        {/* Archive */}
        <Modal
          isOpen={this.state.archiveModal}
          contentLabel="Unloack Employees"
          style={customStyles}
          overlayClassName="RemoveApplntPopup emp_status_modal lock"
        >
          <div className="modal-head">
            <h4>Archive Employees</h4>
            <img
              alt="close"
              className="closeModal"
              onClick={() => this.closeModal('archiveModal')}
              src={require('../assets/images/close_green.png').default}
            />
          </div>
          <div className="modal-body emp_status">
            <section className="d-flex flex-column checkb">
              {!this.state.errorMessage && this.state.errors && (
                <h6 className="text-bold">
                  Are you sure you want to Archive the selected{' '}
                  {this.state.selectedEmployees.length > 1
                    ? 'employees'
                    : 'employee'}{' '}
                  ?
                </h6>
              )}
              {/* if any errors occurs in archive modal activity */}
              {this.state.errorMessage && this.state.errors && (
                <h6 className="text-bold text-red ">
                  {this.state.errorMessage}
                </h6>
              )}
            </section>
            <section className="emp_button-group">
              <button
                className="button grey"
                onClick={() => this.closeModal('archiveModal')}
              >
                Cancel
              </button>
              {/* Hides if any error in archive modal activity */}
              {!this.state.errors.includes('archiveModal') && (
                <button className="button" onClick={this.archive}>
                  Archive
                </button>
              )}
            </section>
          </div>
        </Modal>

        {/* buttons */}
        <section className="button-list">
          {/* Lock Employee */}
          {this.state.selectedEmployees.length > 0 &&
            this.props.type === 'available' &&
            (permList.includes('Manage Docs') ||
              permList.includes('Status Update')) && (
              <button
                className="button remove_resource"
                onClick={() => this.statusToggle(true)}
              >
                Lock
              </button>
            )}
          {/* Unlock Employee */}
          {this.state.selectedEmployees.length > 0 &&
            this.props.type === 'locked' &&
            (permList.includes('Manage Docs') ||
              permList.includes('Status Update')) && (
              <button
                className="button remove_resource"
                onClick={() => this.statusToggle(false)}
              >
                Unlock
              </button>
            )}
          {this.state.selectedEmployees.length > 0 &&
            permList.includes('Remove Employee') && (
              <button
                className="button remove_resource"
                onClick={() => this.archiveToggle(true)}
              >
                Archive
              </button>
            )}
        </section>
        {/* end of buttons */}

        <div className="list_header">
          {(permList.includes('Manage Employee') ||
            permList.includes('Remove Employee') ||
            permList.includes('Status Update') ||
            permList.includes('Manage Docs')) && (
            <div className="list_item_head custom-checkbox ">
              <label className="input-container">
                <input
                  type="checkbox"
                  onChange={this.selectAllEmployees}
                  defaultChecked={this.state.selectAll}
                  //checked={this.state.selectAll}
                  disabled={this.props.type === 'guest'}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          )}
          <div className="list_item_head image-holder">Employee</div>
          <div className="list_item_head">Name</div>
          <div className="list_item_head ">Title</div>
          <div className="list_item_head ">Phone #</div>
          <div className="list_item_head ">Email</div>
          {permList.includes('Manage Docs') && (
            <div className="list_item_head flex-shrink-1 text-center">
              Onboarding
            </div>
          )}
          {permList.includes('Manage Docs') && (
            <div className="list_item_head flex-shrink-1 text-center">
              Rehire
            </div>
          )}
        </div>
        {/* <SortableList items={this.state.employeeList} onSortEnd={this.onSortEnd.bind(this)} pressDelay={200} clickFunction={this.profile.bind(this)} onImageError={this.onImageError.bind(this)} helperclassName='sortableHelper' transitionDuration={600}/> */}
        {employeeList && employeeList.length ? (
          <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="employeeOuterDiv"
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {employeeList.map((item, index) => (
                    <Draggable
                      key={item.id + 'emp' + index}
                      draggableId={item.id + 'emp' + index}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="empployeeItem"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="emp_link_resource crew_wrapper  resource_details bg-fill">
                            {/* Items */}
                            <div className="employee_item bulk-select ">
                              {(permList.includes('Manage Employee') ||
                                permList.includes('Remove Employee') ||
                                permList.includes('Status Update') ||
                                permList.includes('Manage Docs')) && (
                                <div className="list_item custom-checkbox">
                                  <label className="input-container">
                                    <input
                                      type="checkbox"
                                      checked={this.state.selectedEmployees.includes(
                                        item.hash
                                      )}
                                      onChange={() => this.selectEmployee(item)}
                                      disabled={this.props.type === 'guest'}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              )}
                              <div
                                className="list_item image-holder "
                                datato={'/resource-profile/' + item.hash}
                                onClick={() => {
                                  this.props.profile(
                                    '/resource-profile/' + item.hash
                                  );
                                }}
                              >
                                <span className="pos_counter">
                                  {item.index ? item.index + 1 : index + 1}
                                </span>
                                <img
                                  alt={item.first_name + ' ' + item.last_name}
                                  className={item.active}
                                  src={
                                    item.profile_photo
                                      ? config.apiUrl + '/' + item.profile_photo
                                      : config.apiUrl + '/' + config.altImg
                                  }
                                  onError={this.onImageError.bind(this)}
                                />
                              </div>
                              <div
                                className="list_item "
                                datato={'/resource-profile/' + item.hash}
                                onClick={() => {
                                  this.props.profile(
                                    '/resource-profile/' + item.hash
                                  );
                                }}
                              >
                                <span
                                  className={item.colorcode + ' color_code'}
                                />
                                <span className="emp_name">
                                  {item.first_name + ' ' + item.last_name}
                                </span>
                              </div>
                              <div
                                className="list_item "
                                datato={'/resource-profile/' + item.hash}
                                onClick={() => {
                                  this.props.profile(
                                    '/resource-profile/' + item.hash
                                  );
                                }}
                              >
                                {item.user_type}
                              </div>
                              <div
                                className="list_item "
                                datato={'/resource-profile/' + item.hash}
                                onClick={() => {
                                  this.props.profile(
                                    '/resource-profile/' + item.hash
                                  );
                                }}
                              >
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${item.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(item.phone)}
                                </a>
                              </div>
                              <div
                                className="list_item "
                                datato={'/resource-profile/' + item.hash}
                                onClick={() => {
                                  this.props.profile(
                                    '/resource-profile/' + item.hash
                                  );
                                }}
                              >
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`mailto:${item.email}`}
                                  target="_top"
                                >
                                  {item.email}
                                </a>
                              </div>
                              {permList.includes('Manage Docs') && (
                                <div className="list_item flex-shrink-1 docs-status">
                                  <p className="note_item ml-3 text-center">
                                    <span className="mobile-status mr-1">
                                      {
                                        this.getDocsStatus(
                                          item.docs_status,
                                          'onboarding'
                                        ).title
                                      }
                                    </span>
                                    <i
                                      className={
                                        this.getDocsStatus(
                                          item.docs_status,
                                          'onboarding'
                                        ).className
                                      }
                                    ></i>
                                    <span className="note_full docs-status">
                                      {
                                        this.getDocsStatus(
                                          item.docs_status,
                                          'onboarding'
                                        ).title
                                      }
                                    </span>
                                  </p>
                                </div>
                              )}
                              {permList.includes('Manage Docs') && (
                                <div className="list_item flex-shrink-1 docs-status">
                                  <p className="note_item ml-3 text-center">
                                    <span className="mobile-status mr-1">
                                      {
                                        this.getDocsStatus(
                                          item.docs_status,
                                          'rehire'
                                        ).title
                                      }
                                    </span>
                                    <i
                                      className={
                                        this.getDocsStatus(
                                          item.rehire_docs_status,
                                          'rehire'
                                        ).className
                                      }
                                    ></i>
                                    <span className="note_full docs-status">
                                      {
                                        this.getDocsStatus(
                                          item.rehire_docs_status,
                                          'rehire'
                                        ).title
                                      }
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="applicant_item" key="noEmployees">
            <p>No employees found!</p>
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeList;
