import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/inventoryMob.css';
import './inventory.mobile.scss';
import DatePicker from 'react-datepicker';
import ReactModal from 'react-modal';
import moment from 'moment';
import {
  inventoryActions,
  permissionActions,
  resourceActions,
  userActions,
} from '../_actions';
import { authHeader, config } from '../_helpers';
import InventoryQuickAssign from '_components/InventoryQuickAssign';
import FileUpload from '_components/FileUpload';
import { INVENTORY_VIEW_DATE_FORMAT } from '../_utils/export';
import InventoryNotes from './InventoryNotes';
class InventoryDetailsMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invKey: '',
      inv_id: '',
      invType: '',
      timeout: null,
      sidebarLoading: false,
      searchResult: [],
      searchLoading: false,
      step: 1, //current active step of user
      inventoryList: {}, // inventory object with type as  key
      Filteroption: 'All', // by default the filter will be all for inventory filter
      isdetailOptionsOpen: false, //flag to show if the detail side bar is open or closed
      permList: [], //array holding user permissions
      activeEquipDetail: {}, //object contain the active inventory who's details is loaded
      selectedInventory: {}, //id of active inventory once the user click on one inventory (id from inventoryList api)
      allEquipItems: {}, //Equipments
      crewList: [], // list of crew
      crewMembers: [], //list of memebers in a crew
      selectedCrew: {}, //the details of selected crew ( from  crewList)
      selectedMember: {}, //the details of member crew ( from  crewMembers)
      assignDate: moment(),
      inventoryModalOpen: false, // flag to show or hide the popup to display calander
      userAction: '', // current user action used at time of submit
      loginUser: {}, // details of loged in user , used if the permission is only Assign Inventory
      showSucessErrorMessage: { className: '', Message: '' },
      resourcesImages: [],
      printLoading: false,
      sidebar: '',
      div_filter: '',
      INVENTORY_VIEW_DATE_FORMAT: '',
    };
  }
  componentDidMount() {
    let dataOption = new FormData();
    dataOption.append('option', 'All');
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getListEquipments());
    this.props.dispatch(inventoryActions.getList(dataOption));
  }

  componentWillReceiveProps(nextProps) {
    const {
      equipments,
      list,
      equipList,
      available,
      assign,
      printLoading,
      printUrl,
      error,
    } = nextProps.inventory;

    let division_list = [];

    this.setState({ printLoading });

    if (printUrl) {
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        window.open(printUrl);
      } else {
        window.open(printUrl, '_blank');
      }

      delete nextProps.inventory.printUrl;
    }

    const crewMember = nextProps.inventory.users;
    const { permission, resource, user } = nextProps;

    this.setState({
      INVENTORY_VIEW_DATE_FORMAT:
        user?.items?.data?.date_formats?.INVENTORY_VIEW_DATE_FORMAT,
    });

    if (user.items && !this.state.loginUser.hash) {
      let { data } = user.items;
      this.setState({ loginUser: data }, () => {
        this.runFilter(user.items.data.crew_identifier_id);
      });
    }
    if (error) {
      let parseError = this.validJSONString(error);
      this.showErrorSucessMessage(parseError ? parseError : error);
      delete nextProps.inventory.error;
    }
    if (assign) {
      this.showErrorSucessMessage(assign);
      let filter;
      let { Filteroption, loginUser } = this.state;
      if (
        this.state.permList &&
        this.state.permList.includes('Search/List Equipment')
      ) {
        filter = Filteroption;
      } else if (
        this.state.permList &&
        this.state.permList.includes('Assign Inventory')
      ) {
        filter = loginUser.crew_identifier_id;
      }
      this.runFilter(filter);
    }
    if (available) {
      // when permission is just Assign Inventory
      this.loadinventoryList(available);
    }
    if (crewMember) {
      // list members in a crew
      let crewMembers = [];
      crewMembers = crewMember.data;
      this.setState({ crewMembers: crewMembers });
    }
    if (resource.items) {
      //list the crew on search
      let crewList = [];
      Object.keys(resource.items.data).forEach((type) => {
        resource.items.data[type].forEach((crew) => {
          crewList.push(crew);
        });
      });
      this.setState({ crewList: crewList });
    }
    if (equipments) {
      if (equipments.Division) {
        equipments.Division.map((item, index) => {
          division_list.push(
            <option key={index} value={item.id}>
              {item.value}
            </option>
          );
          return division_list;
        });
      }
      this.setState({ allEquipItems: equipments, division_list });
    }

    if (equipList) {
      //on select of one inventory to show the details

      if (equipList.data) {
        if (
          this.state.allEquipItems.Division &&
          this.state.allEquipItems.SubPackages &&
          this.state.allEquipItems.EngineCertification
        ) {
          this.state.allEquipItems.Division.forEach((item) => {
            if (item.id === equipList.data.division_id) {
              equipList.data.divisionValue = item.value;
            }
          });
          this.state.allEquipItems.SubPackages.forEach((item) => {
            if (item.id === equipList.data.subpackage_id) {
              equipList.data.subCateg = item.value;
            }
          });

          this.state.allEquipItems.EngineCertification.forEach(
            (item, index) => {
              if (item.id === parseInt(equipList.engine_certification)) {
                equipList.certification = item.value;
              }
            }
          );
          this.setState({ activeEquipDetail: equipList.data });
        } else {
          this.closeDetails();
        }
      }
    }
    if (permission.permissions && this.state.permList.length == 0) {
      // to store user permissions
      let permList = [];
      permList = permission.permissions.data;
      this.setState({ permList }, () => {
        if (
          this.state.permList &&
          this.state.permList.includes('Search/List Equipment')
        ) {
          this.runFilter(this.state.Filteroption);
        } else if (
          this.state.permList &&
          this.state.permList.includes('Assign Inventory')
        ) {
          this.props.dispatch(userActions.getAll());
        } else {
          console.log('no permission');
        }
      });
    }
    if (list) {
      // to load list of inventories
      this.loadinventoryList(list);
    }
  }

  _handleImageError(e) {
    e.target.setAttribute('src', `${config.apiUrl}/${config.altImg}`);
  }

  validJSONString = (str) => {
    let validJson = null;
    try {
      validJson = JSON.parse(str);
    } catch (e) {
      validJson = {
        message: str,
        success: false,
      };
      return validJson;
    }
    return validJson;
  };

  showErrorSucessMessage = (obj) => {
    let { message } = obj;
    let { showSucessErrorMessage } = this.state;
    // if (assign.success) {
    showSucessErrorMessage.className = obj.success
      ? 'message-display-div alert alert-success'
      : 'message-display-div alert alert-danger';
    showSucessErrorMessage.message = message;
    this.setState({ showSucessErrorMessage });
    setTimeout(() => {
      this.setState({
        showSucessErrorMessage: { className: '', message: '' },
      });
    }, 3000);
  };
  /**
   * function to list all inventory in the first screen.
   * @param {list} : contain all the inventories in list.data
   */
  loadinventoryList = (list) => {
    this.setState(
      {
        inventoryList: {},
        isdetailOptionsOpen: false,
      },
      () => {
        let { inventoryList } = this.state;
        let { data } = list;
        // this.closeDetails(); // called when clicking on inventory item, hence preventing sidebar loading immediately
        if (data) {
          for (var i = 0; i < data.length; i++) {
            // to categorise based on inventory type
            var obj = data[i];
            if (!inventoryList.hasOwnProperty(obj.type)) {
              inventoryList[obj.type] = [];
            }
            inventoryList[obj.type].push(obj);
          }
          this.setState(inventoryList);
        }
      }
    );
  };
  /**
   * function to handle selection of filter on the inventory list
   */
  handleSelect = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        inventoryList: {},
      },
      () => this.runFilter(event.target.value)
    );
  };
  /**
   * function to call api once a filter on the inventory list is selected
   * if the user have Search/List Equipment permisission then we need to call list api to get all inventories
   * if the user only have Assign Inventory permission then we need to call another api with crew id to get only inventories assigned in that crew
   */
  runFilter = (value) => {
    if (
      this.state.permList &&
      this.state.permList.includes('Search/List Equipment')
    ) {
      let dataOption = new FormData();
      dataOption.append('option', this.state.Filteroption);
      dataOption.append('division', this.state.div_filter);
      this.props.dispatch(inventoryActions.getList(dataOption));
    } else if (
      this.state.permList &&
      this.state.permList.includes('Assign Inventory')
    ) {
      let dataOption = new FormData();
      dataOption.append('crew_identifier_id', value);
      // this.props.dispatch(inventoryActions.getAvailableList(dataOption));
      this.props.dispatch(inventoryActions.getCrewAllList(dataOption));
    }
  };
  /**
   * function to load inventory details once user click on a single inventory
   * function also navigate to second step if the inventory is unassigned
   * function work based on the permission level of the user and status of the inventory
   * @param {inv} : selected inventory
   */
  viewDetails = (inv) => {
    if (
      (this.state.step == 1 && inv.status == 2 && !inv.reAssign) ||
      (this.state.step > 1 && inv.status == 1)
    ) {
      this.showDetails(inv);
    } else if (
      this.state.permList &&
      this.state.permList.includes('Assign Inventory')
    ) {
      if ((this.state.step == 1 && inv.status == 1) || inv.reAssign) {
        inv.reAssign = false;
        if (this.state.permList.includes('Search/List Equipment')) {
          if (this.state.searchResult.length) {
            this.clearSearch(() => {
              setTimeout(() => {
                this.setState({
                  step: 2,
                  selectedInventory: inv,
                  crewList: [],
                  crewMembers: [],
                  selectedCrew: {},
                  selectedMember: {},
                });
              });
            });
          } else {
            this.setState({
              step: 2,
              selectedInventory: inv,
              crewList: [],
              crewMembers: [],
              selectedCrew: {},
              selectedMember: {},
            });
          }
        } else {
          let { crew_name, crew_identifier_id } = this.state.loginUser;
          this.setState(
            {
              selectedInventory: inv,
              crewList: [],
              crewMembers: [],
              selectedCrew: {},
              selectedMember: {},
            },
            () => {
              this.oncrewSelect(
                { id: crew_identifier_id, resource_name: crew_name },
                3
              );
            }
          );
        }
      }
    } else if (this.state.permList.includes('Search/List Equipment')) {
      this.showDetails(inv);
    }
  };

  /**
   * function to display Inventory details on slide
   * @param {inv}:selected Inventory
   */
  showDetails = (inv) => {
    let inventory = inv;
    inv.reAssign = false;
    this.setState({
      sidebarLoading: true,
      sidebar: 'inv-sidebar-opened',
      equipmentId: inv?.id,
    });
    if (this.state.activeEquipDetail.id != inv.id) {
      this.props
        .dispatch(inventoryActions.equipmentDetails(inv.id))
        .then((res) => {
          res.success && (inventory = res.data);
          this.setState({
            selectedInventory: inventory,
            activeEquipDetail: inventory,
            isdetailOptionsOpen: false,
            sidebarLoading: false,
            // sidebar: 'inv-sidebar-opened'
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        selectedInventory: inv,
        activeEquipDetail: inv,
        sidebarLoading: false,
        // sidebar: 'inv-sidebar-opened'
      });
      // document.body.className += " " + "inv-sidebar-opened";
    }
  };
  /**
   * function to close the inventory details side bar
   */
  closeDetails = () => {
    this.setState({ sidebar: '' });
    // document.body.classList.remove("inv-sidebar-opened");
  };
  /**
   * function to list crew when user type
   */
  crewListing = (event) => {
    let value = event.target.value;
    if (event.target.value != '') {
      this.setState(
        {
          crewList: [],
        },
        () => {
          let data = new FormData();
          data.append('keyword', value);
          this.props.dispatch(resourceActions.searchResource(data));
        }
      );
    }
  };
  /**
   * function to call api to load members in a crew if next step is 3 otherwise directly navigate to step 4 on assign
   * @param {crew}:selected crew
   * @param {nextStep} : next step to move
   */
  oncrewSelect = (crew, nextStep) => {
    if (nextStep == 3) {
      let data = new FormData();
      data.append('crew_identifier_id', crew.id);
      this.props.dispatch(inventoryActions.getMemberList(data));
    }
    this.setState({ selectedCrew: crew, step: nextStep, selectedMember: {} });
  };
  /**
   * function to navigate to next step 4 when a member is selected
   */
  onMemberSelection = (member, nextStep) => {
    this.setState({ selectedMember: member, step: nextStep });
  };
  /**
   * function to handle back button click
   */
  navigateBack = () => {
    let { step } = this.state;
    step =
      step == 4 && !this.state.selectedMember.first_name ? step - 2 : --step;
    this.setState({
      step: step,
      crewList: [],
      selectedMember: {},
    });
  };
  /**
   * function to move and inventory to master list ( only for assigned inventory)
   */
  moveToMaster = () => {
    this.setState(
      {
        userAction: 'Move to master',
      },
      () => {
        this.submit();
      }
    );
  };
  /**
   * function to show calander on model and close model
   * @param {flag} : if true show calander if false close model
   * @param {action} : represent from where user call the model  like is that at time of move to available or at time of assign etc
   */
  inventoryOpenCloseModal = (flag, action) => {
    this.setState({
      userAction: action,
      inventoryModalOpen: flag,
    });
    this.closeDetails();
  };

  // Set assign date
  handleDateChange = (date) => {
    this.setState({
      assignDate: date,
    });
  };
  /**
   * function to handle Inventory assign and removel
   * work based on the user action in state varible
   */
  submit = () => {
    let {
      selectedInventory,
      Filteroption,
      userAction,
      assignDate,
      activeEquipDetail,
      selectedCrew,
      selectedMember,
      step,
    } = this.state;
    let data = new FormData();
    switch (userAction) {
      case 'Move to available':
        let rid =
          step == 1
            ? activeEquipDetail.resource_identifier_id
            : selectedCrew.id;
        data.append('rid', rid);
        data.append('assign_date', assignDate);
        break;
      case 'Move to master':
        break;
      case 'Assign':
        data.append('rid', selectedCrew.id);
        data.append('hash', selectedMember.hash);
        data.append('assign_date', assignDate);
        break;
      default:
        break;
    }
    data.append('id', selectedInventory.id);
    this.setState({
      activeEquipDetail: {},
      selectedInventory: {},
      inventoryList: {},
      selectedCrew: {},
      step: 1,
    });
    this.props.dispatch(inventoryActions.assignEquipment(data));
    this.inventoryOpenCloseModal(false, userAction);
  };
  reAssign = () => {
    let { selectedInventory } = this.state;
    selectedInventory.reAssign = true;
    this.closeDetails();
    this.viewDetails(selectedInventory);
  };

  // Search handle for inventory search.
  _keyUpFnInventory(event) {
    var value = (event.target.value || '').trim();
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.setState({ invKey: value }, function () {
          this.handleFilter();
        });
        clearTimeout(this.state.timeout);
      }.bind(this),
      500
    );
  }

  handleFilter() {
    if (!this.state.invKey) return;
    let data = new FormData();
    if (this.state.option !== '')
      data.append('option', this.state.Filteroption);
    if (this.state.invKey !== '') data.append('keyword', this.state.invKey);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: data,
    };
    this.setState({
      searchLoading: true,
    });
    fetch(config.apiUrl + '/api/inventory/getlist', requestOptions)
      .then((response) => {
        response.json().then((data) => {
          var result = [];
          if (!data || !data.data || !data.data.length) {
            result = [];
          } else {
            result = data.data;
          }
          this.setState({
            searchResult: result,
            searchLoading: false,
          });
        });
      })
      .catch((err) => {
        this.setState({
          searchResult: [],
          searchLoading: false,
        });
      });
  }

  clearSearch(callback) {
    this.setState(
      {
        invKey: '',
        searchResult: [],
      },
      () => {
        var ele = document.getElementById('search_box_filter');
        ele.value = '';
        typeof callback === 'function' && callback.call(this);
      }
    );
  }

  handleAssignModal() {
    this.setState((state) => ({
      showAssignModal: !state?.showAssignModal,
      sidebar: false,
    }));
  }

  getAllList() {
    let dataOption = new FormData();
    dataOption.append('option', 'All');
    this.props.dispatch(inventoryActions.getList(dataOption));
  }
  render() {
    let {
      inventoryList,
      activeEquipDetail,
      selectedInventory,
      crewList,
      selectedCrew,
      crewMembers,
      selectedMember,
      permList,
    } = this.state;
    return (
      <React.Fragment>
        <div className={`inv-wrapper ${this.state.sidebar}`}>
          <div className="container inv-mobile-wrapper">
            {/* section to show details on click */}
            <span
              className="inv-side-menu-overlay"
              onClick={() => {
                this.closeDetails();
              }}
            />
            <div className="inv-sidemenu-box">
              <div className="inv-header-controls">
                <div
                  className="inv-icon-icons-button  inv-back-arrow"
                  onClick={() => {
                    if (this.state.isdetailOptionsOpen) {
                      this.setState({
                        isdetailOptionsOpen: false,
                      });
                    } else {
                      this.closeDetails();
                    }
                  }}
                >
                  <img
                    src={require('../assets/images/1_objects@2x.png').default}
                  />
                </div>
                {!this.state.isdetailOptionsOpen && (
                  <div
                    className="inv-icon-icons-button  inv-dots "
                    onClick={() => {
                      this.setState({ isdetailOptionsOpen: true });
                    }}
                  >
                    <img
                      src={
                        require('../assets/images/1_objects@2x_1.png').default
                      }
                    />
                  </div>
                )}
              </div>
              <div className="inv-header-contents">
                <div className="inv-item-head-wrap">
                  <div className="inv-item-info-main">
                    <div className="inv-sidebar-item-thumb">
                      <img
                        src={
                          activeEquipDetail.pic &&
                          activeEquipDetail.pic !== null &&
                          activeEquipDetail.pic !== ''
                            ? `${config.s3BucketUrl}/${activeEquipDetail.pic}`
                            : `${config.apiUrl}/${config.altInvImg}`
                        }
                        onError={(e) => {
                          this._handleImageError(e);
                        }}
                      />
                    </div>
                    <div className="inv-item-content">
                      <div className="inv-inline-row">
                        <span className="inv-label-bold">Selected: </span>{' '}
                        {activeEquipDetail.unique_id}
                      </div>
                      <div className="inv-inline-row">
                        <span className="inv-label-bold">Crew: </span>{' '}
                        {activeEquipDetail.resource
                          ? activeEquipDetail.resource.resource_name
                          : ''}
                      </div>
                      <div className="inv-inline-row">
                        <span className="inv-label-bold">Assigned to: </span>
                        {activeEquipDetail.user
                          ? `${activeEquipDetail.user.first_name} ${activeEquipDetail.user.last_name}`
                          : '  '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inv-item-body-banner">
                  {this.state.sidebarLoading ? (
                    <p className="text-center">Loading...</p>
                  ) : (
                    <section>
                      {this.state.isdetailOptionsOpen && (
                        <div className="inv-actions-buttons">
                          {permList.includes('Assign Inventory') &&
                            selectedInventory.status == 2 && (
                              <React.Fragment>
                                {permList.includes(
                                  'Add/Edit/Archive Equipments'
                                ) && (
                                  <button
                                    className="inv-action-button"
                                    onClick={this.moveToMaster}
                                  >
                                    Move to master
                                  </button>
                                )}
                                <button
                                  className="inv-action-button"
                                  onClick={this.reAssign}
                                >
                                  Reassign
                                </button>

                                {this.state.activeEquipDetail.user && (
                                  <button
                                    className="inv-action-button"
                                    onClick={() => {
                                      this.inventoryOpenCloseModal(
                                        true,
                                        'Move to available'
                                      );
                                    }}
                                  >
                                    Move to available
                                  </button>
                                )}
                              </React.Fragment>
                            )}
                          {permList.includes('Add/Edit/Archive Equipments') && (
                            <button
                              className="inv-action-button"
                              sidebarLoading
                              onClick={() => {
                                this.closeDetails();
                                this.props.editInventory(
                                  activeEquipDetail.id,
                                  this.state.selectedInventory.id
                                );
                              }}
                            >
                              Edit
                            </button>
                          )}
                          {permList.includes('Assign Staff') && (
                            <button
                              className="inv-action-button"
                              sidebarLoading
                              onClick={() => {
                                this?.handleAssignModal();
                              }}
                            >
                              Assign
                            </button>
                          )}
                          <button
                            className="inv-action-button"
                            onClick={() => {
                              this.props.dispatch(
                                inventoryActions.printInventory({
                                  id: activeEquipDetail.id,
                                  equipment_type_id: activeEquipDetail.type_id,
                                })
                              );
                            }}
                          >
                            Print
                            {this.state.printLoading && (
                              <div
                                className="mobile-print-loader"
                                style={{
                                  backgroundImage:
                                    'url(' +
                                    require('../assets/images/loader.gif')
                                      .default +
                                    ')',
                                }}
                              ></div>
                            )}
                          </button>
                        </div>
                      )}
                      {!this.state.isdetailOptionsOpen && (
                        <ul className="">
                          <li>
                            <div className="inv-inline-row">Division</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.divisionValue
                                ? activeEquipDetail.divisionValue
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Category</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.type
                                ? activeEquipDetail.type
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Sub Category</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.subCateg
                                ? activeEquipDetail.subCateg
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Description</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.description
                                ? activeEquipDetail.description
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Year</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.year
                                ? activeEquipDetail.year
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Assigned Date</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.assign_date
                                ? activeEquipDetail.assign_date
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Make / Model</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.type_id === 1
                                ? activeEquipDetail.vehicle_model_or_make
                                  ? activeEquipDetail.vehicle_model_or_make
                                  : ''
                                : activeEquipDetail.model_or_make
                                  ? activeEquipDetail.model_or_make
                                  : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">
                              Serial Number / VIN
                            </div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.serial_vin_number
                                ? activeEquipDetail.serial_vin_number
                                : ''}
                            </div>
                          </li>
                          {(activeEquipDetail.type_id === 1 ||
                            activeEquipDetail.type_id === 5 ||
                            activeEquipDetail.type_id === 6 ||
                            activeEquipDetail.type_id === 7 ||
                            activeEquipDetail.type_id === 8 ||
                            activeEquipDetail.type_id === 9 ||
                            activeEquipDetail.type_id === 10 ||
                            activeEquipDetail.type_id === 20 ||
                            activeEquipDetail.type_id === 21 ||
                            activeEquipDetail.type_id === 14 ||
                            activeEquipDetail.type_id === 22) && (
                            <React.Fragment>
                              <li>
                                <div className="inv-inline-row">
                                  License Number
                                </div>
                                <div className="inv-inline-row">
                                  {' '}
                                  {activeEquipDetail.vehicle_license
                                    ? activeEquipDetail.vehicle_license
                                    : activeEquipDetail.license_number
                                      ? activeEquipDetail.license_number
                                      : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">GVW</div>
                                <div className="inv-inline-row">
                                  {activeEquipDetail.GVW
                                    ? activeEquipDetail.GVW
                                    : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">
                                  Engine Family
                                </div>
                                <div className="inv-inline-row">
                                  {' '}
                                  {activeEquipDetail.engine_family
                                    ? activeEquipDetail.engine_family
                                    : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">
                                  Engine Model
                                </div>
                                <div className="inv-inline-row">
                                  {' '}
                                  {activeEquipDetail.engine_model
                                    ? activeEquipDetail.engine_model
                                    : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">
                                  Engine Serial
                                </div>
                                <div className="inv-inline-row">
                                  {activeEquipDetail.engine_serial
                                    ? activeEquipDetail.engine_serial
                                    : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">
                                  Engine Description
                                </div>
                                <div className="inv-inline-row">
                                  {activeEquipDetail.engine_description
                                    ? activeEquipDetail.engine_description
                                    : ''}
                                </div>
                              </li>
                              {/* <li>
                                <div className='inv-inline-row'>Engine Certification</div>
                                <div className='inv-inline-row'>
                                  {activeEquipDetail.certification
                                    ? activeEquipDetail.certification
                                    : ""}
                                </div>
                              </li> */}
                              <li>
                                <div className="inv-inline-row">ARB #</div>
                                <div className="inv-inline-row">
                                  {' '}
                                  {activeEquipDetail.ARB
                                    ? activeEquipDetail.ARB
                                    : ''}
                                </div>
                              </li>
                              <li>
                                <div className="inv-inline-row">Non-Op</div>
                                <div className="inv-inline-row">
                                  {activeEquipDetail.non_op
                                    ? activeEquipDetail.non_op
                                    : ''}
                                </div>
                              </li>
                              {(this?.state?.equip_detail?.type_id === 1 ||
                                this?.state?.equip_detail?.type_id === 5 ||
                                this?.state?.equip_detail?.type_id === 6 ||
                                this?.state?.equip_detail?.type_id === 7 ||
                                this?.state?.equip_detail?.type_id === 8 ||
                                this?.state?.equip_detail?.type_id === 9 ||
                                this?.state?.equip_detail?.type_id === 10) && (
                                <li>
                                  <div className="inv-inline-row">
                                    MSPA Exp. Date
                                  </div>
                                  <div className="inv-inline-row">
                                    {activeEquipDetail.mspa_exp_date
                                      ? moment(
                                          activeEquipDetail.mspa_exp_date
                                        ).format('MM/DD/YY')
                                      : ''}
                                  </div>
                                </li>
                              )}
                            </React.Fragment>
                          )}
                          <li>
                            <div className="inv-inline-row">
                              Current Condition
                            </div>
                            <div className="inv-inline-row">
                              {this.state.allEquipItems.CurrentCondition &&
                                this.state.allEquipItems.CurrentCondition.map(
                                  (item, index) =>
                                    item.id ==
                                      activeEquipDetail.current_condition && (
                                      <p key={index} className="field">
                                        {item.value}
                                      </p>
                                    )
                                )}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Year Purchased</div>
                            <div className="inv-inline-row">
                              {' '}
                              {activeEquipDetail.year_purchased
                                ? activeEquipDetail.year_purchased
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Purchase Value</div>
                            <div className="inv-inline-row">
                              {' '}
                              {activeEquipDetail.cost
                                ? activeEquipDetail.cost
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Notes</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.note
                                ? activeEquipDetail.note
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Sold Date</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.sold_date
                                ? activeEquipDetail.sold_date
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Sold To</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.sold_to
                                ? activeEquipDetail.sold_to
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Sold Value</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.sold_value
                                ? activeEquipDetail.sold_value
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">
                              State of Licensure
                            </div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.state_of_licensure
                                ? activeEquipDetail.state_of_licensure
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">
                              Owner of Equipment
                            </div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.equipment_owner
                                ? activeEquipDetail.equipment_owner
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Insurance</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.insurance
                                ? activeEquipDetail.insurance
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Job Code</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.job_code
                                ? activeEquipDetail.job_code
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">Class Code</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.class_code
                                ? activeEquipDetail.class_code
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">ARB Program</div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.arb_program
                                ? activeEquipDetail.arb_program
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">
                              Mileage/Hobbs Meter
                            </div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.mileage
                                ? activeEquipDetail.mileage
                                : ''}
                            </div>
                          </li>
                          <li>
                            <div className="inv-inline-row">
                              Mileage/Hobbs Date
                            </div>
                            <div className="inv-inline-row">
                              {activeEquipDetail.mileage
                                ? moment(activeEquipDetail.mileage_date).format(
                                    this?.state?.INVENTORY_VIEW_DATE_FORMAT
                                  )
                                : ''}
                            </div>
                          </li>
                        </ul>
                      )}
                      <div>
                        <InventoryNotes
                          inventoryTypeId={
                            this.state?.selectedInventory?.type_id
                          }
                          inventoryId={activeEquipDetail?.id}
                          equipmentId={this.state?.equipmentId}
                        />
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <ReactModal
              isOpen={this.state.inventoryModalOpen}
              contentLabel="inventory Popup"
              overlayClassName="inventoryPopup"
            >
              <div id="inventoryModal" className="inventoryModal">
                <div className="modal-head">
                  <h4 className="modal-title">Choose Assign Date</h4>
                  <img
                    alt="close"
                    className="closeModal"
                    onClick={() => {
                      this.inventoryOpenCloseModal(false, '');
                    }}
                    src={require('../assets/images/close_green.png').default}
                  />
                </div>
                <div className="modal-body">
                  <div className="inv_popup_field">
                    <label>Assign Date</label>
                    <DatePicker
                      className="txt "
                      id="assignDate"
                      name="assignDate"
                      // errors={this.state.assignDate_dateError}
                      selected={this.state.assignDate}
                      onChange={this.handleDateChange}
                      maxDate={moment()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <input
                      type="submit"
                      name="submit"
                      value="Save"
                      onClick={this.submit}
                      className="saveDate right button"
                    />
                  </div>
                </div>
              </div>
            </ReactModal>

            <div className="row">
              <div className="inv-inline-row inv-inline-row-padding">
                <div className="inv-page-head no-border">
                  <div className="col-xs-12 inv-no-padding">
                    <div
                      className={this.state.showSucessErrorMessage.className}
                      role="alert"
                    >
                      {this.state.showSucessErrorMessage.message}
                    </div>
                    {/* <div className="alert alert-danger" role="alert">
                      This is a danger alert—check it out!
                    </div>
                    <div className="alert alert-warning" role="alert">
                      This is a warning alert—check it out!
                    </div> */}
                    <h4 className="inv-inventory-head ">
                      Inventory
                      <div className="top_btn_group flex-group inventoryMobileExcelModal">
                        <FileUpload
                          onComplete={() => {
                            this?.getAllList();
                          }}
                          className="button add_equip"
                        />
                        {this.state.step == 1 &&
                          this.state.permList &&
                          this.state.permList.includes(
                            'Add/Edit/Archive Equipments'
                          ) && (
                            <React.Fragment>
                              <button
                                className="button"
                                onClick={this.props.archivedEquipments}
                              >
                                Archived Equipments
                              </button>
                              <button
                                //className='inv-button inv-button-primary inv-add-inventory button-add-equipment'
                                className="button"
                                onClick={() => {
                                  this.props.addEquip();
                                }}
                              >
                                + Add Equipment
                              </button>
                            </React.Fragment>
                          )}
                      </div>
                    </h4>
                  </div>

                  {/* Mobile search */}
                  {this.state.permList &&
                    this.state.permList.includes('Assign Inventory') && (
                      <div
                        className="col-xs-12 inv-no-padding inv-search-mobile"
                        aria-expanded={
                          this.state.invKey === '' ? 'false' : 'true'
                        }
                      >
                        <div className="search-box-wrapper-inline">
                          <input
                            data-filter
                            type="text"
                            name="invenoryKeyword"
                            onKeyUp={this._keyUpFnInventory.bind(this)}
                            placeholder="Search..."
                            autoComplete="off"
                            aria-expanded={
                              this.state.invKey === '' ? 'false' : 'true'
                            }
                            id="search_box_filter"
                          />
                          <div
                            className="clear-and-close-search"
                            onClick={this.clearSearch.bind(this)}
                          ></div>
                        </div>
                      </div>
                    )}

                  {/* Search result */}

                  {this.state.searchLoading && (
                    <div className="inv-loading-search"></div>
                  )}
                  {!this.state.searchLoading &&
                    this.state.invKey !== '' &&
                    this.state.searchResult.length === 0 && (
                      <div className="no-results-found-inv">
                        No results found
                      </div>
                    )}
                  {this.state.searchResult.length > 0 && (
                    <div className="search-result-wrapper">
                      <div className="inv-result-set">
                        <div className="inv-result-content">
                          <ul className="inv-result-collection">
                            {this.state.searchResult.map((item) => {
                              return (
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    this.viewDetails(item);
                                  }}
                                >
                                  <div className="inv-single-result 1">
                                    <div className="inv-result-thumb">
                                      <img
                                        onError={(e) => {
                                          this._handleImageError(e);
                                        }}
                                        src={
                                          item.pic !== null && item.pic !== ''
                                            ? `${config.s3BucketUrl}/${item.pic}`
                                            : `${config.apiUrl}/${config.altInvImg}`
                                        }
                                      />
                                    </div>
                                    <div className="inv-result-detail-wrapper">
                                      <div className="inv-result-name">
                                        {' '}
                                        {item.unique_id}
                                      </div>
                                      <div className="inv-result-category">
                                        {item.type}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Search result end */}
                </div>
              </div>
              {/* START */}

              <div
                className="main-page-wrapper"
                aria-hidden={
                  this.state.searchResult.length > 0 ? 'true' : 'false'
                }
              >
                <div className="inv-inline-row inv-inline-row-padding">
                  <div className="inv-page-head no-border">
                    {/* Search result end */}
                    {this.state.step == 1 && (
                      <React.Fragment>
                        <div className="inv-inline-row inv-head-select-box padding-top-7">
                          <span className="inv-label-gray">Step 1: </span>
                          <span className="inv-label-green">Select Master</span>
                        </div>
                        {permList.includes('Search/List Equipment') && (
                          <>
                            <select
                              name="Filteroption"
                              value={this.state.Filteroption}
                              onChange={this.handleSelect}
                            >
                              <option value="All">All</option>
                              <option value="Assigned">Assigned</option>
                              <option value="Unassigned">Unassigned</option>
                            </select>
                            <select
                              name="div_filter"
                              value={this.state.div_filter}
                              onChange={this.handleSelect}
                            >
                              <option value="All"> - Division - </option>
                              {this.state.division_list}
                            </select>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>

                {/* END */}
                {this.state.step == 1 &&
                  Object.keys(inventoryList).map((ele, index) => (
                    <React.Fragment key={index}>
                      <div className="inv-inline-row inv-inline-row-padding">
                        <div className="inv-inline-row inv-result-set">
                          <div className="inv-result-set-head">
                            <div className="inv-inline-row inv-title-main">
                              {ele}
                            </div>
                            <div className="inv-inline-row inv-title-sub">
                              {inventoryList[ele].length} {ele}
                            </div>
                          </div>

                          <div className="inv-result-content">
                            <ul className="inv-result-collection">
                              {inventoryList[ele].map((item, itemindex) => (
                                <li key={itemindex}>
                                  <div
                                    className="inv-single-result 2"
                                    onClick={() => {
                                      this.viewDetails(item);
                                    }}
                                  >
                                    <div className="inv-result-thumb">
                                      <img
                                        src={
                                          item.pic !== null && item.pic !== ''
                                            ? `${config.s3BucketUrl}/${item.pic}`
                                            : `${config.apiUrl}/${config.altImg}`
                                        }
                                        onError={(e) => {
                                          this._handleImageError(e);
                                        }}
                                      />
                                    </div>
                                    <div className="inv-result-detail-wrapper">
                                      <div className="inv-result-name">
                                        {item.unique_id}
                                      </div>
                                      <div className="inv-result-category">
                                        {item.type}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                {this.state.step > 1 && (
                  <div className="inv-inline-row inv-inline-row-padding">
                    <div className="inv-assign-section">
                      <div className="inv-selected-head">
                        <div
                          className="inv-selected-head-actions inv-bck"
                          onClick={this.navigateBack}
                        >
                          Back
                        </div>
                        <div
                          className="inv-selected-head-actions inv-cncl"
                          onClick={() => {
                            this.setState({ step: 1 });
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                      <div
                        className="inv-selected-thumb"
                        onClick={() => {
                          this.viewDetails(selectedInventory);
                        }}
                      >
                        <div className="inv-thumb-image">
                          <img
                            src={
                              selectedInventory.pic !== null &&
                              selectedInventory.pic !== ''
                                ? `${config.s3BucketUrl}/${selectedInventory.pic}`
                                : `${config.apiUrl}/${config.altImg}`
                            }
                            onError={(e) => {
                              this._handleImageError(e);
                            }}
                          />
                        </div>
                        <div className="inv-thumb-details">
                          <div className="inv-inline-row">
                            <span className="inv-gray">Selected: </span>
                            <span className="inv-green">
                              {selectedInventory.unique_id}
                            </span>
                            {this.state.step > 2 && (
                              <div className="inv-inline-row">
                                <span className="inv-gray">Crew: </span>
                                <span className="inv-green">
                                  {selectedCrew.resource_name}
                                </span>
                              </div>
                            )}
                            {selectedMember.first_name && (
                              <div className="inv-inline-row">
                                <span className="inv-gray">Assign to: </span>
                                <span className="inv-green">
                                  {selectedMember.first_name}{' '}
                                  {selectedMember.last_name}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.step == 4 && (
                        <div className="inv-crew-list">
                          <div className="inv-crew-list-head">
                            <span className="inv-gray-color">Step 4: </span>
                            <span className="inv-green-color">Confirm</span>
                          </div>
                          <div className="inv-crew-item-wrapper">
                            <div className="inv-top-section">
                              <button
                                className="inv-button inv-button-primary inv-add-inventory"
                                onClick={() => {
                                  let action = selectedMember.first_name
                                    ? 'Assign'
                                    : 'Move to available';
                                  this.inventoryOpenCloseModal(true, action);
                                }}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.step == 2 && (
                        <div className="inv-crew-list">
                          <div className="inv-crew-list-head">
                            <span className="inv-gray-color">Step 2: </span>
                            <span className="inv-green-color">
                              {' '}
                              Select Crew{' '}
                            </span>
                            <input
                              type="text"
                              name="keyword"
                              onKeyUp={this.crewListing}
                              placeholder="Search..."
                              // placeholder='Type crew name...'
                              autoComplete="off"
                            />
                          </div>
                          {crewList.map((crew, index) => (
                            <div key={index} className="inv-crew-item-wrapper">
                              <div className="inv-top-section">
                                <div className="inv-crew-item-thumb">
                                  <img
                                    src={
                                      crew.resource_photo !== null &&
                                      crew.resource_photo !== ''
                                        ? `${crew.resource_photo}`
                                        : `${config.apiUrl}/${config.altImg}`
                                    }
                                    onError={(e) => {
                                      this._handleImageError(e);
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div className="inv-item-details">
                                  <div className="inv-item-details-inner">
                                    <div className="inv-inline-row inv-green">
                                      {crew.resource_name}
                                    </div>
                                    <div className="inv-inline-row innv-gray">
                                      {/* 15 Members */}
                                      {crew.resource_type}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="inv-crew-action-bar-bottom">
                                <button
                                  className="inv-btn-small-actions"
                                  onClick={() => {
                                    this.oncrewSelect(crew, 4);
                                  }}
                                >
                                  Move
                                </button>
                                <button
                                  className="inv-btn-small-actions"
                                  onClick={() => {
                                    this.oncrewSelect(crew, 3);
                                  }}
                                >
                                  Assign
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {this.state.step == 3 && (
                        <div className="inv-crew-list">
                          <div className="inv-crew-list-head">
                            <span className="inv-gray-color">Step 3: </span>
                            <span className="inv-green-color">
                              Select Team Member
                            </span>
                          </div>
                          {crewMembers.map((member, index) => (
                            <div
                              key={index}
                              className="inv-crew-item-wrapper"
                              onClick={() => {
                                this.onMemberSelection(member, 4);
                              }}
                            >
                              <div className="inv-top-section">
                                <div className="inv-crew-item-thumb">
                                  <img
                                    src={
                                      member.profile_photo !== null &&
                                      member.profile_photo !== ''
                                        ? `${config.apiUrl}/${member.profile_photo}`
                                        : `${config.apiUrl}/${config.altImg}`
                                    }
                                    onError={(e) => {
                                      this._handleImageError(e);
                                    }}
                                  />
                                </div>
                                <div className="inv-item-details">
                                  <div className="inv-item-details-inner">
                                    <div className="inv-inline-row inv-green">
                                      {member.first_name} {member.last_name}
                                    </div>
                                    <div className="inv-inline-row innv-gray">
                                      {member.user_type}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {member.inventory.length > 0 && (
                                <div className="inv-result-set">
                                  <div className="inv-result-content">
                                    <ul className="inv-result-collection">
                                      {member.inventory.map(
                                        (item, itemindex) => (
                                          <li key={itemindex}>
                                            <div className="inv-single-result 3">
                                              <div className="inv-result-thumb">
                                                <img
                                                  src={
                                                    item.pic !== null &&
                                                    item.pic !== ''
                                                      ? `${config.s3BucketUrl}/${item.pic}`
                                                      : `${config.apiUrl}/${config.altImg}`
                                                  }
                                                  onError={(e) => {
                                                    this._handleImageError(e);
                                                  }}
                                                />
                                              </div>
                                              <div className="inv-result-detail-wrapper">
                                                <div
                                                  className="inv-result-name"
                                                  // onClick={() => {
                                                  //   this.viewDetails(item);
                                                  // }}
                                                >
                                                  {item.unique_id}
                                                </div>
                                                <div className="inv-result-category">
                                                  {item.type}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this?.state?.showAssignModal}
          contentLabel="inventory Popup"
          overlayClassName="inventoryQuickAssign"
        >
          <InventoryQuickAssign
            equipmentId={this?.state?.equipmentId}
            defaultData={activeEquipDetail}
            onClose={() => this?.handleAssignModal()}
          />
        </ReactModal>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { permission, inventory, resource, user } = state;

  return {
    permission,
    inventory,
    resource,
    user,
  };
}
const connectedHomePage = connect(mapStateToProps)(InventoryDetailsMob);
export { connectedHomePage as InventoryDetailsMob };
// export default InventoryDetailsMob;
