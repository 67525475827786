import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

class SidebarUserMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let documents = this.props.docs.userDocs;
    let docSlug = this.props.match.params.doc;
    let activeDoc = documents.find((doc) => doc.slug === docSlug);
    let docs_count = this.props.docs.userDocs.filter(
      (item) => item.docs_categories_id == 1
    ).length;
    let rehire_docs_count = this.props.docs.userDocs.filter(
      (item) => item.docs_categories_id == 2
    ).length;
    let user;
    this.props.user &&
      this.props.user.items &&
      this.props.user.items.data &&
      (user = this.props.user.items.data);

    return (
      <div className="sidebar-user-mobile">
        {activeDoc && (
          <section className="custom-container">
            <div className="title">
              <h3>
                <span>
                  {!this.props.rehire && 'Onboarding Documents '}
                  {this.props.rehire && 'Rehire Documents '}
                </span>
                <span className="doc-count">
                  {this.props.rehire ? rehire_docs_count : docs_count}
                </span>
              </h3>
            </div>
            <div className="list-container">
              <ul className="list">
                {documents.map((doc) => {
                  if (!this.props.rehire && doc.docs_categories_id == 1) {
                    return (
                      <li
                        key={doc.id}
                        onClick={() => {
                          this.props.setScreen('document');
                        }}
                      >
                        <Link
                          className={`${doc.slug == activeDoc.slug ? 'doc-active ' : ''} ${
                            doc.status >= 2 && !(doc.slug == activeDoc.slug)
                              ? 'doc-complete '
                              : ''
                          }`}
                          to={`/dashboard/docs/${doc.slug}?rehire=false`}
                          key={doc.id}
                        >
                          <span className="icon">
                            {/* <i className='fa fa-check'></i> */}
                          </span>
                          <span className="name">{doc.doc_type_name}</span>
                          <span className="link">
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </Link>
                      </li>
                    );
                  } else if (this.props.rehire && doc.docs_categories_id == 2) {
                    return (
                      <li
                        key={doc.id}
                        onClick={() => {
                          this.props.setScreen('document');
                        }}
                      >
                        <Link
                          className={`${doc.slug == activeDoc.slug ? 'doc-active ' : ''} ${
                            doc.status >= 2 && !(doc.slug == activeDoc.slug)
                              ? 'doc-complete '
                              : ''
                          }`}
                          to={`/dashboard/docs/${doc.slug}?rehire=true`}
                          key={doc.id}
                        >
                          <span className="icon">
                            {/* <i className='fa fa-check'></i> */}
                          </span>
                          <span className="name">{doc.doc_type_name}</span>
                          <span className="link">
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </section>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { user, docs } = state;
  return { user, docs };
}

const connectedSidebarUserMobile = compose(
  withRouter,
  connect(mapStateToProps)
)(SidebarUserMobile);
export { connectedSidebarUserMobile as SidebarUserMobile };
