import React from 'react';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import { manifestActions } from '../_actions';
import moment from 'moment';
//import DatePicker from "react-datepicker";
import '../assets/styles/manifest.css';

function formatPhoneNumber(s) {
  var s2 = ('' + s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : '(' + m[1] + ')-' + m[2] + '-' + m[3];
}

class ViewManifest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      added_date: null,
      crews: [],
      vehicles: [],
      manifestType: this.props.manifestType,
      superintendent: [],
      crewOptions: [],
      vehicleOptions: [],
      statusOpen: false,
      form: {
        ordering_unit: '',
        incident_name: '',
        incident_number: '',
        resource_number: '',
        ifca_number: '',
        dispatch_location: '',
        project_name: '',
        project_code: '',
        report_to: '',
        des_place: '',
        des_eta: '',
        des_etd: '',
        inter_space: '',
        dep_place: '',
        dep_etd: '',
        dep_eta: '',
        vehicle_number: '',
        dispatch_time: '',
        perssonal: '',
        arr: '',
        dep_time: '',
        dep_arr: '',
        pdep_time: '',
        pedep_place: '',
        sign_auth_representative: '',
      },
      shighq: [],
      print: false,
      submit: false,
      manifest_list: false,
      crews_list: false,
      vehicles_list: false,
      saved_id: '',
      numPages: null,
      pageNumber: 1,
      ifca_incident: '',
    };
    this.previous = this.previous.bind(this);
    this.printManifest = this.printManifest.bind(this);
    this.viewOpenModal = this.viewOpenModal.bind(this);
    this.incidentChange = this.incidentChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  incidentChange(e, type) {}

  viewOpenModal(id) {
    this.setState({ viewOpen: true, mid: id });
  }

  viewCloseModal() {
    this.setState({ viewOpen: false });
  }

  onSelect(hash, e) {
    var value = e.target.value;
    this.state.shighq[hash] = value;
    this.setState({ shighq: this.state.shighq }, function () {
      if (this.state.manifestType === '1') {
        this.generateIFCA();
      }
      if (this.state.manifestType === '3') {
        this.generateNCC();
      }
      if (this.state.manifestType === '4') {
        this.generateVIPR();
      }
    });
  }

  printManifest(id) {
    var data =
      '?manifest_id=' +
      this.props.manifest_id +
      '&resource_id=' +
      this.props.resourceDetails;
    // this.props.dispatch(manifestActions.printManifest(data));
    let fdata = new FormData();
    if (this.state.crews.length > 0) {
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          if (this.state.shighq[item.hash] !== undefined) {
            fdata.append('highq[' + index + ']', this.state.shighq[item.hash]);
          } else {
            fdata.append('highq[' + index + ']', item.highq);
          }
        }
        return fdata;
      });
    }
    const requestOptions = {
      method: 'POST',
      // method: 'GET',
      headers: authHeader(),
      body: fdata,
    };

    fetch(config.apiUrl + '/api/manifest/pdf' + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        const pdfLink = data.data;

        var link = document.createElement('a');
        if (
          window.navigator.userAgent.match(/iPad/i) ||
          window.navigator.userAgent.match(/iPhone/i)
        ) {
          link.href = pdfLink;
        } else {
          link.href = pdfLink;
          link.target = '_blank';
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(pdfLink);
        }, 100);
      });
  }

  previous() {
    this.props.details(this.props.resourceDetails);
  }

  formatPhoneNumber(s) {
    var s2 = ('' + s).replace(/\D/g, '');
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : '(' + m[1] + ')-' + m[2] + '-' + m[3];
  }

  componentWillMount() {
    var data = 'resource_id=' + this.props.resourceDetails;
    this.props.dispatch(manifestActions.getSuperintendent(data));

    let data2 = new FormData();
    data2.append('manifest_id', this.props.manifest_id);
    this.props.dispatch(manifestActions.manifestDetails(data2));

    let data3 = new FormData();
    data3.append('resource_id', this.props.resourceDetails);
    data3.append('manifest_id', this.props.manifest_id);
    data3.append('base_manifest_type', this.props.manifestType);
    this.props.dispatch(manifestActions.getUsers(data3));

    let data4 = new FormData();
    data4.append('resource_id', this.props.resourceDetails);
    data4.append('manifest_id', this.props.manifest_id);
    this.props.dispatch(manifestActions.getVehicles(data4));
  }

  generateIFCA() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          count++;
          options.push(
            <div className="middle_table tr" key={'ifca' + index}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>
                  {++index}. {item.first_name + ' ' + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === 'Male' ? 'X' : ''}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === 'Female' ? 'X' : ''}</p>
              </div>
              <div
                className="td print_center"
                data-header-title="Crew Member Number"
              >
                <p>{item.ica_number}</p>
              </div>
              <div
                className="td print_center"
                data-header-title="Incident Position"
              >
                <p>{item.incident_pos}</p>
                {/* <select name="pos_list_drag" className="pos_list_drag disabled" value={this.state.shighq[item.hash] ? this.state.shighq[item.hash] : item.incident_pos} onChange={this.onSelect.bind(this, item.hash)} >
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select> */}
              </div>
              <div
                className="td print_center Sawyer"
                data-header-title="Sawyer"
              >
                <p>{item.sawyer ? 'X' : ''}</p>
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p>{item.certified_emt === 'Yes' ? 'X' : ''}</p>
              </div>
              <div
                className="td print_center bry"
                data-header-title="Experience and Lsa (Blue-Red-Yellow)"
              >
                <p>{item.experience} </p>
              </div>
            </div>
          );
        }
        return options;
      });
      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={'ifca' + i}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div
                className="td print_center"
                data-header-title="Crew Member Number"
              >
                <p />
              </div>
              <div
                className="td print_center"
                data-header-title="Incident Position"
              >
                {/*<select className="pos_list_drag disabled">
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select>*/}
              </div>
              <div
                className="td print_center Sawyer"
                data-header-title="Sawyer"
              >
                <p />
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p />
              </div>
              <div
                className="td print_center bry"
                data-header-title="Experience and Lsa (Blue-Red-Yellow)"
              >
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === 'selected') {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + ' ' + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{item.mspa_exp_date}</p>
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p>{item.vehicle_model_or_make}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });
      if (count < 4) {
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={'veh' + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  generateNCC() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          count++;
          options.push(
            <div className="middle_table tr" key={'manifest' + index}>
              <div
                data-header-title="Employee Name (Last, First)"
                className="print_hash_value td ncc_emp_name"
              >
                <p className="num">{++index}</p>
                <p className="ename">
                  {item.first_name + ' ' + item.last_name}
                </p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p>{item.gender === 'Male' ? 'X' : ''}</p>
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p>{item.gender === 'Female' ? 'X' : ''}</p>
              </div>
              <div
                className="print_center td identification"
                data-header-title="Identification Number"
              >
                <p>{item.fire_id}</p>
              </div>
              <div
                className="print_center td incidentPosition"
                data-header-title="Incident Position"
              >
                <p>{item.incident_pos}</p>
                {/* <select className="pos_list_drag disabled" value={this.state.shighq[item.hash] ? this.state.shighq[item.hash] : item.incident_pos} onChange={this.onSelect.bind(this, item.hash)} >
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select> */}
              </div>
              <div
                className="print_center td sawyer"
                data-header-title="Sawyer Class"
              >
                <p>{item.sawyer ? 'X' : ''}</p>
              </div>
              <div
                className="print_center td"
                data-header-title="Experience Blue-Red-Yellow (B_R_Y)"
              >
                <p>{item.experience.charAt(0)}</p>
              </div>
            </div>
          );
        }
        return options;
      });
      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="tr" key={'manifest' + i}>
              <div
                data-header-title="Employee Name (Last, First)"
                className="print_hash_value td ncc_emp_name"
              >
                <p className="num">{count}</p>
                <p className="ename" />
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p />
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p />
              </div>
              <div
                className="print_center td identification"
                data-header-title="Identification Number"
              >
                <p />
              </div>
              <div
                className="print_center td incidentPosition"
                data-header-title="Incident Position"
              >
                <p />
                {/* <select className="pos_list_drag disabled">
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select> */}
              </div>
              <div
                className="print_center td sawyer"
                data-header-title="Sawyer Class"
              >
                <p />
              </div>
              <div
                className="print_center td"
                data-header-title="Experience Blue-Red-Yellow (B_R_Y)"
              >
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === 'selected') {
          count++;
          optionsVeh.push(
            <div className="tr" key={'vehicle' + index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + ' ' + item.last_name}</p>
              </div>
              <div className="td" data-header-title="Vehicle Make / Model">
                <p>{item.vehicle_model_or_make}</p>
              </div>
              <div className="td st" data-header-title="Year">
                <p>{item.year}</p>
              </div>
              <div
                className="td exp_date"
                data-header-title="Vehicle License No."
              >
                <p>{item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });
      if (count < 4) {
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={'vehicle' + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle Make / Model">
                <p />
              </div>
              <div className="td st" data-header-title="Year">
                <p />
              </div>
              <div
                className="td exp_date"
                data-header-title="Vehicle License No."
              >
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  generateVIPR() {
    if (this.state.crews.length > 0) {
      var options = [];
      var count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          count++;
          options.push(
            <div className="tr middle_table" key={'manifest' + index}>
              <div
                className="print_hash_value td"
                data-header-title="Employee Name"
              >
                <p>
                  {++index}. {item.first_name + ' ' + item.last_name}
                </p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p>{item.gender === 'Male' ? 'X' : ''}</p>
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p>{item.gender === 'Female' ? 'X' : ''}</p>
              </div>
              <div className="print_center td" data-header-title="Position">
                <p>{item.incident_pos}</p>
                {/* <p><select className="pos_list_drag disabled" value={this.state.shighq[item.hash] ? this.state.shighq[item.hash] : item.incident_pos} onChange={this.onSelect.bind(this, item.hash)}>
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select></p> */}
              </div>
              <div
                className="print_center td"
                data-header-title="LAST NIGHT NOT SPENT ON FIRE ASSIGNMENT (DATE)"
              >
                {item.lnnsfa_date
                  ? moment(item.lnnsfa_date).format('MM/DD/YY')
                  : ''}
              </div>
              <div
                className="print_center td"
                data-header-title="UNIQUE EMPLOYEE NUMBER (NO SSN)"
              >
                <p>{item.fire_id}</p>
              </div>
            </div>
          );
        }
        return options;
      });
      if (count < 6) {
        for (var i = count; i < 6; i++) {
          count++;
          options.push(
            <div className="tr" key={'manifest' + i}>
              <div
                className="print_hash_value td"
                data-header-title="Employee Name"
              >
                <p>{count}. </p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p />
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p />
              </div>
              <div className="print_center td" data-header-title="Position">
                <p>
                  {/* <select className="pos_list_drag disabled" >
                                    <option value="--">--</option>
                                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                                    <option value="FFT1/ICT5">FFT1/ICT5</option>
                                    <option value="FFT1">FFT1</option>
                                    <option value="FFT2">FFT2</option>
                                    <option value="CRWB">CRWB</option>
                                    <option value="ENGB">ENGB</option>
                                    <option value="CRWB(T)">CRWB(T)</option>
                                    <option value="FFT1(T)">FFT1(T)</option>
                                </select> */}
                </p>
              </div>
              <div
                className="print_center td"
                data-header-title="LAST NIGHT NOT SPENT ON FIRE ASSIGNMENT (DATE)"
              />
              <div
                className="print_center td"
                data-header-title="UNIQUE EMPLOYEE NUMBER (NO SSN)"
              >
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }
  }

  generateDCRM() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>
                  {count}. {item.first_name + ' ' + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === 'Male' ? 'X' : ''}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === 'Female' ? 'X' : ''}</p>
              </div>
              <div
                className="td print_center em_crew_mem_no"
                data-header-title="Employee Id Number"
              >
                <p>{item.fire_id}</p>
              </div>
              <div
                className="print_center td"
                data-header-title="Project Position"
              >
                <p>{item.incident_pos}</p>
              </div>
              <div
                className="td em_phone print_hash_value"
                data-header-title="Phone Number"
              >
                <p>{this.formatPhoneNumber(item.phone)}</p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={'ifca' + i}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div
                className="td print_center em_crew_mem_no"
                data-header-title="Employee Id Number"
              >
                <p />
              </div>
              <div
                className="td print_center"
                data-header-title="Project Position"
              >
                <p />
                {/* <select className="pos_list_drag disabled">
                      <option value="--">--</option>
                      <option value="CRWB/ICT5">CRWB/ICT5</option>
                      <option value="ENGB/ICT5">ENGB/ICT5</option>
                      <option value="FFT1/ICT5">FFT1/ICT5</option>
                      <option value="FFT1">FFT1</option>
                      <option value="FFT2">FFT2</option>
                      <option value="CRWB">CRWB</option>
                      <option value="ENGB">ENGB</option>
                      <option value="CRWB(T)">CRWB(T)</option>
                      <option value="FFT1(T)">FFT1(T)</option>
                    </select> */}
              </div>
              <div
                className="td em_phone print_hash_value"
                data-header-title="Phone Number"
              >
                <p></p>
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === 'selected') {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + ' ' + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div
                className="td DLIssued"
                data-header-title="Drivers License Issued State"
              >
                <p>{item.DLState}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{item.mspa_exp_date}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });

      if (count < 4) {
        // var tot = count;
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={'veh' + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div
                className="td DLIssued"
                data-header-title="Drivers License Issued State"
              >
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  generatePM() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === 'oncrew') {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>
                  {count}. {item.first_name + ' ' + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === 'Male' ? 'X' : ''}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === 'Female' ? 'X' : ''}</p>
              </div>
              <div
                className="td print_center em_crew_mem_no"
                data-header-title="Employee Id Number"
              >
                <p>{item.fire_id}</p>
              </div>
              <div
                className="td print_center"
                data-header-title="Project Position"
              >
                {item.incident_pos}
              </div>
              <div
                className="td em_phone print_hash_value"
                data-header-title="Phone Number"
              >
                <p>{this.formatPhoneNumber(item.phone)}</p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={'ifca' + i}>
              <div
                className="td print_hash_value"
                data-header-title="Crew Member Name"
              >
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div
                className="td print_center em_crew_mem_no"
                data-header-title="Employee Id Number"
              >
                <p />
              </div>
              <div
                className="td print_center"
                data-header-title="Project Position"
              >
                <p />
                {/* <select className="pos_list_drag disabled">
                      <option value="--">--</option>
                      <option value="CRWB/ICT5">CRWB/ICT5</option>
                      <option value="ENGB/ICT5">ENGB/ICT5</option>
                      <option value="FFT1/ICT5">FFT1/ICT5</option>
                      <option value="FFT1">FFT1</option>
                      <option value="FFT2">FFT2</option>
                      <option value="CRWB">CRWB</option>
                      <option value="ENGB">ENGB</option>
                      <option value="CRWB(T)">CRWB(T)</option>
                      <option value="FFT1(T)">FFT1(T)</option>
                    </select> */}
              </div>
              <div
                className="td em_phone print_hash_value"
                data-header-title="Phone Number"
              >
                <p></p>
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === 'selected') {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + ' ' + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div
                className="td DLIssued"
                data-header-title="Drivers License Issued State"
              >
                <p>{item.DLState}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{item.mspa_exp_date}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });

      if (count < 4) {
        // var tot = count;
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={'veh' + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div
                className="td DLIssued"
                data-header-title="Drivers License Issued State"
              >
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { superintendent, manifest, crews, vehicles } = nextProps;
    if (superintendent.list) {
      const { data } = superintendent.list;
      if (data.length > 0) {
        this.setState({ superintendent: data[0] });
      }
    }
    if (manifest) {
      if (manifest.print) {
        var pdf = manifest.print.data;
        // window.open(pdf, '_blank');
        var windowReference = window.open();

        windowReference.location = pdf;

        this.props.details(this.props.resourceDetails);
      }
    }

    if (manifest.list) {
      if (manifest.list.success) {
        const form = manifest.list.data;
        var state = {};

        if (form.added_date) {
          state['added_date'] = form.added_date;
        }

        if (form.eff_end_date) {
          state['mend'] = moment(form.eff_end_date).format('MM/DD/YY');
        }
        if (form.eff_start_date) {
          state['mstart'] = moment(form.eff_start_date).format('MM/DD/YY');
        }

        if (Object.keys(state).length) {
          this.setState(state);
        }

        this.setState({ form: form }, function () {
          // this.setState({ manifestType:form.base_manifest_id },function(){
          if (this.state.manifestType === '1') {
            this.generateIFCA();
          }
          if (this.state.manifestType === '3') {
            this.generateNCC();
          }
          if (this.state.manifestType === '4') {
            this.generateVIPR();
          }
          if (this.state.manifestType === '5') {
            this.generateDCRM();
          }
          if (this.state.manifestType === '6') {
            this.generatePM();
          }
        });
      }
    }
    if (crews.list) {
      const { data } = crews.list;
      if (data.length > 0) {
        this.setState({ crews: data }, function () {
          if (this.state.manifestType === '1') {
            this.generateIFCA();
          }
          if (this.state.manifestType === '3') {
            this.generateNCC();
          }
          if (this.state.manifestType === '4') {
            this.generateVIPR();
          }
          if (this.state.manifestType === '5') {
            this.generateDCRM();
          }
          if (this.state.manifestType === '6') {
            this.generatePM();
          }
        });
      }
    }
    if (vehicles.vehicleList) {
      const { data } = vehicles.vehicleList;
      if (data.length > 0) {
        this.setState({ vehicles: data }, function () {
          if (this.state.manifestType === '1') {
            this.generateIFCA();
          }
          if (this.state.manifestType === '3') {
            this.generateNCC();
          }
          if (this.state.manifestType === '4') {
            this.generateVIPR();
          }
          if (this.state.manifestType === '5') {
            this.generateDCRM();
          }
          if (this.state.manifestType === '6') {
            this.generatePM();
          }
        });
      }
    }
  }

  render() {
    // const { superintendent,manifest,crews,vehicles } = this.props
    // const { pageNumber, numPages } = this.state;

    let yourDate = new Date();

    if (this.state.added_date) {
      yourDate = new Date(this.state.added_date);
    }

    var curDate = moment(yourDate).format('MM/DD/YY');

    const form = this.state.form;
    var fname, lname, sup_name;
    if (this.state.superintendent) {
      fname = this.state.superintendent.first_name
        ? this.state.superintendent.first_name
        : '';
      lname = this.state.superintendent.last_name
        ? this.state.superintendent.last_name
        : '';
      if (fname !== '' && lname !== '') {
        sup_name = fname + ' ' + lname;
      } else {
        sup_name = '-';
      }
    }

    return (
      <div className="content_div view_manifest_page inner_container">
        <div className="goback" onClick={this.previous.bind(this)}>
          <p>Back</p>
        </div>

        {this.state.manifestType === '1' && (
          <div id="view_print_manifest" className="print_manifest_wrap">
            <h2>View Manifest</h2>

            <div id="view_printing_page">
              <div
                className="responsive_table ifca_view_manifest"
                id="view_general_information_table"
              >
                <div className="table top_table">
                  <div className="tr">
                    <div className="print_center td top_title" colSpan="5">
                      <h3>
                        Exhibit P - <span>Hand Crew Manifest Form</span>
                      </h3>
                    </div>
                  </div>
                  <div className="tr ifca_ordering">
                    <div className="td" data-header-title="Ordering Unit">
                      <h4>Ordering Unit</h4>
                      <input
                        type="text"
                        disabled
                        id="orderingUnit"
                        name="ordering_unit"
                        readOnly
                        value={form.ordering_unit}
                      />
                    </div>
                    <div className="td" data-header-title="Incident Name">
                      <h4>Incident Name</h4>
                      <input
                        type="text"
                        disabled
                        id="incidentName"
                        name="incident_name"
                        readOnly
                        value={form.incident_name}
                      />
                    </div>
                    <div className="td" data-header-title="Incident Number">
                      <h4>Incident Number</h4>
                      <input
                        type="text"
                        disabled
                        id="incidentNumber"
                        name="incident_number"
                        readOnly
                        value={form.incident_number}
                      />
                    </div>
                    {/*<div className="tdw-15 td"></div>*/}
                    <div
                      className="td_split td"
                      data-header-title="Resource Number"
                    >
                      <h4>Resource Number</h4>
                      <b>C-</b>{' '}
                      <span>
                        <input
                          type="text"
                          disabled
                          id="resourceNumber"
                          name="resource_number"
                          readOnly
                          value={form.resource_number}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table top_table no_border">
                  <div className="tr contrac1">
                    <div
                      className="td"
                      data-header-title="Contractor"
                      width="36%"
                    >
                      <h4>Contractor</h4>
                      <p>Firestorm Wildland Fire Supression Inc.</p>
                    </div>
                    <div className="td" data-header-title="Agreement Number">
                      <h4>Agreement Number</h4>
                      <input
                        type="text"
                        disabled
                        id="ifcaNumber"
                        name="ifca_number"
                        readOnly
                        value={form.ifca_number}
                      />
                    </div>
                    {/*<div className="tdw-15 td"></div>*/}
                    <div
                      className="td"
                      data-header-title="Designated Dispatch Location"
                    >
                      <h4>Designated Dispatch Location</h4>
                      <input
                        type="text"
                        disabled
                        id="dispatchLocation"
                        name="dispatch_location"
                        readOnly
                        value={form.dispatch_location}
                      />
                    </div>
                  </div>
                  <div className="tr contrac2">
                    <div
                      className="td"
                      data-header-title="Contractor Represntative"
                    >
                      <h4>Contractor Represntative</h4>
                      <p id="sup_det_view">{sup_name}</p>
                    </div>
                    <div className="td" data-header-title="Contact Phone">
                      <h4>Contact Phone:</h4>
                      <p id="sup_phone_view">
                        {this.state.superintendent &&
                        this.state.superintendent.phone ? (
                          <a
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            className="phone_email_link"
                            href={`tel:${this.state.superintendent.phone}`}
                            target="_top"
                          >
                            {formatPhoneNumber(this.state.superintendent.phone)}
                          </a>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    {/*<div className="tdw-15 td"></div>*/}
                    <div className="td" data-header-title="Report To">
                      <h4>Report To:</h4>
                      <input
                        type="text"
                        disabled
                        id="reportTo"
                        name="report_to"
                        readOnly
                        value={form.report_to}
                      />
                    </div>
                  </div>
                </div>
                <div className="table top_table no_border place">
                  <div className="tr">
                    <div
                      width="36%"
                      className="td print_center no_padding_border"
                    >
                      <div className="table top_table no_border departure_div">
                        <div className="tr">
                          <div
                            className="print_center td_height td tr_title depar_div"
                            data-header-title="Departure"
                            colSpan="3"
                          >
                            <h5>Departure</h5>
                          </div>
                        </div>
                        <div className="tr">
                          <div
                            width="50%"
                            className="double_field td"
                            data-header-title="Place"
                          >
                            <h5>Place</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depPlace"
                                name="dep_place"
                                readOnly
                                value={form.dep_place}
                              />
                            </span>
                          </div>
                          <div
                            className="double_field td"
                            data-header-title="ETD"
                          >
                            <h5>ETD</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depETD"
                                name="dep_etd"
                                readOnly
                                value={form.dep_etd}
                              />
                            </span>
                          </div>
                          <div
                            className="double_field td"
                            data-header-title="ETA"
                          >
                            <h5>ETA</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depETA"
                                name="dep_eta"
                                readOnly
                                value={form.dep_eta}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="td print_center no_padding_border"
                      width="23%"
                    >
                      <div className="table top_table no_border interm_div">
                        <div className="tr">
                          <div
                            className="print_center td_height td tr_title"
                            data-header-title="Intermediate Stops"
                          >
                            <h5>Intermediate Stops</h5>
                          </div>
                        </div>
                        <div className="tr">
                          <div
                            className="double_field td"
                            data-header-title="Place"
                          >
                            <h5>Place</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="interSpace"
                                name="inter_space"
                                readOnly
                                value={form.inter_space}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="td print_center no_padding_border">
                      <div className="table top_table no_border destin_div">
                        <div className="tr">
                          <div
                            className="td print_center td_height tr_title"
                            data-header-title="Place"
                            colSpan="3"
                          >
                            <h5>Destination</h5>
                          </div>
                        </div>
                        <div className="tr">
                          <div
                            className="td double_field"
                            data-header-title="Place"
                          >
                            <h5>Place</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="desPlace"
                                name="des_place"
                                readOnly
                                value={form.des_place}
                              />
                            </span>
                          </div>
                          <div
                            className="td double_field"
                            data-header-title="ETD"
                          >
                            <h5>ETD</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="desETD"
                                name="des_etd"
                                readOnly
                                value={form.des_etd}
                              />
                            </span>
                          </div>
                          <div
                            className="td double_field"
                            data-header-title="ETA"
                          >
                            <h5>ETA</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="desETA"
                                name="des_eta"
                                readOnly
                                value={form.des_eta}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="responsive_table">
                <div
                  className="table top_table table_space ifca_employee_details"
                  id="view_employee_details_table"
                >
                  <p className="mobile_crew">Crew Details</p>
                  <div className="thead">
                    <div className="tr">
                      <div
                        className="print_center th"
                        data-header-title="Crew Member Name"
                      >
                        <h4>Crew Member Name</h4>
                      </div>
                      <div
                        className="print_center gender th"
                        data-header-title="M"
                      >
                        <h4>M</h4>
                      </div>
                      <div
                        className="print_center gender th"
                        data-header-title="F"
                      >
                        <h4>F</h4>
                      </div>
                      <div
                        className="print_center crew_mem_no th"
                        data-header-title="Crew Member Number"
                      >
                        <h4>
                          Crew Member
                          <br />
                          Number
                        </h4>
                      </div>
                      <div
                        className="print_center th"
                        data-header-title="Incident Position"
                      >
                        <h4>
                          Incident
                          <br />
                          Position
                        </h4>
                      </div>
                      <div
                        className="print_center th Sawyer"
                        data-header-title="Sawyer"
                      >
                        <h4>Sawyer</h4>
                      </div>
                      <div
                        className="print_center emt th"
                        data-header-title="EMT"
                      >
                        <h4>EMT</h4>
                      </div>
                      <div
                        className="print_center th"
                        data-header-title="Experience and Lsa (Blue-Red-Yellow)"
                      >
                        <h4>
                          Experience and Lsa
                          <br />
                          <span>(Blue-Red-Yellow)</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="tbody">{this.state.crewOptions}</div>
                </div>
              </div>
              <div className="responsive_table view_driver_vehicle_div">
                <h3 className="inner_head_title">
                  Driver and Vehicle Information
                </h3>
                <div
                  className="table top_table no_border ifca_driver_vehicle_table"
                  id="view_driver_vehicle_table"
                >
                  <div className="thead">
                    <div className="tr">
                      <div className="td no_border_td_right">
                        <h6>Driver Name</h6>
                      </div>
                      <div className="td no_border_td_right">
                        <h6>License Number/State</h6>
                      </div>
                      <div className="td no_border_td_right mdate">
                        <h6>MSPA Exp. Date</h6>
                      </div>
                      <div className="td no_border_td_right">
                        <h6>Vehicle Make/Model</h6>
                      </div>
                      <div className="td no_border_td_right">
                        <h6>Vehicle License No.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="tbody">{this.state.vehicleOptions}</div>
                </div>
              </div>

              <div className="responsive_table ifca_sign">
                <div className="table top_table last_table">
                  <div className="tr">
                    <div className="td">
                      <span className="title_bottom">
                        Signature and printed name of Authorized Company
                        Representative
                      </span>
                      <p id="sup_det_view">{sup_name}</p>
                    </div>
                    <div className="td">
                      <span className="title_bottom">Date</span>
                      <br />
                      <span className="print_date">{curDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearFix" />
            <div className="gear-option-btn-wrap">
              <input
                type="button"
                className="button hide_view_manifest"
                onClick={this.previous.bind(this)}
                value="Cancel"
              />
              <input
                type="button"
                id="to_view_final_print"
                className="button"
                name="print"
                onClick={this.printManifest.bind(this, '1')}
                value="Print Manifest"
              />
            </div>
          </div>
        )}
        {this.state.manifestType === '3' && (
          <div
            id="view_print_manifest"
            className="print_manifest_wrap ncc_manifest_wrapper"
          >
            <h2>View Manifest</h2>

            <div
              className="responsive_table ncc_view_manifest"
              id="general_information_table"
            >
              <div className="top_table table">
                <div className="tr">
                  <div className="print_center td" colSpan="5">
                    <h3>Hand Crew Manifest Form</h3>
                  </div>
                </div>
                <div className="tr ncc_ordering">
                  <div className="td" data-header-title="Ordering Unit">
                    <h4>Ordering Unit</h4>
                    <input
                      type="text"
                      disabled
                      id="orderingUnit"
                      name="ordering_unit"
                      readOnly
                      value={form.ordering_unit}
                    />
                  </div>
                  <div className="td" data-header-title="Incident Name">
                    <h4>Incident Name</h4>
                    <input
                      type="text"
                      disabled
                      id="incidentName"
                      name="incident_name"
                      readOnly
                      value={form.incident_name}
                    />
                  </div>
                  <div className="td" data-header-title="Incident Number">
                    <h4>Incident Number</h4>
                    <input
                      type="text"
                      disabled
                      id="incidentNumber"
                      name="incident_number"
                      readOnly
                      value={form.incident_number}
                    />
                  </div>
                  <div
                    className="td_split td"
                    data-header-title="Resource Number"
                  >
                    <h4>Resource Number</h4>
                    <b>C-</b>{' '}
                    <span>
                      <input
                        type="text"
                        disabled
                        id="resourceNumber"
                        name="resource_number"
                        readOnly
                        value={form.resource_number}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="top_table no_border table">
                <div className="tr">
                  <div
                    width="36%"
                    className="td"
                    data-header-title="Contractor"
                  >
                    <h4>Contractor</h4>
                    <p>Firestorm Wildland Fire Supression Inc.</p>
                  </div>
                  {/* <div className="td" data-header-title="Federal Tax Id Number">
                                    <h4>Federal Tax Id Number</h4>
                                    <input type="text" disabled id="ifcaNumber" name="ifca_number" readOnly value={form.ifca_number}/>
                                </div> */}
                  <div
                    className="td"
                    data-header-title="Designated Dispatch Location"
                  >
                    <h4>Designated Dispatch Location</h4>
                    <input
                      type="text"
                      disabled
                      id="dispatchLocation"
                      name="dispatch_location"
                      readOnly
                      value={form.dispatch_location}
                    />
                  </div>
                </div>
                <div className="tr">
                  <div
                    className="td"
                    data-header-title="Contractor Represntative"
                  >
                    <h4>Contractor Represntative</h4>
                    <p id="sup_det_create">{sup_name}</p>
                  </div>
                  <div className="td" data-header-title="Report To">
                    <h4>Report To:</h4>
                    <input
                      type="text"
                      disabled
                      id="reportTo"
                      name="report_to"
                      readOnly
                      value={form.report_to}
                    />
                  </div>
                  <div className="td" data-header-title="If Delayed, Contact">
                    <h4>If Delayed, Contact:</h4>
                    <p id="sup_phone_create">
                      {this.state.superintendent &&
                      this.state.superintendent.phone ? (
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className="phone_email_link"
                          href={`tel:${this.state.superintendent.phone}`}
                          target="_top"
                        >
                          {formatPhoneNumber(this.state.superintendent.phone)}
                        </a>
                      ) : (
                        ''
                      )}
                      {/* {formatPhoneNumber(this.state.superintendent.phone)} */}
                    </p>
                  </div>
                </div>
              </div>

              <div className="top_table no_border table ncc_place">
                <div className="tr outer_place_div">
                  <div
                    width="36%"
                    className="print_center no_padding_border td"
                  >
                    <div className="top_table no_border table">
                      <div className="tr">
                        <div
                          className="print_center td_height td"
                          colSpan="3"
                          data-header-title="Departure"
                        >
                          <h5>Departure</h5>
                        </div>
                      </div>
                      <div className="tr ncc_dep">
                        <div
                          width="50%"
                          className="double_field td"
                          data-header-title="Place"
                        >
                          <h5>Place</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="depPlace"
                              name="dep_place"
                              readOnly
                              value={form.dep_place}
                            />
                          </span>
                        </div>
                        <div
                          className="double_field td"
                          data-header-title="ETD"
                        >
                          <h5>ETD</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="depETD"
                              name="dep_etd"
                              readOnly
                              value={form.dep_etd}
                            />
                          </span>
                        </div>
                        {/* <div className="double_field td" data-header-title="ETA"><h5>ETA</h5><span><input type="text" disabled id="depETA" name="dep_eta" readOnly value={form.dep_eta}/></span></div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="print_center no_padding_border td intermediate_stop"
                    width="23%"
                  >
                    <div className="top_table no_border table">
                      <div className="tr">
                        <div
                          className="print_center td_height td"
                          colSpan="2"
                          data-header-title="Intermediate Stops"
                        >
                          <h5>Intermediate Stops</h5>
                        </div>
                      </div>
                      <div className="tr ncc_inter">
                        <div
                          className="double_field td"
                          data-header-title="ETA"
                        >
                          <h5>ETA</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="interETA"
                              name="inter_eta"
                              readOnly
                              value={form.inter_eta}
                            />
                          </span>
                        </div>
                        <div
                          className="double_field td"
                          data-header-title="Place"
                        >
                          <h5>Place</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="interSpace"
                              name="inter_space"
                              readOnly
                              value={form.inter_space}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="print_center no_padding_border td">
                    <div className="top_table no_border table">
                      <div className="tr">
                        <div
                          className="print_center td_height td"
                          colSpan="3"
                          data-header-title="Destination"
                        >
                          <h5>Destination</h5>
                        </div>
                      </div>
                      <div className="tr ncc_des">
                        <div
                          className="double_field td"
                          data-header-title="ETD"
                        >
                          <h5>ETD</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="desETD"
                              name="des_etd"
                              readOnly
                              value={form.des_etd}
                            />
                          </span>
                        </div>
                        <div
                          className="double_field td"
                          data-header-title="ETA"
                        >
                          <h5>ETA</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="desETA"
                              name="des_eta"
                              readOnly
                              value={form.des_eta}
                            />
                          </span>
                        </div>
                        <div
                          className="double_field td"
                          data-header-title="Place"
                        >
                          <h5>Place</h5>
                          <span>
                            <input
                              type="text"
                              disabled
                              id="desPlace"
                              name="des_place"
                              readOnly
                              value={form.des_place}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="responsive_table">
              <div
                className="top_table table_space table ncc_employee_details"
                id="view_employee_details_table"
              >
                <p className="mobile_crew">Employee Details</p>
                <div className="thead">
                  <div className="tr">
                    <div className="print_center th" colSpan="2">
                      <h4>Employee Name (Last, First)</h4>
                    </div>
                    <div className="print_center th gender">
                      <h4>M</h4>
                    </div>
                    <div className="print_center th gender">
                      <h4>F</h4>
                    </div>
                    <div className="print_center th identification">
                      <h4>
                        Identification
                        <br />
                        Number
                      </h4>
                    </div>
                    <div className="print_center th incidentPosition">
                      <h4>
                        Incident
                        <br />
                        Position
                      </h4>
                    </div>
                    <div className="print_center th sawyer">
                      <h4>
                        Sawyer
                        <br />
                        Class
                      </h4>
                    </div>

                    <div className="print_center th">
                      <h4>
                        Experience
                        <br />
                        <span>Blue-Red-Yellow (B_R_Y)</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="tbody">{this.state.crewOptions}</div>
              </div>
            </div>
            <div className="responsive_table view_driver_vehicle_div_ncc">
              <h3 className="inner_head_title driver_vehicle_title">
                Driver and Vehicle Information
              </h3>

              <div
                className="top_table no_border table"
                id="view_driver_vehicle_table"
              >
                <div className="thead">
                  <div className="tr">
                    <div className="no_border_td_right th">
                      <h6>Driver Name (Last, First)</h6>
                    </div>
                    <div className="no_border_td_right th">
                      <h6>Vehicle Make / Model</h6>
                    </div>
                    <div className="no_border_td_right th st">
                      <h6>Year</h6>
                    </div>
                    {/* <div className="no_border_td_right th exp_date"><h6>Exp. Date</h6></div>
                                    <div className="no_border_td_right th mspa"><h6>MSPA Number</h6></div> */}
                    <div className="no_border_td_right th">
                      <h6>Vehicle License No.</h6>
                    </div>
                  </div>
                </div>
                <div className="tbody">{this.state.vehicleOptions}</div>
              </div>
            </div>
            <div className="responsive_table">
              <div className="table top_table last_table ncc_sign">
                <div className="tr">
                  <div className="td">
                    <span className="title_bottom">
                      Contractor Representative Name (Print)
                    </span>
                    <br />
                    <p id="sup_det_create">{sup_name}</p>
                  </div>
                  <div className="td">
                    <span className="title_bottom">Date</span>
                    <br />
                    <p className="print_date">{curDate}</p>
                  </div>
                  <div className="td">
                    <span className="title_bottom">Contact Phone Number</span>
                    <br />
                    <p id="sup_phone_create">
                      {this.state.superintendent &&
                      this.state.superintendent.phone ? (
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className="phone_email_link"
                          href={`tel:${this.state.superintendent.phone}`}
                          target="_top"
                        >
                          {formatPhoneNumber(this.state.superintendent.phone)}
                        </a>
                      ) : (
                        ''
                      )}
                      {/* {this.state.superintendent.phone} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearFix" />
            <div className="gear-option-btn-wrap">
              <input
                type="button"
                className="button hide_view_manifest"
                onClick={this.previous.bind(this)}
                value="Cancel"
              />
              <input
                type="button"
                id="to_view_final_print"
                className="button"
                name="print"
                onClick={this.printManifest.bind(this, '3')}
                value="Print Manifest"
              />
            </div>
          </div>
        )}

        {this.state.manifestType === '4' && (
          <div
            id="view_print_manifest"
            className="print_manifest_wrap vipr_manifest_wrapper"
          >
            <h2>View Manifest</h2>
            <div className="effective_date">
              <span className="mani_title">Manifest</span>
              <span className="from">Effective dates: {this.state.mstart}</span>

              <span className="too">To</span>
              <span className="to"> {this.state.mend} </span>
            </div>

            <div id="view_printing_page">
              <div
                className="responsive_table vipr_view_manifest"
                id="general_information_table"
              >
                <div className="top_table table">
                  <div className="tr order_info">
                    <div
                      className="td"
                      data-header-title="Ordering office and name of dispatcher (dispatch location)"
                    >
                      <h4>
                        Ordering office and name of <br />
                        dispatcher (dispatch location)
                      </h4>
                      <input
                        type="text"
                        disabled
                        id="orderingUnit"
                        name="ordering_unit"
                        readOnly
                        value={form.ordering_unit}
                      />
                    </div>
                    <div
                      className="td"
                      data-header-title="Fire name and unit (Destination)"
                    >
                      <h4>Fire name and unit (Destination)</h4>
                      <input
                        type="text"
                        disabled
                        id="incidentName"
                        name="incident_name"
                        readOnly
                        value={form.incident_name}
                      />
                    </div>
                    <div
                      className="td"
                      data-header-title="Resource order number"
                    >
                      <h4>Resource order number</h4>
                      <input
                        type="text"
                        disabled
                        id="resourceNumber"
                        name="resource_number"
                        readOnly
                        value={form.resource_number}
                      />
                    </div>
                  </div>
                </div>

                <div className="top_table no_border table">
                  <div className="tr contractor_info">
                    <div className="td" data-header-title="Name of contractor">
                      <h4>Name of contractor</h4>
                      <p id="sup_det_create">
                        Firestorm Wildland Fire Supression Inc.
                      </p>
                    </div>
                    <div
                      className="td"
                      data-header-title="Unique vehicle number"
                    >
                      <h4>Unique vehicle number</h4>
                      <input
                        type="text"
                        disabled
                        id="uniqVehNumber"
                        name="vehicle_number"
                        readOnly
                        value={form.vehicle_number}
                      />
                    </div>
                    <div className="td" data-header-title="Aggrement Number">
                      <h4>Aggrement Number</h4>
                      <input
                        type="text"
                        disabled
                        id="ifcaNumber"
                        name="ifca_number"
                        readOnly
                        value={form.ifca_number}
                      />
                    </div>
                  </div>
                  <div className="tr location">
                    <div
                      className="td"
                      data-header-title="Time of departure (dispatch location)"
                    >
                      <h4>
                        Time of departure (dispatch <br />
                        location):
                      </h4>
                      <input
                        type="text"
                        disabled
                        id="dispatchTime"
                        name="dispatch_time"
                        readOnly
                        value={form.dispatch_time}
                      />
                    </div>
                    <div
                      className="td"
                      data-header-title="Time of arrival to (final destination)"
                    >
                      <h4>
                        Time of arrival to (final <br />
                        destination):
                      </h4>
                      <input
                        type="text"
                        disabled
                        id="reportTo"
                        name="report_to"
                        readOnly
                        value={form.report_to}
                      />
                    </div>
                    <div
                      className="td"
                      data-header-title="Do you have the personnel to rotate crews?"
                    >
                      <h4>
                        Do you have the personnel to <br />
                        rotate crews?
                      </h4>

                      <select
                        className="perssonal"
                        name="perssonal"
                        readOnly
                        value={form.perssonal}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="top_table no_border place table">
                  <div className="tr">
                    <div className="print_center no_padding_border td">
                      <div className="top_table no_border table place1">
                        <div className="tr">
                          <div
                            className="print_center td_height td"
                            data-header-title="INTERMEDIATE STOPS (PLACE)"
                          >
                            <h5>INTERMEDIATE STOPS (PLACE)</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="interSpace"
                                name="inter_space"
                                readOnly
                                value={form.inter_space}
                              />
                            </span>
                          </div>
                          <div
                            className="print_center td_height td"
                            data-header-title="ARR TIME"
                          >
                            <h5>
                              ARR <br />
                              TIME
                            </h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="arr"
                                name="arr"
                                readOnly
                                value={form.arr}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="print_center no_padding_border td"
                      width="23%"
                    >
                      <div className="top_table no_border table place2">
                        <div className="tr">
                          <div
                            className="print_center td_height td"
                            data-header-title="DEP TIME"
                          >
                            <h5>
                              DEP <br />
                              TIME
                            </h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depTime"
                                name="dep_time"
                                readOnly
                                value={form.dep_time}
                              />
                            </span>
                          </div>
                          <div
                            className="print_center td_height td"
                            data-header-title="PLACE"
                          >
                            <h5>PLACE</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depPlace"
                                name="dep_place"
                                readOnly
                                value={form.dep_place}
                              />
                            </span>
                          </div>
                          <div
                            className="print_center td_height td"
                            data-header-title="ARR TIME"
                          >
                            <h5>
                              ARR <br />
                              TIME
                            </h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="depArr"
                                name="dep_arr"
                                readOnly
                                value={form.dep_arr}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="print_center no_padding_border td">
                      <div className="top_table no_border table place3">
                        <div className="tr">
                          <div
                            className="print_center td_height td"
                            colSpan="3"
                            data-header-title="DEP TIME"
                          >
                            <h5>
                              DEP <br />
                              TIME
                            </h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="perDepTime"
                                name="pdep_time"
                                readOnly
                                value={form.pdep_time}
                              />
                            </span>
                          </div>
                          <div
                            className="print_center td_height td"
                            colSpan="3"
                            data-header-title="PLACE"
                          >
                            <h5>PLACE</h5>
                            <span>
                              <input
                                type="text"
                                disabled
                                id="perDepPlace"
                                name="pedep_place"
                                readOnly
                                value={form.pedep_place}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="responsive_table">
                <div
                  className="top_table table_space vipr_employee_details table"
                  id="view_employee_details_table"
                >
                  <p className="mobile_crew">Employee Details</p>
                  <div className="thead">
                    <div className="tr">
                      <div className="print_center th">
                        <h4>Employee Name</h4>
                      </div>
                      <div className="print_center th gender">
                        <h4>M</h4>
                      </div>
                      <div className="print_center th gender">
                        <h4>F</h4>
                      </div>
                      <div className="print_center th">
                        <h4>Position</h4>
                      </div>
                      <div className="print_center th">
                        <h4>
                          LAST NIGHT NOT SPENT
                          <br /> ON FIRE ASSIGNMENT
                          <br /> (DATE)
                        </h4>
                      </div>
                      <div className="print_center th">
                        <h4>
                          UNIQUE EMPLOYEE <br />
                          NUMBER <br />
                          (NO SSN)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="tbody">{this.state.crewOptions}</div>
                </div>
              </div>
              <div className="top_table no_border vipr_sign signature table">
                <div className="tbody">
                  <div className="tr">
                    <div width="74.3%" className="td">
                      <h4 className="title_bottom">
                        SIGNATURE OF AUTHORIZED REPRESENTATIVE
                      </h4>
                      <p>
                        <input
                          type="text"
                          disabled
                          id="authSign"
                          name="sign_auth_representative"
                          readOnly
                          value={form.sign_auth_representative}
                        />
                      </p>
                    </div>
                    <div className="td">
                      <h4>DATE</h4>
                      <span className="print_date">{curDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearFix" />
            <div className="gear-option-btn-wrap">
              <input
                type="button"
                className="button hide_view_manifest"
                onClick={this.previous.bind(this)}
                value="Cancel"
              />
              <input
                type="button"
                id="to_view_final_print"
                className="button"
                name="print"
                onClick={this.printManifest.bind(this, '4')}
                value="Print Manifest"
              />
            </div>
          </div>
        )}

        {/* -------------------------------------------------------------------------------------------------------------------- */}

        {this.state.manifestType === '5' && (
          <div id="finalize_manifest" className="dcrm print_manifest_wrap">
            <h2>Finalize Manifest</h2>
            <form ref="form5" id="form5" onSubmit={this.handleSubmit}>
              <div id="view_printing_page">
                <div
                  className="responsive_table dcrm_view_manifest"
                  id="view_general_information_table"
                >
                  <div className="table top_table">
                    <div className="tr">
                      <div className="print_center td top_title" colSpan="5">
                        <h3>DEER CREEK RESOURCES MANIFEST FORM</h3>
                      </div>
                    </div>
                    <div className="tr ifca_ordering">
                      <div className="td" data-header-title="Incident Name">
                        <h4>Incident Name</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="incidentName5"
                          name="incident_name"
                          value={form.incident_name}
                        />
                      </div>
                      <div className="td" data-header-title="Incident Number">
                        <h4>Incident Number</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="incidentNumber5"
                          name="incident_number"
                          value={form.incident_number}
                        />
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div
                        className="td_split td"
                        data-header-title="Resource Number"
                      >
                        <h4>Resource</h4>
                        <b>E-</b>{' '}
                        <span>
                          <input
                            type="text"
                            disabled
                            readOnly
                            id="resource5"
                            name="resource"
                            value={form.resource}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="table top_table no_border">
                    <div className="tr contrac5">
                      <div
                        className="td"
                        data-header-title="Contractor Represntative"
                      >
                        <h4>Contractor Represntative</h4>
                        <p id="sup_det_view">{sup_name}</p>
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div
                        className="td"
                        data-header-title="Representative Phone Number"
                      >
                        <h4>Representative Phone Number</h4>
                        <p id="sup_phone_view">
                          {this.state.superintendent &&
                          this.state.superintendent.phone ? (
                            <a
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              className="phone_email_link"
                              href={`tel:${this.state.superintendent.phone}`}
                              target="_top"
                            >
                              {formatPhoneNumber(
                                this.state.superintendent.phone
                              )}
                            </a>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="tr contrac5">
                      <div className="td" data-header-title="Point of Hire">
                        <h4>Point of Hire</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="dispatchLocation6"
                          name="dispatch_location"
                          value={form.point_of_hire}
                        />
                      </div>
                      <div className="td" data-header-title="Job Location">
                        <h4>Job Location</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="departure6"
                          name="departure"
                          value={form.job_loc}
                        />
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div
                        className="td"
                        data-header-title="Estimated Duration"
                      >
                        <h4>Estimated Duration</h4>
                        <div className="duration_date_wrap">
                          <div className="manifesttDateWrapper">
                            <input
                              type="text"
                              disabled
                              readOnly
                              id="departure6"
                              name="departure"
                              value={form.est_start}
                            />
                          </div>
                          <span className="to">To</span>
                          <div className="manifesttDateWrapper">
                            <input
                              type="text"
                              disabled
                              readOnly
                              id="departure6"
                              name="departure"
                              value={form.est_finish}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="responsive_table">
                  <div
                    className="table top_table table_space ifca_employee_details"
                    id="view_employee_details_table"
                  >
                    <p className="mobile_crew">Crew Details</p>
                    <div className="thead">
                      <div className="tr">
                        <div
                          className="print_center th"
                          data-header-title="Crew Member Name"
                        >
                          <h4>Employee Name (Last, First)</h4>
                        </div>
                        <div
                          className="print_center gender th"
                          data-header-title="M"
                        >
                          <h4>M</h4>
                        </div>
                        <div
                          className="print_center gender th"
                          data-header-title="F"
                        >
                          <h4>F</h4>
                        </div>
                        <div
                          className="print_center crew_mem_no th"
                          data-header-title="Employee Id Number"
                        >
                          <h4>
                            Employee Id
                            <br />
                            Number
                          </h4>
                        </div>
                        <div
                          className="print_center th"
                          data-header-title="Project Position"
                        >
                          <h4>
                            Project
                            <br />
                            Position
                          </h4>
                        </div>
                        <div
                          className="print_center th phone"
                          data-header-title="Phone Number"
                        >
                          <h4>
                            Phone <br /> Number
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="tbody">{this.state.crewOptions}</div>
                  </div>
                </div>
                <div className="responsive_table view_driver_vehicle_div">
                  <h3 className="inner_head_title">
                    Driver and Vehicle Information
                  </h3>
                  <div
                    className="table top_table no_border ifca_driver_vehicle_table"
                    id="view_driver_vehicle_table"
                  >
                    <div className="thead">
                      <div className="tr">
                        <div className="td no_border_td_right">
                          <h6>Driver Name (Last, First)</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Driver License No</h6>
                        </div>
                        <div className="td no_border_td_right mdate DLIssued">
                          <h6>ST</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Exp. Date</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Vehicle License No</h6>
                        </div>
                      </div>
                    </div>
                    <div className="tbody">{this.state.vehicleOptions}</div>
                  </div>
                </div>
                <div className="table top_table last_table ncc_sign">
                  <div className="tr">
                    <div className="td">
                      <span className="title_bottom">
                        Crew Leader Represntative (Print)
                      </span>
                      <br />
                      <p id="sup_det_create">{sup_name}</p>
                    </div>
                    <div className="td">
                      <span className="title_bottom">Date</span>
                      <br />
                      <p className="print_date">{curDate}</p>
                    </div>
                    <div className="td">
                      <span className="title_bottom">Contact Phone Number</span>
                      <br />
                      <p id="sup_phone_view">
                        {this.state.superintendent &&
                        this.state.superintendent.phone ? (
                          <a
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            className="phone_email_link"
                            href={`tel:${this.state.superintendent.phone}`}
                            target="_top"
                          >
                            {formatPhoneNumber(this.state.superintendent.phone)}
                          </a>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearFix" />
              <div className="gear-option-btn-wrap">
                <input
                  type="button"
                  className="button hide_view_manifest"
                  onClick={this.previous.bind(this)}
                  value="Cancel"
                />
                <input
                  type="button"
                  id="to_view_final_print"
                  className="button"
                  name="print"
                  onClick={this.printManifest.bind(this, '5')}
                  value="Print Manifest"
                />
              </div>
            </form>
          </div>
        )}

        {/* -------------------------------------------------------------------------------------------------------------------- */}

        {this.state.manifestType === '6' && (
          <div id="finalize_manifest" className="pm print_manifest_wrap">
            <h2>Finalize Manifest</h2>
            <form ref="form6" id="form6" onSubmit={this.handleSubmit}>
              <div id="view_printing_page">
                <div
                  className="responsive_table pm_view_manifest"
                  id="view_general_information_table"
                >
                  <div className="table top_table">
                    <div className="tr">
                      <div className="print_center td top_title" colSpan="5">
                        <h3>Project Manifest</h3>
                      </div>
                    </div>
                    <div className="tr ifca_ordering">
                      <div className="td" data-header-title="Project Name">
                        <h4>Project Name</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="projectName6"
                          name="project_name"
                          value={form.project_name}
                        />
                      </div>
                      <div className="td" data-header-title="Project Code">
                        <h4>Project Code</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="projectCode6"
                          name="project_code"
                          value={form.project_code}
                        />
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div className="td_split td" data-header-title="Resource">
                        <h4>Resource</h4>
                        <b>C-</b>{' '}
                        <span>
                          <input
                            type="text"
                            disabled
                            readOnly
                            id="resource6"
                            name="resource"
                            value={form.resource}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="table top_table no_border">
                    <div className="tr contrac6">
                      <div
                        className="td"
                        data-header-title="Crew Leader Represntative"
                      >
                        <h4>Crew Leader Represntative</h4>
                        <p id="sup_det_view">{sup_name}</p>
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div
                        className="td"
                        data-header-title="Representative Phone Number"
                      >
                        <h4>Representative Phone Number</h4>
                        <p id="sup_phone_view">
                          {this.state.superintendent &&
                          this.state.superintendent.phone ? (
                            <a
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              className="phone_email_link"
                              href={`tel:${this.state.superintendent.phone}`}
                              target="_top"
                            >
                              {formatPhoneNumber(
                                this.state.superintendent.phone
                              )}
                            </a>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="tr contrac6">
                      <div className="td" data-header-title="Point of Hire">
                        <h4>Point of Hire</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="dispatchLocation6"
                          name="dispatch_location"
                          value={form.point_of_hire}
                        />
                      </div>
                      <div className="td" data-header-title="Job Location">
                        <h4>Job Location</h4>
                        <input
                          type="text"
                          disabled
                          readOnly
                          id="departure6"
                          name="departure"
                          value={form.job_loc}
                        />
                      </div>
                      {/*<div className="tdw-15 td"></div>*/}
                      <div
                        className="td"
                        data-header-title="Estimated Duration"
                      >
                        <h4>Estimated Duration</h4>
                        <div className="duration_date_wrap">
                          <div className="manifesttDateWrapper">
                            <input
                              type="text"
                              disabled
                              readOnly
                              id="departure6"
                              name="departure"
                              value={form.est_start}
                            />
                          </div>
                          <span className="to">To</span>
                          <div className="manifesttDateWrapper">
                            <input
                              type="text"
                              disabled
                              readOnly
                              id="departure6"
                              name="departure"
                              value={form.est_finish}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="responsive_table">
                  <div
                    className="table top_table table_space ifca_employee_details"
                    id="view_employee_details_table"
                  >
                    <p className="mobile_crew">Crew Details</p>
                    <div className="thead">
                      <div className="tr">
                        <div
                          className="print_center th"
                          data-header-title="Crew Member Name"
                        >
                          <h4>Employee Name (Last, First)</h4>
                        </div>
                        <div
                          className="print_center gender th"
                          data-header-title="M"
                        >
                          <h4>M</h4>
                        </div>
                        <div
                          className="print_center gender th"
                          data-header-title="F"
                        >
                          <h4>F</h4>
                        </div>
                        <div
                          className="print_center crew_mem_no th"
                          data-header-title="Employee Id Number"
                        >
                          <h4>
                            Employee Id
                            <br />
                            Number
                          </h4>
                        </div>
                        <div
                          className="print_center th"
                          data-header-title="Project Position"
                        >
                          <h4>
                            Project
                            <br />
                            Position
                          </h4>
                        </div>
                        <div
                          className="print_center th phone"
                          data-header-title="Phone Number"
                        >
                          <h4>
                            Phone <br /> Number
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="tbody">{this.state.crewOptions}</div>
                  </div>
                </div>
                <div className="responsive_table view_driver_vehicle_div">
                  <h3 className="inner_head_title">
                    Driver and Vehicle Information
                  </h3>
                  <div
                    className="table top_table no_border ifca_driver_vehicle_table"
                    id="view_driver_vehicle_table"
                  >
                    <div className="thead">
                      <div className="tr">
                        <div className="td no_border_td_right">
                          <h6>Driver Name (Last, First)</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Driver License No</h6>
                        </div>
                        <div className="td no_border_td_right mdate DLIssued">
                          <h6>ST</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Exp. Date</h6>
                        </div>
                        <div className="td no_border_td_right">
                          <h6>Vehicle License No</h6>
                        </div>
                      </div>
                    </div>
                    <div className="tbody">{this.state.vehicleOptions}</div>
                  </div>
                </div>
                <div className="table top_table last_table ncc_sign">
                  <div className="tr">
                    <div className="td">
                      <span className="title_bottom">
                        Crew Leader Represntative (Print)
                      </span>
                      <br />
                      <p id="sup_det_create">{sup_name}</p>
                    </div>
                    <div className="td">
                      <span className="title_bottom">Date</span>
                      <br />
                      <p className="print_date">{curDate}</p>
                    </div>
                    <div className="td">
                      <span className="title_bottom">Contact Phone Number</span>
                      <br />
                      <p id="sup_phone_view">
                        {this.state.superintendent &&
                        this.state.superintendent.phone ? (
                          <a
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            className="phone_email_link"
                            href={`tel:${this.state.superintendent.phone}`}
                            target="_top"
                          >
                            {formatPhoneNumber(this.state.superintendent.phone)}
                          </a>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearFix" />
              <div className="gear-option-btn-wrap">
                <input
                  type="button"
                  className="button hide_view_manifest"
                  onClick={this.previous.bind(this)}
                  value="Cancel"
                />
                <input
                  type="button"
                  id="to_view_final_print"
                  className="button"
                  name="print"
                  onClick={this.printManifest.bind(this, '6')}
                  value="Print Manifest"
                />
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { superintendent, manifest, crews, vehicles } = state;
  return {
    superintendent,
    manifest,
    crews,
    vehicles,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ViewManifest);
export { connectedResourcePage as ViewManifest };
