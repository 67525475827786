import React from 'react';

import Select from 'react-select';

export const expirationDateFilterOptions = [
  { value: 'dl', label: 'Drivers License', color: '#00B8D9' },
  { value: 'dol_physical', label: 'DMV Physical', color: '#5243AA' },
  { value: 'firstaid', label: 'First Aid/CPR', color: '#5243AA' },
  {
    value: 'sexualharrassment',
    label: 'Sexual Harassment',
    color: '#FF5630',
  },
  { value: 'fallerquals', label: 'Faller Qualification', color: '#FF5630' },
  { value: 'status', label: 'Status', color: '#FF5630' },
  { value: 'resource', label: 'Resource', color: '#FF5630' },
];

export const selectAllOption = [
  {
    value: 'all',
    label: 'All',
    color: '#0052CC',
    isFixed: true,
  },
];

export default ({ onChange, value = [], options = [] }) => {
  return (
    <Select
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      value={value}
      onChange={onChange}
      isMulti
      name="expiration-dates"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};
