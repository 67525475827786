import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { authHeader, config } from '../_helpers';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { applicantsActions } from '../_actions';
import { AlertPopup } from '../_components/AlertPopup';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

class EmailFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to: this.props.email,
      step: '1',
      template: '',
      statusOpen: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromText('')
      ),
      var1: 'all',
      var2: 'all',
      selected: '',
      message: '',
      filter_list: [],
      param: {
        var1: 'all',
        var2: 'all',
        start_date: moment(),
        end_date: moment(),
      },
      borderError: '',
      alertPop: false,
      failDateRange: false,
    };
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.chooseTemplate = this.chooseTemplate.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.cancel = this.cancel.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.validate = this.validate.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.validate_date = this.validate_date.bind(this);
  }

  statusOpenModal(template) {
    this.setState({ statusOpen: true, selected: template, template: template });
  }

  componentDidMount() {
    this.props.dispatch(applicantsActions.getFilters());
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps;
    var filter_list = [];
    if (filters) {
      filters.map((item, index) => {
        filter_list.push(
          <option value={item.value} key={index}>
            {item.value}
          </option>
        );
      });
      this.setState({ filter_list });
    }
  }
  //
  statusCloseModal() {
    this.setState({ statusOpen: false });
    if (this.state.param.message !== '') {
      this.setState({
        msg: 'show',
        temp: 'hide',
        custom: this.state.param.message,
      });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  goBack() {
    this.props.goBack();
  }

  chooseTemplate(template) {
    this.setState({ template: template, selected: template, borderError: '' });
  }

  changeStep(step) {
    if (step === '3' && this.state.template === '') {
      this.setState({
        borderError: 'borderError',
      });
    } else {
      this.setState({ step: step });
    }
  }
  isActive(value) {
    return value === this.state.selected ? 'active' : 'default';
  }

  cancel() {
    this.setState({ message: '', statusOpen: false });
    // this.statusCloseModal();
  }

  handleChange(event) {
    this.setState({
      param: {
        ...this.state.param,
        [event.target.name]: event.target.value,
      },
      template: 'custommail',
    });
  }

  handleTextChange = (editorState) => {
    this.setState({
      param: {
        ...this.state.param,
        message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      },
      editorState,
      template: 'custommail',
    });
  };

  handleDateChange(name, date) {
    this.setState(
      {
        param: {
          ...this.state.param,
          [name]: date,
        },
        failDateRange: false,
      },
      () => {
        let diff = moment(this.state.param.end_date).diff(
          this.state.param.start_date,
          'days'
        );
        if (diff >= 7) {
          this.setState({
            failDateRange: true,
          });
        }
      }
    );
  }

  validate() {
    var err = false;
    if (this.state.template === '') {
      return true;
    }
  }

  sendMail() {
    var err = this.validate();
    if (!err) {
      let data = new FormData();
      this.statusCloseModal();

      data.append('startDate', this.state.param.start_date || '');
      data.append('endDate', this.state.param.end_date || '');
      data.append('variable1', this.state.param.var1 || '');
      data.append('variable2', this.state.param.var2 || '');

      data.append('template', this.state.template);
      data.append('message', this.state.custom);
      data.append('to', this.state.to);
      var self = this;
      fetch(config.apiUrl + '/api/filterapplicantsmail', {
        headers: authHeader(),
        method: 'POST',
        body: data,
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          if (data.success) {
            self.setState({
              alertPop: true,
              alertHead: 'Success',
              alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertFunction: self.goBack.bind(self),
            });
          } else {
            self.setState({
              alertPop: true,
              alertHead: 'Error',
              alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertFunction: '',
            });
          }
        });
    } else {
      this.setState({
        borderError: 'borderError',
      });
    }
  }

  validate_date(start, end) {
    if (end < start) return false;
    if (start > end) return false;
    // console.log(end.diff(start,'days'));
    if (end.diff(start, 'days') !== 0 && end.diff(start, 'days') > 6)
      return false;
    return true;
  }

  render() {
    let start_date = moment(this.state.param.start_date, 'DD/MM/YYYY').format(
      'MM/DD/YY'
    );
    let end_date = moment(this.state.param.end_date, 'DD/MM/YYYY').format(
      'MM/DD/YY'
    );
    let date_valid = this.validate_date(
      this.state.param.start_date,
      this.state.param.end_date
    );

    // const template1 = <div className="email_template">
    //     <h3>No Prior Training</h3>
    //     <p>Text removed for NVAM clone</p>
    // </div>
    // const template2 = <div className="email_template">
    //     <h3>Prior Training - Not Current</h3>
    //     <p>Text removed for NVAM clone</p>
    // </div>
    // const template3 = <div className="email_template">
    //     <h3>Prior Training - Current</h3>
    //     <p>Text removed for NVAM clone</p>
    // </div>

    const { editorState } = this.state;
    return (
      <div className="resource_container filter_container">
        <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{' '}
          / Email Applicants
        </div>
        <div className="page_title float">
          <h1>Email Applicants</h1>
        </div>
        <div className="applicants_inner_head inner_head">
          {this.state.step === '3' && (
            <div className="btn_list">
              <button
                className="button grey"
                onClick={this.changeStep.bind(this, '2')}
              >
                Previous
              </button>
              <button className="button" onClick={this.sendMail.bind(this)}>
                Send Email
              </button>
            </div>
          )}
          {this.state.step === '2' && (
            <div className="btn_list">
              <button
                className="button grey"
                onClick={this.changeStep.bind(this, '1')}
              >
                Previous
              </button>
              <button
                className="button"
                onClick={this.changeStep.bind(this, '3')}
              >
                Next
              </button>
            </div>
          )}

          {this.state.step === '1' && (
            <button
              className={`button right ${!date_valid && 'disabled'}`}
              onClick={this.changeStep.bind(this, '2')}
              disabled={!date_valid}
            >
              Next
            </button>
          )}
        </div>
        <div className="inner_container col-lg-12" id="applicants_inner">
          {this.state.step === '1' && (
            <div className="email_step1">
              <div className="filter-block1 filter-block left">
                <label>Date</label>
                <div className="filter-start-date filter-date left">
                  <DatePicker
                    name="start_date"
                    placeholderText="mm/dd/yy"
                    selected={this.state.param.start_date}
                    onChange={this.handleDateChange.bind(this, 'start_date')}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <div>Start Date</div>
                </div>
                <div className="filter-end-date filter-date right">
                  <DatePicker
                    name="end_date"
                    placeholderText="mm/dd/yy"
                    selected={this.state.param.end_date}
                    onChange={this.handleDateChange.bind(this, 'end_date')}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <div>End Date</div>
                </div>
              </div>
              <div className="filter-block2 filter-block left">
                <label>Variable #1</label>
                <select
                  name="var1"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.param.var1}
                >
                  {/* <option value="all">All</option> */}
                  {this.state.filter_list}
                </select>
              </div>
              <div className="filter-block3 filter-block right">
                <label>Variable #2</label>
                <select
                  name="var2"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.param.var2}
                >
                  {/* <option value="all">All</option> */}
                  {this.state.filter_list}
                </select>
              </div>
              {this.state.failDateRange && (
                <p style={{ position: 'absolute', bottom: '20px' }}>
                  <span role="img" aria-label="exclamation icon">
                    &#x26A0;&#xFE0F;
                  </span>{' '}
                  Date range should be 7 days maximum
                </p>
              )}
            </div>
          )}
          {this.state.step === '2' && (
            <div className="email_step2 row">
              {/* Disabled below for NVAM clone */}
              {/* <div className={this.isActive('template1') + " applicants_list col-lg-4 " + this.state.borderError} onClick={this.chooseTemplate.bind(this, 'template1')}>
                                {template1}
                            </div>
                            <div className={this.isActive('template2') + " applicants_list col-lg-4 " + this.state.borderError} onClick={this.chooseTemplate.bind(this, 'template2')}>
                                {template2}
                            </div>
                            <div className={this.isActive('template3') + " applicants_list col-lg-4 " + this.state.borderError} onClick={this.chooseTemplate.bind(this, 'template3')}>
                                {template3}
                            </div> */}
              <div
                className={
                  this.isActive('template4') +
                  ' applicants_list custom col-lg-12 ' +
                  this.state.borderError
                }
                onClick={this.statusOpenModal.bind(this, 'template4')}
              >
                <div className="email_template custom_email">
                  <h3>Email template</h3>
                  <p className={this.state.temp + ' tmp-desc'}>
                    <img
                      className="email-pen"
                      alt="Email pen"
                      src={require('../assets/images/email-pen.svg').default}
                    />
                    Write email
                  </p>
                  <p className={this.state.msg + ' description'}>
                    {this.state.custom
                      ? this.state.custom.replace(/<(?:.|\n)*?>/gm, '')
                      : ''}
                  </p>
                </div>
              </div>
              {/* <div className="clearFix"></div>
                            <div className="btn_list">
                                <button className="button grey" onClick={this.changeStep.bind(this,"1")}>Previous</button>
                                <button className="button" onClick={this.changeStep.bind(this,"3")}>Next</button>
                            </div> */}
            </div>
          )}
          {this.state.step === '3' && (
            <div className="email_step3 row">
              {/* <div className="filter-block-half filter-block-half-final1 left"> */}
              <div className="col-md-12">
                {/* <div className="efinalwrapfilters efinalwrap"> */}
                <div className="filter-options">
                  <h4>Filter Options</h4>
                  {start_date && end_date ? (
                    <div>
                      {(start_date || 'No Date') +
                        ' - ' +
                        (end_date || 'No Date')}
                    </div>
                  ) : (
                    ''
                  )}
                  <div> {this.state.param.var1 || 'All'}</div>
                  <div> {this.state.param.var2 || 'All'}</div>
                </div>
              </div>
              {/* <div className="filter-block-half filter-block-half-final2 left"> */}
              <div className="col-md-12">
                {/* Disabled for NVAM clone */}
                {/* {this.state.template === "template1" && template1}
                                {this.state.template === "template2" && template2}
                                {this.state.template === "template3" && template3} */}
                {this.state.template === 'custommail' && (
                  <div className="email_template email_custom">
                    <h3>Custom Template</h3>
                    <div
                      className="alb-info left"
                      placeholder="Custom message..."
                    >
                      {this.state.custom
                        ? this.state.custom.replace(/<(?:.|\n)*?>/gm, '')
                        : ''}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="btn_list">
                                <button className="button grey" onClick={this.changeStep.bind(this,"2")}>Previous</button>
                                <button className="button" onClick={this.sendMail.bind(this)}>Send Email</button>
                            </div> */}
            </div>
          )}
        </div>
        <Modal
          styles={{ modal: { width: '100%' } }}
          id="status_popup"
          className="status_popup"
          open={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          little
        >
          <div id="statusPopup" className="custom_template_mail">
            <div className="modal-head">
              <h4>Custom Template</h4>
            </div>
            <div className="modal-body">
              {/* <textarea name="message" cols="80" rows="10" value={this.state.param.message} onChange={this.handleChange.bind(this)}></textarea> */}
              <div className="fs-block">
                <Editor
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'list',
                      'history',
                    ],
                  }}
                  editorState={editorState}
                  placeholder="Write a message"
                  onEditorStateChange={this.handleTextChange}
                />
              </div>

              <p className="step_no">
                Signature will automatically be included.
              </p>
              <div className="btn_list">
                <button className="button grey" onClick={this.cancel}>
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.statusCloseModal.bind(this)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/*</div>    */}
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { filters } = state.applicants;
  return {
    filters,
  };
}

const connectedApplicants = connect(mapStateToProps)(EmailFilter);
export { connectedApplicants as EmailFilter };
