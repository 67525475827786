import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import '../assets/styles/your-crew.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  resourceActions,
  //manifestActions, //Disabled for NVAM clone
  permissionActions,
  assignmentStatsActions,
  userActions,
  inventoryActions,
} from '../_actions';
import '../assets/styles/resource.css';
import { authHeader, config } from '../_helpers';
import { Document, Page } from 'react-pdf';
import ReactModal from 'react-modal';
import { AlertPopup } from '../_components/AlertPopup';
import Accordian from '_components/Accordian';
import EmployeeList from 'Employees/EmployeeList';
// a little function to help us with reordering the result

ReactModal.setAppElement('#app');

class ResourceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      keyword: '',
      id: this.props.props,
      dispatchMailList: [],
      mailListDefault: [],
      mailList: [],
      searchList: [],
      emailOpen: false,
      viewOpen: false,
      statusOpen: false,
      deleteOpen: false,
      textarea: true,
      search: false,
      linkClick: false,
      mid: '',
      action: 'hide',
      action_id: '',
      viewStatus: false,
      hideSearch: 'hide',
      numPages: null,
      pageNumber: 1,
      to: '',
      employeeList: [],
      flag: true,
      resultData: [],
      alertPop: false,
      resource_name: '',
      inputValue: false,
      emailSuccess: '',
      manifestPageNum: 1,
      assignmentStatusOptions: ['Available', 'Unavailable', 'Committed'],
      currentAssignmentStatus: 'Available', //varible to save current assignment status
      tempAssignmentStatus: '', //varible to save assignment status when the user change in the popup , for displaying the form fields based on the selection
      currentProjectDetails: [], //array to store all details about current project
      unavailable_reason_id: '',
      unavailable_reasons: [],
      onDate: moment(),
      offDate: moment(),
      crewAdmin: false,
      currentAssignmentDetails: {
        name: '',
        date_off: '',
        date_on: '',
        assignment_type_id: '',
        assignment_type: '',
        shifts: '',
        notes: '',
        id: 0,
      },
      errorMessage: '',
      deleteHistoryOpen: false,
      historyId: '',
      assigmentCategoryOptions: [],
      assigmentStatusHistory: { data: [] },
      incidentProjects: [],
      projectId: null,
      projectName: '',
      projectNote: '',
      projectShift: '',
      tempAssignmentType: '',
      assignmentHistoryId: '',
      validManifestMail: false,
      manifestEmail: '',
      inventoryPage: 1,
      inventoryLastPage: 0,
      inventoryPerPage: 1,
      inventoryList: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.deleteOpenModal = this.deleteOpenModal.bind(this);
    this.deleteCloseModal = this.deleteCloseModal.bind(this);

    this.deleteHistoryCloseModal = this.deleteHistoryCloseModal.bind(this);

    this.emailOpenModal = this.emailOpenModal.bind(this);
    this.emailCloseModal = this.emailCloseModal.bind(this);
    this.viewOpenModal = this.viewOpenModal.bind(this);
    this.viewCloseModal = this.viewCloseModal.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.goBack = this.goBack.bind(this);
    this.remove = this.remove.bind(this);
    this.profile = this.profile.bind(this);
    this.editResource = this.editResource.bind(this);
    this.createManifest = this.createManifest.bind(this); //Disabled for NVAM clone
    this.editManifest = this.editManifest.bind(this); //Disabled for NVAM clone
    this.deleteManifest = this.deleteManifest.bind(this); //Disabled for NVAM clone
    this.printManifest = this.printManifest.bind(this); //Disabled for NVAM clone
    this.emailManifest = this.emailManifest.bind(this); //Disabled for NVAM clone
    this.sendMail = this.sendMail.bind(this);
    this.setMailer = this.setMailer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.emailChangeFn = this.emailChangeFn.bind(this);
    this.removeList = this.removeList.bind(this);
    this.viewManifest = this.viewManifest.bind(this);
    this.viewAction = this.viewAction.bind(this);
    // this.onSortEnd = this.onSortEnd.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.editResourceHistory = this.editResourceHistory.bind(this);
    this.selectProject = this.selectProject.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addManifestEmail = this.addManifestEmail.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.inventoryPagination = this.inventoryPagination.bind(this);
    this.getInventoryList = this.getInventoryList.bind(this);
    this.getUnavailableReasons = this.getUnavailableReasons.bind(this);
    this.employeeListUpdated = this.employeeListUpdated.bind(this);
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: '1' });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  deleteOpenModal(id) {
    this.setState({ deleteOpen: true, mid: id });
  }

  deleteCloseModal() {
    this.setState({ deleteOpen: false });
  }

  deleteHistoryOpenModal = (id) => {
    this.setState({ deleteHistoryOpen: true, historyId: id });
  };

  deleteHistoryCloseModal() {
    this.setState({ deleteHistoryOpen: false, historyId: '' });
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }

  isValidEmail(email) {
    let re = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    console.log(re.test(email.toString()));
    return re.test(email);
  }

  viewOpenModal(id) {
    this.setState({ viewOpen: true, mid: id });
  }

  viewCloseModal() {
    this.setState({ viewOpen: false });
  }

  emailOpenModal() {
    this.setState({
      emailOpen: true,
      mailListDefault: this.state.dispatchMailList,
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  onDocumentLoad(numPages) {
    this.setState({ numPages });
  }

  emailCloseModal() {
    this.setState({ emailOpen: false });
    this.setState({ mailList: [], textarea: true, to: '' });
    document.getElementById('txtSearchManiEmployees').value = '';
    // document.getElementById('multiple_emails').value = "";
  }
  viewAction(id) {
    this.setState({
      action_id: id,
      action: 'show',
      viewStatus: !this.state.viewStatus,
    });
  }

  inventoryPagination() {
    if (this.state.inventoryList && this.state.inventoryList.length > 0) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.inventoryPage;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.inventoryLastPage
          ? currentPage + 2
          : this.state.inventoryLastPage;
      let nextPage =
        currentPage + 1 > this.state.inventoryLastPage
          ? this.state.inventoryLastPage
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;

      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getInventoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? 'active' : 'inactive'}
            key={i}
            onClick={() => {
              this.getInventoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (lastPageIndex + 1 < this.state.inventoryLastPage) {
        pages.push(
          <li className="inactive" key={this.state.inventoryLastPage + 1}>
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.inventoryLastPage) {
        pages.push(
          <li
            className="inactive"
            key={this.state.inventoryLastPage}
            onClick={() => {
              this.getInventoryList(this.state.inventoryLastPage);
            }}
          >
            <a>{this.state.inventoryLastPage}</a>
          </li>
        );
      }

      if (pages.length > 0) {
        return (
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="fs-pagination-wrapper">
              <span
                className="prev"
                onClick={() => {
                  this.getInventoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require('../assets/images/previous.svg').default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className="next"
                onClick={() => {
                  this.getInventoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require('../assets/images/next.svg').default}
                />
              </span>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="applicant_item" key="noEmployees">
          <p>No Inventory Assigned!</p>
        </div>
      );
    }
  }

  editResourceHistory(item) {
    let assignment = this.state.assigmentStatusHistory.data.find(
      (i) => i.id === item.id
    );

    if (assignment) {
      let { id, status, incident_projects, notes, unavailable_reason_id } =
        assignment;

      if (status == 3) {
        this.setState({
          editMode: true,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          tempAssignmentType: incident_projects.assignment_type_id,
          initialStatusId: status,
          projectId: incident_projects.id,
          projectName: incident_projects.name || '',
          projectShift: incident_projects.shifts || '',
          onDate: moment(incident_projects.date_on),
          offDate: moment(incident_projects.date_off),
          projectNote: notes || '',
        });
      } else {
        this.setState({
          editMode: true,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          initialStatusId: status,
          projectNote: notes || '',
          unavailable_reason_id,
        });
      }

      document.body.className += ' ' + 'inv-sidebar-opened';
    }
  }

  getInventoryList(page) {
    let { inventoryLastPage } = this.state;
    if (page && page >= 1 && page <= inventoryLastPage) {
      this.props
        .dispatch(
          inventoryActions.getPaginatedList({ id: this.state.id, page })
        )
        .then((res) => {
          this.setState({
            inventoryPage: res.current_page,
            inventoryLastPage: res.last_page,
            inventoryPerPage: res.per_page,
          });
        });
    }
  }

  selectProject(e) {
    let id = e.target.value;
    this.setState({
      projectId: id || null,
    });
  }

  handleSelect(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addManifestEmail() {
    if (this.state.validManifestMail) {
      this.setState({
        mailSearchKeyword: '',
        hideSearch: 'hide',
        mailList: [
          ...this.state.mailList,
          {
            first_name: '-',
            last_name: '-',
            email: this.state.manifestEmail,
          },
        ],
      });
    }
  }

  // get unavailable reasons
  getUnavailableReasons() {
    const _requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    const _url = `${config.apiUrl}/api/getlist/unavailablereason`;
    fetch(_url, _requestOptions).then((response) =>
      response.json().then(
        (res) => {
          console.log('Unavailable Reasons', res);
          this.setState({ unavailable_reasons: res });
        },
        (err) => console.error(err)
      )
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    var body = document.body;
    body.classList.remove('inv-sidebar-opened');
  }

  componentDidMount() {
    let data = new FormData();
    this.getUnavailableReasons();
    //this.props.dispatch(manifestActions.dispatchMailList()); //Disabled for NVAM clone
    this.props.dispatch(resourceActions.getResourceDetails(this.props.props));
    this.props.dispatch(resourceActions.getProjects());
    this.props.dispatch(assignmentStatsActions.getAssignemntStatusTypes());
    this.props.dispatch(
      assignmentStatsActions.getAssignemntStatus(this.state.id)
    );
    this.props.dispatch(
      assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
    );

    data.append('crew_identifier_id', this.state.id);
    this.props.dispatch(resourceActions.getResourceVehicles(this.state.id));

    data.append('active', true);
    data.append('locked', true);
    data.append('guest', true);
    data.append('pagination', false);

    this.props.dispatch(resourceActions.employeeList(data));
    // this.props.dispatch(resourceActions.employeeList(data));

    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(userActions.getAll());

    let data2 = new FormData();
    data2.append('resource_id', this.state.id);
    this.getManifestList();
    //this.props.dispatch(manifestActions.getVehicles(data2)); //Disabled for NVAM clone
    this.props
      .dispatch(
        inventoryActions.getPaginatedList({ id: this.state.id, page: 1 })
      )
      .then((res) => {
        this.setState({
          inventoryPage: res.current_page,
          inventoryLastPage: res.last_page,
          inventoryPerPage: res.per_page,
        });
      });
  }

  // call after doing some actions in EmployeeList
  employeeListUpdated() {
    let data = new FormData();
    data.append('crew_identifier_id', this.state.id);
    data.append('active', true);
    data.append('locked', true);
    data.append('guest', true);
    data.append('pagination', false);
    this.props.dispatch(resourceActions.employeeList(data));
  }

  getManifestList = (manifestPageNum = 1) => {
    let data2 = new FormData();
    data2.append('resource_id', this.state.id);
    //this.props.dispatch(manifestActions.manifestList(data2, manifestPageNum)); //Disabled for NVAM clone
  };

  createManifest() {
    if (
      this.state.outerVehicle &&
      Array.isArray(this.state.outerVehicle.data) &&
      this.state.outerVehicle.data.length > 0
    ) {
      if (this.state.vehicleList && this.state.vehicleList.data.length === 0) {
        this.setState({
          alertPop: true,
          alertHead: 'Error',
          alertMsg: 'Assign drivers to vehicle for creating manifest',
          alertFunction: '',
        });
      } else {
        this.props.createManifest();
      }
    } else {
      // alert('Assign vehicles to this resource for creating manifest')
      this.setState({
        alertPop: true,
        alertHead: 'Error',
        alertMsg: 'Assign vehicles to this resource for creating manifest',
        alertFunction: '',
      });
    }
  }

  editManifest(id, type) {
    this.props.editManifest(id, type);
  }

  deleteManifest() {
    let data = new FormData();
    data.append('manifest_id', this.state.mid);
    let data2 = new FormData();
    data2.append('resource_id', this.state.id);
    //this.props.dispatch(manifestActions.deleteManifest(data, data2)); //Disabled for NVAM clone
    this.setState({ deleteOpen: false });
  }

  printManifest(id) {
    var data =
      '?manifest_id=' +
      this.state.action_id +
      '&resource_id=' +
      this.props.props;
    // this.props.dispatch(manifestActions.printManifest(data));
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
    };
    var self = this;
    fetch(config.apiUrl + '/api/manifest/pdf' + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          const pdfLink = data.data;
          var link = document.createElement('a');
          if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i)
          ) {
            link.href = pdfLink;
          } else {
            link.href = pdfLink;
            link.target = '_blank';
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfLink);
          }, 100);
        } else {
          self.setState({
            alertPop: true,
            alertHead: 'Error',
            alertMsg: data.message,
            alertFunction: '',
          });
        }
      });
  }

  emailManifest() {
    let data = new FormData();
    data.append('manifest_id', this.state.action_id);
    data.append('resource_id', this.props.props);
    //data.append("to", this.state.to);

    let toList = this.state.mailListDefault;

    if (this.state.mailList && this.state.mailList.length > 0) {
      toList = [...toList, ...this.state.mailList.map((item) => item.email)];
    }

    data.append('to', toList.join());

    //this.props.dispatch(manifestActions.emailManifest(data)); //Disabled for NVAM clone
    this.props.details(this.props.props);
    this.setState({ emailOpen: false, emailSuccess: 'true' });

    this.setState({ mailList: [], textarea: true, to: '' });
    document.getElementById('txtSearchManiEmployees').value = '';
    // document.getElementById('multiple_emails').value = "";
  }

  handleScroll(event) {
    var wrap = document.getElementById('right_sidebar'),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = Math.ceil(yOffset + window.innerHeight),
      applicationItem = [],
      sortVariable = '';

    if (this.state.sort !== '' && this.state.sort) {
      sortVariable =
        '&sort=' + this.state.sort + '&order=' + this.state.orderAll
          ? this.state.orderAll
          : 'asc';
    }

    if (y >= contentHeight) {
      let data = this.state.resultData;

      // const { permission } = this.props;
      // var permList = []
      // if(permission.permissions) {
      //     permList = permission.permissions.data
      // }

      let reqData = new FormData();
      reqData.append('crew_identifier_id', this.state.id);
      if (data.current_page < data.last_page && this.state.flag !== false) {
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: reqData,
        };

        fetch(data.next_page_url + sortVariable, requestOptions)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            this.setState({ resultData: data.data });

            if (data.data.current_page === data.data.last_page) {
              this.setState({ flag: false });
            }

            var appInf = [];

            this.setState({
              employeeList: [...this.state.employeeList, ...data.data.data],
            });

            data.data.data.forEach((item, index) => {
              appInf[item.hash] = item.applicant_info;
              this.setState((state) => ({
                applicant_info: { ...state.applicant_info, ...appInf },
              }));

              applicationItem.push(
                <div className="employee_item">
                  <div className="list_item image-holder second_width">
                    <img
                      alt={item.first_name + ' ' + item.last_name}
                      className={item.active}
                      src={
                        item.profile_photo
                          ? config.apiUrl + '/' + item.profile_photo
                          : config.apiUrl + '/' + config.altImg
                      }
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                  <div className="list_item second_width second_width">
                    <span className={item.colorcode + ' color_code'} />
                    <span className="emp_name">
                      {item.first_name + ' ' + item.last_name}
                    </span>
                  </div>
                  <div className="list_item second_width">{item.email}</div>
                  <div
                    className="list_item sendMail_btn second_width"
                    onClick={this.sendMail.bind(this, item.email)}
                  >
                    Send Mail
                  </div>
                  <div
                    className="list_item close_mail second_width"
                    onClick={this.removeList.bind(this, item.email)}
                  >
                    <img
                      alt="close"
                      src={require('../assets/images/close_button.png').default}
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                </div>
              );
            });

            this.setState({
              applicationItem: [
                ...this.state.applicationItem,
                ...applicationItem,
              ],
            });
          })
          .catch((err) => console.log(err));
      }
    }
  }
  /**
   * function to close slider to edit the assignment status
   */
  closeStatusPopup = () => {
    document.body.classList.remove('inv-sidebar-opened');
  };

  // Submit Assignment Changes
  saveAssignmentChanges = (event) => {
    event.preventDefault();
    let index = this.state.assignmentStatusOptions.findIndex(
      (x) => x == this.state.tempAssignmentStatus
    );
    let data = new FormData();
    data.append('status', index + 1);
    data.append('resource_identifier_id', this.state.id);

    if (this.state.editMode == true) {
      data.append('history_id', this.state.assignmentHistoryId);
    }

    if (this.state.tempAssignmentStatus == 'Committed') {
      data.append('assignment_type', this.refs.AssignmentType.value);

      if (this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      }

      if (this.state.editMode == true) {
        if (this.refs.ProjectName) {
          data.append('name', this.refs.ProjectName.value);
          data.append(
            'date_on',
            moment(this.state.onDate).format('YYYY-MM-DD')
          );
          data.append(
            'date_off',
            moment(this.state.offDate).format('YYYY-MM-DD')
          );
          data.append('shifts', this.refs.totalShifts.value);
        }
        data.append('notes', this.refs.note.value);
      } else if (this.state.editMode == false && this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      } else if (this.state.editMode == false) {
        data.append('name', this.refs.ProjectName.value);
        data.append('assignment_type', this.refs.AssignmentType.value);
        data.append('date_on', moment(this.state.onDate).format('YYYY-MM-DD'));
        data.append(
          'date_off',
          moment(this.state.offDate).format('YYYY-MM-DD')
        );
        data.append('shifts', this.refs.totalShifts.value);
        data.append('notes', this.refs.note.value);
      }
    }

    this.state.tempAssignmentStatus === 'Unavailable' &&
      data.append('unavailable_reason_id', this.state.unavailable_reason_id);

    if (this.state.tempAssignmentStatus !== 'Committed') {
      data.append('notes', this.refs.note.value);
    }

    this.props.dispatch(assignmentStatsActions.saveStatus(data));

    if (this.refs.note) this.refs.note.value = '';

    this.closeStatusPopup();
  };

  viewManifest(id, type) {
    this.props.viewManifest(id, type.toString());
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  goBack() {
    this.props.goBack();
  }

  profile(hash) {
    this.setState({
      linkClick: hash,
    });
  }

  editResource() {
    this.props.editResource(this.props.props.id);
  }

  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(value, false);
      }.bind(this),
      1000
    );
  }

  emailChangeFn(event) {
    var value = event.target.value;
    this.setState(
      {
        mailSearchKeyword: value,
        validManifestMail: false,
      },
      () => {
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(
          function () {
            this.handleSearch(value, true);
          }.bind(this),
          1000
        );
      }
    );
  }

  handleSearch(text, email) {
    let data = new FormData();
    data.append('keyword', text);

    if (text === '') {
      this.setState({ inputValue: false });
    } else {
      this.setState({ inputValue: true });
    }
    if (!email) {
      data.append('crew_identifier_id', this.state.id);
      data.append('active', true);
      data.append('locked', true);
      data.append('guest', true);
      data.append('pagination', false);
      this.props.dispatch(resourceActions.employeeList(data));
    } else {
      let nextState = {
        search: true,
      };

      if (this.isValidEmail(text)) {
        nextState.validManifestMail = true;
        nextState.manifestEmail = text;
      } else {
        nextState.validManifestMail = false;
        nextState.manifestEmail = '';
      }

      this.props.dispatch(resourceActions.employeeEmailList(data));
      this.setState(nextState);
    }
  }

  remove() {
    this.statusCloseModal();
    this.props.dispatch(resourceActions.removeResource(this.state.id));
    // this.setState({
    //   redirect: "/resources"
    // });
  }

  sendMail(email) {
    this.setState(
      { to: email, manifest_id: this.state.action_id, emailSuccess: 'true' },
      function () {
        this.emailManifest();
        this.statusCloseModal();
      }
    );
  }

  setMailer(item) {
    this.setState({
      textarea: false,
      mailList: [...this.state.mailList, item],
      searchList: [],
      hideSearch: 'hide',
    });
  }

  removeList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.mailList);
      mailList.splice(index, 1);
      this.setState({ mailList: mailList, textarea: true, to: '' });
      document.getElementById('txtSearchManiEmployees').value = '';
      // document.getElementById('multiple_emails').value = "";
    }
  }

  removeDispatchList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.mailListDefault);
      mailList.splice(index, 1);
      this.setState({ mailListDefault: mailList, textarea: true, to: '' });
      document.getElementById('txtSearchManiEmployees').value = '';
      // document.getElementById('multiple_emails').value = "";
    }
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener('scroll', this.handleScroll);
    const { vehicles, assignmentStats, user, inventory } = nextProps;

    if (user && user.items && user.items.data.is_admin !== 0) {
      this.setState({ crewAdmin: true });
    }

    var vehiclesOption = [];

    if (nextProps.resource) {
      if (nextProps.resource.remove) {
        const remove_status = nextProps.resource.remove;

        // alert(nextProps.resource.remove.message)

        if (remove_status.success) {
          this.setState({
            redirect: '/resources',
          });
        } else {
          this.setState({
            alertPop: true,
            alertHead: 'Error',
            alertMsg: nextProps.resource.remove.message,
            //alertFunction: nextProps.goBack()
          });
        }
      }

      if (nextProps.resource.projects) {
        this.setState({
          incidentProjects: nextProps.resource.projects,
        });
      }
    }

    if (nextProps.singleresource.items) {
      if (nextProps.singleresource.items.data) {
        this.setState({
          resource_name: nextProps.singleresource.items.data.resource_name,
        });
      }
    }

    if (vehicles.items) {
      this.setState({ outerVehicle: vehicles.items });
      if (vehicles.items.data && vehicles.items.data.length > 0) {
        vehicles.items.data.forEach((item, index) => {
          vehiclesOption.push(
            <div className="applicant_item" key={index}>
              <div className="basic_info">
                <a
                  href={config.baseUrl + 'inventory/inventory-view/' + item.eid}
                  className="vehicleLink"
                >
                  <div className="appln_name">{item.name}</div>
                  <div className="appln_email">
                    {item.user
                      ? item.user.first_name + ' ' + item.user.last_name
                      : ''}
                  </div>
                  <div className="appln_phone">
                    {item.user ? item.user.drivers_license : ''}
                  </div>
                  <div className="appln_date">{item.vehicle_model_or_make}</div>
                  <div className="appln_area">{item.vehicle_license}</div>
                </a>
              </div>
            </div>
          );
        });
      } else {
        vehiclesOption.push(
          <div className="applicant_item" key="noVehicles">
            <p>No Vehicles added!</p>
          </div>
        );
      }
      this.setState({ vehiclesOption });
    }

    if (vehicles.vehicleList) {
      this.setState({ vehicleList: vehicles.vehicleList });
    }

    const { manifest, employee, email } = nextProps;

    if (this.state.search && email.employee) {
      var empOptions = [];
      email.employee.data.data.forEach((item, index) => {
        empOptions.push(
          <li key={index} onClick={this.setMailer.bind(this, item)}>
            {item.first_name + ' ' + item.last_name}
          </li>
        );
      });
      if (this.state.inputValue && empOptions.length > 0) {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: 'show',
        });
      } else {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: 'hide',
        });
      }
    }

    if (employee.list) {
      if (employee.list.data.data && employee.list.data.data.length > 0) {
        this.setState({
          employeeList: employee.list.data.data,
          resultData: employee.list.data,
          flag: true,
        });
      } else {
        this.setState({ employeeList: [], resultData: [] });
      }
    }
    if (email) {
      if (email.manifestEmail) {
        var head = '';
        if (email.manifestEmail.success) {
          head = 'Success';
        } else {
          head = 'Error';
        }
        if (this.state.emailSuccess === 'true') {
          this.setState({
            alertPop: true,
            alertHead: head,
            alertMsg: JSON.parse(JSON.stringify(email.manifestEmail.message)),
            alertFunction: '',
            emailSuccess: '',
          });
        }
      }
    }
    if (manifest) {
      if (manifest.print) {
      }

      if (manifest.dispatchMailList) {
        this.setState({ dispatchMailList: manifest.dispatchMailList });
      }

      if (manifest.ManifestList) {
        this.setState({ ManifestList: manifest.ManifestList.data });
      }
    }
    if (assignmentStats.applicantStats) {
      let { assignmentStatusOptions } = this.state;

      let currentAssignmentDetails = {};

      if (
        assignmentStats.applicantStats.data.summary &&
        assignmentStats.applicantStats.data.summary.length > 0
      ) {
        currentAssignmentDetails = {
          name: assignmentStats.applicantStats.data.summary[0].name,
          date_off: assignmentStats.applicantStats.data.summary[0].date_off,
          date_on: assignmentStats.applicantStats.data.summary[0].date_on,
          assignment_type_id:
            assignmentStats.applicantStats.data.summary[0].assignment_type_id,
          assignment_type:
            assignmentStats.applicantStats.data.summary[0].assignment_type,
          shifts: assignmentStats.applicantStats.data.summary[0].shifts,
          id: assignmentStats.applicantStats.data.summary[0].id,
        };
      }

      if (assignmentStats.applicantStats.data.notes) {
        currentAssignmentDetails.notes =
          assignmentStats.applicantStats.data.notes;
      }

      this.setState({
        currentAssignmentStatus:
          assignmentStatusOptions[
            assignmentStats.applicantStats.data.status - 1
          ],
        currentAssignmentDetails,
      });
    }
    if (assignmentStats.types) {
      this.setState({ assigmentCategoryOptions: assignmentStats.types });
    }
    if (assignmentStats.assignmentHistory) {
      this.setState({
        assigmentStatusHistory: assignmentStats.assignmentHistory.data,
      });
    }

    if (assignmentStats.assignmentStatusSaved) {
      if (assignmentStats.assignmentStatusSaved.success) {
        this.props.dispatch(
          assignmentStatsActions.getAssignemntStatus(this.state.id)
        );
        this.props.dispatch(
          assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
        );
        this.setState({
          tempAssignmentStatus: {
            name: '',
            date_off: '',
            date_on: '',
            assignment_type_id: '',
            assignment_type: '',
            shifts: '',
            notes: '',
            id: 0,
          },
        });
      } else {
        var message = assignmentStats.assignmentStatusSaved.message || {},
          err = [];
        if (Object.keys(message).length) {
          for (var key in message) {
            var ele = message[key];
            var _err = err.push(Array.isArray(ele) ? ele.join(',') : ele);
          }
        }

        if (err.length) {
          this.setState({
            errorMessage: err.join(','),
          });
          setTimeout(() => {
            this.setState({
              errorMessage: '',
            });
          }, 3000);
        }
      }
    }
    if (assignmentStats.historyDelete) {
      this.props.dispatch(
        assignmentStatsActions.getAssignemntStatus(this.state.id)
      );
      this.props.dispatch(
        assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
      );
    }

    if (inventory.list && inventory.list.length) {
      this.setState({
        inventoryList: inventory.list,
      });
    }
  }
  getHistoryList = (pageNum) => {
    this.props.dispatch(
      assignmentStatsActions.getAssignemntHistory(this.state.id, pageNum)
    );
  };

  deleteHistory = () => {
    if (this.state.historyId == '') {
      return;
    }
    this.setState({ deleteHistoryOpen: false });
    this.props.dispatch(
      assignmentStatsActions.historyDelete(this.state.historyId)
    );
  };

  /**
   * function to open slider to edit the assignment status
   */
  editStatus = () => {
    this.setState({
      editMode: false,
      tempAssignmentStatus: '',
      tempAssignmentType: '',
      projectId: null,
      projectName: '',
      projectShift: '',
      initialStatusId: null,
      onDate: moment(),
      offDate: moment(),
      projectNote: '',
    });

    document.body.className += ' ' + 'inv-sidebar-opened';
  };

  handleAssignmentChange = (event) => {
    this.setState({
      tempAssignmentStatus: event.target.value,
    });
  };

  render() {
    if (this.state.linkClick !== false)
      return <Redirect to={this.state.linkClick} />;
    let crew_data = null;
    this.props.assignmentStats &&
      this.props.assignmentStats.applicantStats &&
      (crew_data = this.props.assignmentStats.applicantStats.data);
    const { pageNumber } = this.state;
    const { permission } = this.props;
    // var vehiclesOption = [];
    var manifestsOption = [];
    // var employeesOption = [];
    var permList = [];
    let pages = [];
    let paginationHtml = [];
    let paginationHistoryHtml = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (this.state.redirect === '/resources') {
      return <Redirect to="/resources" />;
    }

    let { inventoryList, inventoryPerPage, inventoryPage } = this.state;

    if (
      this.state.assigmentStatusHistory.data &&
      this.state.assigmentStatusHistory.data.length > 0
    ) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.assigmentStatusHistory.current_page;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.assigmentStatusHistory.last_page
          ? currentPage + 2
          : this.state.assigmentStatusHistory.last_page;
      let nextPage =
        currentPage + 1 > this.state.assigmentStatusHistory.last_page
          ? this.state.assigmentStatusHistory.last_page
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getHistoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? 'active' : 'inactive'}
            key={i}
            onClick={() => {
              this.getHistoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (
        lastPageIndex + 1 <
        this.state.assigmentStatusHistory.data.last_page
      ) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page + 1}
          >
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.assigmentStatusHistory.data.last_page) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page}
            onClick={() => {
              this.getHistoryList(
                this.state.assigmentStatusHistory.data.last_page
              );
            }}
          >
            <a>{this.state.assigmentStatusHistory.data.last_page}</a>
          </li>
        );
      }

      if (pages.length > 0) {
        paginationHistoryHtml.push(
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="fs-pagination-wrapper">
              <span
                className="prev"
                onClick={() => {
                  this.getHistoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require('../assets/images/previous.svg').default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className="next"
                onClick={() => {
                  this.getHistoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require('../assets/images/next.svg').default}
                />
              </span>
            </div>
          </div>
        );
      }
    }

    {
      /* if (this.state.ManifestList) { // Disabled for NVAM clone
      if (this.state.ManifestList.data.length > 0) {
        let currentPage = this.state.ManifestList.current_page;
        let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
        let lastPageIndex =
          currentPage + 2 < this.state.ManifestList.last_page
            ? currentPage + 2
            : this.state.ManifestList.last_page;
        let nextPage =
          currentPage + 1 > this.state.ManifestList.last_page
            ? this.state.ManifestList.last_page
            : currentPage + 1;
        let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
        if (currentPage > 3) {
          pages.push(
            <li
              className="inactive"
              key={0}
              onClick={() => {
                this.getManifestList(1);
              }}
            >
              <a>1</a>
            </li>
          );
        }
        if (currentPage > 4) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page + 1}
            >
              <a>. . .</a>
            </li>
          );
        }
        for (let i = startingIndex; i <= lastPageIndex; i++) {
          // getManifestList
          pages.push(
            <li
              className={i == currentPage ? "active" : "inactive"}
              key={i}
              onClick={() => {
                this.getManifestList(i);
              }}
            >
              <a>{i}</a>
            </li>
          );
        }
        if (lastPageIndex + 1 < this.state.ManifestList.last_page) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page + 1}
            >
              <a>. . .</a>
            </li>
          );
        }
        if (lastPageIndex < this.state.ManifestList.last_page) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page}
              onClick={() => {
                this.getManifestList(this.state.ManifestList.last_page);
              }}
            >
              <a>{this.state.ManifestList.last_page}</a>
            </li>
          );
        }
        if (pages.length > 0) {
          paginationHtml.push(
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="fs-pagination-wrapper">
                <span
                  className="prev"
                  onClick={() => {
                    this.getManifestList(prevPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/images/previous.svg")}
                  />
                </span>
                <ul className="fs-pagination">{pages}</ul>
                <span
                  className="next"
                  onClick={() => {
                    this.getManifestList(nextPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/images/next.svg")}
                  />
                </span>
              </div>
            </div>
          );
        }
        manifestsOption.push(
          <div
            id="manifest-delete-popup"
            className="fs-toast"
            key={`recently_deleted`}
          >
            Manifest Deleted successfully
          </div>
        );
        this.state.ManifestList.data.forEach((item, index) => {
          manifestsOption.push(
            <div className="manifest_items" key={index}>
              <div className="employee_item">
                <div className="list_item">{item.abbreviation}</div>
                <div className="list_item">{item.contract_number}</div>
                <div className="list_item">{item.incident_name}</div>
                <div className="list_item">{item.resource_number}</div>
                <div className="list_item">{item.added_date}</div>
                <div
                  className="list_item action_icon"
                  onClick={this.viewAction.bind(this, item.mid)}
                >
                </div>
              </div>
              {this.state.action_id === item.mid && (
                <div
                  className={
                    this.state.viewStatus
                      ? "action_list show"
                      : "action_list hide"
                  }
                >
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.printManifest.bind(this, item.mid)}>
                      Print
                    </p>
                  )}
                  {permList.includes("Manage Manifests") && (
                    <p
                      onClick={this.editManifest.bind(this, item.mid, item.bid)}
                    >
                      Edit
                    </p>
                  )}
                 
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.deleteOpenModal.bind(this, item.mid)}>
                      Delete
                    </p>
                  )}
                  
                  <p onClick={this.viewManifest.bind(this, item.mid, item.bid)}>
                    View
                  </p>
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.emailOpenModal.bind(this, item.mid)}>
                      Email
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        });
      } else {
        manifestsOption.push(
          <div className="applicant_item" key="noEmployees">
            <p>No Manifest added!</p>
          </div>
        );
      }
    } */
    }

    var obj = {
      url:
        config.apiUrl +
        '/api/manifest/pdf?manifest_id=' +
        this.state.mid +
        '&resource_id=' +
        this.state.id,
      httpHeaders: authHeader(),
    };

    let employeeList = this.state.employeeList
      .sort((employeeA, employeeB) => {
        return employeeA.is_locked - employeeB.is_locked;
      })
      .sort((employeeA, employeeB) => {
        return employeeA.is_guest - employeeB.is_guest;
      })
      .map((employee, index) => {
        return {
          ...employee,
          index,
        };
      });

    // if(employee.list) {
    //     if(employee.list.data.data.length > 0){
    return (
      <div className="resource_container resource_details">
        <div className="breadcrumbs">
          <Link className="green" to={'/resources'}>
            Resources
          </Link>{' '}
          / Details
        </div>
        <div className="page_title float">
          <h1>
            {this.state.resource_name
              ? this.state.resource_name
              : this.props.props.resource_name}
          </h1>
          <div className="btn_list">
            {/* {permList.includes("Add/Edit/Delete Resource") && (
              <Link to={"/create-resource"} className="create_resource button">
                New Resource
              </Link>
            )} */}
            {/* //Disabled for NVAM clone {permList.includes("Manage Manifests") && (
              <button
                className="button remove_resource"
                onClick={this.createManifest.bind(this)}
              >
                Create Manifest
              </button>
            )} */}
            {permList.includes('Add/Edit Resource') &&
              this.props.props.id !== 1 && (
                <button
                  className="button edit_resource"
                  onClick={this.editResource.bind(this)}
                >
                  Edit Resource
                </button>
              )}
            {permList.includes('Archive Resource') &&
              this.props.props.id !== 1 && (
                <button
                  className="button remove_resource"
                  onClick={this.statusOpenModal.bind(this)}
                >
                  Archive Resource
                </button>
              )}
          </div>
        </div>

        <div className="applicants_inner_head crew_outer">
          <div className="inner_head">
            <input
              type="text"
              name="keyword"
              className="resource_search"
              placeholder="Search Employees..."
              onKeyUp={this.keyUpFn.bind(this)}
              autoComplete="off"
            />
          </div>
          <div
            className="inner_container col-lg-12 crew_wrapper"
            id="applicants_inner"
          >
            <React.Fragment>
              <div className="assignment-status">
                {this.state.errorMessage != '' && (
                  <div
                    className="message-display-div alert alert-danger"
                    role="alert"
                  >
                    {this.state.errorMessage}
                  </div>
                )}

                <h5>Assignment Status</h5>
                <div className="status-wrapper">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="status-left-box">
                        <div className="current-status">
                          <h5>Current Status</h5>
                          {this.state.currentAssignmentStatus ==
                            'Committed' && (
                            <div className="committed">
                              <span className="status-image">
                                <img
                                  src={
                                    require('../assets/images/committed.svg')
                                      .default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {this.state.currentAssignmentStatus ==
                            'Available' && (
                            <div className="available">
                              <span className="status-image">
                                <img
                                  src={
                                    require('../assets/images/tick.svg').default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {this.state.currentAssignmentStatus ==
                            'Unavailable' && (
                            <div className="unavailable">
                              <span className="status-image">
                                <img
                                  src={
                                    require('../assets/images/unavailable.svg')
                                      .default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {this.state.crewAdmin && (
                            <span className="editor" onClick={this.editStatus}>
                              <img
                                src={
                                  require('../assets/images/edit.svg').default
                                }
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8">
                      <div className="summary-box">
                        <h5>Summary</h5>
                        {this.state.currentAssignmentStatus == 'Committed' && (
                          <React.Fragment>
                            <p className="shifts">
                              <strong>Project Name:</strong>{' '}
                              {this.state.currentAssignmentDetails.name}
                            </p>
                            <div className="lists-outer-row">
                              <div className="shifts">
                                <strong>Total Shifts:</strong>{' '}
                                {this.state.currentAssignmentDetails.shifts}
                              </div>
                              <div className="shifts">
                                <strong>Type Of Assignment:</strong>{' '}
                                {
                                  this.state.currentAssignmentDetails
                                    .assignment_type
                                }{' '}
                              </div>
                              <div className="shifts">
                                <strong>Date On:</strong>{' '}
                                {this.state.currentAssignmentDetails.date_on}
                              </div>
                              <div className="shifts">
                                <strong>Date Off:</strong>{' '}
                                {this.state.currentAssignmentDetails.date_off}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {this.state.currentAssignmentStatus == 'Unavailable' &&
                          crew_data && (
                            <React.Fragment>
                              <p className="shifts">
                                <strong>Unavailable Reason:</strong>{' '}
                                {crew_data.unavailable_reason}
                              </p>
                            </React.Fragment>
                          )}
                        <p className="shifts">
                          <strong>Notes:</strong>{' '}
                          {this.state.currentAssignmentDetails.notes}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="inv-sidemenu-box">
                <div className="change-stat-head">
                  <h5>Change Status</h5>
                </div>
                <div className="change-status-pop">
                  <form
                    onSubmit={this.saveAssignmentChanges}
                    id="assignment-status-form"
                  >
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">
                        Select Status
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={this.state.tempAssignmentStatus}
                        onChange={this.handleAssignmentChange}
                      >
                        <option value="">Assignment Status</option>
                        <option value="Committed">Committed</option>
                        <option value="Available">Available</option>
                        <option value="Unavailable">Unavailable</option>
                      </select>
                    </div>

                    {/* Committed */}
                    {this.state.tempAssignmentStatus == 'Committed' && (
                      <React.Fragment>
                        <select
                          className="form-control"
                          name="tempAssignmentType"
                          value={this.state.tempAssignmentType}
                          ref="AssignmentType"
                          onChange={this.handleSelect}
                          required
                        >
                          <option value="">Type of Assignment</option>
                          {this.state.assigmentCategoryOptions.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                {item.value}
                              </option>
                            )
                          )}
                        </select>

                        {/* Select Existing Project */}
                        {/* {
                          this.state.initialStatusId !== 3 && 
                          <select 
                            className="form-control"
                            ref="ExistingProject"
                            value={this.state.projectId || ""}
                            onChange={this.selectProject}>
                            <option value="">Choose Existing Project</option>
                            {
                              this.state.incidentProjects
                                .map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                            }
                          </select>
                        } */}

                        {(this.state.projectId == null ||
                          (this.state.editMode == true &&
                            this.state.initialStatusId == 3)) && (
                          <div>
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">
                                Project Name{' '}
                              </label>
                              <input
                                required
                                ref="ProjectName"
                                name="projectName"
                                value={this.state.projectName}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Date On
                              </label>
                              <span className="date-outer">
                                <span
                                  onClick={() => {
                                    this.refs.dateon.setFocus();
                                  }}
                                  className="datepicker-image"
                                >
                                  <img
                                    src={
                                      require('../assets/images/icon_calendar.svg')
                                        .default
                                    }
                                  />
                                </span>
                                <DatePicker
                                  className="txt "
                                  ref="dateon"
                                  id="Dateon"
                                  name="Dateon"
                                  selected={this.state.onDate}
                                  onChange={(date) => {
                                    this.setState({ onDate: date });
                                    if (date > this.state.offDate) {
                                      this.setState({ offDate: date });
                                    }
                                  }}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Date Off
                              </label>
                              <span className="date-outer">
                                <span
                                  onClick={() => {
                                    this.refs.dateoff.setFocus();
                                  }}
                                  className="datepicker-image"
                                >
                                  <img
                                    src={
                                      require('../assets/images/icon_calendar.svg')
                                        .default
                                    }
                                  />
                                </span>
                                <DatePicker
                                  className="txt "
                                  ref="dateoff"
                                  id="Dateoff"
                                  name="assignDate"
                                  selected={this.state.offDate}
                                  onChange={(date) => {
                                    this.setState({ offDate: date });
                                  }}
                                  minDate={this.state.onDate}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Total Shifts
                              </label>
                              <input
                                type="number"
                                name="projectShift"
                                value={this.state.projectShift}
                                onChange={this.handleChange}
                                ref="totalShifts"
                                className="form-control"
                                placeholder="Enter number of shifts required"
                              />
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {/* Unavailable */}
                    {this.state.tempAssignmentStatus === 'Unavailable' && (
                      <React.Fragment>
                        <div className="form-group">
                          <label htmlFor="unavailable_reason">
                            Unavailable Reason
                          </label>
                          <select
                            className="form-control"
                            id="unavailable_reason_id"
                            name="unavailable_reason_id"
                            value={this.state.unavailable_reason_id}
                            onChange={this.handleChange}
                          >
                            <option value="">-no reason-</option>
                            {this.state.unavailable_reasons.map((reason) => {
                              return (
                                <option value={reason.id} key={reason.id}>
                                  {reason.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </React.Fragment>
                    )}

                    {(this.state.projectId == null ||
                      this.state.editMode == true) && (
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          Notes
                        </label>
                        <textarea
                          ref="note"
                          name="projectNote"
                          value={this.state.projectNote}
                          onChange={this.handleChange}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    )}
                    <div className="submit-area">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <button
                        onClick={(event) => {
                          this.closeStatusPopup();
                          event.preventDefault();
                        }}
                        className="btn btn-primary cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {this.state.assigmentStatusHistory.data &&
                this.state.assigmentStatusHistory.data.length > 0 && (
                  <Accordian title="History" persistKey="resource_history_list">
                    <div className="history_lists outer_list ">
                      <div className="mobile_hide">
                        <div className="list_header">
                          <div className="list_item_head">Status</div>
                          <div className="list_item_head">Project Name</div>
                          <div className="list_item_head">Date On</div>
                          <div className="list_item_head">Date Off</div>
                          <div className="list_item_head">
                            Type of Assignment
                          </div>
                          <div className="list_item_head">Notes</div>
                          <div className="list_item_head list_item_head_trash"></div>
                          <div className="list_item_head list_item_head_trash"></div>
                        </div>
                        <div className="history_items ">
                          {this.state.assigmentStatusHistory.data.map(
                            (item, index) => (
                              <div className="status_item" key={index}>
                                <div className="list_item">
                                  {
                                    this.state.assignmentStatusOptions[
                                      item.status - 1
                                    ]
                                  }
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.name
                                    : '-'}
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.date_on
                                    : '-'}
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.date_off
                                    : '-'}
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.assignment_type
                                    : '-'}
                                </div>
                                <div className="list_item note_item">
                                  <div className="notes_ellipsis">
                                    {item.notes}
                                  </div>
                                  <div className="note_full">{item.notes}</div>
                                </div>
                                {this.state.crewAdmin && (
                                  <div className="list_item list_item_trash">
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.editResourceHistory(item);
                                      }}
                                    >
                                      <img
                                        src={
                                          require('../assets/images/edit-black.svg')
                                            .default
                                        }
                                      />
                                    </a>
                                  </div>
                                )}
                                {this.state.crewAdmin && (
                                  <div className="list_item list_item_trash">
                                    <a
                                      href="#"
                                      onClick={(event) => {
                                        this.deleteHistoryOpenModal(item.id);
                                        event.preventDefault();
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={
                                          require('../assets/images/trash.svg')
                                            .default
                                        }
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        {paginationHistoryHtml}
                      </div>
                    </div>
                    {this.state.assigmentStatusHistory.data.map(
                      (item, index) => (
                        <React.Fragment key={index}>
                          <hr></hr>
                          <div className="for_mobile_history_list">
                            <div className="history_items">
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Status</strong>
                                </div>
                                <div className="list_item">
                                  {
                                    this.state.assignmentStatusOptions[
                                      item.status - 1
                                    ]
                                  }
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Project</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.name
                                    : '-'}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Date On</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.date_on
                                    : '-'}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Date Off</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.date_off
                                    : '-'}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Type of Assignment</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.assignment_type
                                    : '-'}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Notes</strong>
                                </div>
                                <div className="list_item">{item.notes}</div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}
                    <div className="for_mobile_history_list">
                      {paginationHistoryHtml}
                    </div>
                  </Accordian>
                )}
            </React.Fragment>

            {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
            {permList.includes('List Vehicles') && (
              <Accordian title="Vehicles" persistKey="resource_vehicles_list">
                <div className="vehicle_list outer_list">
                  <div className="">
                    <div className="list_header">
                      <div className="list_item_head">Unique Name</div>
                      <div className="list_item_head">Driver</div>
                      <div className="list_item_head">Driver License</div>
                      <div className="list_item_head appln_date">
                        Vehicle Make/Model
                      </div>
                      <div className="list_item_head">Vehicle License</div>
                    </div>
                    {this.state.vehiclesOption}
                  </div>
                </div>
              </Accordian>
            )}

            {/* //Disabled for NVAM clone {permList.includes("List Resource Manifests") && (
              <Accordian title="Manifest" persistKey="resource_manifest_list">
                <div className="manifest_list outer_list">
                  <div className="list_header">
                    <div className="list_item_head">Contract Type</div>
                    <div className="list_item_head">Contract Number</div>
                    <div className="list_item_head">Incident Name</div>
                    <div className="list_item_head">Resource Number</div>
                    <div className="list_item_head">Date Added</div>
                    <div className="list_item_head action_icon" />
                  </div>
                  {manifestsOption}
                  {paginationHtml}
                </div>
              </Accordian>
            )} */}

            {permList.includes('Inventory') && (
              <Accordian title="Inventory" persistKey="resource_inventory_list">
                <div className="employee_list outer_list">
                  <div className="list_header">
                    <div className="list_item_head image-holder">Inventory</div>
                    <div className="list_item_head">Item Name</div>
                    <div className="list_item_head">Cost</div>
                    <div className="list_item_head">Assigned To</div>
                  </div>
                  {inventoryList.map((item, index) => {
                    return (
                      <div className="empployeeItem" key={index}>
                        <div className="emp_link_resource crew_wrapper resource_details bg-fill">
                          <div className="employee_item ">
                            <div className="list_item image-holder">
                              <span className="pos_counter">
                                {(inventoryPage - 1) * inventoryPerPage +
                                  index +
                                  1}
                              </span>
                              <img
                                alt=""
                                className={`${
                                  item.user && item.user.hash ? 'Available' : ''
                                }`}
                                src={
                                  item.pic
                                    ? config.s3BucketUrl + '/' + item.pic
                                    : config.apiUrl + '/' + config.altImg
                                }
                                onError={this.onImageError.bind(this)}
                              />
                            </div>
                            <div className="list_item item_width">
                              <Link
                                className="link"
                                to={`/inventory/inventory-view/${item.id}`}
                              >
                                {item.unique_id}
                              </Link>
                            </div>
                            <div className="list_item item_width">
                              {item.cost || '--'}
                            </div>
                            <div className="list_item item_width">
                              {item.user ? (
                                <Link
                                  className="link"
                                  to={`/resource-profile/${item.user.hash}`}
                                >
                                  {item.user.first_name +
                                    ' ' +
                                    item.user.last_name}
                                </Link>
                              ) : (
                                '--'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {this.inventoryPagination()}
                </div>
              </Accordian>
            )}
            {/* Employee List */}
            {parseInt(localStorage.getItem('avail')) === 1 && (
              <Accordian
                title="Employees Available"
                persistKey="resource_available_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  className="available"
                  type="available"
                  permList={permList}
                  resourceId={this.state.id}
                  employeeList={employeeList.filter(
                    (employee) => !employee.is_locked && !employee.is_guest
                  )}
                  profile={this.profile.bind(this)}
                  updateList={this.employeeListUpdated}
                />
              </Accordian>
            )}
            {parseInt(localStorage.getItem('avail')) === 1 && (
              <Accordian
                title="Employees Pending"
                persistKey="resource_guest_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  className="locked"
                  type="guest"
                  permList={permList}
                  resourceId={this.state.id}
                  employeeList={employeeList.filter(
                    (employee) => employee.is_guest
                  )}
                  profile={this.profile.bind(this)}
                  updateList={this.employeeListUpdated}
                />
              </Accordian>
            )}
            {parseInt(localStorage.getItem('avail')) === 1 && (
              <Accordian
                title="Employees Locked"
                persistKey="resource_locked_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  className="locked"
                  type="locked"
                  permList={permList}
                  resourceId={this.state.id}
                  employeeList={employeeList.filter(
                    (employee) => employee.is_locked && !employee.is_guest
                  )}
                  profile={this.profile.bind(this)}
                  updateList={this.employeeListUpdated}
                />
              </Accordian>
            )}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          overlayClassName="deletetPopup deletetPopup-resources"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>*/}
          <div id="statusPopup" className="resources-popup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.statusCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
              />
            </div>
            <div className="modal-body">
              <div className="res_delete step1">
                <label>Do you really want to archive this resource?</label>
                <br />
                <div className="btn_list">
                  <button
                    className="button"
                    onClick={this.remove.bind(this, true)}
                  >
                    OK
                  </button>
                  <button
                    className="button grey"
                    onClick={this.statusCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*</Modal>*/}
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteOpen}
          onClose={this.deleteCloseModal.bind(this)}
          overlayClassName="deletetPopup"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
          <div id="deletePopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.deleteCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
              />
            </div>
            {/* <div className="modal-body"> //Disabled for NVAM clone
              <div className="status_step step1">
                <label>Do you really want to remove this manifest?</label>
                <br />
                <div className="btn_list_popup">
                  <button
                    className="button grey"
                    onClick={this.deleteCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.deleteManifest.bind(this)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteHistoryOpen}
          onClose={this.deleteHistoryCloseModal.bind(this)}
          overlayClassName="deletetPopup"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
          <div id="deletePopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.deleteHistoryCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
              />
            </div>

            <div className="modal-body">
              <div className="status_step step1">
                <label>Do you really want to remove this History?</label>
                <br />
                <div className="btn_list_popup">
                  <button
                    className="button grey"
                    onClick={this.deleteHistoryCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button className="button" onClick={this.deleteHistory}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        {/*</Modal><Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.emailOpen} onClose={this.emailCloseModal.bind(this)} little>*/}
        <ReactModal
          isOpen={this.state.emailOpen}
          onClose={this.emailCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="emailManifestPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">Email Manifest</h4>
              {/* <img className="closeModal" onClick={this.statusCloseModal.bind(this)} src={require("../assets/images/close_green.png")}/>  */}
              <img
                alt="close"
                className="closeModal"
                onClick={this.emailCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
                onError={this.onImageError.bind(this)}
              />
            </div>
            <div className="modal-body">
              <div className="mail_div">
                <div className="left">
                  <input
                    type="text"
                    className="txtSearchManiEmployees"
                    id="txtSearchManiEmployees"
                    name="Emailkeyword"
                    value={this.state.mailSearchKeyword}
                    placeholder="Search Employees..."
                    onChange={this.emailChangeFn.bind(this)}
                    autoComplete="off"
                  />
                  {this.state.validManifestMail &&
                    this.state.searchList.length === 0 && (
                      <button
                        className="button add_mail"
                        onClick={this.addManifestEmail}
                      >
                        Add Email
                      </button>
                    )}
                  <ul className={'searchResultUl ' + this.state.hideSearch}>
                    {this.state.searchList}
                  </ul>
                </div>
                {/* <div className="right">
                  {this.state.textarea && (
                    <div>
                      <textarea
                        id="multiple_emails"
                        name="to"
                        rows="3"
                        cols="10"
                        placeholder="Enter email-id and use comma for multiple emails..."
                        value={this.state.to}
                        onChange={this.handleChange.bind(this)}
                      />
                      <button
                        className="button send_mail"
                        onClick={this.emailManifest.bind(this)}
                      >
                        Send
                      </button>
                    </div>
                  )}

                  <button className="button grey" onClick={this.emailCloseModal.bind(this)}>Cancel</button>
                </div> */}
                <div className="clearFix" />
                <div className="mail-maifest-wrapper">
                  <div className="mail-manifest wrapper">
                    <div className="head">
                      <div className="emp">Employee</div>
                      <div className="name">Name</div>
                      <div className="email">Email</div>
                      {/* <div className="send-mail" /> */}
                      <div className="close" />
                    </div>
                    {/* Dispatch */}
                    {this.state.mailListDefault.map((item, index) => {
                      return (
                        <div key={index} className="employee employee_item">
                          <div className="pic image-holder">
                            <img
                              src={config.apiUrl + '/' + config.altImg}
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                          <div className="name">
                            <span className="">Dispatch Email</span>
                          </div>
                          <div className="email">{item}</div>
                          <div
                            className="close-btn"
                            onClick={this.removeDispatchList.bind(this, index)}
                          >
                            <img
                              alt="close"
                              src={
                                require('../assets/images/close_button.png')
                                  .default
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {/* From List */}
                    {this.state.mailList.map((item, index) => {
                      return (
                        <div className="employee employee_item" key={index}>
                          <div className="pic image-holder">
                            <img
                              alt={item.first_name + ' ' + item.last_name}
                              className={item.active}
                              src={
                                item.profile_photo
                                  ? config.apiUrl + '/' + item.profile_photo
                                  : config.apiUrl + '/' + config.altImg
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                          <div className="name">
                            <span className="">
                              <span
                                className={item.colorcode + ' color_code'}
                              />
                              {item.first_name + ' ' + item.last_name}
                            </span>
                          </div>
                          <div className="email">{item.email}</div>
                          {/* <div
                            className="send-button sendMail_btn"
                            onClick={this.sendMail.bind(this, item.email)}
                          >
                            Send Mail
                          </div> */}
                          <div
                            className="close-btn"
                            onClick={this.removeList.bind(this, index)}
                          >
                            <img
                              alt="close"
                              src={
                                require('../assets/images/close_button.png')
                                  .default
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* {!this.state.textarea && <div>{this.state.mailList}</div>} */}
                {/* <p className="sig_message">This email will automatically be sent to the Dispatch email as well.</p> */}
                <br />
                <button
                  className="button send_mail"
                  onClick={this.emailManifest.bind(this)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          {/*</Modal>*/}
        </ReactModal>

        <ReactModal
          isOpen={this.state.viewOpen}
          onClose={this.viewCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="viewManifestPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">View Manifest</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.viewCloseModal.bind(this)}
                src={require('../assets/images/close_green.png').default}
                onError={this.onImageError.bind(this)}
              />
            </div>
            <div id="viewPdf">
              <Document file={obj}>
                <Page pageNumber={pageNumber} width={800} />
              </Document>
            </div>
          </div>
        </ReactModal>
        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    vehicles,
    employee,
    manifest,
    email,
    resource,
    permission,
    singleresource,
    assignmentStats,
    inventory,
    user,
  } = state;
  const { print } = state.manifest;
  return {
    vehicles,
    employee,
    manifest,
    email,
    resource,
    print,
    permission,
    singleresource,
    assignmentStats,
    user,
    inventory,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ResourceDetails);
export { connectedResourcePage as ResourceDetails };
