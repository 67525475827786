import React from 'react';
import { connect } from 'react-redux';
import { userActions, permissionActions, inventoryActions } from '../_actions';
import NumberFormat from 'react-number-format';
import { config } from '../_helpers';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { getCompanyName, unAvailableReason } from '../_utils/utils';
import ApplicationHistoryAlert from '_components/ApplicantHistoryAlert';
import ViewExpiryAndAssignedDates from '_components/ViewExpiryAndAssignedDates';

function formatPhoneNumber(s) {
  var s2 = ('' + s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + '-' + m[2] + '-' + m[3];
}
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      generalQualificationList: [],
      notes: '',
      profile: [],
      inventoryFlag: true,
      count: 0,
      permList: [],
      unavailableReason: 0,
      is_locked: '',
      otherReason: '',
    };
    this.goBack = this.goBack.bind(this);
    this.ProfilePic = this.ProfilePic.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
  }
  handleChange(event) {
    if (event.target.name === 'notes') {
      if (this.state.permList.includes('Employee Notes')) {
        this.setState({
          [event.target.name]: event.target.value,
          template: 'custommail',
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: 'custommail',
    });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append('hash', hash);
    data.append('note', this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true });
      this.props
        .dispatch(userActions.updateEmployeeNote(data, '?hash=' + hash))
        .then(
          (res) => {
            toast.success('Notes Saved !');
            this.setState({ savingNote: false });
          },
          (err) => {
            toast.error('Notes Cannot be saved');
            this.setState({ savingNote: false });
          }
        );
    }
    // this.props.dispatch(userActions.getProfile("?hash="+hash));
  }

  onImageError(e) {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  }

  alertCloseModal() {
    this.setState({ alertOpen: false });
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    var userData,
      inventory_list = [];
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({
        permList,
      });
    }
    if (inventory.inventory) {
      this.setState({ inventoryFlag: false, count: this.state.count + 1 });
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <div className="inv_cell">
                {index + 1 + '. ' + item.unique_id + ' (' + item.type + ') '}
                {/*<span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  className="cost"
                  value={item.cost}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </div>
              {/*<div className="inv_cell" data-header-title="Unique Id">{item.unique_id}</div>
                            <div className="inv_cell" data-header-title="Cost">{item.cost}</div>*/}
            </div>
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }
    if (this.props.match.path === '/profile/:hash') {
      if (user.items) {
        this.setState({ Eactive: user.items.data.active });
      }
    }
    if (user.profile) {
      this.setState({
        active: user.profile.data.active,
        user_type: user.profile.data.user_type,
        profileHash: user.profile.data.hash,
      });
    } else if (user.items) {
      this.setState({
        active: user.items.data.active,
        user_type: user.items.data.user_type,
        itemHash: user.items.data.hash,
      });
    }
    // this.setState({ active: userData.data.active})
    var generalQualificationList = [];
    if (this.props.match.path === '/profile/:hash') {
      userData = user.items;
    } else {
      if (user.profile) {
        userData = user.profile;
      } else if (user.items) {
        userData = user.items;
      }
    }
    if (userData) {
      var profile = userData.data;
      this.setState(
        {
          notes: this?.state?.notes ? this?.state?.notes : profile.notes,
          profile,
          hash: profile.hash,
          isGuest: profile?.status === 3 ? true : false, //fixed issue where wrong user status was being displayed in the search profile result
          is_locked: profile.is_locked,
          unavailableReason: profile.unavailable_reason,
          otherReason: profile.other_reason,
        },
        function () {}
      );
      let data = new FormData();
      data.append('hash', this.state.hash);
      if (
        !nextProps.inventory.loading &&
        this.state.inventoryFlag &&
        this.state.count === 0
      ) {
        this.props.dispatch(inventoryActions.getInventoryList(data));
      }

      if (profile.qualification) {
        profile.qualification.map((item, index) => {
          generalQualificationList.push(
            <p className="crew-profile-detail" key={index}>
              {item}
            </p>
          );
          return generalQualificationList;
        });
      }
    }
    this.setState({
      generalQualificationList,
    });
  }

  ProfilePic() {
    this.props.ProfilePic();
  }

  goBack() {
    this.props.choosePosition(this.props.type);
  }
  editProfile() {
    this.props.editProfile();
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);
  };

  render() {
    //
    // const { user } = this.props;
    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    // var profile, profileData;
    // if(user.items) {
    //     profile = user.items.data;
    //     profileData = user.items;
    // } else if(user.profile ){
    //     profile = user.profile.data;
    //     profileData = user.profile;
    // }

    var profile_pic;
    if (this.state.profile && this.state.profile.profile_photo) {
      profile_pic = config.apiUrl + '/' + this.state.profile.profile_photo;
    } else {
      profile_pic = config.apiUrl + '/' + config.altImg;
    }
    if (this.state.profile) {
      // if(profileData.data){
      return (
        <div className="content_div settings">
          {/* {permList.includes("Edit User Profile") && (
            <button className="button edit_profile" onClick={this.editProfile}>
              Edit Profile
            </button>
          )} */}
          <div className="profile1 col-sm-3">
            <p>
              <span
                className={
                  this.state.profile && this.state.profile.colorcode
                    ? 'color_code ' + this.state.profile.colorcode
                    : 'color_code'
                }
              />
              {this.state.profile && this.state.profile.first_name
                ? this.state.profile.first_name +
                  ' ' +
                  (this.state.profile.middle_name
                    ? this.state.profile.middle_name + ' '
                    : '') +
                  this.state.profile.last_name
                : ''}
            </p>
            <div>
              <img
                alt={
                  this.state.profile && this.state.profile.first_name
                    ? this.state.profile.first_name +
                      ' ' +
                      this.state.profile.last_name
                    : ''
                }
                className={
                  this.state.is_locked === 1
                    ? 'locked profilePicture availability'
                    : this.state.isGuest
                      ? 'guest-code profilePicture availability'
                      : this.state.itemHash === this.state.profileHash
                        ? this.state.active + ' profilePicture availability'
                        : this.state.profile.active +
                          ' profilePicture availability'
                }
                src={profile_pic}
                onError={this.onImageError.bind(this)}
              />
            </div>
            {/*<button className={this.props.match.path === '/profile/:hash' ? "avail_btn "+this.state.Eactive : "avail_btn "+this.state.active }>{this.props.match.path === '/profile/:hash' ? this.state.Eactive : this.state.active }</button>*/}
            <div className="crew-profile-detail">
              <p>
                {this.state.profile && this.state.profile.user_type
                  ? this.state.profile.user_type
                  : ''}
              </p>
              {this.state.profile.crew_name && (
                <p>{this.state.profile.crew_name}</p>
              )}
              <p>
                {this.state.profile && this.state.profile.nvam_id
                  ? this.state.profile.nvam_id
                  : ''}
              </p>
              {this.state.profile.primary_crew_name && (
                <p>Primary Crew: {this.state.profile.primary_crew_name}</p>
              )}

              {this.state.unavailableReason !== 0 && (
                <p>
                  Unavailable:{' '}
                  {this.setUnAvailableReason(
                    this.state.unavailableReason,
                    this.state.otherReason
                  )}
                </p>
              )}
              {this.state.profile.applying_company !== 0 && (
                <p>
                  Company: {getCompanyName(this.state.profile.applying_company)}
                </p>
              )}
            </div>
            {this.state?.profile?.employment_history_count ? (
              <ApplicationHistoryAlert />
            ) : null}

            <ViewExpiryAndAssignedDates data={this.state.profile} viewRT />
          </div>
          <div className="profile2 cp-profile-info col-sm-5">
            <div className="cp-qual profile-sep">
              <h4 className="cp-title">Qualifications</h4>
              {this.state.generalQualificationList}
            </div>
            <div className="cp-cellno profile-sep">
              <h4 className="cp-title">Cell Number</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.phone ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.profile.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.profile.phone)}
                  </a>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="cp-sec profile-sep">
              <h4 className="cp-title">Secondary Number</h4>
              <p className="crew-profile-detail">
                {this.state.profile &&
                this.state.profile.second_phone !== 'null' ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.profile.second_phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.profile.second_phone)}
                  </a>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="cp-email profile-sep">
              <h4 className="cp-title">Email</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.email ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`mailto:${this.state.profile.email}`}
                    target="_top"
                  >
                    {this.state.profile.email}
                  </a>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="cp-madr profile-sep">
              <h4 className="cp-title">Mailing Address</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.mailing_address
                  ? this.state.profile.mailing_address.address
                  : ''}
              </p>
              <p className="crew-profile-detail txtformat-capitalize">
                {this.state.profile && this.state.profile.mailing_address
                  ? this.state.profile.mailing_address.city
                  : ''}
              </p>
              <p className="crew-profile-detail txtformat-capitalize">
                {this.state.profile && this.state.profile.mailing_address
                  ? this.state.profile.mailing_address.state
                  : ''}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.mailing_address
                  ? this.state.profile.mailing_address.zip
                  : ''}
              </p>
            </div>
            <div className="cp-pa profile-sep">
              <h4 className="cp-title">Physical Address</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.physical_address
                  ? this.state.profile.physical_address.address
                  : ''}
              </p>
              <p className="crew-profile-detail txtformat-capitalize">
                {this.state.profile && this.state.profile.physical_address
                  ? this.state.profile.physical_address.city
                  : ''}
              </p>
              <p className="crew-profile-detail txtformat-capitalize">
                {this.state.profile && this.state.profile.physical_address
                  ? this.state.profile.physical_address.state
                  : ''}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.physical_address
                  ? this.state.profile.physical_address.zip
                  : ''}
              </p>
            </div>
            <div className="cp-sex profile-sep">
              <h4 className="cp-title">Sex</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.gender
                  ? this.state.profile.gender
                  : ''}
              </p>
            </div>
            <div className="cp-contact1 profile-sep">
              <h4 className="cp-title">Emergency Contact #1</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact1
                  ? this.state.profile.emgcontact1.fname +
                    ' ' +
                    this.state.profile.emgcontact1.lname
                  : ''}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact1 ? (
                  // formatPhoneNumber(this.state.profile.emgcontact1.phone)
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.profile.emgcontact1.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.profile.emgcontact1.phone)}
                  </a>
                ) : (
                  ''
                )}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact1
                  ? this.state.profile.emgcontact1.relation
                  : ''}
              </p>
            </div>
            <div className="cp-contact2 profile-sep">
              <h4 className="cp-title">Emergency Contact #2</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact2
                  ? this.state.profile.emgcontact2.fname +
                    ' ' +
                    this.state.profile.emgcontact2.lname
                  : ''}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact2 ? (
                  // formatPhoneNumber(this.state.profile.emgcontact2.phone)
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.profile.emgcontact2.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.profile.emgcontact2.phone)}
                  </a>
                ) : (
                  ''
                )}
              </p>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.emgcontact2
                  ? this.state.profile.emgcontact2.relation
                  : ''}
              </p>
            </div>
            <div className="cp-drivers-license profile-sep">
              <h4 className="cp-title">Drivers License</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.drivers_license
                  ? this.state.profile.drivers_license
                  : ''}
              </p>
            </div>
            {/*<div className="cp-experience profile-sep">
                                    <h4 className="cp-title">Experience</h4><p className="crew-profile-detail">More than 1 season</p>
                            </div>*/}
            <div className="cp-weight profile-sep">
              <h4 className="cp-title">Weight (lbs)</h4>
              <p className="crew-profile-detail">
                {this.state.profile && this.state.profile.weight
                  ? this.state.profile.weight
                  : ''}
              </p>
            </div>
          </div>
          <div className="profile3 col-sm-4">
            {this.state.permList.includes('Manage Docs') &&
              this.state.profile.docs_status === 3 &&
              this.state.profile.userattribute !== null && (
                <section>
                  <h4 className="cp-title">Verified Documents</h4>
                  <form
                    className="verified_documents"
                    onChange={(e) => this.handleCheck(e)}
                  >
                    <ul>
                      <li>
                        <label
                          htmlFor="drug_screening_doc"
                          className="noselect disabled"
                        >
                          <input
                            type="checkbox"
                            name="drug_screening_doc"
                            id="drug_screening_doc"
                            disabled
                            checked={
                              this.state.profile.userattribute
                                .drug_screening_doc === 1
                            }
                          />
                          Drug Screening
                        </label>
                      </li>
                      <li>
                        <label htmlFor="i9_doc" className="noselect disabled">
                          <input
                            type="checkbox"
                            name="i9_doc"
                            id="i9_doc"
                            disabled
                            checked={
                              this.state.profile.userattribute.i9_doc === 1
                            }
                          />
                          ID/I9
                        </label>
                      </li>
                      <li>
                        <label
                          htmlFor="onboarding_doc"
                          className="noselect disabled"
                        >
                          <input
                            type="checkbox"
                            name="onboarding_doc"
                            id="onboarding_doc"
                            disabled
                            checked={
                              this.state.profile.userattribute
                                .onboarding_doc === 1
                            }
                          />
                          Onboarding Documents
                        </label>
                      </li>
                    </ul>
                  </form>
                </section>
              )}

            {/*<div className="single-notes-cp" placeholder="Notes..." data-hash="af0e78de0e404f0eb792126874855d56" autoComplete="off">C3. Not available until future notice Per Duane-- BS 10/1. Missed call BS 10/31.&nbsp;</div>*/}
            {permList.includes('Employee Notes') && (
              <section>
                <textarea
                  name="notes"
                  className="single-notes-cp m-0"
                  placeholder="Notes..."
                  value={this.state.notes || ''}
                  rows="3"
                  cols="20"
                  onChange={this.handleChange}
                >
                  {this.state.notes}
                </textarea>
                <p>
                  <button
                    disabled={this.state?.savingNote}
                    className="btn btn-xs button"
                    onClick={this.updateNotes.bind(this, this.state.hash)}
                  >
                    Save Note
                  </button>
                </p>
              </section>
            )}

            {this.state.inventory_list && (
              <div className="inventory_table">
                {this.state.inventory_list}
                <div className="inv_item total_cost">
                  <div className="inv_cell">
                    {/*Total<span className="cost">{"$"+this.state.total_cost}</span>*/}
                    Total
                    <NumberFormat
                      className="cost"
                      value={this.state.total_cost}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                  <p className="disclaimer">
                    Employee is responsible for the condition, care, and
                    replacement value of issued inventory. Issued Inventory not
                    returned, lost or stolen will be the reasonability of the
                    employee. If you do not see the cost above of your current
                    issued equipment, please contact your supervisor.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
      // }
    }
    return (
      <div>
        <div className="content_div settings">
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ''}
          >
            <img
              alt="Loader"
              src={require('../assets/images/loader.gif').default}
            />
          </center>
        </div>
        {/* uers upate success modal */}
        <ReactModal
          isOpen={this.state.alertOpen}
          onClose={this.alertCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="alertPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">{this.state.alertHead}</h4>
              {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
            </div>
            <div className="modal-body">
              <p>{this.state.alertMsg}</p>
              <div className="btn_list">
                <button
                  className="button"
                  onClick={this.alertCloseModal.bind(this)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { permission, inventory } = state;
  return {
    registering,
    permission,
    inventory,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };
